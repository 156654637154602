import React, { ReactElement, ReactText } from 'react';

interface LabelValueProps {
  label: string;
  value: ReactText;
}

const LabelValue = ({ label, value }: LabelValueProps): ReactElement => {
  return (
    <>
      <strong>{label}</strong>
      <p>{value}</p>
    </>
  );
};

export default LabelValue;
