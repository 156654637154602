import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import RutInput from '../form/input/RutInput';
import { SelectOption } from '../../models/form/Select';
import { AdministradorForm } from '../../models/administradores/Administrador';
import FormButtons from '../form/form-buttons/FormButtons';
import Sede from '../../models/sedes/Sede';
import { validateRut } from '../../utils/rut';
import { validateEmail } from '../../utils/email';

interface AdministradorFormComponentProps {
  formState: FormState<AdministradorForm>;
  submitForm: (administradorForm: AdministradorForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  sedesOptions: SelectOption<Sede>[];
}

const AdministradorFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  sedesOptions,
}: AdministradorFormComponentProps): ReactElement => {
  const {
    value: administradorForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid =
    !administradorForm.nombre ||
    !administradorForm.apellidos ||
    !validateEmail(administradorForm.email) ||
    !validateRut(administradorForm.rut) ||
    !administradorForm.sede;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(administradorForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={administradorForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !administradorForm.nombre}
              invalidText="Debe ingresar el nombre del administrador"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={administradorForm.apellidos}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !administradorForm.apellidos}
              invalidText="Debe ingresar el o los apellidos del coordinador"
            />
          </Col>
          <Col xs={12} md={6}>
            <RutInput
              title="RUT"
              name="rut"
              placeholder="RUT"
              value={administradorForm.rut}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !validateRut(administradorForm.rut)}
              invalidText="Rut Invalido"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Email"
              name="email"
              placeholder="Email"
              value={administradorForm.email}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !validateEmail(administradorForm.email)}
              invalidText="Debe ingresar el email"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Dirección"
              name="direccion"
              placeholder="Dirección"
              value={administradorForm.direccion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={administradorForm.telefono}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Sede"
              name="sede"
              placeholder="Seleccione una sede"
              value={administradorForm.sede}
              options={sedesOptions}
              onSelectWithName={handleFieldChange}
              invalid={areValidationsEnabled && !administradorForm.sede}
              invalidText="Debe seleccionar la sede"
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el administrador?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar administrador"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar administrador"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default AdministradorFormComponent;
