import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import { Card, Button, Form, Spinner } from 'react-bootstrap';
import { Input } from '../../components/form/input/Input';
import { requestPasswordReset } from '../../api/usuarios/usuariosApi';
import ucnLogo from '../../assets/images/ucn.png';
import useFormState from '../../hooks/useFormState';
import { useHistory } from 'react-router';

const ResetPasswordRequestPage = (): ReactElement => {
  const {
    value,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
  } = useFormState({
    email: '',
  });

  const [hasTheRequestBeenSent, setHasTheRequestBeenSent] = useState(false);

  const { email } = value;

  const history = useHistory();

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (!email) return;

    setSubmitting(true);

    requestPasswordReset(email)
      .then(() => {
        setHasTheRequestBeenSent(true);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const renderForm = (): ReactElement => (
    <Form>
      <Input
        title="Ingrese su correo electrónico y le enviaremos las instrucciones para reiniciar su contraseña."
        name="email"
        value={email}
        type="text"
        onChangeWithName={handleFieldChange}
        invalid={areValidationsEnabled && !email}
        invalidText="Debe ingresar su correo"
        invalidTextColor="#9a0024"
        disabled={isSubmitting}
      />
      <Button
        type="submit"
        onClick={handleSubmitClick}
        className="w-100 mt-4"
        variant="outline-light"
        disabled={isSubmitting}>
        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Enviar'}
      </Button>
    </Form>
  );

  const renderSuccessMessage = (): ReactElement => (
    <p className="text-center">
      Solicitud enviada. Si su correo está registrado en el sistema recibirá las instrucciones para
      reiniciar su contraseña.
    </p>
  );

  return (
    <div className="login-container">
      <div className="background" />
      <div className="head d-flex">
        <div className="text-center m-auto d-flex clickable" onClick={() => history.push('/login')}>
          <h4 className="text-center my-auto mr-2 text-dark clickable">ProVETA</h4>
          <img className="mx-auto" width={50} height={50} src={ucnLogo} alt="Logo UCN" />
        </div>
      </div>
      <Card className="m-auto">
        <Card.Body>
          <h4 className="text-center mb-4 text-white">Reiniciar contraseña</h4>
          <hr />
          {hasTheRequestBeenSent ? renderSuccessMessage() : renderForm()}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResetPasswordRequestPage;
