import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import useSedesOptions from '../../hooks/useSedesOptions';
import useNivelesOptions from '../../hooks/useNivelesOptions';
import CursosFilters from '../../models/cursos/CursosFilters';
import CursosFiltersComponent from '../../components/cursos/CursosFiltersComponent';
import CursosList from '../../components/cursos/CursosList';
import NewCursoButton from '../../components/cursos/NewCursoButton';
import ClosedEntitiesToggleButton from '../../components/cursos/ClosedCursosToggleButton';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';
import AuthContext from '../../contexts/AuthContext';
import CursosEstudianteList from '../../components/cursos/CursosEstudianteList';
import ucnLogo from '../../assets/images/ucn.png';

const HomePage = (): ReactElement => {
  const breadcrumb = useBreadcrumb();

  const { jwtData, userRoles } = useContext(AuthContext);

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Home',
        active: true,
      },
    ]);
  }, [breadcrumb]);

  return (
    <div className="mt-3">
      <div className="background" />
      <Card className="m-auto">
        <Card.Header>
        <div className="head d-flex">
        <div className="text-center m-auto d-flex">
          <h4 className="text-center my-auto mr-2">ProVETA</h4>
          <img className="mx-auto" width={50} height={50} src={ucnLogo} alt="Logo UCN" />
        </div>
      </div>
      </Card.Header>
      <Card.Body>
          <h4 className="text-center mb-4">Bienvenido a ProVETA</h4>
          <h6 className="text-center mb-4">Puedes acceder a cada opción a través del menú principal o la barra superior.</h6>
        </Card.Body>
      </Card>
    </div>
  );
};

export default HomePage;