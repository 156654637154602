import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { getParaleloEstudianteDetail } from '../../api/estudiantes/estudiantesApi';
import { ExtendedParaleloEstudiante } from '../../models/estudiantes/Estudiante';
import cogoToast from 'cogo-toast';
import EstudianteParaleloNotas from '../../components/estudiantes/EstudianteParaleloNotas';
import { Asistencia } from '../../models/sesiones/Sesion';
import LabelValueTwo from '../../components/LabelValueTwo';

const ParaleloPage = (): ReactElement => {

  const { paraleloId, estudianteId } = useParams<{ estudianteId: string, paraleloId: string }>();

  const [ paralelo, setParalelo ] = useState<ExtendedParaleloEstudiante>();

  const breadCrumb = useBreadcrumb();

  useEffect(() => {
    getParaleloEstudianteDetail(paraleloId, estudianteId)
    .then((response) => {
      setParalelo(response);
    })
    .catch(() => {
      cogoToast.error('No se pudieron cargar los paralelos del estudiante');
    });
}, [paraleloId, estudianteId, setParalelo]);

  useEffect(() => {
    breadCrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Cursos',
      },
      {
        label: paralelo?.curso.nombre || 'Ver curso',
      },
      {
        label: `Paralelo ${paralelo?.correlativo !== undefined ? paralelo.correlativo : ''}`,
      },
    ]);
  }, [breadCrumb, paralelo]);

  const contarPresentes = (tipoAsistencia: string, asistencia?: Asistencia[]) => {

    let sum = 0;

    asistencia?.map((iterador)=>{
      if(iterador.estado == tipoAsistencia){
        sum = sum + 1;
      }
    });
    return sum;

  };

  const paraleloAsistencia = (cantAsistenciaPresente: number, asistencia?: Asistencia[]): number => {
    let largo = 1;

    if(asistencia){
      largo = asistencia.length;
    }
    return (cantAsistenciaPresente / largo)*100;
  }

  if (!paralelo) {
    return <p className="text-center">No se puede obtener información de este paralelo</p>;
  }

  return (
    <> 
      <Card className="mt-3">
        <Card.Header className="d-flex">
          <h4 className="m-0">Notas</h4>
          <div className="mr-0 ml-auto">
          </div>
        </Card.Header>
        <Card.Body>
          <EstudianteParaleloNotas paralelo={paralelo.paralelo} />
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header className="d-flex">
          <h4 className="m-0">Asistencia</h4>
          <div className="mr-0 ml-auto">
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} lg={3}>
            <LabelValueTwo label={`Asistencia Actual`} value={paraleloAsistencia(contarPresentes("Presente", paralelo.asistencias), paralelo.asistencias) + "%"} />
            </Col>
            <Col xs={6} lg={3}>
            <LabelValueTwo label={`Presentes Totales`} value={contarPresentes("Presente", paralelo.asistencias)} />
            </Col>
            <Col xs={6} lg={3}>
            <LabelValueTwo label={`Justificaciones Totales`} value={contarPresentes("Justificado", paralelo.asistencias)} />
            </Col>
            <Col xs={6} lg={3}>
            <LabelValueTwo label={`Faltas Totales`} value={contarPresentes("Ausente", paralelo.asistencias)} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ParaleloPage;
