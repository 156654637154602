import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { ExtendedCurso } from '../models/cursos/Curso';
import { getCursoById } from '../api/cursos/cursosApi';

interface UseCursoHookValue {
  curso?: ExtendedCurso;
  setCurso: Dispatch<SetStateAction<ExtendedCurso | undefined>>;
  isLoading: boolean;
}

const useCurso = (cursoId: string): UseCursoHookValue => {
  const [curso, setCurso] = useState<ExtendedCurso>();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getCursoById(cursoId)
      .then((responseCurso) => {
        setCurso(responseCurso);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [cursoId, setCurso, setLoading]);

  return {
    curso,
    setCurso,
    isLoading,
  };
};

export default useCurso;
