import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import RutInput from '../form/input/RutInput';
import { SelectOption } from '../../models/form/Select';
import { TutorForm } from '../../models/tutores/Tutor';
import FormButtons from '../form/form-buttons/FormButtons';
import Sede from '../../models/sedes/Sede';
import BasePrograma from '../../models/programas/Programa';
import getCarrerasSelectGroups from '../../resources/carreras';
import NumberInput from '../form/input/NumberInput';
import { validateRut } from '../../utils/rut';
import { validateEmail } from '../../utils/email';

interface TutorFormComponentProps {
  formState: FormState<TutorForm>;
  submitForm: (tutorForm: TutorForm) => void;
  onDeactivation?: () => void;
  onDelete?: () => void;
  onCancel: () => void;
  sedesOptions: SelectOption<Sede>[];
  programasOptions: SelectOption<BasePrograma>[];
}

const TutorFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  onDeactivation,
  sedesOptions,
  programasOptions,
}: TutorFormComponentProps): ReactElement => {
  const {
    value: tutorForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    isConfirmingDeactivation,
    setConfirmingDelete,
    setConfirmingDeactivation,
  } = formState;

  const carrerasSelectGroups = getCarrerasSelectGroups();

  const handleCarreraChange = (fieldName: string, value: SelectOption<string>): void => {
    const carrera = carrerasSelectGroups.find((sedeGroup) => sedeGroup.options.includes(value));

    if (carrera) handleFieldChange('carrera', carrera.label);

    handleFieldChange(fieldName, value);
  };

  const isFormInvalid =
    !tutorForm.nombre ||
    !validateEmail(tutorForm.email) ||
    !validateRut(tutorForm.rut) ||
    !tutorForm.sede ||
    !tutorForm.programa;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(tutorForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);
  const handleDeactivationClick = () => setConfirmingDeactivation(true);
  const cancelDelete = () => setConfirmingDelete(false);
  const cancelDeactivation = () => setConfirmingDeactivation(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={tutorForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !tutorForm.nombre}
              invalidText="Debe ingresar el nombre del tutor"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={tutorForm.apellidos}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <RutInput
              title="Rut"
              name="rut"
              placeholder="Rut"
              value={tutorForm.rut}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !validateRut(tutorForm.rut)}
              invalidText="Rut Inválido"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Email"
              name="email"
              placeholder="Email"
              value={tutorForm.email}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !validateEmail(tutorForm.email)}
              invalidText="Email requerido"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Dirección"
              name="direccion"
              placeholder="Dirección"
              value={tutorForm.direccion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={tutorForm.telefono}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <NumberInput
              title="Horas asignadas"
              name="horasAsignadas"
              placeholder="Horas asignadas"
              value={tutorForm.horasAsignadas}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Carrera"
              name="carrera"
              placeholder="Seleccione una carrera"
              value={tutorForm.carrera}
              options={carrerasSelectGroups}
              onSelectWithName={handleCarreraChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <NumberInput
              title="Semestre"
              name="semestre"
              placeholder="Semestre"
              value={tutorForm.semestre}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Programa"
              name="programa"
              placeholder="Seleccione un programa"
              value={tutorForm.programa}
              options={programasOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalidText="Debe seleccionar el programa"
              invalid={areValidationsEnabled && !tutorForm.programa}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Sede"
              name="sede"
              placeholder="Seleccione una sede"
              value={tutorForm.sede}
              options={sedesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalidText="Debe seleccionar la sede"
              invalid={areValidationsEnabled && !tutorForm.sede}
            />
          </Col>
        </Row>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el tutor?
          </h6>
        )}
        {isConfirmingDeactivation && (
          <h6 className='text-center'>
            ¿Está segura/o de que desea <span className='text-danger font-weight-bold'>{tutorForm.isActive? 'Desactivar': 'Activar'}</span>{' '}
            el tutor?
          </h6>
        )}
        <FormButtons
          deactivateButtonContent={tutorForm.isActive? 'Desactivar': 'Activar'}
          onCancelDeactivation={cancelDeactivation}
          onDeactivateClick={onDeactivation ? handleDeactivationClick : undefined }
          onDeactivateConfirm={onDeactivation}
          isConfirmingDeactivation={isConfirmingDeactivation}
          showDeactivationButton
          submitButtonContent="Guardar tutor"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar tutor"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default TutorFormComponent;
