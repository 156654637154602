import React, { ReactElement, useContext } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { Link } from 'react-router-dom';
import { Input } from '../../components/form/input/Input';
import Credentials from '../../models/usuarios/Credentials';
import ucnLogo from '../../assets/images/ucn.png';
import AuthContext from '../../contexts/AuthContext';
import useFormState from '../../hooks/useFormState';
import { login } from '../../api/usuarios/usuariosApi';

const LoginPage = (): ReactElement => {
  const {
    value: credentials,
    handleFieldChange,
    isSubmitting,
    areValidationsEnabled,
    setAreValidationsEnabled,
    setSubmitting,
  } = useFormState<Credentials>({ email: '', password: '' });

  const { email, password } = credentials;

  const { setJWT } = useContext(AuthContext);

  const history = useHistory();

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (!email || !password) return;

    setSubmitting(true);

    login(credentials)
      .then((response) => {
        const jwt = response.data;

        setJWT(jwt);

        history.replace('/');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        setSubmitting(false);
      });
  };

  return (
    <div className="login-container">
      <div className="background" />
      <div className="head d-flex">
        <div className="text-center m-auto d-flex">
          <h4 className="text-center my-auto mr-2 text-dark">ProVETA</h4>
          <img className="mx-auto" width={50} height={50} src={ucnLogo} alt="Logo UCN" />
        </div>
      </div>
      <Card className="m-auto">
        <Card.Body>
          <h4 className="text-center mb-4 text-white">Iniciar sesión</h4>
          <hr />
          <Form>
            <Input
              title="Correo electrónico"
              name="email"
              value={email}
              type="text"
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !email}
              invalidText="Debe ingresar su correo"
              invalidTextColor="#9a0024"
              disabled={isSubmitting}
            />
            <Input
              title="Contraseña"
              name="password"
              value={password}
              type="password"
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !password}
              invalidText="Debe ingresar su contraseña"
              invalidTextColor="#9a0024"
              disabled={isSubmitting}
            />
            <div className="text-center">
              <p className="mb-0">¿Olvidó su contraseña?</p>
              <Link className="text-white" to="/reiniciar-clave">
                haga click aquí
              </Link>
            </div>
            <Button
              type="submit"
              onClick={handleSubmitClick}
              className="w-100 mt-4"
              variant="outline-light"
              disabled={isSubmitting}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Ingresar'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LoginPage;
