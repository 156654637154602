import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import AdministradorInfo from '../../components/administradores/AdministradorInfo';
import { getAdministrador } from '../../api/administradores/administradoresApi';
import { Administrador } from '../../models/administradores/Administrador';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';

const AdministradorPage = (): ReactElement => {
  const [administrador, setAdministrador] = useState<Administrador>();

  const { administradorId } = useParams<{ administradorId: string }>();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    getAdministrador(administradorId)
      .then((response): void => {
        setAdministrador(response);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del administrador');
      });
  }, [administradorId, setAdministrador]);

  useEffect(() => {
    if (administrador) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Usuarios',
          href: '/usuarios',
        },
        {
          label: administrador.nombre,
          active: true,
        },
      ]);
    }
  }, [administrador, breadcrumb]);

  if (!administrador)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleAdministradorChange = (changedAdministrador: Administrador): void => setAdministrador(changedAdministrador);

  return (
    <>
      <Card>
        <Card.Body>
          <AdministradorInfo administrador={administrador} onAdministradorChange={handleAdministradorChange} />
        </Card.Body>
      </Card>
      <EntityDocumentsCard entityTypeEndpoint="administradores" entityId={administrador.id} />
    </>
  );
};

export default AdministradorPage;
