import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getAnios, getMeses } from '../api/movimientos/fechaMovimientosApi';
import Meses from '../models/movimientos/Meses';

const useMesesOptions = (): SelectOption<Meses>[] => {
  const [mesesOptions, setMesesOptions] = useState<
    SelectOption<Meses>[]
  >([]);

  useEffect((): void => {
    getMeses()
      .then((response): void => {
        setMesesOptions(
          response.data.map((tipo) => {
            return { label: tipo.nombre, value: tipo };
          }),
        );
      })
      .catch(() => {});
  }, [setMesesOptions]);

  return mesesOptions;
};

export default useMesesOptions;
