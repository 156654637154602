import { SelectGroup, SelectOption } from '../models/form/Select';
import { BaseMovimiento, MovimientoForm, MovimientoRequestBody } from '../models/movimientos/Movimiento';
import TipoMovimiento from '../models/movimientos/TipoMovimiento';
  
  export const getEmptyMovimientoForm = (): MovimientoForm => ({
    monto: '',
    motivo: '',
  });
  
  export const formatMovimientoPostBody = (
    movimientoForm: MovimientoForm,
  ): MovimientoRequestBody => {
    const {
      persona,
      presupuesto,
      tipo,
      monto,
      motivo,
    } = movimientoForm;
    
    let tipoId;
    
    if(movimientoForm.tipo){
      
      tipoId = movimientoForm.tipo.value.id;

    }

  
    return {
      monto: monto,
      motivo: motivo,
      personaId: persona ? persona.personaId : undefined,
      presupuestoId: presupuesto ? presupuesto.id : undefined,
      tipoId: tipo ? tipo.value.id : undefined,
    };
  };
  
  export const formatMovimientoForm = (
    movimiento: BaseMovimiento,
    tipoMovimientoOptions: SelectOption<TipoMovimiento>[],
  ): MovimientoForm => {
    const {
      monto = '',
      motivo = '',
    } = movimiento;
  
    const tipoMovimientoSelectOption = tipoMovimientoOptions.find(
      (option) => option.value.id === movimiento.tipo?.id,
    );
  
    return {
      monto,
      motivo,
      tipo: tipoMovimientoSelectOption,
    };
  };