import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import CarrerasFilters from '../../models/carreras/CarreraFilters';
import { Carrera } from '../../models/carreras/Carrera';
import { getCarreras } from '../../api/carreras/carrerasApi';
import Table, { TableColumn } from '../table/Table';
import { formatRut } from '../../utils/rut';

interface CarrerasListProps {
  carrerasfilters: CarrerasFilters;
  setCarrerasFilters: Dispatch<SetStateAction<CarrerasFilters>>;
}

const CarreraList = ({
  carrerasfilters,
  setCarrerasFilters,
}: CarrerasListProps): ReactElement => {
  const [carreras, setCarreras] = useState<Carrera[]>();

  const [isLoading, setLoading] = useState(true);

  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getCarreras(carrerasfilters)
      .then((response): void => {
        setCarreras(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los carreras.');
      })
      .finally(() => setLoading(false));
  }, [carrerasfilters, setCarreras]);

  const handleCarreraClick = (carrera: Carrera): void => {
    history.push(`/carrera/${carrera.id}`);
  };

  const tableColumns: TableColumn<Carrera>[] = [
    {
      title: 'Nombre',
      accessor: 'nombre',
      
    },
  ];

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!carreras)
    return <p className="text-center">No se pudo cargar el listado de carreras</p>;

  return (
    <>
      <Table
        items={carreras}
        columns={tableColumns}
        hover
        onItemClick={handleCarreraClick}
        minRowsCount={carrerasfilters.itemsPerPage}
        pages={pages}
        activePage={carrerasfilters.page}
        onPageSelect={(page): void =>
          setCarrerasFilters({ ...carrerasfilters, page })
        }
      />
      
    </>
  );
};
export default CarreraList;
