import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ProfesoresFilters from '../../models/profesores/ProfesoresFilters';
import ProfesoresFiltersComponent from '../../components/profesores/ProfesoresFiltersComponent';
import ProfesorList from '../../components/profesores/ProfesoresList';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import NewProfesorButton from '../../components/profesores/NewProfesorButton';
import useSedesOptions from '../../hooks/useSedes';
import useProgramasOptions from '../../hooks/usePrograma';
import ClosedEntitiesToggleButton from '../../components/cursos/ClosedCursosToggleButton';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';

const ProfesoresPage = (): ReactElement => {
  const [profesoresfilters, setProfesoresFilters] = useState<ProfesoresFilters>({
    nombreORut: '',
    page: 1,
    itemsPerPage: 8,
  });

  const sedesOptions = useSedesOptions();
  const programasOptions = useProgramasOptions();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Profesores',
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
    <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador, Rol.Profesor, Rol.Tutor]}>
      <Card className="mb-3">
        <Card.Body>
          <ProfesoresFiltersComponent
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            profesoresFilters={profesoresfilters}
            onFiltersChange={setProfesoresFilters}
          />
          <div className="text-right">
            <ClosedEntitiesToggleButton
              areInactivosEnabled={profesoresfilters.includeInactive}
              setInactivosEnabled={(newValue) =>
                setProfesoresFilters((prevState) => ({ ...prevState, includeInactive: newValue }))
              }
            />
            <NewProfesorButton programasOptions={programasOptions} sedesOptions={sedesOptions}/>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ProfesorList
            setProfesoresFilters={setProfesoresFilters}
            profesoresfilters={profesoresfilters}
          />
        </Card.Body>
      </Card>
      </RolProtectedElement>
    </>
  );
};

export default ProfesoresPage;
