import MaterialInventarioForm from '../models/inventario/MaterialInventarioForm';
import {
  MaterialInventario,
  MaterialInventarioRequestBody,
} from '../models/inventario/MaterialInventario';
import { SelectOption } from '../models/form/Select';
import Sede from '../models/sedes/Sede';
import BasePrograma from '../models/programas/Programa';

export function getEmptyMaterialInventarioForm(): MaterialInventarioForm {
  return {
    nombre: '',
    cantidad: '',
    lugarFisico: '',
    descripcion: '',
  };
}

export function formatMaterialInventarioForm(
  materialInventario: MaterialInventario,
  sedesOptions: SelectOption<Sede>[],
  programasOptions: SelectOption<BasePrograma>[],
): MaterialInventarioForm {
  const { cantidad, sede, programa, nombre, lugarFisico, descripcion } = materialInventario;

  return {
    nombre,
    sede: sedesOptions.find((theSede) => theSede.value.id === sede.id),
    programa: programasOptions.find((thePrograma) => thePrograma.value.id === programa.id),
    lugarFisico: lugarFisico || '',
    descripcion: descripcion || '',
    cantidad: cantidad.toString(),
  };
}

export function formatMaterialInventarioRequestBody(
  materialInventarioForm: MaterialInventarioForm,
): MaterialInventarioRequestBody {
  const { nombre, sede, programa, descripcion, lugarFisico, cantidad } = materialInventarioForm;

  if (!sede) throw new Error("Value 'sede' must not be empty.");
  
  if (!programa) throw new Error("Value 'programa' must not be empty.");

  return {
    nombre,
    sedeId: sede.value.id,
    programaId: programa.value.id,
    descripcion,
    cantidad: Number(cantidad),
    lugarFisico,
  };
}
