import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import RutInput from '../form/input/RutInput';
import { SelectOption } from '../../models/form/Select';
import { CoordinadorForm } from '../../models/coordinadores/Coordinador';
import FormButtons from '../form/form-buttons/FormButtons';
import Sede from '../../models/sedes/Sede';
import { validateRut } from '../../utils/rut';
import { validateEmail } from '../../utils/email';

interface CoordinadorFormComponentProps {
  formState: FormState<CoordinadorForm>;
  submitForm: (coordinadorForm: CoordinadorForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  sedesOptions: SelectOption<Sede>[];
}

const CoordinadorFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  sedesOptions,
}: CoordinadorFormComponentProps): ReactElement => {
  const {
    value: coordinadorForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid =
    !coordinadorForm.nombre ||
    !coordinadorForm.apellidos ||
    !validateEmail(coordinadorForm.email) ||
    !validateRut(coordinadorForm.rut) ||
    !coordinadorForm.sede;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(coordinadorForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={coordinadorForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !coordinadorForm.nombre}
              invalidText="Debe ingresar el nombre del coordinador"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={coordinadorForm.apellidos}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !coordinadorForm.apellidos}
              invalidText="Debe ingresar el o los apellidos del coordinador"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <RutInput
              title="RUT"
              name="rut"
              placeholder="RUT"
              value={coordinadorForm.rut}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !validateRut(coordinadorForm.rut)}
              invalidText="Rut Invalido"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Email"
              name="email"
              placeholder="Email"
              value={coordinadorForm.email}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !validateEmail(coordinadorForm.email)}
              invalidText="Debe ingresar el email"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Dirección"
              name="direccion"
              placeholder="Dirección"
              value={coordinadorForm.direccion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={coordinadorForm.telefono}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Sede"
              name="sede"
              placeholder="Seleccione una sede"
              value={coordinadorForm.sede}
              options={sedesOptions}
              onSelectWithName={handleFieldChange}
              invalid={areValidationsEnabled && !coordinadorForm.sede}
              invalidText="Debe seleccionar la sede"
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el coordinador?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar coordinador"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar coordinador"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default CoordinadorFormComponent;
