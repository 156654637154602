import React, { ReactElement, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import useEstudiantesOptions from '../../hooks/useEstudiantesOptions';
import { BaseEstudiante } from '../../models/estudiantes/Estudiante';
import { SelectOption } from '../../models/form/Select';
import { ExtendedParalelo } from '../../models/paralelo/Paralelo';
import { formatRut } from '../../utils/rut';
import Select from '../form/select/Select';
import Table, { TableColumn } from '../table/Table';

interface AddParaleloEstudiantesFormProps {
  paralelo: ExtendedParalelo;
  submit: (estudiantes: BaseEstudiante[]) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const ParaleloEstudiantesForm = ({paralelo, submit, onCancel, isLoading}: AddParaleloEstudiantesFormProps): ReactElement => {
  const [estudiantes, setEstudiantes] = useState<BaseEstudiante[]>(paralelo.estudiantes);
  const [isConfirming, setConfirming] = useState(false);

  const estudiantesOptions = useEstudiantesOptions(paralelo.curso.programa.id);

  const notAddedEstudiantesOptions = estudiantesOptions.filter((estudianteOption) => (
    ![...estudiantes, ...paralelo.estudiantes].some((estudiante) => estudiante.id === estudianteOption.value.id)
  ));

  const addEstudiante = (estudianteOption: SelectOption<BaseEstudiante>): void => {
    setEstudiantes([...estudiantes, estudianteOption.value]);
  };

  const removeEstudiante = (estudiante: BaseEstudiante): void => {
    setEstudiantes(estudiantes.filter((addedEstudiante) => addedEstudiante !== estudiante));
  };

  const handleSubmitClick = (): void => {
    setConfirming(true);
  };

  const handleConfirm = (): void => {
    submit(estudiantes);
  }

  const cancelSubmit = (): void => {
    setConfirming(false);
  };

  const columns: TableColumn<BaseEstudiante>[] = [
    {
      title: 'Nombre',
      accessor: (estudiante) => {
        return `${estudiante.nombre} ${estudiante.apellidos}`
      }
    },
    {
      title: 'RUT',
      accessor: ({rut}) => rut ? formatRut(rut) : '',
    },
    {
      title: 'Establecimiento',
      accessor: ({establecimiento}) => establecimiento?.nombre || '',
    },
    {
      accessor: (estudiante) => (
        <Button variant="danger" size="sm" onClick={(): void => removeEstudiante(estudiante)}><i className="mdi mdi-close" /></Button>
      ),
    },
  ];

  if (isConfirming) {
    let estudiantesCountToBeAdded = 0;
    let estudiantesCountToBeDeleted = 0;

    estudiantes.forEach((estudiante) => {
      if (!paralelo.estudiantes.includes(estudiante)) {
        estudiantesCountToBeAdded += 1;
      }
    });

    paralelo.estudiantes.forEach((estudiante) => {
      if (!estudiantes.includes(estudiante)) {
        estudiantesCountToBeDeleted += 1;
      }
    });

    return (
      <div className="text-center">
        <p>¿Está segura/o de que desea actualizar la lista de estudiantes?</p>
        {!!estudiantesCountToBeDeleted && (
          <p className="text-danger"><b>Se eliminarán {estudiantesCountToBeDeleted} estudiantes</b></p>
        )}
        {!!estudiantesCountToBeAdded && (
          <p className="text-success"><b>Se agregarán {estudiantesCountToBeAdded} estudiantes</b></p>
        )}
        <div className="text-center">
          <Button className="mr-2" variant="danger" onClick={cancelSubmit}>No</Button>
          <Button 
          variant="success"
          onClick={handleConfirm}>
            {isLoading ? <Spinner animation="border" /> : 'Sí. Actualizar'}
        </Button>
        </div>
      </div>
    )
  }

  return (
    <Form>
      <Select
        title="Agregar estudiante"
        name="estudiantes-dropdown"
        options={notAddedEstudiantesOptions}
        onSelect={addEstudiante}
        disabled={isLoading}
        applyFuzzySearch
      />

      <Table columns={columns} items={estudiantes}/>

      <div className="text-right">
        <Button
        className="mr-2"
        variant="secondary"
        onClick={onCancel}>
          Cancelar
        </Button>
        <Button 
          variant="success"
          onClick={handleSubmitClick}>
          Actualizar lista
        </Button>
      </div>
    </Form>
  )
}

export default ParaleloEstudiantesForm;
