import React, { ReactElement } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import { SelectOption } from '../../models/form/Select';
import { InstitucionForm } from '../../models/instituciones/Institucion';
import TipoInstitucion from '../../models/instituciones/TipoInstitucion';
import FormButtons from '../form/form-buttons/FormButtons';
import Region from '../../models/regiones/Region';

interface InstitucionFormComponentProps {
  formState: FormState<InstitucionForm>;
  submitForm: (institucionForm: InstitucionForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  regionesOptions: SelectOption<Region>[];
  tiposInstitucionOptions: SelectOption<TipoInstitucion>[];
}

const InstitucionFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  tiposInstitucionOptions,
  regionesOptions,
}: InstitucionFormComponentProps): ReactElement => {
  const {
    value: institucionForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !institucionForm.nombre;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(institucionForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={institucionForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !institucionForm.nombre}
              invalidText="Debe ingresar el nombre de la institución"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Región"
              name="region"
              placeholder="Seleccione una región"
              value={institucionForm.region}
              options={regionesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Tipo de institución"
              name="tipoInstitucion"
              placeholder="Seleccione un tipo"
              value={institucionForm.tipoInstitucion}
              options={tiposInstitucionOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        <hr />
        <hr />
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> la institución?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar Institución"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar Institución"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default InstitucionFormComponent;
