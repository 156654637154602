import {
  ExtendedParalelo,
  NotasEstudiante,
  NotasEstudianteForm,
  ParaleloForm,
  ParaleloPostBody,
  ParaleloPutBody,
  UpdateParaleloNotasBody,
} from '../models/paralelo/Paralelo';
import { SelectOption } from '../models/form/Select';
import { BaseProfesor } from '../models/profesores/Profesor';

export function getEmptyParaleloForm(): ParaleloForm {
  return {};
}

export function formatParaleloForm(
  paralelo: ExtendedParalelo,
  profesorOptions: SelectOption<BaseProfesor>[],
): ParaleloForm {
  const { profesor } = paralelo;

  return {
    profesor: profesorOptions.find((profesorOption) => profesorOption.value.id === profesor.id),
  };
}

export function formatParaleloPostBody(
  paraleloForm: ParaleloForm,
  cursoId: number,
): ParaleloPostBody {
  const { profesor } = paraleloForm;

  if (!profesor) {
    throw new Error('Invalid paralelo form.');
  }

  return {
    profesorId: profesor.value.id,
    cursoId,
  };
}

export function formatParaleloPutBody(paraleloForm: ParaleloForm): ParaleloPutBody {
  const { profesor } = paraleloForm;

  if (!profesor) {
    throw new Error('Invalid paralelo form.');
  }

  return {
    profesorId: profesor.value.id,
  };
}

export function formatNotasEstudiantesForms(notasEstudiantes: NotasEstudiante[]): NotasEstudianteForm[] {
  return notasEstudiantes.map((notasEstudiante) => ({
    estudiante: notasEstudiante.estudiante,
    evaluaciones: notasEstudiante.evaluaciones.map(({evaluacion, nota}) => ({
      evaluacion,
      nota: nota?.toString() || '',
    })),
    promedioCalculado: notasEstudiante.promedioCalculado,
    promedioFinal: notasEstudiante.promedioFinal?.toString() || ''
  }))
}

export function formatUpdateParaleloNotasBody(notasEstudiantesForms: NotasEstudianteForm[]): UpdateParaleloNotasBody {
  const body: UpdateParaleloNotasBody = {
    paraleloNotas: [],
    paraleloPromedios: [],
  };

  notasEstudiantesForms.forEach((notasEstudianteForm) => {
    notasEstudianteForm.evaluaciones.forEach((notaEvaluacion) => {
      if (!notaEvaluacion) return;

      body.paraleloNotas.push({
        estudianteId: notasEstudianteForm.estudiante.id,
        evaluacionId: notaEvaluacion.evaluacion.id,
        nota: Number(notaEvaluacion.nota),
      });
    });

    if (notasEstudianteForm.promedioFinal) {
      body.paraleloPromedios.push({
        estudianteId: notasEstudianteForm.estudiante.id,
        promedio: Number(notasEstudianteForm.promedioFinal),
      })
    }
  });

  return body;
}
