import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { revokeInvitacion } from '../../api/invitaciones/invitacionesApi';
import Usuario from '../../models/usuarios/Usuario';

interface RevokeUserInvitationButtonProps {
  usuario: Usuario;
  onChange?: () => void;
  className?: string;
}

const RevokeUserInvitationButton = ({
  usuario,
  onChange,
  className,
}: RevokeUserInvitationButtonProps): ReactElement => {
  const [isRevoked, setIsRevoked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onClick = () => {
    setIsDisabled(true);
    const cleanedrut = revokeInvitacion({ rut: usuario.rut })
      .then(() => {
        setIsDisabled(false);
        setIsRevoked(true);
        cogoToast.success('Invitación revocada');
        onChange && onChange();
      })
      .catch((error) => cogoToast.error(error.message));
  };

  return (
    <>
      <Button
        className={className}
        variant="warning"
        size="sm"
        disabled={isRevoked || isDisabled}
        onClick={onClick}>
        Revocar Invitación
      </Button>
    </>
  );
};

export default RevokeUserInvitationButton;
