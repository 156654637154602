import { useEffect, useState } from 'react';
import TipoEstablecimiento from '../models/establecimientos/TipoEstablecimiento';
import { SelectOption } from '../models/form/Select';
import { getSedes } from '../api/sedes/sedesApi';

const useSedesOptions = (): SelectOption<TipoEstablecimiento>[] => {
  const [sedeOptions, setSedeOptions] = useState<
    SelectOption<TipoEstablecimiento>[]
  >([]);

  useEffect((): void => {
    getSedes()
      .then((response): void => {
        setSedeOptions(
          response.data.map((sede) => {
            return { label: sede.nombre, value: sede };
          }),
        );
      })
      .catch(() => {});
  }, [setSedeOptions]);

  return sedeOptions;
};

export default useSedesOptions;
