import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getProgramas } from '../api/programas/programasApi';
import BasePrograma from '../models/programas/Programa';

const useProgramasOptions = (): SelectOption<BasePrograma>[] => {
  const [programaOptions, setProgramaOptions] = useState<SelectOption<BasePrograma>[]>([]);

  useEffect((): void => {
    getProgramas()
      .then((response): void => {
        setProgramaOptions(
          response.data.map((programa) => {
            return { label: programa.nombre, value: programa };
          }),
        );
      })
      .catch(() => {});
  }, [setProgramaOptions]);

  return programaOptions;
};

export default useProgramasOptions;
