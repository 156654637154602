import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import NewAdministradorButton from '../../components/administradores/NewAdministradorButton';
import NewCoordinadorButton from '../../components/coordinadores/NewCoordinadorButton';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import UsuariosFiltersComponent from '../../components/usuarios/UsuariosFiltersComponent';
import UsuariosList from '../../components/usuarios/UsuariosList';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import useSedesOptions from '../../hooks/useSedes';
import Rol from '../../models/usuarios/Rol';
import UsersFilters from '../../models/usuarios/UsersFilters';

const ManageUsersPage = (): ReactElement => {
  const [usuariosFilters, setUsuariosFilters] = useState<UsersFilters>({
    itemsPerPage: 8,
    page: 1,
  });

  const sedesOptions = useSedesOptions();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Usuarios',
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <div className="d-flex">
            <div>
              <h4 className="float-left">
                <b>Lista de usuarios</b>
              </h4>
            </div>
            <div className="flex-grow-1" style={{ whiteSpace: 'nowrap', display: 'flex' }}>
              <div className="mt-0 mb-auto mr-0 ml-auto">
                <NewCoordinadorButton sedesOptions={sedesOptions} />
                <RolProtectedElement roles={[Rol.Administrador]}>
                  <NewAdministradorButton className="ml-2" sedesOptions={sedesOptions} />
                </RolProtectedElement>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <UsuariosFiltersComponent
            sedesOptions={sedesOptions}
            usuariosFilters={usuariosFilters}
            onFiltersChange={setUsuariosFilters}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <div className="text-right">
            <UsuariosList
              usuariosfilters={usuariosFilters}
              setUsuariosFilters={setUsuariosFilters}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ManageUsersPage;
