import React, { ReactElement } from 'react';
import { ExtendedParalelo, NotasEstudiante } from '../../models/paralelo/Paralelo';
import { formatRut } from '../../utils/rut';
import Table, { TableColumn } from '../table/Table';

interface ParaleloNotasProps {
  paralelo: ExtendedParalelo;
}

const ParaleloNotas = ({paralelo}: ParaleloNotasProps): ReactElement => {
  const { notasEstudiantes } = paralelo;

  if (notasEstudiantes.length === 0) {
    return <p className="text-center">No hay estudiantes.</p>
  }

  const columns: TableColumn<NotasEstudiante>[] = [
    {
      title: 'Estudiante',
      accessor: (notaEstudiante) => {
        const { rut, nombre, apellidos } = notaEstudiante.estudiante;

        return `${nombre} ${apellidos} ${formatRut(rut) || ''}`;
      }
    },
    ...notasEstudiantes[0].evaluaciones.map(({evaluacion}): TableColumn<NotasEstudiante> => {
      return {
        title: evaluacion.nombre,
        accessor: (notasEstudiante) => {
          const notaEvaluacion = notasEstudiante.evaluaciones.find((testNotaEvaluacion) => testNotaEvaluacion.evaluacion.id === evaluacion.id);
          
          return notaEvaluacion?.nota !== null ? notaEvaluacion?.nota || '-' : '-';
        },
      }
    }),
    {
      title: 'Promedio',
      accessor: ({promedioCalculado, promedioFinal}) => promedioFinal !== null ? promedioFinal : promedioCalculado,
    },
  ];

  

  return (
    <Table columns={columns} items={notasEstudiantes} responsive/>
  );
};

export default ParaleloNotas;
