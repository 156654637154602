import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { SelectOption } from '../../models/form/Select';
import TipoMovimiento from '../../models/movimientos/TipoMovimiento';
import { formatMovimientoPostBody, getEmptyMovimientoForm } from '../../utils/movimientos';
import { MovimientoForm } from '../../models/movimientos/Movimiento';
import { postMovimiento } from '../../api/movimientos/movimientosApi';
import MovimientoFormComponent from './MovimientoFormComponent';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';
import Persona from '../../models/personas/Persona';

interface NewMovimientoButtonProps {
  movimientosFilters: MovimientosFilters;
  presupuestoId: string;
  tiposMovimientosOptions: SelectOption<TipoMovimiento>[];
  className?: string;
  persona: Persona;
}

const NewMovimientoButton = ({
  tiposMovimientosOptions,
  movimientosFilters,
  presupuestoId,
  persona,
  className,
}: NewMovimientoButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyMovimientoForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (movimientoForm: MovimientoForm): void => {
    postMovimiento(formatMovimientoPostBody(movimientoForm))
      .then((response) => {
        history.goBack();

        cogoToast.success('Movimiento creado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button className={className} variant="success" onClick={handleShow}>
        Realizar Movimiento
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Realizar Movimiento</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <MovimientoFormComponent
            persona={persona}
            presupuestoId={presupuestoId}
            movimientosFilters={movimientosFilters}
            formState={formState}
            submitForm={submitForm}
            tiposMovimientoOptions={tiposMovimientosOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewMovimientoButton;
