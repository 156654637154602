import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import { SelectOption } from '../../models/form/Select';
import { MovimientoForm } from '../../models/movimientos/Movimiento';
import TipoMovimiento from '../../models/movimientos/TipoMovimiento';
import FormButtons from '../form/form-buttons/FormButtons';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';
import { BasePresupuesto } from '../../models/presupuestos/Presupuesto';
import { getPresupuesto } from '../../api/presupuestos/presupuestosApi';
import cogoToast from 'cogo-toast';
import LabelValue from '../LabelValue';
import Persona from '../../models/personas/Persona';

interface MovimientoFormComponentProps {
  presupuestoId: string;
  persona: Persona;
  movimientosFilters: MovimientosFilters;
  formState: FormState<MovimientoForm>;
  submitForm: (movimientoForm: MovimientoForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  tiposMovimientoOptions: SelectOption<TipoMovimiento>[];
}

const MovimientoFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  tiposMovimientoOptions,
  presupuestoId,
  movimientosFilters,
  persona,
}: MovimientoFormComponentProps): ReactElement => {
  const {
    value: movimientoForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const [isLoading, setLoading] = useState(true);

  const [presupuesto, setPresupuesto] = useState<BasePresupuesto>();

  useEffect(() => {
    getPresupuesto(presupuestoId, movimientosFilters)
      .then((response): void => {
        setPresupuesto(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del movimiento');
      })
      .then(() => setLoading(false));
  }, [presupuestoId, setPresupuesto]);

  const isFormInvalid = !movimientoForm.monto;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    movimientoForm.persona = persona;
    movimientoForm.presupuesto = presupuesto;

    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(movimientoForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Monto"
              name="monto"
              placeholder="Monto"
              value={movimientoForm.monto}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Tipo de Movimiento"
              name="tipo"
              placeholder="Seleccione un tipo de movimiento"
              value={movimientoForm.tipo}
              options={tiposMovimientoOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Motivo del Movimiento"
              name="motivo"
              placeholder="Motivo"
              value={movimientoForm.motivo}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
              <LabelValue label="Programa" value={ presupuesto?.programa.nombre || '-'} />
          </Col>
          <Col xs={12} md={6}>
              <LabelValue label="Sede" value={ presupuesto?.sede.nombre || '-'} />
          </Col>
        </Row>
        <hr />
        <hr />
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> la movimiento?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar movimiento"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar movimiento"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default MovimientoFormComponent;
