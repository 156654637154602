import { SelectGroup } from '../models/form/Select';

const getCarrerasSelectGroups = (): SelectGroup<string>[] => [
  {
    label: 'Antofagasta',
    options: [
      { label: 'Analista Químico', value: 'Analista Químico' },
      { label: 'Arquitectura', value: 'Arquitectura' },
      { label: 'Contador Auditor – Contador Público', value: 'Contador Auditor – Contador Público' },
      { label: 'Derecho – Antofagasta', value: 'Derecho – Antofagasta' },
      { label: 'Geología', value: 'Geología' },
      { label: 'Ingeniería Civil', value: 'Ingeniería Civil' },
      { label: 'Ingeniería Civil Ambiental', value: 'Ingeniería Civil Ambiental' },
      { label: 'Ingeniería Civil de Minas', value: 'Ingeniería Civil de Minas' },
      { label: 'Ingeniería Civil en Computación e Informática – Antofagasta', value: 'Ingeniería Civil en Computación e Informática – Antofagasta' },
      { label: 'Ingeniería Civil en Gestión de la Construcción', value: 'Ingeniería Civil en Gestión de la Construcción' },
      { label: 'Ingeniería Civil Industrial – Antofagasta', value: 'Ingeniería Civil Industrial – Antofagasta' },
      { label: 'Ingeniería Civil Metalúrgica', value: 'Ingeniería Civil Metalúrgica' },
      { label: 'Ingeniería Civil Plan Común', value: 'Ingeniería Civil Plan Común' },
      { label: 'Ingeniería Civil Química', value: 'Ingeniería Civil Química' },
      { label: 'Ingeniería Comercial – Antofagasta', value: 'Ingeniería Comercial – Antofagasta' },
      { label: 'Ingeniería en Computación e Informática', value: 'Ingeniería en Computación e Informática' },
      { label: 'Ingeniería en Construcción', value: 'Ingeniería en Construcción' },
      { label: 'Ingeniería en Información y Control de Gestión', value: 'Ingeniería en Información y Control de Gestión' },
      { label: 'Ingeniería en Metalurgia', value: 'Ingeniería en Metalurgia' },
      { label: 'Ingeniería en Procesos Químicos', value: 'Ingeniería en Procesos Químicos' },
      { label: 'Licenciatura en Física con Mención en Astronomía', value: 'Licenciatura en Física con Mención en Astronomía' },
      { label: 'Licenciatura en Matemática', value: 'Licenciatura en Matemática' },
      { label: 'Pedagogía en Educación Básica con Especialización', value: 'Pedagogía en Educación Básica con Especialización' },
      { label: 'Pedagogía en Inglés', value: 'Pedagogía en Inglés' },
      { label: 'Pedagogía en Matemática en Educación Media', value: 'Pedagogía en Matemática en Educación Media' },
      { label: 'Periodismo', value: 'Periodismo' },
      { label: 'Programa de Prosecución de Estudios de Formación Pedagógica (ingreso especial)', value: 'Programa de Prosecución de Estudios de Formación Pedagógica (ingreso especial)' },
      { label: 'Psicología', value: 'Psicología' },
      { label: 'Química Industrial', value: 'Química Industrial' },
      { label: 'Química y Farmacia', value: 'Química y Farmacia' },
    ]
  },
  {
    label: 'Coquimbo',
    options: [
      { label: 'Biología Marina', value: 'Biología Marina' },
      { label: 'Derecho – Coquimbo', value: 'Derecho – Coquimbo' },
      { label: 'Enfermería', value: 'Enfermería' },
      { label: 'Ingeniería Civil en Computación e Informática – Coquimbo', value: 'Ingeniería Civil en Computación e Informática – Coquimbo' },
      { label: 'Ingeniería Civil Industrial – Coquimbo', value: 'Ingeniería Civil Industrial – Coquimbo' },
      { label: 'Ingeniería Comercial – Coquimbo', value: 'Ingeniería Comercial – Coquimbo' },
      { label: 'Ingeniería en Acuicultura', value: 'Ingeniería en Acuicultura' },
      { label: 'Ingeniería en Información y Control de Gestión – Coquimbo', value: 'Ingeniería en Información y Control de Gestión – Coquimbo' },
      { label: 'Ingeniería en Prevención de Riesgos y Medioambiente – Coquimbo', value: 'Ingeniería en Prevención de Riesgos y Medioambiente – Coquimbo' },
      { label: 'Ingeniería en Tecnologías de Información', value: 'Ingeniería en Tecnologías de Información' },
      { label: 'Kinesiología', value: 'Kinesiología' },
      { label: 'Licenciatura en Ciencias Religiosas (ingreso especial)', value: 'Licenciatura en Ciencias Religiosas (ingreso especial)' },
      { label: 'Medicina', value: 'Medicina' },
      { label: 'Nutrición y Dietética', value: 'Nutrición y Dietética' },
      { label: 'Pedagogía en Filosofía y Religión', value: 'Pedagogía en Filosofía y Religión' },
    ],
  },
];

export default getCarrerasSelectGroups;
