import React, { ReactElement, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormState } from '../../hooks/useFormState';
import { CursoForm } from '../../models/cursos/Curso';
import { SelectOption } from '../../models/form/Select';
import BasePrograma from '../../models/programas/Programa';
import Sede from '../../models/sedes/Sede';
import { Input } from '../form/input/Input';
import Select from '../form/select/Select';
import FormButtons from '../form/form-buttons/FormButtons';
import Nivel from '../../models/niveles/Nivel';

interface CursoFormComponentProps {
  formState: FormState<CursoForm>;
  submitForm: (cursoForm: CursoForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  onDeactivation?: () => void; 
  programaOptions: SelectOption<BasePrograma>[];
  sedeOptions: SelectOption<Sede>[];
  nivelOptions: SelectOption<Nivel>[];
  showDeactivationButton?: boolean;
}

const CursoFormComponent = ({
  formState,
  onCancel,
  onDelete,
  onDeactivation,
  programaOptions,
  sedeOptions,
  nivelOptions,
  submitForm,
  showDeactivationButton,
}: CursoFormComponentProps): ReactElement => {
  const {
    value,
    handleFieldChange,
    areValidationsEnabled,
    isConfirmingDelete,
    isSubmitting,
    setAreValidationsEnabled,
    setConfirmingDelete,
  } = formState;

  const [isConfirmingDeactivation, setConfirmingDeactivation] = useState(false);

  const { nombre, codigo, year, programa, sede, nivel, isActive } = value;

  const isFormInvalid = !nombre || !codigo || !year || !programa || !sede || !nivel;

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const handleDeactivationClick = (): void => setConfirmingDeactivation(true)

  const cancelDelete = (): void => setConfirmingDelete(false);

  const cancelDeactivation = (): void => setConfirmingDeactivation(false);

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    submitForm(value);
  };

  return (
    <Form>
      <Row>
        <Col xs={12} md={6}>
          <Input
            name='nombre'
            title='Nombre'
            placeholder='Nombre'
            value={nombre}
            onChangeWithName={handleFieldChange}
            invalid={areValidationsEnabled && !nombre}
            invalidText='Debe ingresar el nombre.'
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            name='codigo'
            title='Código'
            placeholder='Código'
            value={codigo}
            onChangeWithName={handleFieldChange}
            invalid={areValidationsEnabled && !codigo}
            invalidText='Debe ingresar el código.'
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            type='number'
            name='year'
            title='Año academico'
            placeholder='Año'
            value={year}
            onChangeWithName={handleFieldChange}
            invalid={areValidationsEnabled && !year}
            invalidText='Debe ingresar el año academico.'
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Select
            name='sede'
            title='Sede'
            placeholder='Sede'
            value={sede}
            options={sedeOptions}
            onSelectWithName={handleFieldChange}
            invalid={areValidationsEnabled && !sede}
            invalidText='Debe seleccionar el sede.'
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Select
            name='programa'
            title='Programa'
            placeholder='Programa'
            value={programa}
            options={programaOptions}
            onSelectWithName={handleFieldChange}
            invalid={areValidationsEnabled && !programa}
            invalidText='Debe seleccionar el programa.'
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Select
            name='nivel'
            title='Nivel'
            placeholder='Nivel'
            value={nivel}
            options={nivelOptions}
            onSelectWithName={handleFieldChange}
            invalid={areValidationsEnabled && !nivel}
            invalidText='Debe seleccionar el nivel.'
            disabled={isSubmitting}
          />
        </Col>
      </Row>
      <hr />
      {isFormInvalid && areValidationsEnabled && (
        <div className='text-danger text-center mb-3'>
          <small>Hay campos obligatorios sin ingresar</small>
        </div>
      )}
      {isConfirmingDelete && (
        <h6 className='text-center'>
          ¿Está segura/o de que desea <span className='text-danger font-weight-bold'>eliminar</span>{' '}
          el curso?
        </h6>
      )}
      {isConfirmingDeactivation && (
        <h6 className='text-center'>
          ¿Está segura/o de que desea <span className='text-danger font-weight-bold'>{isActive? 'Desactivar': 'Activar'}</span>{' '}
          el curso?
        </h6>
      )}
      <FormButtons
        submitButtonContent='Guardar curso'
        deactivateButtonContent={isActive? 'Desactivar': 'Activar'}
        onSubmitClick={handleSubmitClick}
        onCancelClick={onCancel}
        onDeactivateClick={onDeactivation ? handleDeactivationClick : undefined}
        isConfirmingDelete={isConfirmingDelete}
        isConfirmingDeactivation={isConfirmingDeactivation}
        onDeleteClick={onDelete ? handleDeleteClick : undefined}
        onDeleteConfirm={onDelete}
        onDeactivateConfirm={onDeactivation}
        onCanceleDelete={cancelDelete}
        onCancelDeactivation={cancelDeactivation}
        deleteButtonContent='Eliminar curso'
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        showDeactivationButton
      />
    </Form>
  );
};

export default CursoFormComponent;
