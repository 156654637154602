import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row, Card } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import {
  BaseMovimiento,
  MovimientoForm,
} from '../../models/movimientos/Movimiento';
import useTiposMovimientosOptions from '../../hooks/useTiposMovimiento';
import useRegionesOptions from '../../hooks/useRegionesOptions';

interface MovimientosInfoProps {
  movimiento: BaseMovimiento;
  onMovimientoChange: (editedMovimiento: BaseMovimiento) => void;
}

const MovimientoInfoCard = ({
  movimiento,
  onMovimientoChange,
}: MovimientosInfoProps): ReactElement => {
  const {
    id,
    persona,
    monto,
    fecha,
    motivo,
    tipo,
  } = movimiento;

  const tiposMovimientoSelectOptions = useTiposMovimientosOptions();
  const regionesOptions = useRegionesOptions();

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h3 className="m-0"> {persona?.nombre + "" + persona?.apellidos} </h3>
          <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={handleShow}>
            Editar
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} lg={3}>
              <LabelValue label="Monto" value={monto || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tipo" value={movimiento.tipo.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Motivo" value={motivo || '-'} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Movimiento</Modal.Title>
          </Col>
        </Modal.Header>
      </Modal>
    </>
  );
};
export default MovimientoInfoCard;
