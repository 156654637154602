import cogoToast from 'cogo-toast';
import React, {ReactElement, useEffect, useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { deleteParaleloById, updateParalelo } from '../../api/paralelos/paralelosApi';
import useFormState from '../../hooks/useFormState';
import useProfesoresOptions from '../../hooks/useProfesoresOptions';
import { ExtendedParalelo, ParaleloForm } from '../../models/paralelo/Paralelo';
import { formatParaleloForm, formatParaleloPutBody } from '../../utils/paralelos';
import ParaleloFormComponent from './ParaleloFormComponent';

interface EditParaleloButtonProps {
  paralelo: ExtendedParalelo;
  onParaleloChange: (paralelo: ExtendedParalelo) => void;
}

const EditParaleloButton = ({paralelo, onParaleloChange}: EditParaleloButtonProps): ReactElement => {
  const { curso } = paralelo;

  const profesorOptions = useProfesoresOptions(curso.programa.id);

  const formState = useFormState(formatParaleloForm(paralelo, profesorOptions));

  const [isFormModalActive, setFormModalActive] = useState(false);

  const history = useHistory();

  useEffect(() => {
    formState.setForm(formatParaleloForm(paralelo, profesorOptions));
  }, [formState.setForm, profesorOptions]);

  const openFormModal = (): void => {
    setFormModalActive(true);
  };

  const closeFormModal = (): void => {
    setFormModalActive(false);
  };

  const cancelEdition = (): void => {
    closeFormModal();

    formState.reset();
  };

  const submitForm = (paraleloForm: ParaleloForm): void => {
    formState.setSubmitting(true);

    updateParalelo(paralelo.id, formatParaleloPutBody(paraleloForm))
      .then((updatedParalelo) => {
        cogoToast.success('Paralelo actualizado');

        onParaleloChange(updatedParalelo);

        closeFormModal();
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  const deleteParalelo = (): void => {
    formState.setSubmitting(true);

    deleteParaleloById(paralelo.id)
      .then(() => {
        cogoToast.success('Paralelo eliminado');

        history.replace(`cursos/${curso.id}`);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  return (
    <>
    <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={openFormModal}>
            Editar
          </Button>

          <Modal show={isFormModalActive} onHide={closeFormModal} size="xl">
        <Modal.Header closeButton>
          <div className="text-sm-center">
            <Modal.Title>Editar Paralelo</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ParaleloFormComponent
            formState={formState}
            submitForm={submitForm}
            profesorOptions={profesorOptions}
            onCancel={cancelEdition}
            onDelete={deleteParalelo}
          />
        </Modal.Body>
      </Modal>
          </>
          
  );
}

export default EditParaleloButton;
