import cogoToast from 'cogo-toast';
import { format, parse } from 'date-fns';
import React, { ReactElement, useState } from 'react';
import { Button, Card, Col, Row, Spinner} from 'react-bootstrap';
import { getBonoLocomocion, getReporteAsistencia } from '../../api/reportes/reportesApi';
import CursosFiltersComponent from '../../components/cursos/CursosFiltersComponent';
import { Input } from '../../components/form/input/Input';
import Table, { TableColumn } from '../../components/table/Table';
import useCursos from '../../hooks/useCursos';
import useNivelesOptions from '../../hooks/useNivelesOptions';
import useProgramasOptions from '../../hooks/usePrograma';
import useProgramas from '../../hooks/useProgramas';
import useSedesOptions from '../../hooks/useSedes';
import { BaseCurso } from '../../models/cursos/Curso';
import CursosFilters from '../../models/cursos/CursosFilters';
import BasePrograma from '../../models/programas/Programa';
import CursosFiltersBonoLocomocion from './CursosFiltersBonoLocomocion';

const ReporteBonoLocomocion = (): ReactElement => {
  const { programas } = useProgramas();
  const [cursosFilters, setCursosFilters] = useState<CursosFilters>({});
  const { cursos, isLoading } = useCursos(cursosFilters);
  const [isGeneratingReporte, setIsGeneratingReporte] = useState(false);
  const [titulo, setTitulo] = useState('');

  const today = new Date()
  const startOfMonthDate = new Date(today.getFullYear(), today.getMonth(), 1)
  const [date, setDate] = useState(startOfMonthDate)

  const programasOptions = useProgramasOptions();
  const sedesOptions = useSedesOptions();
  const nivelesOptions = useNivelesOptions();

  const onDatechange = (date: string) => {
    const newDate: Date = parse(date,'dd/MM/yyyy', new Date())
    setDate(newDate)
  }

  const downloadReporteBonoLocomocion = async (programaId: number): Promise<void> => {
    setIsGeneratingReporte(true);
    try {
      await getBonoLocomocion({ programaId, date, titulo }, cursosFilters)
      setIsGeneratingReporte(false);
    } catch (error) {
      setIsGeneratingReporte(false);
      cogoToast.error(await error);
    }
  }

  const tableColumns: TableColumn<BasePrograma>[] = [
    {
      title: 'Nombre',
      accessor: 'nombre',
    }, 
    {
      title: 'Acciones',
      ignoreClick: true,
      tdStyle: { whiteSpace: 'nowrap' },
      accessor: (programa) => {
        return (
          <Button 
            variant="success" 
            onClick={() => downloadReporteBonoLocomocion(programa.id)}
            disabled={isGeneratingReporte}
          >
            Generar reporte de bono locomoción
          </Button>
        )
      },
    }
  ];

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  const dateStr = format(date, 'dd/MM/yyyy');

  return (
    <Card>
      <Card.Header>
        Generador de reportes de Bono Locomoción
      </Card.Header>
      <Card.Body>
        <p><b>Filtro de cursos</b></p>
        <CursosFiltersBonoLocomocion
          cursosFilters={cursosFilters}
          setCursosFilters={setCursosFilters}
          programasOptions={programasOptions}
          sedesOptions={sedesOptions}
          nivelesOptions={nivelesOptions}
        />
        <p><b>Opciones del reporte</b></p>
        <Row>
          <Col>
            <Input title="Título del reporte (Opcional)" type='text' value={titulo} onChange={(value) => setTitulo(value)}/>
          </Col>
          <Col>
            <Input title="Fecha" type="date" value={dateStr} onChange={onDatechange}></Input>
          </Col>
        </Row>
        <p><b>Programas</b></p>
        <Table responsive items={programas} columns={tableColumns} hover />
      </Card.Body>
    </Card>
  )
}

export default ReporteBonoLocomocion;