import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import EntrevistasFilters from '../../models/entrevistas/EntrevistasFilters';
import { Entrevista, EntrevistaRequestBody, EntrevistasGetResponse, ExtendedEntrevista } from '../../models/entrevistas/Entrevista';

export function getEntrevista(entrevistaId: number): AxiosPromise<ExtendedEntrevista> {
  return axios.get(`entrevistas/${entrevistaId}`);
}

export function putEntrevista(entrevistaId: number, requestBody: EntrevistaRequestBody): AxiosPromise<ExtendedEntrevista> {
  return axios.put(`entrevistas/${entrevistaId}`, requestBody);
}

export function postEntrevista(requestBody: EntrevistaRequestBody): AxiosPromise<ExtendedEntrevista> {
  return axios.post('entrevistas', requestBody);
}

export function getEntrevistas(filters: EntrevistasFilters): AxiosPromise<EntrevistasGetResponse> {
  return axios.get('entrevistas', { params: filters });
}

export function deleteEntrevista(entrevistaId: number): AxiosPromise<void> {
  return axios.delete(`entrevistas/${entrevistaId}`);
}
