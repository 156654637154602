import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import {
  Tutor,
  TutorForm,
} from '../../models/tutores/Tutor';
import TutorFormComponent from './TutorFormComponent';
import {
  deleteTutor,
  putTutor,
} from '../../api/tutores/tutoresApi';
import {
  formatTutorForm,
  formatTutorPostBody,
} from '../../utils/tutores';
import useFormState from '../../hooks/useFormState';
import useProgramasOptions from '../../hooks/usePrograma';
import useSedesOptions from '../../hooks/useSedes';
import getCarrerasSelectGroups from '../../resources/carreras';
import { formatRut } from '../../utils/rut';

interface TutoresInfoProps {
  tutor: Tutor;
  onTutorChange: (editedTutor: Tutor) => void;
}

const TutorInfo = ({
  tutor,
  onTutorChange,
}: TutoresInfoProps): ReactElement => {
  const {
    id,
    nombre,
    apellidos,
    rut,
    email,
    telefono,
    direccion,
    carrera,
    semestre,
    horasAsignadas,
    isActive,
  } = tutor;

  const programasOptions = useProgramasOptions();
  const sedesOptions = useSedesOptions();

  const formState = useFormState(
    formatTutorForm(
      tutor,
      programasOptions,
      sedesOptions,
      getCarrerasSelectGroups()
    ),
  );

  useEffect(() => {
    formState.setForm(    
      formatTutorForm(
        tutor,
        programasOptions,
        sedesOptions,
        getCarrerasSelectGroups()
      ),
    );
  }, [programasOptions, sedesOptions])

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const submitForm = (tutorForm: TutorForm): void => {
    putTutor(id, formatTutorPostBody(tutorForm))
      .then((updatedTutor) => {
        formState.setSubmitting(false);

        setShow(false);

        onTutorChange(updatedTutor);

        cogoToast.success('Tutor actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentTutor = (): void => {
    formState.setSubmitting(true);

    deleteTutor(id).then(() => {
      cogoToast.success('Tutor eliminado!');
      history.replace('/tutores');
    }).catch((error) => {
      cogoToast.error(error.message);
    }).finally(() => {
      formState.setSubmitting(false);
      formState.setConfirmingDelete(false);
    })
  };

  const deactivateTutor = (): void => {
    formState.setSubmitting(true);

    putTutor(id, {isActive: !isActive})
      .then((updatedTutor) => {
        cogoToast.success(updatedTutor.isActive? 'Tutor activado': 'Tutor desactivado');
        onTutorChange(updatedTutor);
        history.replace('/tutores');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setConfirmingDeactivation(false);
        formState.setSubmitting(false);
      });
  }

  return (
    <>
      <div className="d-flex">
        <h3> {`${nombre} ${apellidos}`} </h3>
        <Button
          style={{ margin: '0 0 auto auto' }}
          variant="warning"
          size="sm"
          onClick={handleShow}>
          Editar
        </Button>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="Rut" value={rut ? formatRut(rut.toString()) : '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Email" value={email || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Teléfono" value={telefono || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Dirección" value={direccion || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Carrera" value={carrera || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Semestre" value={semestre || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Horas asignadas" value={horasAsignadas || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Programa" value={tutor.programa?.nombre || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Sede" value={tutor.sede?.nombre || '-'} />
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Tutor</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <TutorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            onCancel={handleClose}
            onDelete={deleteCurrentTutor}
            onDeactivation={deactivateTutor}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TutorInfo;
