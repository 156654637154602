import { useEffect, useMemo, useState } from 'react';

import { getProfesores } from '../api/profesores/profesoresApi';
import { SelectOption } from '../models/form/Select';
import { BaseProfesor } from '../models/profesores/Profesor';
import ProfesoresFilters from '../models/profesores/ProfesoresFilters';

const useProfesoresOptions = (programaId?: number): SelectOption<BaseProfesor>[] => {
  const [profesoresOptions, setProfesoresOptions] = useState<SelectOption<BaseProfesor>[]>([]);

  const fixedFilters = useMemo(() => {
    return {
      programaId,
      itemsPerPage: 10000000,
    } as ProfesoresFilters;
  }, [programaId]);

  useEffect(() => {
    getProfesores(fixedFilters).then((profesoresList) => {
      setProfesoresOptions(
        profesoresList.items.map(
          (profesor): SelectOption<BaseProfesor> => ({
            label: `${profesor.nombre} ${profesor.apellidos}`,
            value: profesor,
          }),
        ),
      );
    });
  }, [fixedFilters, setProfesoresOptions]);

  return profesoresOptions;
};

export default useProfesoresOptions;
