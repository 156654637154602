import React, { ReactElement, useRef } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import useEntityFiles from '../../hooks/useEntityFiles';
import FilesGridDropzone from '../files/FilesGridDropzone';
import DocumentFile from '../../models/files/DocumentFile';
import RolProtectedElement from '../roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';

interface EntityDocumentsCardProps {
  entityTypeEndpoint: string;
  entityId: number | string;
  readonly?: boolean;
  docsUrl?: string;
}

const EntityDocumentsCard = ({
  entityTypeEndpoint,
  entityId,
  docsUrl = 'documentos',
  readonly,
}: EntityDocumentsCardProps): ReactElement => {
  const { files, isLoading, setFiles } = useEntityFiles(entityTypeEndpoint, entityId);

  const uploadButtonRef = useRef<HTMLButtonElement>(null);

  const handleFileDelete = (deletedFile: DocumentFile): void =>
    setFiles((prevState) => prevState?.filter((file) => file.id !== deletedFile.id));

  const renderCardContent = (): ReactElement => {
    if (isLoading)
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );

    if (!files) return <p className="text-center m-0">No se pudieron cargar los documentos</p>;

    /* console.log(docsUrl); */

    return (
      <>
        <FilesGridDropzone
          files={files}
          onChange={setFiles}
          onFileDelete={handleFileDelete}
          uploadEndpoint={`${entityTypeEndpoint}/${entityId}/${docsUrl}`}
          deleteEndpoint="documentos"
          selectFileButtonRef={uploadButtonRef}
          readonly={readonly}
        />
      </>
    );
  };

  return (
    <Card className="mt-3">
      <Card.Header className="d-flex pb-2">
        <h5 className="m-0 flex-grow-1">Documentos</h5>
        <RolProtectedElement roles={[Rol.Coordinador, Rol.Administrador]}>
          <Button ref={uploadButtonRef} variant="success" className="m-auto float-right" size="sm">
            Subir documento
          </Button>
        </RolProtectedElement>
      </Card.Header>
      <Card.Body>{renderCardContent()}</Card.Body>
    </Card>
  );
};

export default EntityDocumentsCard;
