import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import { Profesor, ProfesorForm } from '../../models/profesores/Profesor';
import ProfesorFormComponent from './ProfesorFormComponent';
import { deleteProfesor, putProfesor } from '../../api/profesores/profesoresApi';
import { formatProfesorForm, formatProfesorPostBody } from '../../utils/profesores';
import useFormState from '../../hooks/useFormState';
import useProgramasOptions from '../../hooks/usePrograma';
import useSedesOptions from '../../hooks/useSedes';
import { formatRut } from '../../utils/rut';

interface ProfesoresInfoProps {
  profesor: Profesor;
  onProfesorChange: (editedProfesor: Profesor) => void;
}

const ProfesorInfo = ({ profesor, onProfesorChange }: ProfesoresInfoProps): ReactElement => {
  const { id, nombre, apellidos, rut, email, telefono, direccion, horasAsignadas, isActive } = profesor;

  const programasOptions = useProgramasOptions();
  const sedesOptions = useSedesOptions();

  const formState = useFormState(formatProfesorForm(profesor, programasOptions, sedesOptions));

  useEffect(() => {
    formState.setForm(formatProfesorForm(profesor, programasOptions, sedesOptions));
  }, [programasOptions, sedesOptions]);

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const submitForm = (profesorForm: ProfesorForm): void => {
    putProfesor(id, formatProfesorPostBody(profesorForm))
      .then((updatedProfesor) => {
        formState.setSubmitting(false);
        setShow(false);

        onProfesorChange(updatedProfesor);
        cogoToast.success('Profesor actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentProfesor = (): void => {
    formState.setSubmitting(true);

    deleteProfesor(id).then(() => {
      cogoToast.success('Profesor eliminado!');
      history.replace('/profesores');
    }).catch((error) => {
      cogoToast.error(error.message, {hideAfter: 10});
    }).finally(() => {
      formState.setSubmitting(false);
      formState.setConfirmingDelete(false)
    })
  };

  const deactivateProfesor = (): void => {
    formState.setSubmitting(true);

    putProfesor(id, {isActive: !isActive})
      .then((updatedProfesor) => {
        cogoToast.success(updatedProfesor.isActive? 'Profesor activado': 'Profesor desactivado');
        onProfesorChange(updatedProfesor);
        history.replace('/profesores');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setConfirmingDeactivation(false);
        formState.setSubmitting(false);
      });
  }

  return (
    <>
      <div className="d-flex">
        <h3> {`${nombre} ${apellidos}`} </h3>
        <Button
          style={{ margin: '0 0 auto auto' }}
          variant="warning"
          size="sm"
          onClick={handleShow}>
          Editar
        </Button>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="RUT" value={rut ? formatRut(rut.toString()) : '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Email" value={email || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Teléfono" value={telefono || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Dirección" value={direccion || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Horas asignadas" value={horasAsignadas || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Programa" value={profesor.programa?.nombre || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Sede" value={profesor.sede?.nombre || '-'} />
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Profesor</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <ProfesorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            onCancel={handleClose}
            onDelete={deleteCurrentProfesor}
            onDeactivation={deactivateProfesor}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ProfesorInfo;
