import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import PresupuestosFilters from '../../models/presupuestos/PresupuestosFilters';
import { BasePresupuesto, Presupuesto, PresupuestosGetResponse, PresupuestoRequestBody } from '../../models/presupuestos/Presupuesto';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';

export function getPresupuestos(
  filters: PresupuestosFilters,
): AxiosPromise<PresupuestosGetResponse> {
  return axios.get('presupuestos', { params: filters });
}

export function getPresupuestos2(
): AxiosPromise<PresupuestosGetResponse> {
  return axios.get('presupuestos');
}

export function postPresupuesto(
  requestBody: PresupuestoRequestBody,
): AxiosPromise<Presupuesto> {
  return axios.post('presupuestos', requestBody);
}

export function getPresupuesto(presupuestoId: string, filters: MovimientosFilters): AxiosPromise<BasePresupuesto> {
  return axios.get(`presupuestos/${presupuestoId}`, { params: filters });
}

export function putPresupuesto(
  presupuestoId: number,
  requestBody: PresupuestoRequestBody,
): AxiosPromise<Presupuesto> {
  return axios.put(`presupuestos/${presupuestoId}`, requestBody);
}

export function deletePresupuesto(presupuestoId: number): AxiosPromise<void> {
  return axios.delete(`presupuestos/${presupuestoId}`);
}
