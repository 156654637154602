import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import PresupuestosFilters from '../../models/presupuestos/PresupuestosFilters';
import { BasePresupuesto } from '../../models/presupuestos/Presupuesto';
import { getPresupuestos } from '../../api/presupuestos/presupuestosApi';
import Table, { TableColumn } from '../table/Table';

interface PresupuestosListProps {
  presupuestosfilters: PresupuestosFilters;
  setPresupuestosFilters: Dispatch<SetStateAction<PresupuestosFilters>>;
}

const PresupuestoList = ({
  presupuestosfilters,
  setPresupuestosFilters,
}: PresupuestosListProps): ReactElement => {
  const [presupuestos, setPresupuestos] = useState<BasePresupuesto[]>();

  const [isLoading, setLoading] = useState(true);

  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getPresupuestos(presupuestosfilters)
      .then((response): void => {
        setPresupuestos(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los presupuestos.');
      })
      .finally(() => setLoading(false));
  }, [presupuestosfilters, setPresupuestos]);

  const handlePresupuestoClick = (presupuesto: BasePresupuesto): void => {
    history.push(`/presupuestos/${presupuesto.id}`);
  };

  const tableColumns: TableColumn<BasePresupuesto>[] = [

    {
      title: 'Sede',
      accessor: (presupuesto) =>
        presupuesto.sede.nombre,
        hideUntil: 'lg',
    },
    {
      title: 'Programa',
      accessor: (presupuesto) =>
        presupuesto.programa.nombre,
        hideUntil: 'lg',
    },
  ];

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!presupuestos)
    return <p className="text-center">No se pudo cargar el listado de presupuestos</p>;

  return (
    <>
      <Table
        items={presupuestos}
        columns={tableColumns}
        hover
        onItemClick={handlePresupuestoClick}
        minRowsCount={presupuestosfilters.itemsPerPage}
        pages={pages}
        activePage={presupuestosfilters.page}
        onPageSelect={(page): void =>
          setPresupuestosFilters({ ...presupuestosfilters, page })
        }
      />
    </>
  );
};
export default PresupuestoList;
