export interface ReporteAsistenciaQuery {
  titulo?: string;
  cursoId: number;
  startDate: Date;
  endDate: Date;
}

export enum TiposReportes {
  Asistencia = 'Asistencia por Curso',
  Locomocion = 'Bono Locomoción'
}