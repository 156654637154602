import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';
import { BaseMovimiento, MovimientosGetResponse, MovimientoRequestBody } from '../../models/movimientos/Movimiento';

export function getMovimientos(
  filters: MovimientosFilters,
): AxiosPromise<MovimientosGetResponse> {
  return axios.get('movimientos', { params: filters });
}

export function getMovimientos2(
): AxiosPromise<MovimientosGetResponse> {
  return axios.get('movimientos');
}

export function postMovimiento(
  requestBody: MovimientoRequestBody,
): AxiosPromise<BaseMovimiento> {
  return axios.post('movimientos', requestBody);
}

export function getMovimiento(movimientoId: string): AxiosPromise<BaseMovimiento> {
  return axios.get(`movimientos/${movimientoId}`);
}

export function putMovimiento(
  movimientoId: number,
  requestBody: MovimientoRequestBody,
): AxiosPromise<BaseMovimiento> {
  return axios.put(`movimientos/${movimientoId}`, requestBody);
}

export function deleteMovimiento(movimientoId: number): AxiosPromise<void> {
  return axios.delete(`movimientos/${movimientoId}`);
}
