import React, { ReactElement, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { SelectOption } from '../../models/form/Select';
import BasePrograma from '../../models/programas/Programa';
import Sede from '../../models/sedes/Sede';
import CursoFormComponent from './CursoFormComponent';
import useFormState from '../../hooks/useFormState';
import { formatCursoPostBody, getEmptyCursoForm } from '../../utils/cursos';
import { CursoForm } from '../../models/cursos/Curso';
import { postCurso } from '../../api/cursos/cursosApi';
import Nivel from '../../models/niveles/Nivel';

interface NewCursoButtonProps {
  programaOptions: SelectOption<BasePrograma>[];
  sedeOptions: SelectOption<Sede>[];
  nivelOptions: SelectOption<Nivel>[];
}

const NewCursoButton = ({
  programaOptions,
  sedeOptions,
  nivelOptions,
}: NewCursoButtonProps): ReactElement => {
  const [isModalActive, setModalActive] = useState(false);

  const formState = useFormState(getEmptyCursoForm());

  const history = useHistory();

  const openFormModal = (): void => {
    setModalActive(true);
  };

  const closeFormModal = (): void => {
    setModalActive(false);
  };

  const submitForm = (cursoForm: CursoForm): void => {
    formState.setSubmitting(true);

    postCurso(formatCursoPostBody(cursoForm))
      .then((createdCurso) => {
        cogoToast.success('Curso creado.');

        history.push(`/cursos/${createdCurso.id}`);
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const cancelForm = (): void => {
    closeFormModal();

    formState.reset();
  };

  return (
    <>
      <Button variant="success" onClick={openFormModal}>
        Agregar curso
      </Button>
      <Modal show={isModalActive} onHide={closeFormModal}>
        <Modal.Header>
          <div className="text-sm-center">
            <Modal.Title>Agregar Curso</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CursoFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={cancelForm}
            programaOptions={programaOptions}
            sedeOptions={sedeOptions}
            nivelOptions={nivelOptions}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewCursoButton;
