import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { Spinner } from 'react-bootstrap';
import EgresadosFilters from '../../models/egresados/EgresadosFilters';
import { BaseEgresado } from '../../models/egresados/Egresado';
import { getEgresados } from '../../api/egresados/egresadosApi';
import Table, { TableColumn } from '../table/Table';
import { formatRut } from '../../utils/rut';

interface EgresadosListProps {
  egresadosFilters: EgresadosFilters;
  setEgresadosFilters: Dispatch<SetStateAction<EgresadosFilters>>;
}

const EgresadosList = ({
  egresadosFilters,
  setEgresadosFilters,
}: EgresadosListProps): ReactElement => {
  const [egresados, setEgresados] = useState<BaseEgresado[]>();
  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getEgresados(egresadosFilters)
      .then((response) => {
        setEgresados(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los egresados.');
      });
  }, [egresadosFilters, setEgresados]);

  // eslint-disable-next-line no-shadow
  const handleEgresadosClick = (egresados: BaseEgresado) => {
    history.push(`/egresados/${egresados.id}`);
  };

  const tableColumns: TableColumn<BaseEgresado>[] = [
    {
      title: 'Nombre',
      accessor: (egresado) => `${egresado.estudiante?.nombre} ${egresado.estudiante?.apellidos}`,
    },
    {
      title: 'Rut',
      accessor: (egresado) => (egresado.estudiante?.rut ? formatRut(egresado.estudiante?.rut.toString()) : ''),
      hideUntil: 'lg',
    },
    {
      title: 'Institución',
      accessor: (egresado) => {return egresado.institucion?.nombre},
      hideUntil: 'md',
    },
  ];

  if (!egresados)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <Table
        items={egresados}
        columns={tableColumns}
        hover
        onItemClick={handleEgresadosClick}
        minRowsCount={egresadosFilters.itemsPerPage}
        pages={pages}
        activePage={egresadosFilters.page}
        onPageSelect={(page) => setEgresadosFilters({ ...egresadosFilters, page })}
      />
    </>
  );
};
export default EgresadosList;
