import React, { ReactElement, useEffect, useRef, useState } from 'react';
import axios from '../../api/axiosWithAuth';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './file-dropzone.scss';
import { useDropzone } from 'react-dropzone';
import cogoToast from 'cogo-toast';
import DocumentFile from '../../models/files/DocumentFile';
import getMimeIconClassName, { downloadFileWithAuth } from '../../utils/files';
import { getDocumento } from '../../api/documentos/documentoApi';

interface FileDropzoneProps {
  files: Array<DocumentFile>;
  onChange: (files: DocumentFile[]) => void;
  onFileDelete: (deletedFile: DocumentFile) => void;
  uploadEndpoint: string;
  deleteEndpoint: string;
  selectFileButtonRef?: React.RefObject<HTMLButtonElement>;
  readonly?: boolean;
}

const FilesGridDropzone = ({
  files,
  onChange,
  onFileDelete,
  uploadEndpoint,
  deleteEndpoint,
  selectFileButtonRef,
  readonly,
}: FileDropzoneProps): React.ReactElement => {
  const [isUploading, setUploading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [fileDeleteState, setFileDeleteState] = useState({
    fileToDelete: files[0],
    isConfirming: false,
    isDeleting: false,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadProgress = (progressEvent: ProgressEvent<EventTarget>): void => {
    const completedPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    setUploadProgress(completedPercentage);
  };

  const handleFilesDropAccepted = (acceptedFiles: File[]): void => {
    setUploading(true);

    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('files', file);
    });

    axios
      .post(uploadEndpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: handleUploadProgress,
      })
      .then((response) => {
        if (onChange) onChange(response.data);
      })
      .catch((error) => cogoToast.error(error.message))
      .finally(() => {
        setUploadProgress(0);
        setUploading(false);
      });
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted: handleFilesDropAccepted,
    disabled: isUploading || readonly,
    noClick: true,
    noKeyboard: true,
    maxSize: 75 * 1024 * 1024,
    onDropRejected: (fileRejections): void => {
      cogoToast.error(fileRejections[0].errors[0].message);
    },
  });

  useEffect(() => {
    if (selectFileButtonRef && selectFileButtonRef.current) {
      const button = selectFileButtonRef.current;
      button.onclick = open;
    }
  }, [selectFileButtonRef, open]);

  const openFile = async (documento: DocumentFile): Promise<void> => {
    try {
      const {name, mime, file} = await getDocumento(documento.id);
      downloadFileWithAuth(file, name, mime, false);
    } catch (error) {
      cogoToast.error(await error);
    }
  };

  const handleDeleteClick = (fileToDelete: DocumentFile): void =>
    setFileDeleteState((prevState) => ({
      ...prevState,
      fileToDelete,
      isConfirming: true,
    }));

  const cancelFileDelete = (): void =>
    setFileDeleteState((prevState) => ({
      ...prevState,
      isConfirming: false,
    }));

  const deleteFile = (): void => {
    setFileDeleteState((prevState) => ({
      ...prevState,
      isConfirming: false,
      isDeleting: true,
    }));

    axios
      .delete(`${deleteEndpoint}/${fileDeleteState.fileToDelete.id}`, {
        onUploadProgress: handleUploadProgress,
      })
      .catch(() => cogoToast.error('No se pudo eliminar el documento.'))
      .then(() => onFileDelete(fileDeleteState.fileToDelete))
      .finally(() =>
        setFileDeleteState((prevState) => ({
          ...prevState,
          isDeleting: false,
        })),
      );
  };

  const renderContent = (): ReactElement => {
    if (isUploading)
      return (
        <div className="d-flex h-100">
          <div className="m-auto flex-grow-1 px-3">
            <p className="text-center">Subiendo archivo(s)...</p>
            <div className="progress">
              <div className="progress-bar" style={{ width: uploadProgress }} />
            </div>
          </div>
        </div>
      );

    if (fileDeleteState.isConfirming)
      return (
        <div className="d-flex h-100">
          <div className="m-auto flex-grow-1 px-3">
            <p className="text-center">{`¿Está seguro de que desea eliminar el documento '${fileDeleteState.fileToDelete?.name}'`}</p>
            <div className="text-center">
              <Button variant="secondary" className="mr-2" onClick={(): void => cancelFileDelete()}>
                No
              </Button>
              <Button variant="danger" onClick={(): void => deleteFile()}>
                Sí. Eliminar
              </Button>
            </div>
          </div>
        </div>
      );

    if (fileDeleteState.isDeleting)
      return (
        <div className="d-flex h-100">
          <div className="m-auto flex-grow-1 px-3">
            <p className="text-center">Eliminando documento...</p>
            <div className="progress">
              <div className="progress-bar" style={{ width: uploadProgress }} />
            </div>
          </div>
        </div>
      );

    if (isDragActive) return <h6 className="text-center m-auto">Suelte aquí para agregar</h6>;

    return (
      <div className="files-grid">
        {files.length > 0 ? (
          files.map((file) => (
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{file.name}</Tooltip>}>
              <div className="file-container">
                <div className="position-relative">
                  <button
                    className="btn btn-danger delete-button"
                    type="button"
                    onClick={() => handleDeleteClick(file)}>
                    <i className="mdi mdi-close" />
                  </button>
                </div>
                <div
                  key={file.name}
                  role="presentation"
                  className="file"
                  onClick={(): void => {
                    openFile(file);
                  }}>
                  <i className={`fa ${getMimeIconClassName(file.mime)}`} />
                  <div className="filename-wrapper">
                    <small className="filename">
                      {file.name.slice(0, 20)}
                      {file.name.length > 20 && '...'}
                    </small>
                  </div>
                </div>
              </div>
            </OverlayTrigger>
          ))
        ) : (
          <p className="m-auto text-center">No hay archivos</p>
        )}
      </div>
    );
  };

  return (
    <div className="file-select" {...getRootProps()}>
      <Card className="file-select-card">
        <Card.Body className={isDragActive ? 'd-flex' : ''}>
          <input ref={inputRef} {...getInputProps()} />
          {renderContent()}
        </Card.Body>
      </Card>
      <div className="text-center">
        <small className="m-0">Arrastre para agregar archivos</small>
      </div>
    </div>
  );
};

export default FilesGridDropzone;
