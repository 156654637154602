import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';
import AuthContext from '../../contexts/AuthContext';
import CursosSimpleList from '../../components/cursos/CursosEstudianteList';
import { getCursosByEstudianteEmail } from '../../api/cursos/cursosApi';
import { BaseCurso } from '../../models/cursos/Curso';
import cogoToast from 'cogo-toast';
import CursosEstudianteList from '../../components/cursos/CursosEstudianteList';
import EstudianteParalelos from '../../components/estudiantes/EstudianteParalelos';

const CursosPage = (): ReactElement => {
  const breadcrumb = useBreadcrumb();

  const { jwtData, userRoles, email } = useContext(AuthContext);

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Cursos',
        active: true,
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
      <RolProtectedElement roles={[Rol.Estudiante]}>
      <Card className="mt-3">
        <Card.Body>
          <EstudianteParalelos/>
        </Card.Body>
      </Card>
      </RolProtectedElement>
      
    </>
  );
};

export default CursosPage;
