import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import ProfesorInfo from '../../components/profesores/ProfesorInfo';
import { getProfesor } from '../../api/profesores/profesoresApi';
import { Profesor } from '../../models/profesores/Profesor';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';

const ProfesorPage = (): ReactElement => {
  const [profesor, setProfesor] = useState<Profesor>();

  const { profesorId } = useParams<{ profesorId: string }>();

  const breadcrumb = useBreadcrumb();

  const history = useHistory();

  useEffect(() => {
    getProfesor(profesorId)
      .then((responseProfesor): void => {
        setProfesor(responseProfesor);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      });
  }, [profesorId, setProfesor]);

  useEffect(() => {
    if (profesor) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Profesores',
          href: '/profesores',
        },
        {
          label: `${profesor.nombre} ${profesor.apellidos}`,
          active: true,
        },
      ]);
    }
  }, [profesor, breadcrumb]);

  if (!profesor)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleProfesorChange = (changedProfesor: Profesor): void => setProfesor(changedProfesor);

  const goToBitacora = (): void => {
    history.push(`/profesores/${profesorId}/bitacora`);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <ProfesorInfo profesor={profesor} onProfesorChange={handleProfesorChange} />
        </Card.Body>
      </Card>
      <EntityDocumentsCard entityTypeEndpoint="profesores" entityId={profesor.id} />
      <div className="mt-3 text-center">
        <Button variant="info" onClick={goToBitacora}>Ver bitácora</Button>
      </div>
    </>
  );
};

export default ProfesorPage;
