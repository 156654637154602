import React, { ReactElement } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormState } from '../../hooks/useFormState';
import { EgresadoForm } from '../../models/egresados/Egresado';
import { SelectOption } from '../../models/form/Select';
import { Input } from '../form/input/Input';
import Select from '../form/select/Select';
import FormButtons from '../form/form-buttons/FormButtons';
import BasePrograma from '../../models/programas/Programa';
import { BaseInstitucion } from '../../models/instituciones/Institucion';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';
import Estado from '../../models/estados/Estado';
import Beneficio from '../../models/egresados/Beneficio';
import Ingreso from '../../models/egresados/Ingreso';
import {Carrera} from '../../models/carreras/Carrera';

interface EgresadoFormComponentProps {
  formState: FormState<EgresadoForm>;
  submitForm: (egresadosForm: EgresadoForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  onDeactivation?: () => void;
  mostrarTasasOcultar: () => void;
  showTasas: boolean;
  sedesOptions: SelectOption<Sede>[];
  nivelesOptions: SelectOption<Nivel>[];
  programasOptions: SelectOption<BasePrograma>[];
  institucionesOptions: SelectOption<BaseInstitucion>[];
  estadosOptions: SelectOption<Estado>[];
  ingresosOptions: SelectOption<Ingreso>[];
  beneficiosOptions: SelectOption<Beneficio>[];
  carrerasOptions: SelectOption<Carrera>[];
}
const EgresadoFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  onDeactivation,
  mostrarTasasOcultar,
  showTasas,
  sedesOptions,
  nivelesOptions,
  programasOptions,
  institucionesOptions,
  estadosOptions,
  ingresosOptions,
  beneficiosOptions,
  carrerasOptions,
}: EgresadoFormComponentProps): ReactElement => {
  const {
    value: egresadoForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    isConfirmingDeactivation,
    setConfirmingDelete,
    setConfirmingDeactivation
  } = formState;

  const isFormInvalid =

    !egresadoForm.institucion;
    

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(egresadoForm);
  };

  const handleShowClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {

    showTasas = !showTasas;
    
    event.preventDefault();

  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const handleDeactivationClick = () => setConfirmingDeactivation(true);
  const cancelDelete = () => setConfirmingDelete(false);
  const cancelDeactivation = () => setConfirmingDeactivation(false);

  if (showTasas){
    return (
      <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Select
              title="Tipo de Ingreso"
              name="tipoIngreso"
              placeholder="Tipo de Ingreso"
              value={egresadoForm.tipoIngreso}
              options={ingresosOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Tipo de Beneficio"
              name="tipoBeneficio"
              placeholder="Tipo de Beneficio"
              value={egresadoForm.tipoBeneficio}
              options={beneficiosOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Institución"
              name="institucion"
              placeholder="Institución"
              value={egresadoForm.institucion}
              options={institucionesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !egresadoForm.institucion}
              invalidText="Debe seleccionar la Institución"
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Carrera"
              name="carrera"
              placeholder="Carrera"
              value={egresadoForm.carrera}
              options={carrerasOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Tasa de Titulación Oportuna"
              name="tasaTitulacionOportuna"
              placeholder="Tasa de Titulación Oportuna"
              value={egresadoForm.tasaTitulacionOportuna}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Tasa de Aprobación"
              name="tasaAprobacion"
              placeholder="Tasa de Aprobación"
              value={egresadoForm.tasaAprobacion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Tasa de Retención 1° Año"
              name="tasaRetencionPrimerAno"
              placeholder="Tasa de Retención 1° Año"
              value={egresadoForm.tasaRetencionPrimerAno}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Tasa de Retención 2° Año"
              name="tasaRetencionSegundoAno"
              placeholder="Tasa de Retención 2° Año"
              value={egresadoForm.tasaRetencionSegundoAno}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Tasa de Retención 3° Año"
              name="tasaRetencionTercerAno"
              placeholder="Tasa de Retención 1° Año"
              value={egresadoForm.tasaRetencionTercerAno}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        <div className="text-center mt-4">
          <Button variant="success" onClick={mostrarTasasOcultar} disabled={isSubmitting}>
            Ocultar Tasas
          </Button>
        </div>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el egresado?
          </h6>
        )}
        {isConfirmingDeactivation && (
        <h6 className='text-center'>
          ¿Está segura/o de que desea <span className='text-danger font-weight-bold'>{egresadoForm.isActive? 'Desactivar': 'Activar'}</span>{' '}
          el egresado?
        </h6>
        )}
        <FormButtons
          deactivateButtonContent={egresadoForm.isActive? 'Desactivar': 'Activar'}
          onCancelDeactivation={cancelDeactivation}
          onDeactivateClick={onDeactivation ? handleDeactivationClick : undefined }
          onDeactivateConfirm={onDeactivation}
          isConfirmingDeactivation={isConfirmingDeactivation}
          showDeactivationButton
          
          submitButtonContent="Guardar Egresado"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar Egresado"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
    );
  }

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Select
              title="Tipo de Ingreso"
              name="tipoIngreso"
              placeholder="Tipo de Ingreso"
              value={egresadoForm.tipoIngreso}
              options={ingresosOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Tipo de Beneficio"
              name="tipoBeneficio"
              placeholder="Tipo de Beneficio"
              value={egresadoForm.tipoBeneficio}
              options={beneficiosOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Institución"
              name="institucion"
              placeholder="Institución"
              value={egresadoForm.institucion}
              options={institucionesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !egresadoForm.institucion}
              invalidText="Debe seleccionar la Institución"
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Carrera"
              name="carrera"
              placeholder="Carrera"
              value={egresadoForm.carrera}
              options={carrerasOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        
        <div className="text-center mt-4">
          <Button variant="success" onClick={mostrarTasasOcultar} disabled={isSubmitting}>
            Agregar Tasas
          </Button>
        </div>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el egresado?
          </h6>
        )}
        {isConfirmingDeactivation && (
        <h6 className='text-center'>
          ¿Está segura/o de que desea <span className='text-danger font-weight-bold'>{egresadoForm.isActive? 'Desactivar': 'Activar'}</span>{' '}
          el egresado?
        </h6>
        )}
        <FormButtons
          deactivateButtonContent={egresadoForm.isActive? 'Desactivar': 'Activar'}
          onCancelDeactivation={cancelDeactivation}
          onDeactivateClick={onDeactivation ? handleDeactivationClick : undefined }
          onDeactivateConfirm={onDeactivation}
          isConfirmingDeactivation={isConfirmingDeactivation}
          showDeactivationButton
          
          submitButtonContent="Guardar Egresado"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar Egresado"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};

export default EgresadoFormComponent;
