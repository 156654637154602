import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import EstudiantesFilters from '../../models/estudiantes/EstudiantesFilters';
import EstudiantesFiltersComponent from '../../components/estudiantes/EstudiantesFiltersComponent';
import useSedesOptions from '../../hooks/useSedesOptions';
import useEstablecimientosOptions from '../../hooks/useEstablecimientosOptions';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import useNivelesOptions from '../../hooks/useNivelesOptions';
import EstudiantesList from '../../components/estudiantes/EstudiantesList';
import NewEstudianteButton from '../../components/estudiantes/NewEstudianteButton';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import ClosedEntitiesToggleButton from '../../components/cursos/ClosedCursosToggleButton';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import Rol from '../../models/usuarios/Rol';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import useEstadosOptions from '../../hooks/useEstadoOptions';

const EstudiantesPage = (): ReactElement => {
  const [estudiantesFilters, setEstudiantesFilters] = useState<EstudiantesFilters>({
    nombreORut: '',
    page: 1,
    itemsPerPage: 8,
  });

  const sedesOptions = useSedesOptions();
  const establecimientosOptions = useEstablecimientosOptions();
  const estadosOptions = useEstadosOptions();
  const programasOptions = useProgramasOptions();
  const nivelesOptions = useNivelesOptions();

  const breadcrumb = useBreadcrumb();

  const shouldShowAddButton =  useUserHasAnyRole([Rol.Coordinador, Rol.Administrador]);

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Estudiantes',
        href: '/estudiantes',
      },
    ]);
  }, [breadcrumb]);

  const updateIncludeInactiveFilter = () => {
    setEstudiantesFilters((prevFilters) => ({
      ...prevFilters,
      includeInactive: !estudiantesFilters.includeInactive,
    }));
  }

  return (
    <>
    <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
      <Card className="mb-3">
        <Card.Body>
          <EstudiantesFiltersComponent
            estadosOptions={estadosOptions}
            establecimientosOptions={establecimientosOptions}
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            nivelesOptions={nivelesOptions}
            estudiantesFilters={estudiantesFilters}
            onFiltersChange={setEstudiantesFilters}
          />

          <div className="text-right">
            <ClosedEntitiesToggleButton
              areInactivosEnabled={estudiantesFilters.includeInactive}
              setInactivosEnabled={(newValue) =>
                setEstudiantesFilters((prevState) => ({ ...prevState, includeInactive: newValue }))
              }
            />
            {shouldShowAddButton && (
              <NewEstudianteButton
                sedesOptions={sedesOptions}
                nivelesOptions={nivelesOptions}
                programasOptions={programasOptions}
                establecimientosOptions={establecimientosOptions}
                estadosOptions={estadosOptions}
              />
            )}
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <EstudiantesList
            setEstudiantesFilters={setEstudiantesFilters}
            estudiantesFilters={estudiantesFilters}
          />
        </Card.Body>
      </Card>
      </RolProtectedElement>
    </>
  );
};

export default EstudiantesPage;
