import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { ExtendedParalelo } from '../models/paralelo/Paralelo';
import { getParaleloById } from '../api/paralelos/paralelosApi';

interface UseParaleloHookValue {
  paralelo?: ExtendedParalelo;
  setParalelo: Dispatch<SetStateAction<ExtendedParalelo | undefined>>;
  isLoading: boolean;
  reloadParalelo: () => void;
}

const useParalelo = (paraleloId: number | string): UseParaleloHookValue => {
  const [paralelo, setParalelo] = useState<ExtendedParalelo>();

  const [isLoading, setLoading] = useState(true);

  const requestParalelo = useCallback(() => {
    getParaleloById(paraleloId)
      .then((responseParalelo) => {
        setParalelo(responseParalelo);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paraleloId, setParalelo, setLoading]);

  useEffect(() => {
    requestParalelo();
  }, [requestParalelo]);

  return {
    paralelo,
    setParalelo,
    isLoading,
    reloadParalelo: requestParalelo,
  };
};

export default useParalelo;
