import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import TutoresFilters from '../../models/tutores/TutoresFilters';
import TutoresFiltersComponent from '../../components/tutores/TutoresFiltersComponent';
import TutorList from '../../components/tutores/TutoresList';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import NewTutorButton from '../../components/tutores/NewTutorButton';
import useSedesOptions from '../../hooks/useSedes';
import useProgramasOptions from '../../hooks/usePrograma';
import ClosedEntitiesToggleButton from '../../components/cursos/ClosedCursosToggleButton';

const TutoresPage = (): ReactElement => {
  const [tutoresfilters, setTutoresFilters] = useState<TutoresFilters>({
    nombreORut: '',
    page: 1,
    itemsPerPage: 8,
  });

  const sedesOptions = useSedesOptions();
  const programasOptions = useProgramasOptions();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Tutores',
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <TutoresFiltersComponent
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            tutoresFilters={tutoresfilters}
            onFiltersChange={setTutoresFilters}
          />
          <div className="text-right">
            <ClosedEntitiesToggleButton
              areInactivosEnabled={tutoresfilters.includeInactive}
              setInactivosEnabled={(newValue) =>
                setTutoresFilters((prevState) => ({ ...prevState, includeInactive: newValue }))
              }
            />
            <NewTutorButton programasOptions={programasOptions} sedesOptions={sedesOptions}/>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <TutorList
            setTutoresFilters={setTutoresFilters}
            tutoresfilters={tutoresfilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default TutoresPage;
