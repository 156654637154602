import React, { ReactElement, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormState } from '../../hooks/useFormState';
import { EstudianteForm } from '../../models/estudiantes/Estudiante';
import { SelectOption } from '../../models/form/Select';
import { Input } from '../form/input/Input';
import RutInput from '../form/input/RutInput';
import Select from '../form/select/Select';
import FormButtons from '../form/form-buttons/FormButtons';
import ApoderadoFormComponent from '../apoderados/ApoderadoFormComponent';
import { ApoderadoForm } from '../../models/estudiantes/Apoderado';
import { getEmptyApoderadoForm } from '../../utils/estudiantes';
import BasePrograma from '../../models/programas/Programa';
import { validateRut } from '../../utils/rut';
import { validateEmail } from '../../utils/email';
import { BaseEstablecimiento } from '../../models/establecimientos/Establecimiento';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';
import Estado from '../../models/estados/Estado';

interface EstudianteFormComponentProps {
  formState: FormState<EstudianteForm>;
  submitForm: (estudiantesForm: EstudianteForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  onDeactivation?: () => void;
  sedesOptions: SelectOption<Sede>[];
  nivelesOptions: SelectOption<Nivel>[];
  programasOptions: SelectOption<BasePrograma>[];
  establecimientosOptions: SelectOption<BaseEstablecimiento>[];
  estadosOptions: SelectOption<Estado>[];
}
const EstudianteFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  onDeactivation,
  sedesOptions,
  nivelesOptions,
  programasOptions,
  establecimientosOptions,
  estadosOptions,
}: EstudianteFormComponentProps): ReactElement => {
  const {
    value: estudianteForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    isConfirmingDeactivation,
    setConfirmingDelete,
    setConfirmingDeactivation
  } = formState;
  
  const isFormInvalid =
    !estudianteForm.nombre ||
    !validateEmail(estudianteForm.email) ||
    !validateRut(estudianteForm.rut) ||
    !estudianteForm.sede ||
    !estudianteForm.programa ||
    !estudianteForm.establecimiento ||
    !estudianteForm.estado ||
    !estudianteForm.nivel;

  const handleApoderadoFormChange = (apoderadoIndex: number, newValue: ApoderadoForm): void => {
    const updatedApoderadosForm = [...estudianteForm.apoderadosForm];

    updatedApoderadosForm[apoderadoIndex] = newValue;

    handleFieldChange('apoderadosForm', updatedApoderadosForm);
  };

  const addApoderadosForm = (): void => {
    const updatedApoderadosForm = [...estudianteForm.apoderadosForm, getEmptyApoderadoForm()];

    handleFieldChange('apoderadosForm', updatedApoderadosForm);
  };

  const deleteApoderadoFormAtIndex = (index: number): void => {
    const updatedApoderadosForm = [...estudianteForm.apoderadosForm];

    updatedApoderadosForm.splice(index, 1);

    handleFieldChange('apoderadosForm', updatedApoderadosForm);
  };

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(estudianteForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const handleDeactivationClick = () => setConfirmingDeactivation(true);
  const cancelDelete = () => setConfirmingDelete(false);
  const cancelDeactivation = () => setConfirmingDeactivation(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre Legal"
              name="nombre"
              placeholder="Nombre Legal"
              value={estudianteForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !estudianteForm.nombre}
              invalidText="Debe ingresar el nombre del estudiante"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={estudianteForm.apellidos}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Nombre Social"
              name="nombreSocial"
              placeholder="Nombre Social"
              value={estudianteForm.nombreSocial}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <RutInput
              title="Rut"
              name="rut"
              placeholder="Rut"
              value={estudianteForm.rut}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={
                areValidationsEnabled && (!validateRut(estudianteForm.rut))
              }
              invalidText="Rut inválido"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Fecha de nacimiento"
              name="fechaNacimiento"
              placeholder="Fecha de nacimiento"
              value={estudianteForm.fechaNacimiento}
              onChangeWithName={handleFieldChange}
              type="date"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Dirección"
              name="direccion"
              placeholder="Dirección"
              value={estudianteForm.direccion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={estudianteForm.telefono}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Correo"
              name="email"
              placeholder="Correo"
              value={estudianteForm.email}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !validateEmail(estudianteForm.email)}
              invalidText="Debe ingresar el correo"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="IVE"
              name="ive"
              placeholder="IVE"
              value={estudianteForm.ive}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Género"
              name="genero"
              placeholder="Género"
              value={estudianteForm.genero}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Observaciones"
              name="observaciones"
              placeholder="Observaciones"
              value={estudianteForm.observaciones}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Sede"
              name="sede"
              placeholder="Sede"
              value={estudianteForm.sede}
              options={sedesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !estudianteForm.sede}
              invalidText="Debe seleccionar la sede"
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Nivel"
              name="nivel"
              placeholder="Nivel"
              value={estudianteForm.nivel}
              options={nivelesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !estudianteForm.nivel}
              invalidText="Debe seleccionar el nivel"
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Programa"
              name="programa"
              placeholder="Programa"
              value={estudianteForm.programa}
              options={programasOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !estudianteForm.programa}
              invalidText="Debe seleccionar el programa."
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Establecimiento"
              name="establecimiento"
              placeholder="Establecimiento"
              value={estudianteForm.establecimiento}
              options={establecimientosOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !estudianteForm.establecimiento}
              invalidText="Debe seleccionar el establecimiento"
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Estado"
              name="estado"
              placeholder="Estado"
              value={estudianteForm.estado}
              options={estadosOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !estudianteForm.estado}
              invalidText="Debe seleccionar el estado del estudiante"
            />
          </Col>
        </Row>
        <h5>Apoderados</h5>
        {estudianteForm.apoderadosForm.map((apoderadoForm, index) => (
          <>
            {index > 0 && <hr />}
            <ApoderadoFormComponent
              apoderadoForm={apoderadoForm}
              onChange={(newValue) => handleApoderadoFormChange(index, newValue)}
              onDeleteClick={(): void => deleteApoderadoFormAtIndex(index)}
              areValidationsEnabled={areValidationsEnabled}
              disabled={isSubmitting}
            />
          </>
        ))}
        <div className="text-center mt-4">
          <Button variant="success" onClick={addApoderadosForm} disabled={isSubmitting}>
            Agregar Apoderado
          </Button>
        </div>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el estudiante?
          </h6>
        )}
        {isConfirmingDeactivation && (
        <h6 className='text-center'>
          ¿Está segura/o de que desea <span className='text-danger font-weight-bold'>{estudianteForm.isActive? 'Desactivar': 'Activar'}</span>{' '}
          el estudiante?
        </h6>
        )}
        <FormButtons
          deactivateButtonContent={estudianteForm.isActive? 'Desactivar': 'Activar'}
          onCancelDeactivation={cancelDeactivation}
          onDeactivateClick={onDeactivation ? handleDeactivationClick : undefined }
          onDeactivateConfirm={onDeactivation}
          isConfirmingDeactivation={isConfirmingDeactivation}
          showDeactivationButton
          
          submitButtonContent="Guardar estudiante"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar estudiante"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};

export default EstudianteFormComponent;
