import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import useSedesOptions from '../../hooks/useSedesOptions';
import useNivelesOptions from '../../hooks/useNivelesOptions';
import CursosFilters from '../../models/cursos/CursosFilters';
import CursosFiltersComponent from '../../components/cursos/CursosFiltersComponent';
import CursosList from '../../components/cursos/CursosList';
import NewCursoButton from '../../components/cursos/NewCursoButton';
import ClosedEntitiesToggleButton from '../../components/cursos/ClosedCursosToggleButton';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';
import AuthContext from '../../contexts/AuthContext';
import CursosSimpleList from '../../components/cursos/CursosEstudianteList';
import CursosEstudianteList from '../../components/cursos/CursosEstudianteList';

const CursosPage = (): ReactElement => {
  const breadcrumb = useBreadcrumb();

  const { jwtData, userRoles } = useContext(AuthContext);

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Cursos',
        active: true,
      },
    ]);
  }, [breadcrumb]);

  const defaultCursosFilters: CursosFilters = {
    year: new Date().getFullYear(),
    includeInactive: false,
  }
  const [cursosFilters, setCursosFilters] = useState<CursosFilters>(defaultCursosFilters);

  const programasOptions = useProgramasOptions();

  const sedesOptions = useSedesOptions();

  const nivelesOptions = useNivelesOptions();

  return (
    <>
    <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador, Rol.Profesor, Rol.Tutor]}>
      <Card>
        <Card.Body>
          <CursosFiltersComponent
            cursosFilters={cursosFilters}
            setCursosFilters={setCursosFilters}
            programasOptions={programasOptions}
            sedesOptions={sedesOptions}
            nivelesOptions={nivelesOptions}
          />
          <div className="text-right">
            <ClosedEntitiesToggleButton
              areInactivosEnabled={cursosFilters.includeInactive}
              setInactivosEnabled={(newValue) =>
                setCursosFilters((prevState) => ({ ...prevState, includeInactive: newValue }))
              }
            />
            <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
              <NewCursoButton
                programaOptions={programasOptions}
                sedeOptions={sedesOptions}
                nivelOptions={nivelesOptions}
              />
            </RolProtectedElement>
          </div>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <CursosList cursosFilters={cursosFilters} />
        </Card.Body>
      </Card>

    </RolProtectedElement>

      
    </>
  );
};

export default CursosPage;
