import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import { BaseParaleloEstudiante, Estudiante, EstudianteForm } from '../../models/estudiantes/Estudiante';
import AuthContext from '../../contexts/AuthContext';
import { getEstudianteEmail, getParalelosByEstudianteEmail } from '../../api/estudiantes/estudiantesApi';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import Table, { TableColumn } from '../table/Table';

const EstudianteParalelos = (): ReactElement => {

    const { jwtData, userRoles, email } = useContext(AuthContext);

    const [paralelos, setParalelos] = useState<BaseParaleloEstudiante[]>();

    const [estudiantes, setEstudiantes] = useState<Estudiante>();

    useEffect(() => {
      getEstudianteEmail(email)
      .then((response) => {
        setEstudiantes(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudieron cargar los paralelos del estudiante');
      });
  }, [email]);
  
    useEffect(() => {
        getParalelosByEstudianteEmail(email)
        .then((response) => {
          setParalelos(response);
        })
        .catch(() => {
          cogoToast.error('No se pudieron cargar los paralelos del estudiante');
        });
    }, [email, setParalelos]);
  
    const history = useHistory();
  
    const onParaleloSelect = (paralelo: BaseParaleloEstudiante): void => { 
      if(estudiantes?.id){
      history.push(`/paralelosInscritos/${paralelo.id}/${estudiantes?.id}`);
    }
    };
  
    const tableColumns: TableColumn<BaseParaleloEstudiante>[] = [
      {
        title: 'Curso',
        accessor: (paralelo) => {return paralelo.curso.nombre}
      },
      {
        title: 'Correlativo',
        accessor: 'correlativo',
      },
      {
        title: 'Profesor',
        accessor: (paralelo) => {return paralelo.profesor.nombre}
      }
    ];
  
    if (!paralelos) {
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );
    }
    if (paralelos.length == 0) {
      return (
        <div className="text-center">
          El estudiante no tiene cursos inscritos
        </div>
      );
    }
  
    return (
      <Table responsive items={paralelos} columns={tableColumns} onItemClick={onParaleloSelect} hover />
    );
};
  

export default EstudianteParalelos;

