import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import Table, { TableColumn } from '../table/Table';
import { formatRut } from '../../utils/rut';
import UsersFilters from '../../models/usuarios/UsersFilters';
import Usuario from '../../models/usuarios/Usuario';
import { getUsuarios } from '../../api/usuarios/usuariosApi';
import Rol from '../../models/usuarios/Rol';
import { getActionButtonFromUserStatus } from '../../utils/usuarios';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';

interface UsersListProps {
  usuariosfilters: UsersFilters;
  setUsuariosFilters: Dispatch<SetStateAction<UsersFilters>>;
}

const UsuariosList = ({
  usuariosfilters,
  setUsuariosFilters: setProfesoresFilters,
}: UsersListProps): ReactElement => {
  const [usuarios, setUsuarios] = useState<Usuario[]>();
  const [pages, setPages] = useState(1);

  const history = useHistory();

  const userIsAdmin = useUserHasAnyRole([Rol.Administrador]);

  const fetchUsuarios = () => {
    getUsuarios(usuariosfilters)
      .then((response): void => {
        setUsuarios(response.items);
        setPages(response.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los usuarios.');
      });
  };

  useEffect(() => {
    fetchUsuarios();
  }, [usuariosfilters, setUsuarios]);

  const handleUsuariosClick = (usuario: Usuario): void => {
    history.push(`/usuarios/${usuario.id}`);
  };

  const tableColumns: TableColumn<Usuario>[] = [
    {
      title: 'Nombre',
      accessor: (usuario) => {
        return `${usuario.nombre} ${usuario.apellidos}`;
      },
    },
    {
      title: 'Rut',
      accessor: (usuario) => (usuario.rut ? formatRut(usuario.rut.toString()) : ''),
    },
    {
      title: 'Email',
      accessor: 'email',
    },
    {
      title: 'Sede',
      accessor: (usuario) => {
        return usuario.sede?.nombre;
      },
    },
    {
      title: 'Rol',
      accessor: (usuario) => {
        return usuario.roles.join(', ');
      },
    },
    {
      title: 'Estado',
      accessor: (usuario) => {
        return usuario.status;
      },
    },
    {
      title: 'Acciones',
      ignoreClick: true,
      tdStyle: { whiteSpace: 'nowrap' },
      accessor: (usuario) => {
        if (usuario.roles.includes(Rol.Administrador) && !userIsAdmin) return null;
        
        return getActionButtonFromUserStatus(usuario, fetchUsuarios);
      },
    },
  ];

  if (!usuarios)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <Table
        items={usuarios}
        columns={tableColumns}
        hover
        responsive
        onItemClick={handleUsuariosClick}
        minRowsCount={usuariosfilters.itemsPerPage}
        pages={pages}
        activePage={usuariosfilters.page}
        onPageSelect={(page): void => setProfesoresFilters({ ...usuariosfilters, page })}
      />
    </>
  );
};
export default UsuariosList;
