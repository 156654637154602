import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import CursosFilters from '../../models/cursos/CursosFilters';
import { SelectOption } from '../../models/form/Select';
import BasePrograma from '../../models/programas/Programa';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';
import Select from '../form/select/Select';
import { Input } from '../form/input/Input';

interface CursosFiltersComponentProps {
  cursosFilters: CursosFilters;
  setCursosFilters: Dispatch<SetStateAction<CursosFilters>>;
  programasOptions: SelectOption<BasePrograma>[];
  sedesOptions: SelectOption<Sede>[];
  nivelesOptions: SelectOption<Nivel>[];
}

const CursosFiltersComponent = ({
  cursosFilters,
  nivelesOptions,
  programasOptions,
  sedesOptions,
  setCursosFilters,
}: CursosFiltersComponentProps): ReactElement => {
  const { programaId, nivelId, sedeId } = cursosFilters;

  const handleFilterChange = (filterName: string, value?: number): void => {
    if (filterName === 'year' && Number.isNaN(value)) {
      return;
    }
    if (filterName === 'semestre' && Number.isNaN(value)) {
      return;
    }
    setCursosFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  }

  return (
    <Row>
      <Col lg={4}>
        <Select
          title="Programa"
          placeholder="Programa"
          value={programasOptions.find((programaOption) => programaOption.value.id === programaId)}
          options={programasOptions}
          onSelect={(selectedPrograma): void => {
            handleFilterChange('programaId', selectedPrograma.value.id);
          }}
          onClear={() => handleFilterChange('programaId', undefined)}
        />
      </Col>
      <Col lg={4}>
        <Select
          title="Sede"
          placeholder="Sede"
          value={sedesOptions.find((sedeOption) => sedeOption.value.id === sedeId)}
          options={sedesOptions}
          onSelect={(selectedSede): void => {
            handleFilterChange('sedeId', selectedSede.value.id);
          }}
          onClear={() => handleFilterChange('sedeId', undefined)}
        />
      </Col>
      <Col lg={4}>
        <Select
          title="Nivel"
          placeholder="Nivel"
          value={nivelesOptions.find((nivelOption) => nivelOption.value.id === nivelId)}
          options={nivelesOptions}
          onSelect={(selectedNivel): void => {
            handleFilterChange('nivelId', selectedNivel.value.id);
          }}
          onClear={() => handleFilterChange('nivelId', undefined)}
        />
      </Col>
      <Col lg={2}>
        <Input
          type='number'
          value={cursosFilters.year || ''}
          onChange={(value) => handleFilterChange('year', Number(value))}
          placeholder="Año"
          title="Año academico"
        />
      </Col>
    </Row>
  );
};

export default CursosFiltersComponent;
