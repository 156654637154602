import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import {
  AsistenciaForm,
  AsistenciaGetResponse,
  AsistenciaPutBody,
  AsistenciasPutBodies,
  BaseAsistencia,
  Sesion,
  SesionesGetResponse,
  SesionRequestBody,
} from '../../models/sesiones/Sesion';
import SesionesFilters from '../../models/sesiones/SesionesFilters';
import AsistenciaFilters from '../../models/sesiones/AsistenciaFilters';
import TipoAsistencia from '../../models/sesiones/TipoAsistencia';

export function getSesion(sesionId: string): AxiosPromise<Sesion> {
  return axios.get(`sesiones/${sesionId}`);
}

export function putSesion(sesionId: number, requestBody: SesionRequestBody): AxiosPromise<Sesion> {
  return axios.put(`sesiones/${sesionId}`, requestBody);
}

export function postSesion(requestBody: SesionRequestBody): AxiosPromise<Sesion> {
  return axios.post('sesiones', requestBody);
}

export function getSesiones(filters: SesionesFilters): AxiosPromise<SesionesGetResponse> {
  return axios.get('sesiones', { params: filters });
}

export function getAsistencia(filters: AsistenciaFilters): AxiosPromise<AsistenciaGetResponse> {
  return axios.get(`sesiones/${filters.id}/asistencia`, { params: filters });
}

export function updateAsistencia(requestBody: AsistenciasPutBodies): AxiosPromise<void> {
  return axios.put('sesiones', requestBody);
}

export function deleteSesion(sesionId: number): AxiosPromise<void> {
  return axios.delete(`sesiones/${sesionId}`);
}

export function getTipoAsistencia(): AxiosPromise<TipoAsistencia[]> {
  return axios.get('sesiones/asistencia/tipos');
}
