import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import dotenv from 'dotenv';
import '@mdi/font/css/materialdesignicons.min.css';
import './assets/styles/app.scss';
import App from './App';

dotenv.config();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    throw new Error(err.response?.data?.message || 'Error.');
  },
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
