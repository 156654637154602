import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import CoordinadorInfo from '../../components/coordinadores/CoordinadorInfo';
import { getCoordinador } from '../../api/coordinadores/coordinadoresApi';
import { Coordinador } from '../../models/coordinadores/Coordinador';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';

const CoordinadorPage = (): ReactElement => {
  const [coordinador, setCoordinador] = useState<Coordinador>();

  const { coordinadorId } = useParams<{ coordinadorId: string }>();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    getCoordinador(coordinadorId)
      .then((response): void => {
        setCoordinador(response);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del coordinador');
      });
  }, [coordinadorId, setCoordinador]);

  useEffect(() => {
    if (coordinador) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Usuarios',
          href: '/usuarios',
        },
        {
          label: coordinador.nombre,
          active: true,
        },
      ]);
    }
  }, [coordinador, breadcrumb]);

  if (!coordinador)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleCoordinadorChange = (changedCoordinador: Coordinador): void => setCoordinador(changedCoordinador);

  return (
    <>
      <Card>
        <Card.Body>
          <CoordinadorInfo coordinador={coordinador} onCoordinadorChange={handleCoordinadorChange} />
        </Card.Body>
      </Card>
      <EntityDocumentsCard entityTypeEndpoint="coordinadores" entityId={coordinador.id} />
    </>
  );
};

export default CoordinadorPage;
