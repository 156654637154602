import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row, Card } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import {
  Institucion,
  InstitucionForm,
} from '../../models/instituciones/Institucion';
import InstitucionFormComponent from './InstitucionFormComponent';
import {
  deleteInstitucion,
  putInstitucion,
} from '../../api/instituciones/institucionesApi';
import {
  formatInstitucionForm,
  formatInstitucionPostBody,
} from '../../utils/instituciones';
import useFormState from '../../hooks/useFormState';
import useTiposInstitucionesOptions from '../../hooks/useTiposInstitucion';
import getComunasSelectGroups from '../../resources/comunas';
import useRegionesOptions from '../../hooks/useRegionesOptions';

interface InstitucionesInfoProps {
  institucion: Institucion;
  onInstitucionChange: (editedInstitucion: Institucion) => void;
}

const InstitucionInfoCard = ({
  institucion,
  onInstitucionChange,
}: InstitucionesInfoProps): ReactElement => {
  const {
    id,
    nombre,
    ciudad,
    comuna,
    direccion,
    emailDirector,
    nombreDirector,
    telefono,
  } = institucion;

  const tiposInstitucionSelectOptions = useTiposInstitucionesOptions();
  const regionesOptions = useRegionesOptions();

  const formState = useFormState(
    formatInstitucionForm(
      institucion,
      getComunasSelectGroups(),
      tiposInstitucionSelectOptions,
      regionesOptions,
    ),
  );

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const submitForm = (institucionForm: InstitucionForm): void => {
    putInstitucion(id, formatInstitucionPostBody(institucionForm))
      .then((response) => {
        formState.setSubmitting(false);

        setShow(false);

        onInstitucionChange(response.data);

        cogoToast.success('Institucion actualizada!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentInstitucion = (): void => {
    formState.setSubmitting(true);

    deleteInstitucion(id).then(() => {
      cogoToast.success('Institucion eliminada!');
      formState.setConfirmingDelete(false);
      history.replace('/instituciones');
    }).catch((error) => {
      cogoToast.error(error.message);
      formState.setConfirmingDelete(false);
      formState.setSubmitting(false);
    })
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h3 className="m-0"> {nombre} </h3>
          <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={handleShow}>
            Editar
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} lg={3}>
              <LabelValue label="Región" value={institucion.region?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tipo" value={institucion.tipoInstitucion?.nombre || '-'} />
            </Col>
            {emailDirector && (
              <Col xs={12} lg={6}>
                <LabelValue label="Correo director" value={emailDirector || '-'} />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Institución</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <InstitucionFormComponent
            formState={formState}
            submitForm={submitForm}
            regionesOptions={regionesOptions}
            tiposInstitucionOptions={tiposInstitucionSelectOptions}
            onCancel={handleClose}
            onDelete={deleteCurrentInstitucion}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default InstitucionInfoCard;
