import cogoToast from 'cogo-toast';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { getBitacoraProfesor, getProfesor } from '../../api/profesores/profesoresApi';
import RegistroBitacoraCard from '../../components/bitacoras/RegistroBitacoraCard';
import PaginationComponent from '../../components/pagination/PaginationComponent';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { RegistroBitacora } from '../../models/bitacoras/Bitacora';
import { Profesor } from '../../models/profesores/Profesor';


const BitacoraProfesorPage = (): ReactElement => {
  const [profesor, setProfesor] = useState<Profesor>();

  const [pages, setPages] = useState(1);

  const [items, setItems] = useState<RegistroBitacora[]>([]);

  const itemsPerPage = 5;

  const { profesorId } = useParams<{ profesorId: string }>();

  const breadcrumb = useBreadcrumb();

  const history = useHistory();

  const { search } = useLocation();

  const { page } = queryString.parse(search);

  useEffect(() => {
    getProfesor(profesorId)
      .then((responseProfesor): void => {
        setProfesor(responseProfesor);
      })
      .catch((error) => {
        cogoToast.error(error.message);

   });
  }, [profesorId, setProfesor]);

 
  
  useEffect(() => {
    if (profesor) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Profesores',
          href: '/profesores',
        },
        {
          label: `${profesor.nombre} ${profesor.apellidos}`,
          href: `/profesores/${profesorId}`,
        },
        {
          label: 'Ver Bitácora',
          active: true,
        }
      ]);
    }
  }, [profesor, breadcrumb]);

  useEffect(() => {
    getBitacoraProfesor(Number(profesorId), { page: Number(page || 1), itemsPerPage })
        .then((bitacoraList): void => {
          setPages(bitacoraList.pages);
          setItems(bitacoraList.items);
        })
        .catch((error) => {
          cogoToast.error(error.message);
        });
  } ,[page, itemsPerPage, setPages]);

  const goToPage = (page: number): void => {
    history.push(`/profesores/${profesorId}/bitacora?page=${page}`);
  };

  return (
    <>
      <Card className="text-right mb-3">
        <Card.Body>
          <Row>
            <Col className="text-left">
              <h4>{`Bitácora de ${profesor?.nombre || ''}`}</h4>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {items.map(
        (item): ReactElement => (
          <Card className="mb-3">
            <RegistroBitacoraCard bitacora={item} readonly />
          </Card>
        ),
      )}
      <PaginationComponent pageCount={pages} onChange={goToPage} />
    </>
  )
};

export default BitacoraProfesorPage;