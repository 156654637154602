import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';

import {
  Establecimiento,
  EstablecimientoRequestBody,
  EstablecimientosGetResponse,
} from '../../models/establecimientos/Establecimiento';
import EstablecimientosFilters from '../../models/establecimientos/EstablecimientosFilters';

export function getEstablecimientos(
  filters: EstablecimientosFilters,
): AxiosPromise<EstablecimientosGetResponse> {
  return axios.get('establecimientos', { params: filters });
}

export function postEstablecimiento(
  requestBody: EstablecimientoRequestBody,
): AxiosPromise<Establecimiento> {
  return axios.post('establecimientos', requestBody);
}

export function getEstablecimiento(establecimientoId: string): AxiosPromise<Establecimiento> {
  return axios.get(`establecimientos/${establecimientoId}`);
}

export function putEstablecimiento(
  establecimientoId: number,
  requestBody: EstablecimientoRequestBody,
): AxiosPromise<Establecimiento> {
  return axios.put(`establecimientos/${establecimientoId}`, requestBody);
}

export function deleteEstablecimiento(establecimientoId: number): AxiosPromise<void> {
  return axios.delete(`establecimientos/${establecimientoId}`);
}
