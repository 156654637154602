import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import {
  formatAdministradorPostBody,
  getEmptyAdministradorForm,
} from '../../utils/administradores';
import { AdministradorForm } from '../../models/administradores/Administrador';
import { postAdministrador } from '../../api/administradores/administradoresApi';
import AdministradorFormComponent from './AdministradorFormComponent';
import { SelectOption } from '../../models/form/Select';
import Sede from '../../models/sedes/Sede';

interface NewAdministradorButtonProps {
  sedesOptions: SelectOption<Sede>[];
  className?: string;
}

const NewAdministradorButton = ({
  sedesOptions,
  className,
}: NewAdministradorButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyAdministradorForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (administradorForm: AdministradorForm): void => {
    postAdministrador(formatAdministradorPostBody(administradorForm))
      .then((response) => {
        const createdAdministradorId = response.id;

        history.push(`/administradores/${createdAdministradorId}`);

        cogoToast.success('Administrador creado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button className={className} variant="success" onClick={handleShow}>
        Agregar Administrador
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Administrador</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <AdministradorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewAdministradorButton;
