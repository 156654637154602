import { AxiosResponse } from "axios";
import download from 'downloadjs'
import { base64 } from "../models/files/DocumentFile";

const getMimeIconClassName = (mime: string): string => {
  const iconClasses = [
    {
      mime: 'image',
      icon: 'fa-file-image',
    },
    {
      mime: 'application/pdf',
      icon: 'fa-file-pdf',
    },
    {
      mime: 'application/msword',
      icon: 'fa-file-word',
    },
    {
      mime: 'application/vnd.ms-word',
      icon: 'fa-file-word',
    },
    {
      mime: 'application/vnd.openxmlformatsfficedocument.wordprocessingml.document',
      icon: 'fa-file-word',
    },
    {
      mime: 'application/vnd.ms-excel',
      icon: 'fa-file-excel',
    },
    {
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      icon: 'fa-file-excel'
    },
    {
      mime: 'application/vnd.openxmlformatsfficedocument.spreadsheetml',
      icon: 'fa-file-excel',
    },
    {
      mime: 'application/vnd.oasis.opendocument.spreadsheet',
      icon: 'fa-file-excel',
    },
    {
      mime: 'application/vnd.ms-powerpoint',
      icon: 'fa-file-powerpoint',
    },
    {
      mime: 'application/vnd.openxmlformatsfficedocument.presentationml',
      icon: 'fa-file-powerpoint',
    },
    {
      mime: 'application/vnd.oasis.opendocument.presentation',
      icon: 'fa-file-powerpoint',
    },
  ];

  const mimeIconClass = iconClasses.find((iconClass) => mime.includes(iconClass.mime));

  return mimeIconClass?.icon || 'fa-file';
};

export const downloadFileWithAuth = (file: base64 | File | Blob, filename: string = 'document', mime: string = 'application/octet-stream', downloadfile: boolean = true):void => {
  if (!downloadfile) {
    const data = window.URL.createObjectURL(file);
    var link = document.createElement('a');
    link.href = data;
    link.download=filename;
    link.target='_blank';
    link.click();
    setTimeout(function(){
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
    return;
  }
  download(file, filename, mime)
  // const url = window.URL.createObjectURL(`data:${mime}base64,${file}`);
  // const link = document.createElement('a');
  // link.href = url;
  // link.setAttribute('download', filename); //or any other extension
  // document.body.appendChild(link);
  // link.click();
}

export default getMimeIconClassName;
