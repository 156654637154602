import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import { Estudiante, EstudianteForm } from '../../models/estudiantes/Estudiante';
import useSedesOptions from '../../hooks/useSedesOptions';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import useNivelesOptions from '../../hooks/useNivelesOptions';
import useFormState from '../../hooks/useFormState';
import { formatEstudianteForm, formatEstudiantePostBody } from '../../utils/estudiantes';
import { deleteEstudiante, putEstudiante } from '../../api/estudiantes/estudiantesApi';
import LabelValue from '../LabelValue';
import EstudianteFormComponent from './EstudianteFormComponent';
import useEstablecimientosOptions from '../../hooks/useEstablecimientosOptions';
import { formatRut, getRutVerifier } from '../../utils/rut';
import Rol from '../../models/usuarios/Rol';
import RolProtectedElement from '../roles/RolProtectedElement';
import useEstadosOptions from '../../hooks/useEstadoOptions';

interface EstudianteInfoProps {
  estudiante: Estudiante;
  perfil?: string;
  onEstudianteChange: (editedEstudiante: Estudiante) => void;
}

const EstudianteInfoCard = ({
  estudiante, perfil,
  onEstudianteChange,
}: EstudianteInfoProps): ReactElement => {
  const {
    id,
    nombre,
    apellidos,
    rut,
    fechaNacimiento,
    ive,
    telefono,
    observaciones,
    nombreSocial,
    genero,
    direccion,
    establecimiento,
    email,
    estado,
    estudiantePrograma,
    isActive,
  } = estudiante;

  const sedesSelectOptions = useSedesOptions();
  const programasSelectOptions = useProgramasOptions();
  const nivelesSelectOption = useNivelesOptions();
  const establecimientosOptions = useEstablecimientosOptions();
  const estadosOptions = useEstadosOptions();
  const uploadButtonRef = useRef<HTMLButtonElement>(null);

  const formState = useFormState(
    formatEstudianteForm(
      estudiante,
      sedesSelectOptions,
      programasSelectOptions,
      nivelesSelectOption,
      establecimientosOptions,
      estadosOptions,
    ),
  );

  useEffect(() => {
    formState.setForm(
      formatEstudianteForm(
        estudiante,
        sedesSelectOptions,
        programasSelectOptions,
        nivelesSelectOption,
        establecimientosOptions,
        estadosOptions,
      ),
    );
  }, [
    sedesSelectOptions,
    programasSelectOptions,
    nivelesSelectOption,
    establecimientosOptions,
    estudiante,
    estadosOptions,
  ]);

  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (estudianteForm: EstudianteForm) => {
    putEstudiante(id, formatEstudiantePostBody(estudianteForm))
      .then((response) => {
        formState.setSubmitting(false);
        setShow(false);
        onEstudianteChange(response.data);
        cogoToast.success('Estudiante actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  const deleteCurrentEstudiante = (): void => {
    formState.setSubmitting(true);
    deleteEstudiante(id).then(() => {
      cogoToast.success('Estudiante eliminado!');
      history.replace('/estudiantes');
    }).catch((error) => {
      cogoToast.error(error.message);
      formState.setSubmitting(false);
      formState.setConfirmingDelete(false);
    })
  };

  const deactivateEstudiante = (): void => {
    formState.setSubmitting(true);

    putEstudiante(id, {isActive: !isActive})
      .then((response) => {
        cogoToast.success(response.data.isActive? 'Estudiante activado': 'Estudiante desactivado');
        onEstudianteChange(response.data);
        history.replace('/estudiantes');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setConfirmingDeactivation(false);
        formState.setSubmitting(false);
      });
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h3 className="m-0">
            {`${estudiante.nombreSocial || estudiante.nombre} ${estudiante.apellidos}`}{' '}
          </h3>
          <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            {!perfil && (<Button
              style={{ margin: '0 0 auto auto' }}
              variant="warning"
              size="sm"
              onClick={handleShow}>
              Editar
            </Button>)}
          </RolProtectedElement>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} lg={3}>
              <LabelValue label="Nombre" value={nombreSocial || '-'} />
            </Col>
            <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            <Col xs={6} lg={3}>
              <LabelValue label="Nombre Legal" value={nombre || '-'} />
            </Col>
            </RolProtectedElement>
            <Col xs={6} lg={3}>
              <LabelValue label="Apellidos" value={apellidos || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Rut" value={rut ? formatRut(`${rut}${getRutVerifier(Number(rut))}`.toString()) : '-'} />
            </Col>
            {email && (
              <Col xs={6} lg={3}>
                <small>Email</small>
                <p>
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              </Col>
            )}
            <Col xs={6} lg={3}>
              <LabelValue
                label="Fecha de nacimiento"
                value={fechaNacimiento ? format(fechaNacimiento, 'dd/MM/yyyy') : '-'}
              />
            </Col>
            <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            <Col xs={6} lg={3}>
              <LabelValue label="Estado" value={estado?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Género" value={genero || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="IVE" value={ive !== null && ive !== undefined ? ive : '-'} />
            </Col>
            </RolProtectedElement>
            <Col xs={6} lg={3}>
              <LabelValue label="Sede" value={estudiante.sede?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Programa" value={estudiante.estudiantePrograma?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Nivel" value={estudiante.nivel?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <small>Teléfono</small>
              <p>{telefono ? <a href={`tel:${telefono}`}>{telefono}</a> : '-'}</p>
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Dirección" value={direccion || '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Establecimiento" value={establecimiento?.nombre || '-'} />
            </Col>
            <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            <Col xs={12} lg={6}>
              <LabelValue label="Observaciones" value={observaciones || '-'} />
            </Col>
            </RolProtectedElement>
            <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            </RolProtectedElement>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Estudiante</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <EstudianteFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesSelectOptions}
            onCancel={handleClose}
            programasOptions={programasSelectOptions}
            nivelesOptions={nivelesSelectOption}
            establecimientosOptions={establecimientosOptions}
            estadosOptions={estadosOptions}
            onDelete={deleteCurrentEstudiante}
            onDeactivation={deactivateEstudiante}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EstudianteInfoCard;
