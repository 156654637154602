import React, { ReactElement, useContext } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LayoutContext from '../../contexts/LayoutContext';

const HeaderBreadCrumb = (): ReactElement => {
  const { breadcrumbItems } = useContext(LayoutContext);

  const renderBreadcrumbItems = (): Array<ReactElement> =>
    breadcrumbItems.map((breadcrumbItem) => {
      const { label, href, active } = breadcrumbItem;

      return (
        <BreadcrumbItem linkAs="div" active={active}>
          {href ? <Link to={href}>{label}</Link> : label}
        </BreadcrumbItem>
      );
    });

  return <Breadcrumb>{renderBreadcrumbItems()}</Breadcrumb>;
};

export default HeaderBreadCrumb;
