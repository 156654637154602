import React, {ReactElement} from 'react';
import { Form , Button, Spinner } from 'react-bootstrap';
import { FormState } from '../../hooks/useFormState';
import { NotasEstudiante, NotasEstudianteForm } from '../../models/paralelo/Paralelo';
import { roundNota } from '../../utils/nota';
import { formatRut } from '../../utils/rut';
import { Input } from '../form/input/Input';
import NumberInput from '../form/input/NumberInput';
import Table, { TableColumn } from '../table/Table';
import ParaleloNotas from './ParaleloNotas';

interface ParaleloNotasFormProps {
  formState: FormState<NotasEstudianteForm[]>;
  submit: (notasEstudiantesForms: NotasEstudianteForm[]) => void;
  cancel: () => void;
}

const ParaleloNotasForm = ({formState, submit, cancel}: ParaleloNotasFormProps): ReactElement => {
  const {
    value,
    handleFieldChange,
    isSubmitting,
    setForm,
  } = formState;

  const handleSubmitClick = (): void => {
    submit(value);
  }

  if (value.length === 0) {
    return <p className="text-center">No hay estudiantes</p>;
  }

  const columns: TableColumn<NotasEstudianteForm>[] = [
    {
      title: 'Estudiante',
      accessor: (notasEstudianteForm) => {
        const { rut, nombre, apellidos } = notasEstudianteForm.estudiante;

        return `${nombre} ${apellidos} ${formatRut(rut || '') || ''}`;
      },
      noWrap: true,
    },
    ...value[0].evaluaciones.map(({evaluacion}, evaluacionIndex): TableColumn<NotasEstudianteForm> => {
      return {
        title: evaluacion.nombre,
        accessor: (notasEstudianteForm): ReactElement => {
          const notaEvaluacionForm = notasEstudianteForm.evaluaciones.find((testNotaEvaluacion) => testNotaEvaluacion.evaluacion.id === evaluacion.id);
          
          const onNotaChange = (nota: string): void => {
              const updatedForms = [...value];

              const notasEstudianteFormIndex = updatedForms.indexOf(notasEstudianteForm);

              const updatedNotasEstudianteForm = {...notasEstudianteForm};

              const updatedNotaEvaluacion = {
                ...updatedNotasEstudianteForm.evaluaciones[evaluacionIndex],
                nota,
              };

              updatedNotasEstudianteForm.evaluaciones[evaluacionIndex] = updatedNotaEvaluacion;

              let promedioCalculado = 0;

              updatedNotasEstudianteForm.evaluaciones.forEach((notaEvaluacion) => {
                promedioCalculado += Number(notaEvaluacion.nota || 0) * notaEvaluacion.evaluacion.peso;
              });

              updatedNotasEstudianteForm.promedioCalculado = promedioCalculado;

              updatedForms[notasEstudianteFormIndex] = updatedNotasEstudianteForm;

              setForm(updatedForms);
          };

          const inputValue = notaEvaluacionForm !== null ? notaEvaluacionForm?.nota || '' : '';

          return <NumberInput value={inputValue} onChange={onNotaChange}/>;
        },
      }
    }),
    {
      title: 'Promedio calculado',
      accessor: ({promedioCalculado}) => {
        return <Input value={roundNota(promedioCalculado)} disabled />;
      }
    },
    {
      title: 'Promedio final',
      accessor: (notasEstudianteForm) => {
        const onPromedioChange = (promedio: string): void => {
          setForm((prevForms) => {
            const updatedForms = [...prevForms];

            const notasEstudianteFormIndex = updatedForms.indexOf(notasEstudianteForm);

            const updatedNotasEstudianteForm = {...notasEstudianteForm};

            updatedNotasEstudianteForm.promedioFinal = promedio;

            updatedForms[notasEstudianteFormIndex] = updatedNotasEstudianteForm;

            return updatedForms;
          })
        }

        return <NumberInput value={notasEstudianteForm.promedioFinal} onChange={onPromedioChange}/>
      }
    }
  ]

  return (
    <Form>
      <Table responsive columns={columns} items={value} />
      <hr />
      <div className="text-right">
        <Button
          className="mr-2"
          variant="secondary"
          onClick={cancel} 
          disabled={isSubmitting}>
            Cancelar
        </Button>
        <Button
          variant="success"
          onClick={handleSubmitClick}
          disabled={isSubmitting}>
            {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Guardar cambios'}
        </Button>
      </div>
    </Form>
  )
};

export default ParaleloNotasForm;
