import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router';
import { Alert, Card, Col, Row, Spinner } from 'react-bootstrap';
import useCurso from '../../hooks/useCurso';
import CursoInfo from '../../components/cursos/CursoInfo';
import CursoParalelosTable from '../../components/cursos/CursoParalelosTable';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import CursoEvaluacionesTable from '../../components/cursos/CursoEvaluacionesTable';
import NewParaleloButton from '../../components/paralelos/NewParaleloButton';
import NewEvaluacionButton from '../../components/evaluaciones/NewEvaluacionButton';
import { Evaluacion } from '../../models/evaluaciones/Evaluacion';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';

const CursoPage = (): ReactElement => {
  const { cursoId } = useParams();

  const { curso, setCurso, isLoading } = useCurso(cursoId);

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Cursos',
      },
      {
        label: curso?.nombre || '',
        active: true,
      },
    ]);
  }, [breadcrumb, curso]);

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!curso) {
    return <p className="text-center">El curso no existe</p>;
  }

  const addEvaluacion = (newEvaluacion: Evaluacion): void => {
    setCurso({
      ...curso,
      evaluaciones: [...curso.evaluaciones, newEvaluacion].sort(
        (a, b) => a.correlativo - b.correlativo,
      ),
    });
  };

  const sumaPesosEvaluaciones: number = curso.evaluaciones.reduce((acc, ev) => acc + Number(ev.peso), 0)

  return (
    <>
    <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
      <Card>
        <Card.Body>
          <CursoInfo curso={curso} onCursoChange={setCurso} />
        </Card.Body>
      </Card>
      </RolProtectedElement>
      <Card className="mt-3">
        <Card.Header>
          <Row>
            <Col md={6} className="d-flex">
              <h5 className="my-auto">Paralelos</h5>
            </Col>
            <Col md={6} className="text-right">
              <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
                <NewParaleloButton programaId={curso.programa.id} cursoId={curso.id} />
              </RolProtectedElement>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <CursoParalelosTable paralelos={curso.paralelos} />
        </Card.Body>
      </Card>
      <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
      <Card className="mt-3">
        <Card.Header>
          <Row>
            <Col md={6} className="d-flex">
              <h5 className="my-auto">Evaluaciones</h5>
            </Col>
            <Col md={6} className="text-right">
            <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador, Rol.Profesor]}>
              <NewEvaluacionButton curso={curso} onCreate={addEvaluacion} />
            </RolProtectedElement>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {
            sumaPesosEvaluaciones < 1 &&
            <Alert variant='warning'>Advertencia: Las ponderaciones de las evaluaciones no suman 100%</Alert>
          }
          <CursoEvaluacionesTable evaluaciones={curso.evaluaciones} cursoId={curso.id} />
        </Card.Body>
      </Card>
      </RolProtectedElement>
    </>
  );
};

export default CursoPage;
