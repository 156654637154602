import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { Evaluacion } from '../models/evaluaciones/Evaluacion';
import { getEvaluacion } from '../api/evaluaciones/evaluacionesApi';

interface UseEvaluacionHookValue {
  evaluacion?: Evaluacion;
  setEvaluacion: Dispatch<SetStateAction<Evaluacion | undefined>>;
  isLoading: boolean;
}

const useEvaluacion = (evaluacionId: string): UseEvaluacionHookValue => {
  const [evaluacion, setEvaluacion] = useState<Evaluacion>();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getEvaluacion(evaluacionId)
      .then(setEvaluacion)
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [evaluacionId]);

  return {
    evaluacion,
    setEvaluacion,
    isLoading,
  };
};

export default useEvaluacion;
