import { format, parse } from 'date-fns';
import {
  Estudiante,
  EstudianteForm,
  EstudianteRequestBody,
} from '../models/estudiantes/Estudiante';
import { Apoderado, ApoderadoForm } from '../models/estudiantes/Apoderado';
import { SelectOption } from '../models/form/Select';
import BasePrograma from '../models/programas/Programa';
import { BaseEstablecimiento } from '../models/establecimientos/Establecimiento';
import Sede from '../models/sedes/Sede';
import Nivel from '../models/niveles/Nivel';
import { getRutVerifier } from './rut';
import Estado from '../models/estados/Estado';

export const getEmptyEstudianteForm = (): EstudianteForm => ({
  nombre: '',
  apellidos: '',
  nombreSocial: '',
  rut: '',
  fechaNacimiento: '',
  direccion: '',
  telefono: '',
  email: '',
  ive: '',
  observaciones: '',
  genero: '',
  apoderadosForm: [],
});

export const getEmptyApoderadoForm = (): ApoderadoForm => ({
  nombre: '',
  apellidos: '',
  rut: '',
  email: '',
  telefono: '',
  direccion: '',
});

export const formatEstudiantePostBody = (estudianteForm: EstudianteForm): EstudianteRequestBody => {
  const {
    nombre,
    apellidos,
    rut,
    fechaNacimiento,
    genero,
    telefono,
    direccion,
    email,
    nombreSocial,
    ive,
    observaciones,
    establecimiento,
    sede,
    nivel,
    programa,
    apoderadosForm,
    estado,
  } = estudianteForm;

  return {
    nombre,
    apellidos: apellidos || undefined,
    rut: Number(rut ? rut.slice(0, -1) : undefined),
    fechaNacimiento: fechaNacimiento
      ? parse(fechaNacimiento, 'dd/MM/yyyy', new Date()).getTime()
      : undefined,
    genero: genero || undefined,
    nombreSocial: nombreSocial || undefined,
    direccion: direccion || undefined,
    telefono: telefono || undefined,
    email: email || undefined,
    ive: ive.length > 0 ? Number(ive) : undefined,
    observaciones: observaciones || undefined,
    establecimientoId: establecimiento ? establecimiento.value.id : undefined,
    sedeId: sede ? sede.value.id : undefined,
    nivelId: nivel ? nivel.value.id : undefined,
    programaId: programa ? programa.value.id : undefined,
    estadoId: estado ? estado.value.id : undefined,
    apoderados:
      apoderadosForm.length > 0
        ? apoderadosForm.map((apoderadoForm) => ({
            nombre: apoderadoForm.nombre,
            apellidos: apoderadoForm.apellidos || undefined,
            rut: apoderadoForm.rut ? apoderadoForm.rut.slice(0, -1) : undefined,
            email: apoderadoForm.email || undefined,
            telefono: apoderadoForm.telefono || undefined,
            direccion: apoderadoForm.direccion || undefined,
          }))
        : undefined,
  };
};

const formatApoderadosForms = (apoderados: Apoderado[]): ApoderadoForm[] =>
  apoderados.map(
    ({ nombre = '', apellidos = '', rut = '', email = '', telefono = '', direccion = '' }) => ({
      nombre,
      apellidos,
      rut: rut ? `${rut.toString()}${getRutVerifier(Number(rut))}` : '',
      email,
      telefono,
      direccion,
    }),
  );

export const formatEstudianteForm = (
  estudiante: Estudiante,
  sedesOptions: SelectOption<Sede>[],
  programasOptions: SelectOption<BasePrograma>[],
  nivelesOptions: SelectOption<Nivel>[],
  establecimientosOptions: SelectOption<BaseEstablecimiento>[],
  estadoOptions: SelectOption<Estado>[],
): EstudianteForm => {
  const {
    nombre,
    apellidos = '',
    rut = '',
    fechaNacimiento = '',
    ive = '',
    telefono = '',
    email = '',
    observaciones = '',
    nombreSocial = '',
    genero = '',
    direccion = '',
    apoderados = [],
    isActive,
  } = estudiante;

  const sedesSelectOption = sedesOptions.find((option) => option.value.id === estudiante.sede?.id);

  const programasSelectOption = programasOptions.find(
    (option) => option.value.id === estudiante.estudiantePrograma?.programaId,
  );

  const nivelesSelectOption = nivelesOptions.find(
    (option) => option.value.id === estudiante.nivel?.id,
  );

  const establecimientosSelectOption = establecimientosOptions.find(
    (option) => option.value.id === estudiante.establecimiento?.id,
  );

  const estadosSelectOption = estadoOptions.find(
    (option) => option.value.id === estudiante.estado?.id,
  );

  return {
    nombre,
    apellidos,
    rut: `${rut}${getRutVerifier(Number(rut))}`,
    fechaNacimiento: fechaNacimiento ? format(new Date(fechaNacimiento), 'dd/MM/yyyy') : '',
    ive: ive ? ive.toString() : '',
    telefono: telefono ? telefono.toString() : '',
    observaciones,
    nombreSocial,
    genero,
    direccion,
    email,
    apoderadosForm: formatApoderadosForms(apoderados),
    sede: sedesSelectOption,
    nivel: nivelesSelectOption,
    programa: programasSelectOption,
    establecimiento: establecimientosSelectOption,
    estado: estadosSelectOption,
    isActive
  };
};
