import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import { Card, Spinner } from 'react-bootstrap';
import { Egresado } from '../../models/egresados/Egresado';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { getEgresado } from '../../api/egresados/egresadosApi';
import EgresadoInfoCard from '../../components/egresados/EgresadoInfoCard';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import Rol from '../../models/usuarios/Rol';

const EgresadoPage = (): ReactElement => {
  const [egresado, setEgresado] = useState<Egresado>();
  const { egresadoId, perfil } = useParams();
  
  const breadcrumb = useBreadcrumb();

  const userCanEdit = useUserHasAnyRole([Rol.Administrador, Rol.Coordinador]);

  useEffect(() => {
    getEgresado(egresadoId)
      .then((response) => {
        setEgresado(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del egresado');
      });
  }, [egresadoId, setEgresado]);

  useEffect(() => {
    if (egresado) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Egresados',
        },
        {
          label: `${egresado.estudiante?.nombre} ${egresado.estudiante?.apellidos}`,
          active: true,
        },
      ]);
    }
  }, [egresado, breadcrumb]);

  if (!egresado)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleEgresadoChange = (changedEgresado: Egresado): void => {
    setEgresado(changedEgresado);
  }

  return (
    <>
      <EgresadoInfoCard egresado={egresado} perfil={perfil} onEgresadoChange={handleEgresadoChange} />
    </>
  );
};
export default EgresadoPage;
