import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import ProfesoresFilters from '../../models/profesores/ProfesoresFilters';
import { BaseProfesor } from '../../models/profesores/Profesor';
import { getProfesores } from '../../api/profesores/profesoresApi';
import Table, { TableColumn } from '../table/Table';
import { formatRut } from '../../utils/rut';

interface ProfesoresListProps {
  profesoresfilters: ProfesoresFilters;
  setProfesoresFilters: Dispatch<SetStateAction<ProfesoresFilters>>;
}

const ProfesorList = ({
  profesoresfilters,
  setProfesoresFilters,
}: ProfesoresListProps): ReactElement => {
  const [profesores, setProfesores] = useState<BaseProfesor[]>();
  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getProfesores(profesoresfilters)
      .then((profesoresList): void => {
        setProfesores(profesoresList.items);
        setPages(profesoresList.pages);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      });
  }, [profesoresfilters, setProfesores]);

  const handleProfesorClick = (profesor: BaseProfesor): void => {
    history.push(`/profesores/${profesor.id}`);
  };

  const tableColumns: TableColumn<BaseProfesor>[] = [
    {
      title: 'Nombre',
      accessor: (profesor) => (`${profesor.nombre} ${profesor.apellidos}`),
    },
    {
      title: 'Rut',
      accessor: (profesor) => (profesor.rut ? formatRut(profesor.rut.toString()) : ''),
    },
    {
      title: 'Programa',
      accessor: (profesor) => profesor.programa.nombre,
    },
    {
      title: 'Email',
      accessor: 'email',
    },
    {
      title: 'Teléfono',
      accessor: 'telefono',
    },
  ];

  if (profesoresfilters.includeInactive) {
    tableColumns.push({
      title: 'Estado',
      accessor: (profesor) => {return profesor.isActive? 'Activo': 'Inactivo'},
      hideUntil: 'md'
    })
  }

  if (!profesores)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <Table
        items={profesores}
        columns={tableColumns}
        hover
        onItemClick={handleProfesorClick}
        minRowsCount={profesoresfilters.itemsPerPage}
        pages={pages}
        activePage={profesoresfilters.page}
        onPageSelect={(page): void => setProfesoresFilters({ ...profesoresfilters, page })}
      />
    </>
  );
};
export default ProfesorList;
