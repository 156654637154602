import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import EgresadosFilters from '../../models/egresados/EgresadosFilters';
import EgresadosFiltersComponent from '../../components/egresados/EgresadosFiltersComponent';
import useSedesOptions from '../../hooks/useSedesOptions';
import useInstitucionesOptions from '../../hooks/useInstitucionesOptions';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import EgresadosList from '../../components/egresados/EgresadosList';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import Rol from '../../models/usuarios/Rol';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import useIngresosOptions from '../../hooks/useIngresosOptions';
import useBeneficiosOptions from '../../hooks/useBeneficiosOptions';
import useCarrerasOptions from '../../hooks/useCarrerasOptions';
import useRegionesOptions from '../../hooks/useRegionesOptions';
import useTiposInstitucionesOptions from '../../hooks/useTiposInstitucion';

const EgresadosPage = (): ReactElement => {
  const [egresadosFilters, setEgresadosFilters] = useState<EgresadosFilters>({
    nombreORut: '',
    page: 1,
    itemsPerPage: 8,
  });

  const sedesOptions = useSedesOptions();
  const regionesOptions = useRegionesOptions();
  const institucionesOptions = useInstitucionesOptions();
  const programasOptions = useProgramasOptions();
  const tiposBeneficiosOptions = useTiposInstitucionesOptions();
  const ingresosOptions = useIngresosOptions();
  const beneficiosOptions = useBeneficiosOptions();
  const carrerasOptions = useCarrerasOptions();

  const breadcrumb = useBreadcrumb();

  const shouldShowAddButton =  useUserHasAnyRole([Rol.Coordinador, Rol.Administrador]);

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Egresados',
        href: '/egresados',
      },
    ]);
  }, [breadcrumb]);

  const updateIncludeInactiveFilter = () => {
    setEgresadosFilters((prevFilters) => ({
      ...prevFilters,
      includeInactive: !egresadosFilters.includeInactive,
    }));
  }

  return (
    <>
    <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
      <Card className="mb-3">
        <Card.Body>
          <EgresadosFiltersComponent
            sedesOptions = {sedesOptions}
            regionesOptions = {regionesOptions}
            institucionesOptions = {institucionesOptions}
            programasOptions = {programasOptions}
            ingresosOptions = {ingresosOptions}
            beneficiosOptions= {beneficiosOptions}
            carrerasOptions = {carrerasOptions}
            tiposInstitucionOptions = {tiposBeneficiosOptions}
            onFiltersChange={setEgresadosFilters}
            egresadosFilters={egresadosFilters}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <EgresadosList
            setEgresadosFilters={setEgresadosFilters}
            egresadosFilters={egresadosFilters}
          />
        </Card.Body>
      </Card>
      </RolProtectedElement>
    </>
  );
};

export default EgresadosPage;
