import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SesionForm } from '../../models/sesiones/Sesion';
import { FormState } from '../../hooks/useFormState';
import { Input } from '../form/input/Input';
import Select from '../form/select/Select';
import { BaseTutor } from '../../models/tutores/Tutor';
import { SelectOption } from '../../models/form/Select';
import FormButtons from '../form/form-buttons/FormButtons';
import { EntrevistaForm } from '../../models/entrevistas/Entrevista';
import { BaseCoordinador } from '../../models/coordinadores/Coordinador';

interface EditarEntrevistaFormProps {
  formState: FormState<EntrevistaForm>;
  submitForm: (entrevistaForm: EntrevistaForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  coordinadoresOptions: SelectOption<BaseCoordinador>[];
}
const EditarEntrevistaForm = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  coordinadoresOptions,
}: EditarEntrevistaFormProps): ReactElement => {
  const {
    value: entrevistaForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !entrevistaForm.fecha || !entrevistaForm.coordinador || !entrevistaForm.tema;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(entrevistaForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const cancelDelete = () => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Fecha"
              name="fecha"
              placeholder="Fecha"
              value={entrevistaForm.fecha}
              onChangeWithName={handleFieldChange}
              type="date"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Coordinador"
              name="coordinador"
              placeholder="Coordinador"
              value={entrevistaForm.coordinador}
              options={coordinadoresOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !entrevistaForm.coordinador}
              invalidText="Debe seleccionar un coordinador"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Tema"
              name="tema"
              placeholder="Tema"
              value={entrevistaForm.tema}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalidText="Debe ingresar el tema"
              invalid={areValidationsEnabled && !entrevistaForm.tema}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Resumen"
              name="resumen"
              placeholder="Resumen"
              value={entrevistaForm.resumen}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              type="textarea"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Observación"
              name="observacion"
              placeholder="Observación"
              value={entrevistaForm.observacion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              type="textarea"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Compromiso"
              name="compromiso"
              placeholder="Compromiso"
              value={entrevistaForm.compromiso}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              type="textarea"
            />
          </Col>
        </Row>
        <FormButtons
          submitButtonContent="Actualizar Entrevista"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar Entrevista"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default EditarEntrevistaForm;