import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import Beneficio from '../models/egresados/Beneficio';
import { getTiposBeneficio } from '../api/beneficios/beneficiosApi';

const useBeneficiosOptions = (): SelectOption<Beneficio>[] => {
  const [beneficiosOptions, setBeneficiosOptions] = useState<
    SelectOption<Beneficio>[]
  >([]);

  useEffect(() => {
    getTiposBeneficio()
      .then((response) => {
        setBeneficiosOptions(
          response.data.map((tipoBeneficio) => {
            return { label: tipoBeneficio.nombre, value: tipoBeneficio };
          }),
        );
      })
      .catch(() => {});
  }, [setBeneficiosOptions]);
  return beneficiosOptions;
};
export default useBeneficiosOptions;
