import React, { ReactElement, ReactNode } from 'react';
import Sidebar from './sidebar/Sidebar';
import ContentContainer from './ContentContainer';
import Header from './Header';
import LayoutStore from '../../stores/LayoutStore';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps): ReactElement => {
  return (
    <>
      <LayoutStore>
        <Header />
        <Sidebar />
        <ContentContainer>{children}</ContentContainer>
      </LayoutStore>
    </>
  );
};

export default Layout;
