import React, { ReactElement } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { getEmptyColaboradorForm } from '../../utils/establecimientos';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import getComunasSelectGroups from '../../resources/comunas';
import { ColaboradorForm } from '../../models/establecimientos/Colaborador';
import RutInput from '../form/input/RutInput';
import ColaboradorFormComponent from '../colaboradores/ColaboradorFormComponent';
import { SelectOption } from '../../models/form/Select';
import { EstablecimientoForm } from '../../models/establecimientos/Establecimiento';
import TipoEstablecimiento from '../../models/establecimientos/TipoEstablecimiento';
import FormButtons from '../form/form-buttons/FormButtons';

interface EstablecimientoFormComponentProps {
  formState: FormState<EstablecimientoForm>;
  submitForm: (establecimientoForm: EstablecimientoForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  tiposEstablecimientoOptions: SelectOption<TipoEstablecimiento>[];
}

const EstablecimientoFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  tiposEstablecimientoOptions,
}: EstablecimientoFormComponentProps): ReactElement => {
  const {
    value: establecimientoForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const comunasSelectGroups = getComunasSelectGroups();

  const isFormInvalid = !establecimientoForm.nombre;

  const handleComunaChange = (fieldName: string, value: SelectOption<string>): void => {
    const ciudad = comunasSelectGroups.find((ciudadGroup) => ciudadGroup.options.includes(value));

    if (ciudad) handleFieldChange('ciudad', ciudad.label);

    handleFieldChange(fieldName, value);
  };

  const handleColaboradorFormChange = (
    colaboradorIndex: number,
    newValue: ColaboradorForm,
  ): void => {
    const updatedColaboraboresForm = [...establecimientoForm.colaboradoresForms];

    updatedColaboraboresForm[colaboradorIndex] = newValue;

    handleFieldChange('colaboradoresForms', updatedColaboraboresForm);
  };

  const addColaboradorForm = (): void => {
    const updatedColaboraboresForm = [
      ...establecimientoForm.colaboradoresForms,
      getEmptyColaboradorForm(),
    ];

    handleFieldChange('colaboradoresForms', updatedColaboraboresForm);
  };

  const deleteColaboradorFormAtIndex = (index: number): void => {
    const updatedColaboraboresForm = [...establecimientoForm.colaboradoresForms];

    updatedColaboraboresForm.splice(index, 1);

    handleFieldChange('colaboradoresForms', updatedColaboraboresForm);
  };

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(establecimientoForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={establecimientoForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !establecimientoForm.nombre}
              invalidText="Debe ingresar el nombre del establecimiento"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <RutInput
              title="RBD"
              name="rbd"
              placeholder="RBD"
              value={establecimientoForm.rbd}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Comuna"
              name="comuna"
              placeholder="Seleccione una comuna"
              value={establecimientoForm.comuna}
              options={comunasSelectGroups}
              onSelectWithName={handleComunaChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Dirección"
              name="direccion"
              placeholder="Dirección"
              value={establecimientoForm.direccion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Nombre del director"
              name="nombreDirector"
              placeholder="Nombre"
              value={establecimientoForm.nombreDirector}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={establecimientoForm.telefono}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Tipo de establecimiento"
              name="tipoEstablecimiento"
              placeholder="Seleccione un tipo"
              value={establecimientoForm.tipoEstablecimiento}
              options={tiposEstablecimientoOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        <hr />
        <h5>Colaboradores</h5>
        {establecimientoForm.colaboradoresForms.map((colaboradorForm, index) => (
          <>
            {index > 0 && <hr />}
            <ColaboradorFormComponent
              colaboradorForm={colaboradorForm}
              onChange={(newValue) => handleColaboradorFormChange(index, newValue)}
              onDeleteClick={(): void => deleteColaboradorFormAtIndex(index)}
              areValidationsEnabled={areValidationsEnabled}
              disabled={isSubmitting}
            />
          </>
        ))}
        <div className="text-center mt-4">
          <Button variant="success" onClick={addColaboradorForm} disabled={isSubmitting}>
            Agregar Colaborador
          </Button>
        </div>
        <hr />
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el establecimiento?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar establecimiento"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar establecimiento"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default EstablecimientoFormComponent;
