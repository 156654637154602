import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getNivel } from '../api/estudiantes/estudiantesApi';
import Nivel from '../models/niveles/Nivel';

const useNivelesOptions = (): SelectOption<Nivel>[] => {
  const [nivelesOptions, setNivelesOptions] = useState<SelectOption<Nivel>[]>([]);

  useEffect(() => {
    getNivel()
      .then((response) => {
        setNivelesOptions(
          response.data.map((nivel) => {
            return { label: nivel.nombre, value: nivel };
          }),
        );
      })
      .catch(() => {});
  }, [setNivelesOptions]);
  return nivelesOptions;
};
export default useNivelesOptions;
