import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import RutInput from '../form/input/RutInput';
import { SelectOption } from '../../models/form/Select';
import { UsuarioForm } from '../../models/usuarios/Usuario';
import FormButtons from '../form/form-buttons/FormButtons';
import Sede from '../../models/sedes/Sede';
import BasePrograma from '../../models/programas/Programa';

interface UsuarioFormComponentProps {
  formState: FormState<UsuarioForm>;
  submitForm: (usuarioForm: UsuarioForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  sedesOptions: SelectOption<Sede>[];
  programasOptions: SelectOption<BasePrograma>[];
}

const UsuarioFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  sedesOptions,
}: UsuarioFormComponentProps): ReactElement => {
  const {
    value: usuarioForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !usuarioForm.nombre;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(usuarioForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={usuarioForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !usuarioForm.nombre}
              invalidText="Debe ingresar el nombre del usuario"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={usuarioForm.apellidos}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <RutInput
              title="RUT"
              name="rut"
              placeholder="RUT"
              value={usuarioForm.rut}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Email"
              name="email"
              placeholder="Email"
              value={usuarioForm.email}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Dirección"
              name="direccion"
              placeholder="Dirección"
              value={usuarioForm.direccion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={usuarioForm.telefono}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Sede"
              name="sede"
              placeholder="Seleccione una sede"
              value={usuarioForm.sede}
              options={sedesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el usuario?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar usuario"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar usuario"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default UsuarioFormComponent;
