import {
  Establecimiento,
  EstablecimientoForm,
  EstablecimientoRequestBody,
} from '../models/establecimientos/Establecimiento';
import { Colaborador, ColaboradorForm } from '../models/establecimientos/Colaborador';
import { SelectGroup, SelectOption } from '../models/form/Select';
import TipoEstablecimiento from '../models/establecimientos/TipoEstablecimiento';

export const getEmptyEstablecimientoForm = (): EstablecimientoForm => ({
  nombre: '',
  rbd: '',
  ciudad: '',
  nombreDirector: '',
  emailDirector: '',
  direccion: '',
  telefono: '',
  ive: '',
  observaciones: '',
  colaboradoresForms: [],
});

export const getEmptyColaboradorForm = (): ColaboradorForm => ({
  nombre: '',
  rut: '',
  email: '',
  telefono: '',
  cargo: '',
});

export const formatEstablecimientoPostBody = (
  establecimientoForm: EstablecimientoForm,
): EstablecimientoRequestBody => {
  const {
    nombreDirector,
    colaboradoresForms,
    ciudad,
    comuna,
    tipoEstablecimiento,
    telefono,
    direccion,
    rbd,
    nombre,
    emailDirector,
    ive,
    observaciones,
  } = establecimientoForm;

  return {
    nombre,
    rbd: Number(rbd) || undefined,
    ciudad: ciudad || undefined,
    comuna: comuna ? comuna.label : undefined,
    nombreDirector: nombreDirector || undefined,
    emailDirector: emailDirector || undefined,
    direccion: direccion || undefined,
    telefono: telefono || undefined,
    ive: ive.length > 0 ? Number(ive) : undefined,
    observaciones: observaciones || undefined,
    tipoEstablecimientoId: tipoEstablecimiento ? tipoEstablecimiento.value.id : undefined,
    colaboradores:
      colaboradoresForms.length > 0
        ? colaboradoresForms.map((colaboradorForm) => ({
          nombre: colaboradorForm.nombre,
          rut: Number(colaboradorForm.rut) || undefined,
          email: colaboradorForm.email || undefined,
          telefono: colaboradorForm.telefono || undefined,
          cargo: colaboradorForm.cargo || undefined,
        }))
        : undefined,
  };
};

const formatColaboradoresForms = (colaboradores: Colaborador[]): ColaboradorForm[] =>
  colaboradores.map(({ nombre = '', cargo = '', email = '', rut, telefono = '' }) => ({
    nombre,
    cargo,
    email,
    rut: rut ? rut.toString() : '',
    telefono,
  }));

export const formatEstablecimientoForm = (
  establecimiento: Establecimiento,
  comunaGroups: SelectGroup<string>[],
  tipoEstablecimientoOptions: SelectOption<TipoEstablecimiento>[],
): EstablecimientoForm => {
  const {
    nombre,
    emailDirector = '',
    direccion = '',
    nombreDirector = '',
    ciudad = '',
    colaboradores = [],
    ive = '',
    observaciones = '',
    rbd = '',
    telefono = '',
  } = establecimiento;

  const comunaSelectOption = comunaGroups
    .find((comunaGroup) => comunaGroup.label === ciudad)
    ?.options.find((option) => option.value === establecimiento.comuna);

  const tipoEstablecimientoSelectOption = tipoEstablecimientoOptions.find(
    (option) => option.value.id === establecimiento.tipoEstablecimiento?.id,
  );

  return {
    nombre,
    emailDirector,
    nombreDirector,
    ciudad,
    direccion,
    comuna: comunaSelectOption,
    colaboradoresForms: formatColaboradoresForms(colaboradores),
    observaciones,
    telefono,
    tipoEstablecimiento: tipoEstablecimientoSelectOption,
    ive: ive ? ive.toString() : '',
    rbd: rbd ? rbd.toString() : '',
  };
};
