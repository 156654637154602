import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { updateParaleloEstudiantes } from '../../api/paralelos/paralelosApi';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import { BaseEstudiante } from '../../models/estudiantes/Estudiante';
import { ExtendedParalelo } from '../../models/paralelo/Paralelo';
import Rol from '../../models/usuarios/Rol';
import ParaleloEstudiantesForm from './ParaleloEstudiantesForm';

interface ParaleloEstudiantesProps {
  paralelo: ExtendedParalelo;
  onAdd: () => void;
}

const ParaleloEstudiantes = ({ paralelo, onAdd }: ParaleloEstudiantesProps): ReactElement => {
  const [isSubmittingEstudiantes, setSubmittingEstudiantes] = useState(false);

  const [isFormModalActive, setFormModalActive] = useState(false);

  const { cantidadEstudiantes } = paralelo;
  
  const shouldShowManageEstudiantesButton = useUserHasAnyRole([Rol.Administrador, Rol.Administrador]);

  let cantidadEstudiantesText = `${cantidadEstudiantes}`;

  if (cantidadEstudiantes !== 1) {
    cantidadEstudiantesText += ' estudiantes inscritos';
  } else {
    cantidadEstudiantesText += ' estudiante inscrito';
  }

  const openFormModal = (): void => {
    setFormModalActive(true);
  }

  const closeFormModal = (): void => {
    setFormModalActive(false);
  }

  const addEstudiantes = (estudiantes: BaseEstudiante[]):void => {
    setSubmittingEstudiantes(true);

    const estudiantesIds = estudiantes.map((estudiante) => estudiante.id);

    updateParaleloEstudiantes(paralelo.id, estudiantesIds)
    .then(() => {
      setFormModalActive(false);
      
      cogoToast.success('Listado de estudiantes actualizada.');

      onAdd();
    })
    .catch((error) => {
      cogoToast.error(error.message);
    })
    .finally(() => {
      setSubmittingEstudiantes(false);
    });
  }

  return (
    <>
      <Row>
        <Col md={6} className="d-flex">
          <h4 className="my-auto">{cantidadEstudiantesText}</h4>
        </Col>
        <Col md={6} className="text-left text-md-right">
          {shouldShowManageEstudiantesButton && (
            <Button className="mt-2 mt-0" variant="success" onClick={openFormModal}>
              Gestionar estudiantes
            </Button>
          )}
        </Col>
      </Row>
      <Modal show={isFormModalActive} onHide={closeFormModal}>
        <Modal.Header>
          <Modal.Title className="text-sm-center">Gestionar estudiantes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ParaleloEstudiantesForm 
            paralelo={paralelo} 
            submit={addEstudiantes} 
            isLoading={isSubmittingEstudiantes}
            onCancel={closeFormModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ParaleloEstudiantes;
