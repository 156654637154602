import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import TipoMovimiento from '../models/movimientos/TipoMovimiento';
import getTiposMovimientos from '../api/movimientos/tiposMovimientosApi';

const useTiposMovimientosOptions = (): SelectOption<TipoMovimiento>[] => {
  const [tiposMovimientoOptions, setTiposMovimientoOptions] = useState<
    SelectOption<TipoMovimiento>[]
  >([]);

  useEffect((): void => {
    getTiposMovimientos()
      .then((response): void => {
        setTiposMovimientoOptions(
          response.data.map((tipo) => {
            return { label: tipo.nombre, value: tipo };
          }),
        );
      })
      .catch(() => {});
  }, [setTiposMovimientoOptions]);

  return tiposMovimientoOptions;
};

export default useTiposMovimientosOptions;
