import React, { ReactElement, ReactNode } from 'react';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import Rol from '../../models/usuarios/Rol';

interface RolProtectedElementProps {
  children: ReactNode;
  roles: Rol[];
}

const RolProtectedElement = ({children, roles}: RolProtectedElementProps): ReactElement => {
  const userHasAccess = useUserHasAnyRole(roles);
  return (
    <>
      {userHasAccess && children}
    </> 
  );
};

export default RolProtectedElement;
