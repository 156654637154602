import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Select from '../../components/form/select/Select';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { SelectOption } from '../../models/form/Select';
import { TiposReportes } from '../../models/reportes/Reportes';
import ReporteAsistenciaGenerator from './ReporteAsistenciaGenerator';
import ReporteBonoLocomocion from './ReporteBonoLocomocion';

const ReportesPage = (): ReactElement => {
  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Reportes',
        active: true,
      },
    ]);
  }, [breadcrumb]);

  const tiposReportesOptions: SelectOption<string>[] = [
    { label: TiposReportes.Asistencia, value: TiposReportes.Asistencia },
    { label: TiposReportes.Locomocion, value: TiposReportes.Locomocion }
  ];
  const [selectedReporte, setSelectedReporte] = useState<SelectOption<string>>(tiposReportesOptions[0] || undefined)

  let reporte: ReactElement;

  switch (selectedReporte.value) {
    case TiposReportes.Asistencia:
      reporte = <ReporteAsistenciaGenerator />
      break;
    case TiposReportes.Locomocion:
      reporte = <ReporteBonoLocomocion />
      break;
    default:
      reporte = <ReporteAsistenciaGenerator />
      break;
  }

  return (
    <>
    <Card className="mb-3">
      <Card.Header>
        Seleccione el tipo de reporte a generar
      </Card.Header>
      <Card.Body>
        <Select
          title="Tipo de reporte"
          placeholder=""
          value={tiposReportesOptions[0] || undefined}
          options={tiposReportesOptions}
          onSelect={(selectedReporte: SelectOption<string>): void => {
            setSelectedReporte(selectedReporte);
          }}
        />
      </Card.Body>
    </Card>
    {reporte}
    </>
  )
}

export default ReportesPage;