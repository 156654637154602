import axios, { AxiosPromise } from 'axios';
import axiosWithAuth from '../axiosWithAuth';
import { ListResponse } from '../../models/personas/Persona';
import Credentials from '../../models/usuarios/Credentials';
import InscriptionRequestBody from '../../models/usuarios/Inscription';
import Usuario, {
  ChangeUserAccessRequestBody,
  UsuarioRequestBody,
} from '../../models/usuarios/Usuario';
import UsuariosFilters from '../../models/usuarios/UsuariosFilters';
import {
  transformApiBaseUsuarioIntoBaseUsuario,
  transformApiUsuarioIntoUsuario,
} from '../../utils/usuarios';

export function login(credentials: Credentials): AxiosPromise<string> {
  return axios.post('auth/login', credentials);
}

export function inscribeUser(inscriptionRequestBody: InscriptionRequestBody): AxiosPromise<string> {
  return axios.post(
    `invitaciones/${inscriptionRequestBody.invitationToken}/accept`,
    inscriptionRequestBody,
  );
}

export function changeUserAccess(
  changeUserAccessParams: ChangeUserAccessRequestBody,
): AxiosPromise<void> {
  return axiosWithAuth.patch(
    `usuarios/acceso/${changeUserAccessParams.rol}/${changeUserAccessParams.id}`,
    {
      isActive: changeUserAccessParams.isActive,
    },
  );
}

export async function getUsuarios(filters: UsuariosFilters): Promise<ListResponse<Usuario>> {
  const axiosResponse = await axiosWithAuth.get('usuarios', { params: filters });

  const { items, pages } = axiosResponse.data;

  return {
    items: items.map(transformApiBaseUsuarioIntoBaseUsuario),
    pages,
  };
}

export async function getUsuario(usuarioId: string): Promise<Usuario> {
  const axiosResponse = await axiosWithAuth.get(`usuarios/${usuarioId}`);

  const apiUsuario = axiosResponse.data;

  return transformApiUsuarioIntoUsuario(apiUsuario) as Usuario;
}

export async function putUsuario(
  usuarioId: number,
  requestBody: UsuarioRequestBody,
): Promise<Usuario> {
  const axiosResponse = await axiosWithAuth.put(`usuarios/${usuarioId}`, requestBody);

  return transformApiUsuarioIntoUsuario(axiosResponse.data) as Usuario;
}

export function deleteUsuario(usuarioId: number): AxiosPromise<void> {
  return axiosWithAuth.delete(`usuarios/${usuarioId}`);
}

export function requestPasswordReset(email: string): AxiosPromise<void> {
  return axios.post('auth/forgot-password', { email });
}

export function resetPassword(token: string, password: string): AxiosPromise<void> {
  return axios.post('auth/reset-password', { token, password });
}
