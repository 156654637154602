import React, { ReactElement } from 'react';
import ReactSelect, { Styles, ValueType } from 'react-select';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import { SelectGroup, SelectOption } from '../../../models/form/Select';
import colors from '../../../resources/colors';
import './select.scss';
import { Option } from 'react-select/src/filters';

interface FormSelectProps<T> {
  formGroupClassName?: string;
  name?: string;
  title?: string;
  value?: SelectOption<T>;
  options: SelectOption<T>[] | SelectGroup<T>[];
  onSelectWithName?: (name: string, selected: SelectOption<T>) => void;
  onSelect?: (selected: SelectOption<T>) => void;
  onClear?: () => void;
  onClearWithName?: (name: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  horizontal?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  helpText?: string;
  helpTextColor?: string;
  invalidText?: string;
  invalid?: boolean;
  isClearable?: boolean;
  applyFuzzySearch?: boolean;
  defaultValue?: SelectOption<T>;
}

const Select = <T,>({
  formGroupClassName,
  disabled,
  invalidText,
  helpText,
  helpTextColor,
  horizontal,
  invalid,
  isClearable,
  applyFuzzySearch: searchWithoutPunctuation,
  name,
  onBlur,
  onFocus,
  onSelect,
  onSelectWithName,
  onClear,
  onClearWithName,
  placeholder,
  tabIndex,
  title,
  value,
  options,
  defaultValue,
}: FormSelectProps<T>): ReactElement => {
  const formGroupClassNames = classNames('form-group', formGroupClassName, {
    row: horizontal && title,
  });

  const inputWrapperClassNames = classNames({
    'col-8': horizontal && title,
  });

  let label = null;
  if (title) {
    const labelClassNames = classNames({
      'col-4 col-form-label': horizontal && title,
    });

    label = (
      <label htmlFor={name} className={labelClassNames}>
        {title}
      </label>
    );
  }

  // @ts-ignore
  const handleChange = (selected?: ValueType<SelectOption<T>>): void => {
    if (selected) {
      if (onSelect) onSelect(selected as SelectOption<T>);
      if (onSelectWithName && name) onSelectWithName(name, selected as SelectOption<T>);
    } else {
      if (onClear) onClear();
      if (onClearWithName && name) onClearWithName(name);
    }
  };

  function onFuzzySearch(option: Option, filter: string) {
    if (filter) {
      const fuse = new Fuse([option], {keys: ['label']});
      if (fuse.search(filter).length !== 0) {
        return true;
      }
      return false;
    }
    return true;
  }
  
  // @ts-ignore
  const styles: Partial<Styles> = {
    // @ts-ignore
    control: (base) => ({
      ...base,
      borderColor: invalid ? colors.danger : base.borderColor,
    }),
  };

  return (
    <div className={formGroupClassNames}>
      {label}
      <div className={inputWrapperClassNames}>
        <ReactSelect
          className="select"
          classNamePrefix="select"
          name={name}
          placeholder={placeholder}
          isClearable={!!(onClear || onClearWithName)}
          value={value}
          options={options}
          onChange={handleChange}
          isDisabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          tabIndex={tabIndex?.toString()}
          styles={styles}
          // filterOption={onFuzzySearch}
        />
        {helpText && <small className={`text-${helpTextColor}`}>{helpText}</small>}
        {invalid && invalidText && <small style={{ color: colors.danger }}>{invalidText}</small>}
      </div>
    </div>
  );
};

export default Select;
