import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { Egresado, EgresadoForm } from '../../models/egresados/Egresado';
import useSedesOptions from '../../hooks/useSedesOptions';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import useNivelesOptions from '../../hooks/useNivelesOptions';
import useFormState from '../../hooks/useFormState';
import { formatEgresadoForm, formatEgresadoPostBody } from '../../utils/egresados';
import { deleteEgresado, putEgresado } from '../../api/egresados/egresadosApi';
import LabelValue from '../LabelValue';
import EgresadoFormComponent from './EgresadoFormComponent';
import { formatRut, getRutVerifier } from '../../utils/rut';
import Rol from '../../models/usuarios/Rol';
import RolProtectedElement from '../roles/RolProtectedElement';
import useEstadosOptions from '../../hooks/useEstadoOptions';
import useInstitucionesOptions from '../../hooks/useInstitucionesOptions';
import useBeneficiosOptions from '../../hooks/useBeneficiosOptions';
import useIngresosOptions from '../../hooks/useIngresosOptions';
import useCarrerasOptions from '../../hooks/useCarrerasOptions';

interface EgresadoInfoProps {
  egresado: Egresado;
  perfil?: string;
  onEgresadoChange: (editedEgresado: Egresado) => void;
}

const EgresadoInfoCard = ({
  egresado, perfil,
  onEgresadoChange,
}: EgresadoInfoProps): ReactElement => {

  const [showTasas, setShowTasas] = useState(false);

  const {
    id,
    estudiante,
  } = egresado;

  const sedesSelectOptions = useSedesOptions();
  const programasSelectOptions = useProgramasOptions();
  const nivelesSelectOption = useNivelesOptions();
  const institucionesOptions = useInstitucionesOptions();
  const estadosOptions = useEstadosOptions();
  const ingresosOptions = useIngresosOptions();
  const beneficiosOptions = useBeneficiosOptions();
  const carrerasOptions = useCarrerasOptions();
  const uploadButtonRef = useRef<HTMLButtonElement>(null);

  const mostrarTasasOcultar = () => {

    setShowTasas(!showTasas)

  };

  const formState = useFormState(
    formatEgresadoForm(
      egresado,
      sedesSelectOptions,
      programasSelectOptions,
      nivelesSelectOption,
      institucionesOptions,
      estadosOptions,
      ingresosOptions,
      beneficiosOptions,
      carrerasOptions,
    ),
  );

  useEffect(() => {
    formState.setForm(
      formatEgresadoForm(
        egresado,
        sedesSelectOptions,
        programasSelectOptions,
        nivelesSelectOption,
        institucionesOptions,
        estadosOptions,
        ingresosOptions,
        beneficiosOptions,
        carrerasOptions,
      ),
    );
  }, [
    sedesSelectOptions,
    programasSelectOptions,
    nivelesSelectOption,
    institucionesOptions,
    egresado,
    estadosOptions,
  ]);

  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (egresadoForm: EgresadoForm) => {
    putEgresado(id, formatEgresadoPostBody(egresadoForm))
      .then((response) => {
        formState.setSubmitting(false);
        setShow(false);
        onEgresadoChange(response.data);
        cogoToast.success('Egresado actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  const deleteCurrentEgresado = (): void => {
    formState.setSubmitting(true);
    deleteEgresado(id).then(() => {
      cogoToast.success('Egresado eliminado!');
      history.replace('/egresados');
    }).catch((error) => {
      cogoToast.error(error.message);
      formState.setSubmitting(false);
      formState.setConfirmingDelete(false);
    })
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h3 className="m-0">
            {`${egresado.estudiante?.nombre} ${egresado.estudiante?.apellidos}`}{' '}
          </h3>
          <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            {!perfil && (<Button
              style={{ margin: '0 0 auto auto' }}
              variant="warning"
              size="sm"
              onClick={handleShow}>
              Editar
            </Button>)}
          </RolProtectedElement>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} lg={3}>
              <LabelValue label="Nombre" value={egresado.estudiante?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Apellidos" value={egresado.estudiante?.apellidos || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Rut" value={egresado.estudiante?.rut ? formatRut(`${egresado.estudiante?.rut}${getRutVerifier(Number(egresado.estudiante?.rut))}`.toString()) : '-'} />
            </Col>
            {egresado.estudiante?.email && (
              <Col xs={6} lg={3}>
                <small>Email</small>
                <p>
                  <a href={`mailto:${egresado.estudiante?.email}`}>{egresado.estudiante?.email}</a>
                </p>
              </Col>
            )}
            <Col xs={6} lg={3}>
              <small>Teléfono</small>
              <p>{egresado.estudiante?.telefono ? <a href={`tel:${egresado.estudiante?.telefono}`}>{egresado.estudiante?.telefono}</a> : '-'}</p>
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Dirección" value={egresado.estudiante?.direccion || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tasa de Titulación Oportuna" value={egresado.tasaTitulacionOportuna + '%' || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tasa de Aprobación" value={egresado.tasaAprobacion + '%' || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tasa Retención 1° Año" value={egresado.tasaRetencionPrimerAno + '%' || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tasa Retención 2° Año" value={egresado.tasaRetencionSegundoAno + '%' || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tasa Retención 3° Año" value={egresado.tasaRetencionTercerAno + '%' || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Institución" value={egresado.institucion?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Carrera" value={egresado.carrera?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tipo de Ingreso" value={egresado.tipoIngreso?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tipo de Beneficio" value={egresado.tipoBeneficio?.nombre || '-'} />
            </Col>
            <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            </RolProtectedElement>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Egresado</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <EgresadoFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesSelectOptions}
            onCancel={handleClose}
            mostrarTasasOcultar={mostrarTasasOcultar}
            showTasas={showTasas}
            programasOptions={programasSelectOptions}
            nivelesOptions={nivelesSelectOption}
            institucionesOptions={institucionesOptions}
            estadosOptions={estadosOptions}
            ingresosOptions={ingresosOptions}
            beneficiosOptions={beneficiosOptions}
            carrerasOptions={carrerasOptions}
            onDelete={deleteCurrentEgresado}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EgresadoInfoCard;
