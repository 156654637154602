import React, { CSSProperties, ReactElement, ReactNode, useEffect } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';
import './table.scss';
import PaginationComponent from '../pagination/PaginationComponent';

export interface TableColumn<T> {
  title?: string;
  accessor: ItemValueAccessor<T>;
  ignoreClick?: boolean;
  hideUntil?: 'sm' | 'md' | 'lg' | 'xl';
  minWidth?: string;
  noWrap?: boolean;
  thClassName?: string;
  thStyle?: CSSProperties;
  tdClassName?: string;
  tdStyle?: CSSProperties;
}

type ItemValueAccessor<T> = ((item: T) => ReactNode) | string;

interface TableProps<T> {
  items: T[];
  columns: TableColumn<T>[];
  onItemClick?: (item: T) => void;
  stripped?: boolean;
  hover?: boolean;
  minRowsCount?: number;
  pages?: number;
  activePage?: number;
  onPageSelect?: (page: number) => void;
  responsive?: boolean;
}

const Table = <T,>({
  items,
  columns,
  onItemClick,
  stripped,
  hover,
  minRowsCount,
  pages,
  activePage,
  onPageSelect,
  responsive,
}: TableProps<T>): ReactElement => {
  useEffect(() => {
    if (onPageSelect) onPageSelect(1);
  }, [pages]);

  const rowStyle: CSSProperties = {
    cursor: onItemClick ? 'pointer' : 'inherit',
  };

  const renderEmptyRows = (): Array<ReactElement> => {
    if (!minRowsCount) return [];

    const emptyRowsCount = minRowsCount - items.length;

    if (emptyRowsCount <= 0) return [];

    return [...Array(emptyRowsCount)].map((_, rowIndex) => (
      <tr key={items.length + rowIndex}>
        {columns.map((__, columnIndex) => (
          <td key={columnIndex} />
        ))}
      </tr>
    ));
  };

  const getCellContent = (item: T, accessor: ItemValueAccessor<T>): ReactNode => {
    if (typeof accessor === 'string') {
      return ((item as unknown) as { [key: string]: ReactNode })[accessor as string];
    }

    return (accessor as (item: T) => ReactNode)(item);
  };

  const getThClassname = (column: TableColumn<T>): string => {
    let className = '';

    if (column.hideUntil) className += `d-none d-${column.hideUntil}-table-cell `;

    if (column.thClassName) className += column.thClassName;

    return className;
  };

  const getThStyle = (column: TableColumn<T>): CSSProperties => {
    let style: CSSProperties = {
      minWidth: column.minWidth,
      whiteSpace: column.noWrap ? 'nowrap' : undefined,
    };

    if (column.thStyle)
      style = {
        ...style,
        ...column.thStyle,
      };

    return style;
  };

  const getTdClassname = (column: TableColumn<T>): string => {
    let className = '';

    if (column.hideUntil) className += `d-none d-${column.hideUntil}-table-cell `;

    if (column.tdClassName) className += column.tdClassName;

    return className;
  };

  const getTdStyle = (column: TableColumn<T>): CSSProperties => {
    let style: CSSProperties = {
      minWidth: column.minWidth,
      whiteSpace: column.noWrap ? 'nowrap' : undefined,
    };

    if (column.tdStyle)
      style = {
        ...style,
        ...column.tdStyle,
      };

    return style;
  };

  return (
    <>
      <BootstrapTable size="sm" striped={stripped} hover={hover} responsive={responsive}>
        <thead>
          <tr>
            {
              columns.map((column, columnIndex) => (
                <th className={getThClassname(column)} style={getThStyle(column)} key={columnIndex}>
                  {column.title || ''}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {items.map((item, itemIndex) => (
            <tr
              key={itemIndex}
              style={rowStyle}>
              {columns.map((column, columnIndex) => (
                <td 
                  onClick={onItemClick && !column.ignoreClick ? (): void => onItemClick(item) : undefined}
                  className={getTdClassname(column)} 
                  style={getTdStyle(column)} 
                  key={columnIndex}
                  >
                  {getCellContent(item, column.accessor)}
                </td>
              ))}
            </tr>
          ))}
          {renderEmptyRows()}
        </tbody>
      </BootstrapTable>
      {!!pages && (
        <PaginationComponent
          pageCount={pages}
          activePage={activePage !== undefined ? activePage : 1}
          onChange={onPageSelect || ((): void => {})}
        />
      )}
    </>
  );
};

export default Table;
