import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import MovimientoInfoCard from '../../components/movimientos/MovimientoInfoCard';
import { getMovimiento } from '../../api/movimientos/movimientosApi';
import { BaseMovimiento } from '../../models/movimientos/Movimiento';
import useBreadcrumb from '../../hooks/useBreadcrumb';

const MovimientoPage = (): ReactElement => {
  const [movimiento, setMovimiento] = useState<BaseMovimiento>();

  const [isLoading, setLoading] = useState(true);

  const { movimientoId } = useParams();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    getMovimiento(movimientoId)
      .then((response): void => {
        setMovimiento(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del movimiento');
      })
      .then(() => setLoading(false));
  }, [movimientoId, setMovimiento]);

  useEffect(() => {
    if (movimiento) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Movimientos',
          href: '/movimientos',
        },
        {
          label: movimiento.motivo,
          active: true,
        },
      ]);
    }
  }, [movimiento, breadcrumb]);

  if (isLoading)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  if (!movimiento)
    return (
      <Card>
        <Card.Body className="text-center">
          {isLoading ? (
            <Spinner animation="border" />
          ) : (
            <p className="m-0">El movimiento no existe</p>
          )}
        </Card.Body>
      </Card>
    );

  const handleMovimientoChange = (changedMovimiento: BaseMovimiento): void =>
    setMovimiento(changedMovimiento);

  return (
    <>
      <MovimientoInfoCard
        movimiento={movimiento}
        onMovimientoChange={handleMovimientoChange}
      />
    </>
  );
};

export default MovimientoPage;
