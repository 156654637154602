import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import cogoToast from 'cogo-toast';
import { Button, Modal } from 'react-bootstrap';
import { MaterialInventario } from '../../models/inventario/MaterialInventario';
import Table, { TableColumn } from '../table/Table';
import CountInput from '../form/input/CountInput';
import { updateMaterialInventario } from '../../api/inventario/inventarioApi';
import {
  formatMaterialInventarioForm,
  formatMaterialInventarioRequestBody,
  getEmptyMaterialInventarioForm,
} from '../../utils/inventario';
import useSedesOptions from '../../hooks/useSedes';
import MaterialInventarioFormComponent from './MaterialInventarioFormComponent';
import useFormState from '../../hooks/useFormState';
import MaterialInventarioForm from '../../models/inventario/MaterialInventarioForm';
import useProgramasOptions from '../../hooks/useProgramasOptions';

interface InventarioListProps {
  materiales: MaterialInventario[];
  setMateriales: Dispatch<SetStateAction<MaterialInventario[]>>;
  isEditing?: boolean;
}

const InventarioList = ({
  materiales,
  setMateriales,
  isEditing,
}: InventarioListProps): ReactElement => {
  const [updatingMaterials, setUpdatingMaterials] = useState<MaterialInventario[]>([]);

  const materialInventarioFormState = useFormState(getEmptyMaterialInventarioForm());

  const [editingMaterialId, setEditingMaterialId] = useState(0);

  const [isEditModalOpen, setEditingModalOpen] = useState(false);

  const sedesOptions = useSedesOptions();

  const programasOptions = useProgramasOptions();

  const updateMaterialCountLocal = (material: MaterialInventario, newCount: string): void => {
    setMateriales((prevMateriales) =>
      prevMateriales.map((theMaterial) => {
        if (theMaterial.id === material.id) {
          return {
            ...material,
            cantidad: Math.max(Number(newCount), 0),
          };
        }
        return theMaterial;
      }),
    );
  };

  const updateMaterialCount = (material: MaterialInventario, newCount: string): void => {
    if (!updatingMaterials.includes(material))
      setUpdatingMaterials((prevMateriales) => [...prevMateriales, material]);

    setMateriales((prevMateriales) =>
      prevMateriales.map((theMaterial) => {
        if (theMaterial.id === material.id) {
          return {
            ...material,
            cantidad: Math.max(Number(newCount), 0),
          };
        }
        return theMaterial;
      }),
    );

    updateMaterialInventario(material.id, { cantidad: Number(newCount) })
      .catch((error) => {
        cogoToast.error(error.message);

        setMateriales((prevMateriales) =>
          prevMateriales.map((theMaterial) => {
            if (theMaterial.id === material.id) {
              return material;
            }
            return theMaterial;
          }),
        );
      })
      .finally(() =>
        setUpdatingMaterials((prevMateriales) =>
          prevMateriales.filter((theMaterial) => theMaterial.id !== material.id),
        ),
      );
  };

  const openEditionModal = (materialToEdit: MaterialInventario): void => {
    materialInventarioFormState.setForm(formatMaterialInventarioForm(materialToEdit, sedesOptions, programasOptions));

    setEditingMaterialId(materialToEdit.id);

    setEditingModalOpen(true);
  };

  const closeEditionModal = (): void => {
    if (!materialInventarioFormState.isSubmitting) setEditingModalOpen(false);
  };

  const updateMaterial = (updatedMaterialInventarioForm: MaterialInventarioForm): void => {
    materialInventarioFormState.setSubmitting(true);

    updateMaterialInventario(
      editingMaterialId,
      formatMaterialInventarioRequestBody(updatedMaterialInventarioForm),
    )
      .then((response) => {
        setMateriales((prevMateriales) =>
          prevMateriales.map((theMaterial) => {
            if (theMaterial.id === editingMaterialId) {
              return response.data;
            }
            return theMaterial;
          }),
        );

        closeEditionModal();

        cogoToast.success('Material actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => materialInventarioFormState.setSubmitting(false));
  };

  const tableColumns: TableColumn<MaterialInventario>[] = [
    {
      title: 'Material',
      accessor: 'nombre',
    },
    {
      title: 'Cantidad',
      accessor: (material) => {
        if (isEditing) {
          const isLoading = updatingMaterials.some((theMaterial) => theMaterial.id === material.id);

          return (
            <div className="d-flex" style={{ height: '2.2rem' }}>
              <CountInput
                formGroupClassName="m-auto"
                formGroupStyle={{ width: '8rem' }}
                value={material.cantidad || '0'}
                size="sm"
                onChange={(value) => updateMaterialCountLocal(material, value)}
                onBlur={() => updateMaterialCount(material, material.cantidad.toString())}
                onChangeByButton={(value) => updateMaterialCount(material, value)}
                disabled={isLoading}
              />
            </div>
          );
        }
        return material.cantidad;
      },
      thClassName: 'text-center',
      tdClassName: 'text-center',
      tdStyle: {
        width: '8rem',
        height: '2.2rem',
        padding: '0 0.5rem',
      },
      noWrap: true,
    },
    {
      title: 'Sede',
      accessor: (material) => material.sede.nombre,
    },
    {
      title: 'Ubicación',
      accessor: 'lugarFisico',
    },
    {
      title: 'Descripción',
      accessor: 'descripcion',
    },
    {
      title: 'Programa',
      accessor: (material) => material.programa.nombre,
    },
  ];

  if (isEditing)
    tableColumns.push({
      accessor: (material) => (
        <div className="d-flex d-flex" style={{ height: '2.2rem' }}>
          <Button
            className="m-auto"
            size="sm"
            variant="warning"
            onClick={() => openEditionModal(material)}>
            Editar
          </Button>
        </div>
      ),
      tdStyle: {
        padding: '0 0.5rem',
      },
    });

  return (
    <>
      <Table items={materiales} columns={tableColumns} responsive />
      <Modal show={isEditModalOpen} onHide={closeEditionModal}>
        <Modal.Header>
          <h4>Editar material</h4>
        </Modal.Header>
        <Modal.Body>
          <MaterialInventarioFormComponent
            formState={materialInventarioFormState}
            onCancel={closeEditionModal}
            submit={updateMaterial}
            sedesOptions={sedesOptions}
            programasOption={programasOptions}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InventarioList;
