import { useEffect, useState } from 'react';
import TipoEstablecimiento from '../models/establecimientos/TipoEstablecimiento';
import { SelectOption } from '../models/form/Select';
import getTiposEstablecimientos from '../api/establecimientos/tiposEstablecimientosApi';

const useTiposEstablecimientosOptions = (): SelectOption<TipoEstablecimiento>[] => {
  const [tiposEstablecimientoOptions, setTiposEstablecimientoOptions] = useState<
    SelectOption<TipoEstablecimiento>[]
  >([]);

  useEffect((): void => {
    getTiposEstablecimientos()
      .then((response): void => {
        setTiposEstablecimientoOptions(
          response.data.map((tipo) => {
            return { label: tipo.nombre, value: tipo };
          }),
        );
      })
      .catch(() => {});
  }, [setTiposEstablecimientoOptions]);

  return tiposEstablecimientoOptions;
};

export default useTiposEstablecimientosOptions;
