import React, { ReactElement } from 'react';
import { Input, InputProps } from './Input';

export interface NumberInputProps extends InputProps {
  positive?: boolean;
  min?: number;
  max?: number;
}

const NumberInput = ({
  onChangeWithName,
  onChange,
  onBlur,
  value,
  name,
  positive,
  min,
  max,
  ...inputGroupProps
}: NumberInputProps & InputProps): ReactElement => {
  const getNumberValue = (originalValue: string): string => {
    let formatedValue = originalValue.replace(/[^0-9.-]/g, '');

    let isNegative = false;

    if (formatedValue.length === 0) return '0';

    if (formatedValue.includes('-') && !positive) isNegative = true;

    formatedValue = formatedValue.split('-').join('');

    let dotIndex = formatedValue.indexOf('.');

    formatedValue = formatedValue.split('.').join('');

    if (formatedValue.length > 0) {
      if (dotIndex !== -1) {
        let intValue = Number.parseInt(formatedValue.slice(0, dotIndex), 10);
        if (Number.isNaN(intValue)) {
          intValue = 0;
          dotIndex += 1;
        }

        formatedValue = `${intValue}${formatedValue.slice(dotIndex)}`;
      } else formatedValue = Number.parseInt(formatedValue, 10).toString();
    }

    if (dotIndex !== -1) {
      formatedValue = `${formatedValue.slice(0, dotIndex)}.${formatedValue.slice(dotIndex)}`;
    }

    if (isNegative) formatedValue = `-${formatedValue}`;

    if (!formatedValue) formatedValue = '0';

    return formatedValue;
  };

  const handleChange = (newValue: string): void => {
    const numberValue = getNumberValue(newValue);
    if (onChange) onChange(numberValue);
  };

  const handleChangeWithName = (nameParam: string, newValue: string): void => {
    const numberValue = getNumberValue(newValue);
    if (onChangeWithName) onChangeWithName(nameParam, numberValue);
  };

  const handleBlur = (): void => {
    if (Number.isNaN(Number(value)) || value === '-0') {
      if (onChange) onChange('0');
      if (onChangeWithName && name) onChangeWithName(name, '0');
    } else {
      let numericValue = Number(value);

      if (min !== undefined) {
        numericValue = Math.max(numericValue, min);
      }

      if (max !== undefined) {
        numericValue = Math.min(numericValue, max);
      }

      if (onChange) onChange(numericValue.toString());

      if (onChangeWithName && name) onChangeWithName(name, numericValue.toString());
    }

    if (onBlur) onBlur();
  };

  return (
    <Input
      name={name}
      value={value ? getNumberValue(value.toString()) : ''}
      onChangeWithName={handleChangeWithName}
      onChange={handleChange}
      onBlur={handleBlur}
      {...inputGroupProps}
    />
  );
};

export default NumberInput;
