import React, { ReactElement } from 'react';
import { Apoderado } from '../../models/estudiantes/Apoderado';
import { formatRut, getRutVerifier } from '../../utils/rut';
import Table, { TableColumn } from '../table/Table';

interface ApoderadosInfoListProps {
  apoderados: Apoderado[];
}

const ApoderadoInfoList = ({ apoderados }: ApoderadosInfoListProps): ReactElement => {
  const tableColumns: TableColumn<Apoderado>[] = [
    {
      title: 'Nombre',
      accessor: 'nombre',
    },
    {
      title: 'Apellidos',
      accessor: 'apellidos',
      hideUntil: 'lg',
    },
    {
      title: 'Rut',
      accessor: (apoderado) => (apoderado.rut ? formatRut(`${apoderado.rut.toString()}${getRutVerifier(Number(apoderado.rut))}`) : ''),
      hideUntil: 'lg',
    },
    {
      title: 'Email',
      accessor: (apoderado) =>
        apoderado.email ? (
          <a rel="noreferrer" target="_blank" href={`mailto:${apoderado.email}`}>
            {apoderado.email}
          </a>
        ) : (
          ''
        ),
    },
    {
      title: 'Teléfono',
      accessor: (apoderado) =>
        apoderado.telefono ? (
          <a rel="noreferrer" target="_blank" href={`tel:${apoderado.telefono}`}>
            {apoderado.telefono}
          </a>
        ) : (
          ''
        ),
    },
    {
      title: 'Dirección',
      accessor: 'direccion',
      hideUntil: 'xl',
    },
  ];
  return <Table items={apoderados} columns={tableColumns} />;
};
export default ApoderadoInfoList;
