import React, { ReactElement } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApoderadoForm } from '../../models/estudiantes/Apoderado';
import { validateRut } from '../../utils/rut';
import { Input } from '../form/input/Input';
import RutInput from '../form/input/RutInput';

interface ApoderadoFormComponentProps {
  apoderadoForm: ApoderadoForm;
  onChange: (newValue: ApoderadoForm) => void;
  onDeleteClick?: () => void;
  areValidationsEnabled?: boolean;
  disabled: boolean;
}

const ApoderadoFormComponent = ({
  apoderadoForm,
  onChange,
  onDeleteClick,
  areValidationsEnabled,
  disabled,
}: ApoderadoFormComponentProps): ReactElement => {
  const handleFieldChange = (fieldName: string, value: string): void => {
    onChange({
      ...apoderadoForm,
      [fieldName]: value,
    });
  };
  return (
    <Row>
      <Col md={6}>
        <Input
          title="Nombre"
          name="nombre"
          placeholder="Nombre del apoderado"
          value={apoderadoForm.nombre}
          onChangeWithName={handleFieldChange}
          invalid={areValidationsEnabled && !apoderadoForm.nombre}
          invalidText="Debe ingresar el nombre del apoderado"
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Input
          title="Apellidos"
          name="apellidos"
          placeholder="Apellidos del apoderado"
          value={apoderadoForm.apellidos}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <RutInput
          title="Rut"
          name="rut"
          placeholder="Rut del apoderado"
          value={apoderadoForm.rut}
          onChangeWithName={handleFieldChange}
          invalid={areValidationsEnabled && !validateRut(apoderadoForm.rut)}
          invalidText="Rut Invalido"
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Input
          title="Correo"
          name="email"
          placeholder="Correo del apoderado"
          value={apoderadoForm.email}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Input
          title="Teléfono"
          name="telefono"
          placeholder="Teléfono del apoderado"
          value={apoderadoForm.telefono}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Input
          title="Dirección"
          name="direccion"
          placeholder="Dirección del apoderado"
          value={apoderadoForm.direccion}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      {!!onDeleteClick && (
        <Col md={6}>
          <div className="mt-md-3 text-center text-md-left">
            <Button
              className=" mt-md-3"
              variant="danger"
              onClick={onDeleteClick}
              disabled={disabled}>
              Eliminar Apoderado
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
};
export default ApoderadoFormComponent;
