import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { putUsuario } from '../../api/usuarios/usuariosApi';

interface EnableUserAccessButtonProps {
  userId: number;
  onChange?: () => (void);
}

const EnableUserAccessButton = ({userId, onChange}: EnableUserAccessButtonProps): ReactElement => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true);
    putUsuario(userId, {isEnabled: true})
      .then(() => {
        setIsDisabled(false);
        cogoToast.success('El usuario fue activado');
        onChange && onChange();
      })
      .catch((error) => cogoToast.error(error.message));
  }

  return (
    <>
      <Button variant='warning' size='sm' onClick={handleClick} disabled={isDisabled}>
        Habilitar
      </Button>
    </>
  )
}

export default EnableUserAccessButton;