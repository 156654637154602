import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { Card, Spinner } from 'react-bootstrap';
import AsistenciaFilters from '../../models/sesiones/AsistenciaFilters';
import { Asistencia } from '../../models/sesiones/Sesion';
import { getAsistencia } from '../../api/sesiones/sesionesPageRequests';
import Table, { TableColumn } from '../table/Table';
import EditarAsistenciaAux from './EditarAsistenciaAux';

interface AsistenciasListProps {
  asistenciaFilters: AsistenciaFilters;
  setAsistenciaFilters: Dispatch<SetStateAction<AsistenciaFilters>>;
  sesionId: number;
}

const AsistenciaList = ({
  asistenciaFilters,
  setAsistenciaFilters,
  sesionId,
}: AsistenciasListProps): ReactElement => {
  const [asistencias, setAsistencias] = useState<Asistencia[]>();
  const [pages, setPages] = useState(1);
  const [asistenciasEdit, setAsistenciasEdit] = useState<Asistencia[]>();

  const [asistenciaFiltersEdit, setAsistenciaFiltersEdit] = useState<AsistenciaFilters>({
    id: sesionId,
    page: 0,
    itemsPerPage: 1,
  });

  const loadAsistencias = useCallback(() => {
    getAsistencia(asistenciaFilters)
      .then((response) => {
        setAsistencias(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de las asistencias');
      });

      getAsistencia(asistenciaFiltersEdit)
      .then((response) => {
        setAsistenciasEdit(response.data.items);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de las asistencias');
      });
  }, [asistenciaFilters, setAsistencias, setAsistenciasEdit]);

  useEffect(loadAsistencias, [loadAsistencias]);

  const tableColumns: TableColumn<Asistencia>[] = [
    {
      title: 'Estudiante',
      accessor: (asistenciaTabla) =>
        `${asistenciaTabla.estudiante.nombre}  ${asistenciaTabla.estudiante.apellidos}`,
    },
    {
      title: 'Estado',
      tdStyle: { whiteSpace: 'nowrap' },
      accessor: (asistenciaTabla) => asistenciaTabla.tipoAsistencia.descripcion,
    },
    {
      title: 'Observaciones',
      accessor: (asistenciaTabla) =>
        `${
          asistenciaTabla.observaciones || ''
        }`
    },
  ];

  if (!asistencias) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Card className="my-3">
      <Card.Header className="d-flex pb-2">
        <h5 className="m-0 flex-grow-1">Asistencia</h5>
        {asistenciasEdit ? (
            <EditarAsistenciaAux asistencia={asistenciasEdit} onUpdate={loadAsistencias} />
          ) : null}
      </Card.Header>
      <Card.Body>
        {asistencias.length === 0 ? 
          (
            <p className="text-center">No hay estudiantes</p>
          ) : (
            <Table
              items={asistencias}
              columns={tableColumns}
              hover
              minRowsCount={asistenciaFilters.itemsPerPage}
              pages={pages}
              activePage={asistenciaFilters.page}
              onPageSelect={(page) => setAsistenciaFilters({ ...asistenciaFilters, page })}
            />
          )
        }
      </Card.Body>
    </Card>
  );
};
export default AsistenciaList;
