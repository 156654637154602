import { parse, format } from 'date-fns';
import {
  Asistencia,
  AsistenciaForm,
  AsistenciaPutBody,
  AsistenciasPutBodies,
  Sesion,
  SesionBitacoras,
  SesionBitacorasForm,
  SesionForm,
  SesionRequestBody,
} from '../models/sesiones/Sesion';
import { SelectOption } from '../models/form/Select';
import { Tutor } from '../models/tutores/Tutor';
import TipoAsistencia from '../models/sesiones/TipoAsistencia';

export const formatSesionForm = (
  sesion: Sesion,
  tutoresOptions: SelectOption<Tutor>[],
): SesionForm => {
  const {
    fecha = '',
    descripcion = '',
    observaciones = '',
    bitacoraProfesor = '',
    bitacoraTutor = '',
    tema,
    id = sesion.id,
  } = sesion;

  const tutoresSelectOptions = tutoresOptions.find((option) => option.value.id === sesion.tutorId);

  return {
    id,
    fecha: fecha ? format(new Date(fecha), 'dd/MM/yyyy') : '',
    descripcion,
    observaciones,
    bitacoraProfesor,
    bitacoraTutor,
    tutor: tutoresSelectOptions,
    tema,
  };
};

export const formatSesionPostBody = (sesionForm: SesionForm): SesionRequestBody => {
  const {
    id,
    fecha,
    descripcion,
    observaciones,
    bitacoraProfesor,
    bitacoraTutor,
    tutor,
    tema,
    paralelo,
  } = sesionForm;

  return {
    fecha: fecha ? parse(fecha, 'dd/MM/yyyy', new Date()).getTime() : undefined,
    descripcion: descripcion || undefined,
    observaciones: observaciones || undefined,
    bitacoraProfesor: bitacoraProfesor || undefined,
    bitacoraTutor: bitacoraTutor || undefined,
    tutorId: tutor ? tutor.value.id : undefined,
    paraleloId: paralelo ? paralelo.id : undefined,
    tema,
    id,
  };
};

export const getEmptySesionForm = (): SesionForm => ({
  fecha: '',
  tema: '',
  descripcion: '',
  observaciones: '',
  bitacoraProfesor: '',
  bitacoraTutor: '',
  id: 0,
});

export const formatSesionBitacoraForm = (sesionBitacoras: SesionBitacoras): SesionBitacorasForm => {
  let { bitacoraProfesor, bitacoraTutor, id } = sesionBitacoras;

  if (!bitacoraProfesor) {
    bitacoraProfesor = '';
  }
  if (!bitacoraTutor) {
    bitacoraTutor = '';
  }
  return {
    id,
    bitacoraProfesor,
    bitacoraTutor,
  };
};

export const formatSesionBitacoraPutBody = (
  sesionBitacorasForm: SesionBitacorasForm,
): SesionBitacoras => {
  const { bitacoraProfesor, bitacoraTutor, id } = sesionBitacorasForm;

  return {
    id,
    bitacoraProfesor: bitacoraProfesor || undefined,
    bitacoraTutor: bitacoraTutor || undefined,
  };
};

export const formatAsistenciaForm = (
  asistencia: Asistencia,
  tipoAsistenciaOptions: SelectOption<TipoAsistencia>[],
): AsistenciaForm => {
  const { id, estudiante, observaciones = '' } = asistencia;

  const options = tipoAsistenciaOptions.find(
    (option) => option.value.id === asistencia.tipoAsistencia.id,
  );

  return {
    id,
    estudiante,
    tipoAsistencia: options,
    observaciones,
  };
};

export const formatAsistenciaPutBody = (asistencia: AsistenciaForm): AsistenciaPutBody => {
  const { id, estudiante, observaciones, tipoAsistencia } = asistencia;

  return {
    id,
    tipoAsistenciaId: tipoAsistencia ? tipoAsistencia.value.id : undefined,
    observaciones,
    estudianteId: estudiante.id,
  };
};

export const formatAsistenciasPutBodies = (asistencias: AsistenciaForm[]): AsistenciasPutBodies => {
  const asistenciasPutBodies = asistencias.map(formatAsistenciaPutBody);
  return {
    asistencias: asistenciasPutBodies,
  };
};
