import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import Ingreso from '../models/egresados/Ingreso';
import { getTiposIngreso } from '../api/ingresos/ingresosApi';

const useIngresosOptions = (): SelectOption<Ingreso>[] => {
  const [ingresosOptions, setIngresosOptions] = useState<
    SelectOption<Ingreso>[]
  >([]);

  useEffect(() => {
    getTiposIngreso()
      .then((response) => {
        setIngresosOptions(
          response.data.map((tipoIngreso) => {
            return { label: tipoIngreso.nombre, value: tipoIngreso };
          }),
        );
      })
      .catch(() => {});
  }, [setIngresosOptions]);
  return ingresosOptions;
};
export default useIngresosOptions;
