import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfesoresFilters from '../../models/profesores/ProfesoresFilters';
import { SelectOption } from '../../models/form/Select';
import Select from '../form/select/Select';
import { Input } from '../form/input/Input';
import Sede from '../../models/sedes/Sede';
import BasePrograma from '../../models/programas/Programa';

interface ProfesoresFiltersProps {
  sedesOptions: SelectOption<Sede>[];
  programasOptions: SelectOption<BasePrograma>[];
  profesoresFilters: ProfesoresFilters;
  onFiltersChange: Dispatch<SetStateAction<ProfesoresFilters>>;
}

const ProfesoresFiltersComponent = ({
  sedesOptions,
  programasOptions,
  profesoresFilters,
  onFiltersChange,
}: ProfesoresFiltersProps): ReactElement => {
  return (
    <>
      <Row>
        <Col lg={4}>
          <Input
            value={profesoresFilters.nombreORut || ''}
            onChange={(value) => onFiltersChange({ ...profesoresFilters, nombreORut: value })}
            type="text"
            placeholder="Nombre o RUT"
            title="Nombre o RUT"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Programa"
            options={programasOptions}
            onSelect={(programa): void => {
              if (programa)
                onFiltersChange({
                  ...profesoresFilters,
                  programaId: (programa as SelectOption<BasePrograma>).value.id,
                });
            }}
            placeholder="Programa"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Sede"
            options={sedesOptions}
            onSelect={(sede): void => {
              if (sede)
                onFiltersChange({
                  ...profesoresFilters,
                  sedeId: (sede as SelectOption<Sede>).value.id,
                });
            }}
            placeholder="Sede"
          />
        </Col>
      </Row>
    </>
  );
};

export default ProfesoresFiltersComponent;
