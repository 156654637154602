import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getSedes } from '../api/estudiantes/estudiantesApi';
import Sede from '../models/sedes/Sede';

const useSedesOptions = (): SelectOption<Sede>[] => {
  const [sedesOptions, setSedesOptions] = useState<SelectOption<Sede>[]>([]);

  useEffect(() => {
    getSedes()
      .then((response) => {
        setSedesOptions(
          response.data.map((sede) => {
            return { label: sede.nombre, value: sede };
          }),
        );
      })
      .catch(() => {});
  }, [setSedesOptions]);
  return sedesOptions;
};
export default useSedesOptions;
