import React, { MouseEvent, ReactElement, useContext } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import queryString from 'query-string';
import { useHistory, useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import ucnLogo from '../../assets/images/ucn.png';
import { Input } from '../../components/form/input/Input';
import useFormState from '../../hooks/useFormState';
import { inscribeUser } from '../../api/usuarios/usuariosApi';
import InscriptionRequestBody from '../../models/usuarios/Inscription';
import AuthContext from '../../contexts/AuthContext';

const InvitationAcceptPage = (): ReactElement => {
  const {
    value,
    handleFieldChange,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    areValidationsEnabled,
  } = useFormState({ password: '', rewrittenPassword: '' });

  const { password, rewrittenPassword } = value;

  const { setJWT } = useContext(AuthContext);

  const history = useHistory();

  const { invitationToken } = useParams();

  const queryParams = queryString.parse(history.location.search);

  const getInvalidPasswordMessage = (): string => {
    if (!password) return 'Debe ingresar una contraseña';

    if (password.length < 6) return 'Debe tener un mínimo de 6 caracteres';

    return '';
  };

  const getInvalidRewrittenPasswordMessage = (): string => {
    if (!!password && !rewrittenPassword) return 'Debe reescribir la contraseña';

    if (password !== rewrittenPassword) return 'Las contraseñas no coinciden';

    return '';
  };

  const passwordInvalidMessage = getInvalidPasswordMessage();

  const rewrittenPasswordInvalidMessage = getInvalidRewrittenPasswordMessage();

  const handleSubmitClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (!password || !rewrittenPassword || rewrittenPassword !== password || password.length < 6)
      return;

    setSubmitting(true);

    const inscriptionRequestBody: InscriptionRequestBody = {
      invitationToken,
      password,
    };

    inscribeUser(inscriptionRequestBody)
      .then((response) => {
        cogoToast.success('Inscrito exitosamente!');
        const jwt = response.data;

        setJWT(jwt);

        history.replace('/');
      })
      .catch((error) => {
        setSubmitting(false);
        cogoToast.error(error.message);
      });
  };

  return (
    <div className="login-container">
      <div className="background" />
      <div className="head d-flex">
        <div className="text-center m-auto d-flex">
          <h4 className="text-center my-auto mr-2 text-dark">ProVETA</h4>
          <img className="mx-auto" width={50} height={50} src={ucnLogo} alt="Logo UCN" />
        </div>
      </div>
      <Card className="m-auto">
        <Card.Body>
          <h4 className="text-center mb-4 text-white">Registro</h4>
          <hr />
          <p className="text-justify">
            Ha sido invitado al sistema de ProVETA. <b>Por favor, cree su contraseña.</b>
          </p>
          <Form>
            <Input
              title="Correo electrónico"
              name="username"
              value={queryParams.email as string}
              type="text"
              disabled
            />
            <Input
              title="Contraseña"
              name="password"
              value={password}
              type="password"
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !!passwordInvalidMessage}
              invalidText={passwordInvalidMessage}
              invalidTextColor="#9a0024"
              disabled={isSubmitting}
            />
            <Input
              title="Reescriba la contraseña"
              name="rewrittenPassword"
              value={rewrittenPassword}
              type="password"
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !!rewrittenPasswordInvalidMessage}
              invalidText={rewrittenPasswordInvalidMessage}
              invalidTextColor="#9a0024"
              disabled={isSubmitting}
            />
            <Button
              type="submit"
              onClick={handleSubmitClick}
              className="w-100 mt-4"
              variant="outline-light"
              disabled={isSubmitting}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Registrarse'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InvitationAcceptPage;
