import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';
import useTiposMovimientosOptions from '../../hooks/useTiposMovimiento';
import MovimientosFiltersComponent from '../../components/movimientos/MovimientosFiltersComponent';
import MovimientoList from '../../components/movimientos/MovimientosList';
import useSedesOptions from '../../hooks/useSedesOptions';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import { BasePresupuesto } from '../../models/presupuestos/Presupuesto';
import { getPresupuesto } from '../../api/presupuestos/presupuestosApi';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import useAniosOptions from '../../hooks/useAnios';
import useMesesOptions from '../../hooks/useMeses';
import Persona from '../../models/personas/Persona';
import { getUsuario } from '../../api/usuarios/usuariosApi';
import AuthContext from '../../contexts/AuthContext';

const MovimientosPage = (): ReactElement => {
  const [movimientosfilters, setMovimientosFilters] = useState<MovimientosFilters>({
    page: 1,
    itemsPerPage: 8,
  });

  const [persona, setPersona] = useState<Persona>();

  const { id } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);

  const [presupuesto, setPresupuesto] = useState<BasePresupuesto>();

  const { presupuestoId } = useParams();

  const tiposMovimientosOptions = useTiposMovimientosOptions();
  const aniosOptions = useAniosOptions();
  const mesesOptions = useMesesOptions();
  const sedesOptions = useSedesOptions();
  const programasOptions = useProgramasOptions();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    getPresupuesto(presupuestoId, movimientosfilters)
      .then((response): void => {
        setPresupuesto(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del movimiento');
      })
      .then(() => setLoading(false));
  }, [presupuestoId, setPresupuesto]);

  useEffect(() => {
    getUsuario(id)
      .then((response): void => {
        setPersona(response);
      })
      .catch(() => {
        cogoToast.error('No se pudo identificar a la persona para el movimiento');
      })
      .then(() => setLoading(false));
  }, [id, setPersona]);

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Movimientos',
      },
    ]);
  }, [breadcrumb]);

  if (!persona)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );
  

  return (
    <>
      <Card className="mb-3">
      <MovimientosFiltersComponent
            persona={persona}
            presupuestoId={presupuestoId}
            aniosOptions={aniosOptions}
            mesesOptions={mesesOptions}
            tiposMovimientosOptions={tiposMovimientosOptions}
            movimientosFilters={movimientosfilters}
            onFiltersChange={setMovimientosFilters}
          />
      </Card>
      <Card>
        <Card.Body>
          <MovimientoList
            setMovimientosFilters={setMovimientosFilters}
            movimientosfilters={movimientosfilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default MovimientosPage;