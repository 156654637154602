import { useContext, useMemo } from 'react';
import LayoutContext from '../contexts/LayoutContext';
import { BreadcrumbItem } from '../models/layout/BreadcrumbItem';

interface UseBreadcrumbHookValue {
  setItems: (items: BreadcrumbItem[]) => void;
}

const useBreadcrumb = (): UseBreadcrumbHookValue => {
  const { setBreadcrumbItems } = useContext(LayoutContext);

  const breadcrumb = useMemo(
    () => ({
      setItems: setBreadcrumbItems,
    }),
    [setBreadcrumbItems],
  );

  return breadcrumb;
};

export default useBreadcrumb;
