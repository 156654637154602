import React, { ReactElement, useState } from 'react';
import { Button, Col, Row, Modal, ModalBody } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { Estudiante } from '../../models/estudiantes/Estudiante';
import { formatEntrevistaPostBody, getEmptyEntrevistaForm } from '../../utils/entrevista';
import useCoordinadoresOptions from '../../hooks/useCoordinadoresOptions';
import { EntrevistaForm } from '../../models/entrevistas/Entrevista';
import { postEntrevista } from '../../api/entrevistas/entrevistasPageRequests';
import EntrevistaFormComponent from './EntrevistaFormComponent';

interface EstudianteEntrevistasProps {
  estudiante: Estudiante; 
}

const EstudianteEntrevistas = ({ estudiante }: EstudianteEntrevistasProps): ReactElement => {
  const { cantidadEntrevistas } = estudiante;

  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyEntrevistaForm());

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const history = useHistory();

  const coordinadoresOptions = useCoordinadoresOptions(estudiante.sede.id);

  const submitForm = (entrevistaForm: EntrevistaForm) => {
    postEntrevista(formatEntrevistaPostBody(entrevistaForm))
      .then((response) => {
        const createdEntrevistaId = response.data.id;
        history.push(`/entrevistas/${createdEntrevistaId}`);
        cogoToast.success('Entrevista creada');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  let entrevistasRealizadasText = `Agregar Entrevistas`;

  return (
    <Row>
      <Col md={6} className="d-flex">
        <h4 className="my-auto">{entrevistasRealizadasText}</h4>
      </Col>
      <Col md={6} className="text-left text-md-right">
        <Button className="mt-2 mt-0" variant="success" onClick={handleShow}>
          Nuevo
        </Button>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Col className="text-sm-center">
              <Modal.Title>Agregar Entrevista</Modal.Title>
            </Col>
          </Modal.Header>
          <ModalBody>
            <EntrevistaFormComponent
              formState={formState}
              submitForm={submitForm}
              coordinadoresOptions={coordinadoresOptions}
              estudiante={estudiante}
              onCancel={handleClose}
            />
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  );
};

export default EstudianteEntrevistas;