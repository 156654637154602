import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import { Card, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { Entrevista, ExtendedEntrevista } from '../../models/entrevistas/Entrevista';
import { getEntrevista } from '../../api/entrevistas/entrevistasPageRequests';
import EntrevistaInfoCard from '../../components/entrevistas/EntrevistaInfoCard';

const EntrevistaPage = (): ReactElement => {
  const [entrevista, setEntrevista] = useState<ExtendedEntrevista>();
  const { entrevistaId } = useParams();
  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    getEntrevista(entrevistaId)
      .then((response) => {
        setEntrevista(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de la entrevista');
      });
  }, [entrevistaId, setEntrevista]);

  useEffect(() => {
    if (entrevista) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
      ]);
    }
  }, [breadcrumb, entrevista]);

  if (!entrevista)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleEntrevistaChange = (changedEntrevista: ExtendedEntrevista): void => setEntrevista(changedEntrevista);

  return (
    <>
      <EntrevistaInfoCard entrevista={entrevista} onEntrevistaChange={handleEntrevistaChange} />
    </>
  );
};
export default EntrevistaPage;