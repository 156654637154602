import { Evaluacion, EvaluacionPostBody } from '../../models/evaluaciones/Evaluacion';
import axios from '../axiosWithAuth';

export async function getEvaluacion(evaluacionId: string): Promise<Evaluacion> {
  const response = await axios.get(`cursos/evaluaciones/${evaluacionId}`);

  return response.data;
}

export async function updateEvaluacion(
  evaluacionPutBody: EvaluacionPostBody,
  evaluacionId: number | string,
): Promise<Evaluacion> {
  const response = await axios.put(`cursos/evaluaciones/${evaluacionId}`, evaluacionPutBody);

  return response.data;
}

export async function createEvaluacion(
  evaluacionPostBody: EvaluacionPostBody,
): Promise<Evaluacion> {
  const response = await axios.post(
    `cursos/${evaluacionPostBody.cursoId}/evaluaciones`,
    evaluacionPostBody,
  );

  return response.data;
}

export async function deleteEvaluacionById(evaluacionId: string | number): Promise<void> {
  await axios.delete(`cursos/evaluaciones/${evaluacionId}`);
}
