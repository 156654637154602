import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import { EstablecimientosGetResponse } from '../../models/establecimientos/Establecimiento';
import {
  BaseParaleloEstudiante,
  Estudiante,
  EstudianteRequestBody,
  EstudiantesGetResponse,
  ExtendedParaleloEstudiante,
} from '../../models/estudiantes/Estudiante';
import EstudiantesFilters from '../../models/estudiantes/EstudiantesFilters';
import BasePrograma from '../../models/programas/Programa';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';
import { getParaleloById } from '../paralelos/paralelosApi';

export function getEstablecimientos(): AxiosPromise<EstablecimientosGetResponse> {
  return axios.get('establecimientos');
}

export function getSedes(): AxiosPromise<Sede[]> {
  return axios.get('sedes');
}

export function getProgramas(): AxiosPromise<BasePrograma[]> {
  return axios.get('programas');
}

export function getNivel(): AxiosPromise<Nivel[]> {
  return axios.get('niveles');
}

export function getEstudiantes(filters: EstudiantesFilters): AxiosPromise<EstudiantesGetResponse> {
  return axios.get('estudiantes', { params: filters });
}

export function postEstudiante(requestBody: EstudianteRequestBody): AxiosPromise<Estudiante> {
  return axios.post('estudiantes', requestBody);
}
export function getEstudiante(estudianteId: string): AxiosPromise<Estudiante> {
  return axios.get(`estudiantes/${estudianteId}`);
}

export function getEstudianteEmail(estudianteEmail: string): AxiosPromise<Estudiante> {
  return axios.get(`estudiantes/perfil/${estudianteEmail}`);
}

export function putEstudiante(
  estudianteId: number,
  requestBody: EstudianteRequestBody,
): AxiosPromise<Estudiante> {
  return axios.put(`estudiantes/${estudianteId}`, requestBody);
}

export function deleteEstudiante(estudianteId: number): AxiosPromise<void> {
  return axios.delete(`estudiantes/${estudianteId}`);
}

export async function getParalelosByEstudianteEmail(email: string): Promise<BaseParaleloEstudiante[]> {

  const axiosResponse = await axios.get(`estudiantes/paralelosInscritos/${email}`);

  return axiosResponse.data;
}

export async function getParaleloEstudianteDetail(paraleloId: string, estudianteId: string): Promise<ExtendedParaleloEstudiante> {

  const axiosResponse = await axios.get(`estudiantes/paraleloDetalle/${paraleloId}/${estudianteId}`);

  let paraleloEstudiante = axiosResponse.data as ExtendedParaleloEstudiante;

  paraleloEstudiante.paralelo = await getParaleloById(paraleloEstudiante.id, estudianteId);

  return paraleloEstudiante;
}
