import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { putUsuario } from '../../api/usuarios/usuariosApi';

interface DisableUserAccessButtonProps {
  usuarioId: number;
  onChange?: () => void;
}

const DisableUserAccessButton = ({usuarioId: userId, onChange}: DisableUserAccessButtonProps): ReactElement => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true)
    putUsuario(userId, {isEnabled: false})
      .then(() => {
        setIsDisabled(false);
        cogoToast.success('El usuario fue desactivado');
        onChange && onChange();
      })
      .catch((error) => cogoToast.error(error.message));
  }

  return (
    <>
      <Button variant='warning' size='sm' onClick={handleClick} disabled={isDisabled}>
        Deshabilitar
      </Button>
    </>
  )
}

export default DisableUserAccessButton;