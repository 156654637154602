import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { SelectOption } from '../../models/form/Select';
import {
  formatMaterialInventarioRequestBody,
  getEmptyMaterialInventarioForm,
} from '../../utils/inventario';
import MaterialInventarioForm from '../../models/inventario/MaterialInventarioForm';
import { createMaterialInventario } from '../../api/inventario/inventarioApi';
import MaterialInventarioFormComponent from './MaterialInventarioFormComponent';
import Sede from '../../models/sedes/Sede';
import BasePrograma from '../../models/programas/Programa';

interface NewEstablecimientoButtonProps {
  sedesOptions: SelectOption<Sede>[];
  programasOptions: SelectOption<BasePrograma>[];
  onMaterialCreated: () => void;
}

const NewMaterialButton = ({
  sedesOptions,
  programasOptions,
  onMaterialCreated,
}: NewEstablecimientoButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyMaterialInventarioForm());

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (materialInventarioForm: MaterialInventarioForm): void => {
    formState.setSubmitting(true);

    createMaterialInventario(formatMaterialInventarioRequestBody(materialInventarioForm))
      .then(() => {
        onMaterialCreated();

        cogoToast.success('Material creado!');

        handleClose();

        formState.reset();
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => formState.setSubmitting(false));
  };

  return (
    <>
      <Button className="mt-3" variant="success" onClick={handleShow}>
        Agregar material
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar material</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <MaterialInventarioFormComponent
            formState={formState}
            submit={submitForm}
            sedesOptions={sedesOptions}
            programasOption={programasOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewMaterialButton;
