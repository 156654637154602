import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import {
  formatEstablecimientoPostBody,
  getEmptyEstablecimientoForm,
} from '../../utils/establecimientos';
import { EstablecimientoForm } from '../../models/establecimientos/Establecimiento';
import { postEstablecimiento } from '../../api/establecimientos/establecimientosApi';
import EstablecimientoFormComponent from './EstablecimientoFormComponent';
import { SelectOption } from '../../models/form/Select';
import TipoEstablecimiento from '../../models/establecimientos/TipoEstablecimiento';

interface NewEstablecimientoButtonProps {
  tiposEstablecimientosOptions: SelectOption<TipoEstablecimiento>[];
}

const NewEstablecimientoButton = ({
  tiposEstablecimientosOptions,
}: NewEstablecimientoButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyEstablecimientoForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (establecimientoForm: EstablecimientoForm): void => {
    postEstablecimiento(formatEstablecimientoPostBody(establecimientoForm))
      .then((response) => {
        const createdEstablecimientoId = response.data.id;

        history.push(`/establecimientos/${createdEstablecimientoId}`);

        cogoToast.success('Establecimiento creado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar Establecimiento
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Establecimiento</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <EstablecimientoFormComponent
            formState={formState}
            submitForm={submitForm}
            tiposEstablecimientoOptions={tiposEstablecimientosOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewEstablecimientoButton;
