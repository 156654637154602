import React, { ReactElement } from 'react';
import { AsistenciaForm } from '../../models/sesiones/Sesion';
import { SelectOption } from '../../models/form/Select';
import TipoAsistencia from '../../models/sesiones/TipoAsistencia';
import Select from '../form/select/Select';
import { FormState } from '../../hooks/useFormState';
import Table, { TableColumn } from '../table/Table';
import { Input } from '../form/input/Input';
import FormButtons from '../form/form-buttons/FormButtons';

interface EditarAsistenciaProps {
  formState: FormState<AsistenciaForm[]>;
  submitForm: (asistenciasForm: AsistenciaForm[]) => void;
  onCancel: () => void;
  tipoAsistenciasOptions: SelectOption<TipoAsistencia>[];
}
const EditarAsistencia = ({
  formState,
  submitForm,
  onCancel,
  tipoAsistenciasOptions,
}: EditarAsistenciaProps): ReactElement => {
  const {
    value: asistenciasForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setSubmitting(true);
    submitForm(asistenciasForm);
  };

  const tableColumns: TableColumn<AsistenciaForm>[] = [
    {
      title: 'Estudiante',
      accessor: (asistencia) =>
        `${asistencia.estudiante.nombre}  ${asistencia.estudiante.apellidos}`,
    },
    {
      title: 'Estado',
      tdStyle: { whiteSpace: 'nowrap' },
      accessor: (asistencia) => {
        return (
          <Select
            options={tipoAsistenciasOptions}
            placeholder="Estado"
            name="tipoAsistencia"
            value={asistencia.tipoAsistencia}
            defaultValue={asistencia.tipoAsistencia}
            disabled={isSubmitting}
            onSelect={(select) => {
              const updatedAsistenciasForm = asistenciasForm.map((asist) => {
                if (asistencia.id === asist.id) {
                  return {
                    id: asist.id,
                    estudiante: asist.estudiante,
                    tipoAsistencia: select,
                    observaciones: asist.observaciones,
                  };
                }
                return asist;
              });
              formState.setForm(updatedAsistenciasForm);
            }}
          />
        );
      },
    },
    {
      title: 'Observaciones',
      accessor: (asistencia) => {
        return (
          <Input
            name="observaciones"
            placeholder="Observaciones"
            value={asistencia.observaciones}
            disabled={isSubmitting}
            onChange={(observaciones) => {
              const updatedAsistenciasForm = asistenciasForm.map((asist) => {
                if (asistencia.id === asist.id) {
                  return {
                    id: asist.id,
                    estudiante: asist.estudiante,
                    tipoAsistencia: asist.tipoAsistencia,
                    observaciones,
                  };
                }
                return asist;
              });
              formState.setForm(updatedAsistenciasForm);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Table items={asistenciasForm} columns={tableColumns} hover />
      <FormButtons
        submitButtonContent="Guardar asistencia"
        onSubmitClick={handleSubmitClick}
        onCancelClick={onCancel}
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      />
    </>
  );
};
export default EditarAsistencia;
