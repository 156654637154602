import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from '../form/select/Select';
import EgresadosFilters from '../../models/egresados/EgresadosFilters';
import { Input } from '../form/input/Input';
import { SelectOption } from '../../models/form/Select';
import BasePrograma from '../../models/programas/Programa';
import {
  BaseInstitucion,
  Institucion,
} from '../../models/instituciones/Institucion';
import Region from '../../models/regiones/Region';
import Beneficio from '../../models/egresados/Beneficio';
import Ingreso from '../../models/egresados/Ingreso';
import { Carrera } from '../../models/carreras/Carrera';
import Sede from '../../models/sedes/Sede';
import Programa from '../../models/programas/Programa';
import TipoInstitucion from '../../models/instituciones/TipoInstitucion';

interface EgresadosFiltersProps {
  institucionesOptions: SelectOption<BaseInstitucion>[];
  programasOptions: SelectOption<BasePrograma>[];
  sedesOptions: SelectOption<Sede>[]
  regionesOptions: SelectOption<Region>[];
  beneficiosOptions: SelectOption<Beneficio>[];
  ingresosOptions: SelectOption<Ingreso>[];
  carrerasOptions: SelectOption<Carrera>[];
  tiposInstitucionOptions: SelectOption<TipoInstitucion>[];
  egresadosFilters: EgresadosFilters;
  onFiltersChange: Dispatch<SetStateAction<EgresadosFilters>>;
}

const EgresadosFiltersComponent = ({
  institucionesOptions,
  programasOptions,
  sedesOptions,
  regionesOptions,
  beneficiosOptions,
  ingresosOptions,
  carrerasOptions,
  egresadosFilters,
  tiposInstitucionOptions,
  onFiltersChange,
}: EgresadosFiltersProps): ReactElement => {
  return (
    <>
      <Row>
        <Col lg={4}>
          <Input
            value={egresadosFilters.nombreORut || ''}
            onChange={(value) => onFiltersChange({ ...egresadosFilters, nombreORut: value })}
            type="text"
            placeholder="Nombre o Rut"
            title="Nombre o Rut"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Programa"
            options={programasOptions}
            onSelect={(programa) => {
              if (programa)
                onFiltersChange({
                  ...egresadosFilters,
                  programaId: (programa as SelectOption<Programa>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
                programaId: undefined,
              });
            }}
            placeholder="Programa"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Sede"
            options={sedesOptions}
            onSelect={(sede) => {
              if (sede)
                onFiltersChange({
                  ...egresadosFilters,
                  institucionId: (sede as SelectOption<Sede>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
              sedeId: undefined,
              });
            }}
            placeholder="Sede"
          />
        </Col>
      </Row>
      <Row>
      <Col lg={4}>
          <Select
            title="Institución"
            options={institucionesOptions}
            onSelect={(institucion) => {
              if (institucion)
                onFiltersChange({
                  ...egresadosFilters,
                  institucionId: (institucion as SelectOption<Institucion>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
                institucionId: undefined,
              });
            }}
            placeholder="Institución"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Carrera"
            options={carrerasOptions}
            onSelect={(carrera) => {
              if (carrera)
                onFiltersChange({
                  ...egresadosFilters,
                  carreraId: (carrera as SelectOption<Carrera>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
                carreraId: undefined,
              });
            }}
            placeholder="Carrera"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Tipo Institución"
            options={tiposInstitucionOptions}
            onSelect={(tipoInstitucion) => {
              if (tipoInstitucion)
                onFiltersChange({
                  ...egresadosFilters,
                  tipoInstitucionId: (tipoInstitucion as SelectOption<TipoInstitucion>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
                tipoInstitucionId: undefined,
              });
            }}
            placeholder="Tipo Institución"
          />
        </Col>
       
      </Row>
      <Row>
        <Col lg={4}>
          <Select
            title="Región"
            options={regionesOptions}
            onSelect={(region) => {
              if (region)
                onFiltersChange({
                  ...egresadosFilters,
                  regionId: (region as SelectOption<Region>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
                regionId: undefined,
              });
            }}
            placeholder="Región"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Tipo Beneficio"
            options={beneficiosOptions}
            onSelect={(beneficio) => {
              if (beneficio)
                onFiltersChange({
                  ...egresadosFilters,
                  beneficioId: (beneficio as SelectOption<Beneficio>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
                beneficioId: undefined,
              });
            }}
            placeholder="Tipo Beneficio"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Tipo Ingreso"
            options={ingresosOptions}
            onSelect={(ingreso) => {
              if (ingreso)
                onFiltersChange({
                  ...egresadosFilters,
                  ingresoId: (ingreso as SelectOption<Ingreso>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...egresadosFilters,
                ingresoId: undefined,
              });
            }}
            placeholder="Tipo Ingreso"
          />
        </Col>
      </Row>
    </>
  );
};

export default EgresadosFiltersComponent;
