import { CursoForm, CursoPostBody, ExtendedCurso } from '../models/cursos/Curso';
import { SelectOption } from '../models/form/Select';
import Sede from '../models/sedes/Sede';
import BasePrograma from '../models/programas/Programa';
import Nivel from '../models/niveles/Nivel';

export function getEmptyCursoForm(): CursoForm {
  const todayDate = new Date();
  const year = todayDate.getFullYear().toString();
  const semestre = todayDate.getMonth() < 6 ? '1': '2';

  return {
    nombre: '',
    codigo: '',
    year,
    semestre,
  };
}

export function formatCursoForm(
  curso: ExtendedCurso,
  sedeOptions: SelectOption<Sede>[],
  programaOptions: SelectOption<BasePrograma>[],
  nivelOptions: SelectOption<Nivel>[],
): CursoForm {
  const { nombre, codigo, semestre, programa, sede, nivel, isActive, year } = curso;

  return {
    nombre,
    codigo,
    year: year.toString(),
    semestre: semestre.toString(),
    programa: programaOptions.find((programaOption) => programaOption.value.id === programa.id),
    sede: sedeOptions.find((sedeOption) => sedeOption.value.id === sede.id),
    nivel: nivelOptions.find((nivelOption) => nivelOption.value.id === nivel.id),
    isActive
  };
}

export function formatCursoPostBody(cursoForm: CursoForm): CursoPostBody {
  const { nombre, programa, semestre, codigo, sede, nivel, year } = cursoForm;

  if (!programa || !sede || !nivel || !year || !semestre ) {
    throw new Error('Invalid form value.');
  }

  return {
    nombre,
    semestre: Number(semestre),
    year: Number(year),
    codigo,
    programaId: programa.value.id,
    sedeId: sede.value.id,
    nivelId: nivel.value.id,
  };
}
