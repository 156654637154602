import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router-dom';
import { CursoForm, ExtendedCurso } from '../../models/cursos/Curso';
import LabelValue from '../LabelValue';
import useFormState from '../../hooks/useFormState';
import { formatCursoForm, formatCursoPostBody } from '../../utils/cursos';
import useSedesOptions from '../../hooks/useSedesOptions';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import EstablecimientoFormComponent from '../establecimientos/EstablecimientoFormComponent';
import CursoFormComponent from './CursoFormComponent';
import useNivelesOptions from '../../hooks/useNivelesOptions';
import { deleteCursoById, updateCurso } from '../../api/cursos/cursosApi';
import RolProtectedElement from '../roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';

interface CursoInfoProps {
  curso: ExtendedCurso;
  onCursoChange: (editedCurso: ExtendedCurso) => void;
}

const CursoInfo = ({ curso, onCursoChange }: CursoInfoProps): ReactElement => {
  const {
    id,
    programa,
    sede,
    year,
    semestre,
    codigo,
    nombre,
    nivel,
    isActive,
    evaluaciones,
    paralelos,
  } = curso;

  const history = useHistory();

  const nivelOptions = useNivelesOptions();

  const sedeOptions = useSedesOptions();

  const programaOptions = useProgramasOptions();

  const formState = useFormState(
    formatCursoForm(curso, sedeOptions, programaOptions, nivelOptions),
  ); 

  const [isFormModalActive, setFormModalActive] = useState(false);

  useEffect(() => {
    formState.setForm(formatCursoForm(curso, sedeOptions, programaOptions, nivelOptions));
  }, [formState.setForm, sedeOptions, programaOptions, nivelOptions]);

  const openFormModal = (): void => {
    setFormModalActive(true);
  };

  const closeFormModal = (): void => {
    setFormModalActive(false);
  };

  const cancelEdition = (): void => {
    closeFormModal();

    formState.reset();
  };

  const deleteCurso = (): void => {
    formState.setSubmitting(true);

    deleteCursoById(curso.id)
      .then(() => {
        cogoToast.success('Curso eliminado.');

        history.replace('/cursos');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  const deactivateCurso = (): void => {
    formState.setSubmitting(true);

    updateCurso(curso.id, {isActive: !curso.isActive})
      .then((updatedCurso) => {
        cogoToast.success(updatedCurso.isActive? 'Curso activado': 'Curso desactivado');
        onCursoChange(updatedCurso);
        history.replace('/cursos');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
        closeFormModal();
      });
  }

  const submitForm = (cursoForm: CursoForm): void => {
    formState.setSubmitting(true);

    updateCurso(curso.id, formatCursoPostBody(cursoForm))
      .then((updatedCurso) => {
        cogoToast.success('Curso actualizado.');

        onCursoChange(updatedCurso);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);

        closeFormModal();
      });
  };

  return (
    <>
      <div className="d-flex">
        <h3>{`${nombre} [${codigo}]`}</h3>
        <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
          <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={openFormModal}>
            Editar
          </Button>
          </RolProtectedElement>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="Nombre" value={nombre} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Código" value={codigo} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Periodo académico" value={`${curso.year}`} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Programa" value={programa.nombre} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Sede" value={sede.nombre} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Nivel" value={nivel.nombre} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Estado" value={isActive ? 'Abierto' : 'Cerrado'} />
        </Col>
      </Row>
      <Modal show={isFormModalActive} onHide={closeFormModal} size="xl">
        <Modal.Header closeButton>
          <div className="text-sm-center">
            <Modal.Title>Editar Curso</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CursoFormComponent
            formState={formState}
            submitForm={submitForm}
            nivelOptions={nivelOptions}
            programaOptions={programaOptions}
            sedeOptions={sedeOptions}
            onCancel={cancelEdition}
            onDelete={deleteCurso}
            onDeactivation={deactivateCurso}
            showDeactivationButton
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CursoInfo;
