import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import {
  Administrador,
  AdministradorRequestBody,
} from '../../models/administradores/Administrador';
import { transformApiAdministradorIntoAdministrador } from '../../utils/administradores';

export async function postAdministrador(requestBody: AdministradorRequestBody): Promise<Administrador> {
  const axiosResponse = await axios.post('administradores', requestBody);

  return transformApiAdministradorIntoAdministrador(axiosResponse.data);
}

export async function getAdministrador(administradorId: string): Promise<Administrador> {
  const axiosResponse = await axios.get(`administradores/${administradorId}`);

  const apiAdministrador = axiosResponse.data;

  return transformApiAdministradorIntoAdministrador(apiAdministrador);
}

export async function putAdministrador(
  administradorId: number,
  requestBody: AdministradorRequestBody,
): Promise<Administrador> {
  const axiosResponse = await axios.put(`administradores/${administradorId}`, requestBody);

  return transformApiAdministradorIntoAdministrador(axiosResponse.data);
}

export function deleteAdministrador(administradorId: number): AxiosPromise<void> {
  return axios.delete(`administradores/${administradorId}`);
}
