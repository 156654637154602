import axios from '../axiosWithAuth';
import { ReporteAsistenciaQuery } from '../../models/reportes/Reportes';
import { downloadFileWithAuth } from '../../utils/files';
import { base64 } from '../../models/files/DocumentFile';
import { ReporteBonoLocomocionQuery } from '../../models/reportes/ReportesBonoLocomocion';
import CursosFilters from '../../models/cursos/CursosFilters';

export async function getReporteAsistencia(query: ReporteAsistenciaQuery): Promise<void> {
    const response = await axios.get<base64>('reportes/asistencia', {params: query, responseType: 'blob'})
    downloadFileWithAuth(response.data, response.headers['filename'], response.headers['content-type']);
}

export async function getBonoLocomocion(query: ReporteBonoLocomocionQuery, filters: CursosFilters): Promise<void> {
    query.nivelId = filters.nivelId;
    query.sedeId = filters.sedeId;
    const response = await axios.get<base64>('reportes/bonoLocomocion', {params: query, responseType: 'blob'})
    downloadFileWithAuth(response.data, response.headers['filename'], response.headers['content-type']);
}