import React, { ReactElement } from 'react';
import { ExtendedParalelo, NotasEstudiante } from '../../models/paralelo/Paralelo';
import Table, { TableColumn } from '../table/Table';

interface EstudianteParaleloNotasProps {
  paralelo: ExtendedParalelo;
}

const EstudianteParaleloNotas = ({paralelo}: EstudianteParaleloNotasProps): ReactElement => {
  const { notasEstudiantes } = paralelo;

  if (notasEstudiantes.length === 0) {
    return <p className="text-center">El estudiante no tiene notas.</p>
  }

  const columns: TableColumn<NotasEstudiante>[] = [
    ...notasEstudiantes[0].evaluaciones.map(({evaluacion}): TableColumn<NotasEstudiante> => {
      return {
        title: evaluacion.nombre,
        accessor: (notasEstudiante) => {
          const notaEvaluacion = notasEstudiante.evaluaciones.find((testNotaEvaluacion) => testNotaEvaluacion.evaluacion.id === evaluacion.id);
          
          return notaEvaluacion?.nota !== null ? notaEvaluacion?.nota || '-' : '-';
        },
      }
    }),
    {
      title: 'Promedio',
      accessor: ({promedioCalculado, promedioFinal}) => promedioFinal !== null ? promedioFinal : promedioCalculado,
    },
  ];

  

  return (
    <Table columns={columns} items={notasEstudiantes} responsive/>
  );
};

export default EstudianteParaleloNotas;
