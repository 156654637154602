import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row, Card } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import {
  Establecimiento,
  EstablecimientoForm,
} from '../../models/establecimientos/Establecimiento';
import EstablecimientoFormComponent from './EstablecimientoFormComponent';
import {
  deleteEstablecimiento,
  putEstablecimiento,
} from '../../api/establecimientos/establecimientosApi';
import {
  formatEstablecimientoForm,
  formatEstablecimientoPostBody,
} from '../../utils/establecimientos';
import useFormState from '../../hooks/useFormState';
import useTiposEstablecimientosOptions from '../../hooks/useTiposEstablecimiento';
import getComunasSelectGroups from '../../resources/comunas';

interface EstablecimientosInfoProps {
  establecimiento: Establecimiento;
  onEstablecimientoChange: (editedEstablecimiento: Establecimiento) => void;
}

const EstablecimientoInfoCard = ({
  establecimiento,
  onEstablecimientoChange,
}: EstablecimientosInfoProps): ReactElement => {
  const {
    id,
    nombre,
    ciudad,
    comuna,
    direccion,
    emailDirector,
    ive,
    nombreDirector,
    rbd,
    telefono,
  } = establecimiento;

  const tiposEstablecimientoSelectOptions = useTiposEstablecimientosOptions();

  const formState = useFormState(
    formatEstablecimientoForm(
      establecimiento,
      getComunasSelectGroups(),
      tiposEstablecimientoSelectOptions,
    ),
  );

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const submitForm = (establecimientoForm: EstablecimientoForm): void => {
    putEstablecimiento(id, formatEstablecimientoPostBody(establecimientoForm))
      .then((response) => {
        formState.setSubmitting(false);

        setShow(false);

        onEstablecimientoChange(response.data);

        cogoToast.success('Establecimiento actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentEstablecimiento = (): void => {
    formState.setSubmitting(true);

    deleteEstablecimiento(id).then(() => {
      cogoToast.success('Establecimiento eliminado!');
      formState.setConfirmingDelete(false);
      history.replace('/establecimientos');
    }).catch((error) => {
      cogoToast.error(error.message);
      formState.setConfirmingDelete(false);
      formState.setSubmitting(false);
    })
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h3 className="m-0"> {nombre} </h3>
          <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={handleShow}>
            Editar
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} lg={3}>
              <LabelValue label="RBD" value={rbd || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Ciudad" value={ciudad || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Comuna" value={comuna || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="IVE" value={ive !== null && ive !== undefined ? ive : '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Tipo" value={establecimiento.tipoEstablecimiento?.nombre || '-'} />
            </Col>
            <Col xs={6} lg={3}>
              <LabelValue label="Telefono" value={telefono || '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Dirección" value={direccion || '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Director" value={nombreDirector || '-'} />
            </Col>
            {emailDirector && (
              <Col xs={12} lg={6}>
                <LabelValue label="Correo director" value={emailDirector || '-'} />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Establecimiento</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <EstablecimientoFormComponent
            formState={formState}
            submitForm={submitForm}
            tiposEstablecimientoOptions={tiposEstablecimientoSelectOptions}
            onCancel={handleClose}
            onDelete={deleteCurrentEstablecimiento}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EstablecimientoInfoCard;
