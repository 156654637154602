import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import UsuariosFilters from '../../models/usuarios/UsuariosFilters';
import { SelectOption } from '../../models/form/Select';
import Select from '../form/select/Select';
import { Input } from '../form/input/Input';
import Sede from '../../models/sedes/Sede';

interface UsuariosFiltersProps {
  sedesOptions: SelectOption<Sede>[];
  usuariosFilters: UsuariosFilters;
  onFiltersChange: Dispatch<SetStateAction<UsuariosFilters>>;
}

const UsuariosFiltersComponent = ({
  sedesOptions,
  usuariosFilters,
  onFiltersChange,
}: UsuariosFiltersProps): ReactElement => {
  return (
    <>
      <Row>
        <Col lg={4}>
          <Input
            value={usuariosFilters.nombreORut as string}
            onChange={(value) =>
              onFiltersChange({ ...usuariosFilters, nombreORut: value })
            }
            type="text"
            placeholder="Nombre o Rut"
            title="Nombre o RUT"
          />
        </Col>
        <Col lg={4}>
        <Select
            title="Sede"
            options={sedesOptions}
            onSelect={(sede): void => {
              if (sede)
                onFiltersChange({
                  ...usuariosFilters,
                  sedeId: (sede as SelectOption<Sede>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange((prevFilters) => ({
                ...prevFilters,
                sedeId: undefined,
              }))
            }}
            placeholder="Sedes"
          />
        </Col>
      </Row>
    </>
  );
};

export default UsuariosFiltersComponent;
