import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { formatCoordinadorPostBody, getEmptyCoordinadorForm } from '../../utils/coordinadores';
import { CoordinadorForm } from '../../models/coordinadores/Coordinador';
import { postCoordinador } from '../../api/coordinadores/coordinadoresApi';
import CoordinadorFormComponent from './CoordinadorFormComponent';
import { SelectOption } from '../../models/form/Select';
import Sede from '../../models/sedes/Sede';

interface NewCoordinadorButtonProps {
  sedesOptions: SelectOption<Sede>[];
}

const NewCoordinadorButton = ({ sedesOptions }: NewCoordinadorButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyCoordinadorForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (coordinadorForm: CoordinadorForm): void => {
    postCoordinador(formatCoordinadorPostBody(coordinadorForm))
      .then((response) => {
        const createdCoordinadorId = response.id;

        history.push(`/coordinadores/${createdCoordinadorId}`);

        cogoToast.success('Coordinador creado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar Coordinador
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Coordinador</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <CoordinadorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewCoordinadorButton;
