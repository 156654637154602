import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { formatBitacoraPostBody, getEmptyBitacoraForm } from '../../utils/bitacoras';
import { postBitacora } from '../../api/bitacoras/bitacorasApi';
import { BitacoraForm } from '../../models/bitacoras/Bitacora';
import BitacoraFormComponent from './BitacoraFormComponent';

interface NewBitacoraButtonProps {
  onCreate: () => void;
}

const NewBitacoraButton = ({ onCreate }: NewBitacoraButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyBitacoraForm());

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (bitacoraForm: BitacoraForm): void => {
    formState.setSubmitting(true);
    postBitacora(formatBitacoraPostBody(bitacoraForm))
      .then(() => {
        handleClose();
        onCreate();
        cogoToast.success('Bitacora creada!');
        formState.reset();
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar registro
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title> Agregar registro a la bitácora </Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <BitacoraFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewBitacoraButton;
