import axios from 'axios';
import cogoToast from 'cogo-toast';
import localStorageKeys from '../utils/localStorageKeys';

const axiosWithAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosWithAuth.interceptors.request.use((request) => {
  const jwt = localStorage.getItem(localStorageKeys.jwt);

  if (!jwt) {
    window.location.pathname = 'login';
  }

  request.headers = {
    ...request.headers,
    jwt,
  };

  return request;
});

axiosWithAuth.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err.request.responseType === 'blob' &&
      err.response.data instanceof Blob &&
      err.response.data.type &&
      err.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          err.response.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(err.response?.data?.message || 'Error.'));
        };

        reader.onerror = () => {
          reject(err);
        };

        reader.readAsText(err.response.data);
      });
    }
    if (err.response.status === 401) {
      throw new Error('No tiene los permisos para visualizar todo o parte de esta pagina');
      return;
    }
    if (err.response.status === 501) {
      throw new Error('Esta funcionalidad no está implementada');
    }
    throw new Error(err.response?.data?.message || 'Error.');
  },
);

export default axiosWithAuth;
