import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import { getSesiones } from '../../api/sesiones/sesionesPageRequests';
import { BaseSesion } from '../../models/sesiones/Sesion';
import Table, { TableColumn } from '../table/Table';
import SesionesFilters from '../../models/sesiones/SesionesFilters';

interface ParaleloSesionesListaProps {
  sesionesFilters: SesionesFilters;
  setSesionesFilters: Dispatch<SetStateAction<SesionesFilters>>;
}

const ParaleloSesionesLista = ({
  sesionesFilters,
  setSesionesFilters,
}: ParaleloSesionesListaProps): ReactElement => {
  const [sesiones, setSesiones] = useState<BaseSesion[]>();
  const [pages, setPages] = useState(1);
  const history = useHistory();

  useEffect(() => {
    getSesiones(sesionesFilters)
      .then((response) => {
        setSesiones(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de las sesiones');
      });
  }, [sesionesFilters, setSesiones]);

  const handleSesionesClick = (sesion: BaseSesion) => {
    history.push(`/cursos/sesiones/${sesion.id}`);
  };

  const tableColumns: TableColumn<BaseSesion>[] = [
    {
      title: 'Fecha',
      accessor: (sesion) => `${format(sesion.fecha, 'dd/MM/yyyy')}`,
    },
    {
      title: 'Tutor',
      accessor: (sesion) => {
        if (sesion.tutor) {
          return `${sesion.tutor.nombre} ${sesion.tutor.apellidos}`
        } 
          return 'Tutor eliminado del sistema';
        
      },
    },
    {
      title: 'Descripción',
      accessor: (sesion) => `${sesion.descripcion}`,
    },
  ];

  if (!sesiones) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  const sortedSesiones = sesiones.sort((s1, s2) => {
    return s2.fecha - s1.fecha 
  })
  return (
    <>
      <Table
        items={sortedSesiones}
        columns={tableColumns}
        hover
        onItemClick={handleSesionesClick}
        minRowsCount={sesionesFilters.itemsPerPage}
        pages={pages}
        activePage={sesionesFilters.page}
        onPageSelect={(page) => setSesionesFilters({ ...sesionesFilters, page })}
      />
    </>
  );
};
export default ParaleloSesionesLista;
