import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import TipoInstitucion from '../models/instituciones/TipoInstitucion';
import getTiposInstituciones from '../api/instituciones/tiposInstitucionesApi';

const useTiposInstitucionesOptions = (): SelectOption<TipoInstitucion>[] => {
  const [tiposInstitucionOptions, setTiposInstitucionOptions] = useState<
    SelectOption<TipoInstitucion>[]
  >([]);

  useEffect((): void => {
    getTiposInstituciones()
      .then((response): void => {
        setTiposInstitucionOptions(
          response.data.map((tipo) => {
            return { label: tipo.nombre, value: tipo };
          }),
        );
      })
      .catch(() => {});
  }, [setTiposInstitucionOptions]);

  return tiposInstitucionOptions;
};

export default useTiposInstitucionesOptions;
