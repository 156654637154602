import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import {
  BaseCoordinador,
  Coordinador,
  CoordinadorRequestBody,
} from '../../models/coordinadores/Coordinador';
import { transformApiCoordinadorIntoCoordinador } from '../../utils/coordinadores';
import CoordinadoresFilters from '../../models/coordinadores/CoordinadoresFilters';
import { ListResponse } from '../../models/personas/Persona';

export async function postCoordinador(requestBody: CoordinadorRequestBody): Promise<Coordinador> {
  const axiosResponse = await axios.post('coordinadores', requestBody);

  return transformApiCoordinadorIntoCoordinador(axiosResponse.data);
}

export async function getCoordinador(coordinadorId: string): Promise<Coordinador> {
  const axiosResponse = await axios.get(`coordinadores/${coordinadorId}`);

  const apiCoordinador = axiosResponse.data;

  return transformApiCoordinadorIntoCoordinador(apiCoordinador);
}

export async function putCoordinador(
  coordinadorId: number,
  requestBody: CoordinadorRequestBody,
): Promise<Coordinador> {
  const axiosResponse = await axios.put(`coordinadores/${coordinadorId}`, requestBody);

  return transformApiCoordinadorIntoCoordinador(axiosResponse.data);
}

export function deleteCoordinador(coordinadorId: number): AxiosPromise<void> {
  return axios.delete(`coordinadores/${coordinadorId}`);
}

export async function getCoordinadores(filters: CoordinadoresFilters): Promise<ListResponse<BaseCoordinador>> {
  const axiosResponse = await axios.get('coordinadores', { params: filters });

  const { items, pages } = axiosResponse.data;

  return {
    items: items.map(transformApiCoordinadorIntoCoordinador),
    pages,
  };
}

export async function getTodosCoordinadores(): Promise<ListResponse<BaseCoordinador>> {
  const axiosResponse = await axios.get('coordinadores');

  const { items, pages } = axiosResponse.data;

  return {
    items: items.map(transformApiCoordinadorIntoCoordinador),
    pages,
  };
}


