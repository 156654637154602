import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import EstablecimientosFilters from '../../models/establecimientos/EstablecimientosFilters';
import { BaseEstablecimiento } from '../../models/establecimientos/Establecimiento';
import { getEstablecimientos } from '../../api/establecimientos/establecimientosApi';
import Table, { TableColumn } from '../table/Table';
import { formatRut } from '../../utils/rut';

interface EstablecimientosListProps {
  establecimientosfilters: EstablecimientosFilters;
  setEstablecimientosFilters: Dispatch<SetStateAction<EstablecimientosFilters>>;
}

const EstablecimientoList = ({
  establecimientosfilters,
  setEstablecimientosFilters,
}: EstablecimientosListProps): ReactElement => {
  const [establecimientos, setEstablecimientos] = useState<BaseEstablecimiento[]>();

  const [isLoading, setLoading] = useState(true);

  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getEstablecimientos(establecimientosfilters)
      .then((response): void => {
        setEstablecimientos(response.data.establecimientos);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los establecimientos.');
      })
      .finally(() => setLoading(false));
  }, [establecimientosfilters, setEstablecimientos]);

  const handleEstablecimientoClick = (establecimiento: BaseEstablecimiento): void => {
    history.push(`/establecimientos/${establecimiento.id}`);
  };

  const tableColumns: TableColumn<BaseEstablecimiento>[] = [
    {
      title: 'Nombre',
      accessor: 'nombre',
    },
    {
      title: 'RBD',
      accessor: (establecimiento) =>
        establecimiento.rbd ? formatRut(establecimiento.rbd.toString()) : '',
      hideUntil: 'lg',
    },
    {
      title: 'Ciudad',
      accessor: 'ciudad',
    },
    {
      title: 'IVE',
      accessor: 'ive',
      hideUntil: 'md',
    },
  ];

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!establecimientos)
    return <p className="text-center">No se pudo cargar el listado de establecimientos</p>;

  return (
    <>
      <Table
        items={establecimientos}
        columns={tableColumns}
        hover
        onItemClick={handleEstablecimientoClick}
        minRowsCount={establecimientosfilters.itemsPerPage}
        pages={pages}
        activePage={establecimientosfilters.page}
        onPageSelect={(page): void =>
          setEstablecimientosFilters({ ...establecimientosfilters, page })
        }
      />
    </>
  );
};
export default EstablecimientoList;
