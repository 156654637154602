import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router-dom';
import { Evaluacion, EvaluacionForm } from '../../models/evaluaciones/Evaluacion';
import LabelValue from '../LabelValue';
import EvaluacionFormComponent from './EvaluacionFormComponent';
import useFormState from '../../hooks/useFormState';
import { formatEvaluacionForm, formatEvaluacionPostBody } from '../../utils/evaluaciones';
import { deleteEvaluacionById, updateEvaluacion } from '../../api/evaluaciones/evaluacionesApi';

interface EvaluacionInfoProps {
  evaluacion: Evaluacion;
  onEvaluacionChange: (evaluacion: Evaluacion) => void;
}

const EvaluacionInfo = ({ evaluacion, onEvaluacionChange }: EvaluacionInfoProps): ReactElement => {
  const formState = useFormState(formatEvaluacionForm(evaluacion));

  const history = useHistory();

  const [isFormModalActive, setFormModalActive] = useState(false);

  const openFormModal = (): void => {
    setFormModalActive(true);
  };

  const closeFormModal = (): void => {
    setFormModalActive(false);
  };

  const cancelEdition = (): void => {
    closeFormModal();

    formState.reset();
  };

  const deleteCurso = (): void => {
    formState.setSubmitting(true);

    deleteEvaluacionById(evaluacion.id)
      .then(() => {
        cogoToast.success('Evaluación eliminado.');

        history.replace(`/cursos/${evaluacion.curso.id}`);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  const submitForm = (evaluacionForm: EvaluacionForm): void => {
    formState.setSubmitting(true);

    updateEvaluacion(formatEvaluacionPostBody(evaluacionForm), evaluacion.id)
      .then((updatedEvaluacion) => {
        cogoToast.success('Evaluación actualizada.');

        onEvaluacionChange(updatedEvaluacion);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);

        closeFormModal();
      });
  };

  return (
    <>
      <div className="d-flex">
        <h3>{evaluacion.nombre}</h3>
        <Button
          style={{ margin: '0 0 auto auto' }}
          variant="warning"
          size="sm"
          onClick={openFormModal}>
          Editar
        </Button>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="Ponderación" value={`${evaluacion.peso * 100}%`} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Descripción" value={evaluacion.descripcion} />
        </Col>
      </Row>
      <Modal show={isFormModalActive} onHide={closeFormModal} size="xl">
        <Modal.Header closeButton>
          <div className="text-sm-center">
            <Modal.Title>Editar Evaluación</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <EvaluacionFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={cancelEdition}
            onDelete={deleteCurso}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EvaluacionInfo;
