import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import Table, { TableColumn } from '../table/Table';
import { Evaluacion } from '../../models/evaluaciones/Evaluacion';

interface CursoEvaluacionesTableProps {
  evaluaciones: Evaluacion[];
  cursoId: number | string;
}

const CursoEvaluacionesTable = ({
  evaluaciones,
  cursoId,
}: CursoEvaluacionesTableProps): ReactElement => {
  const history = useHistory();

  if (evaluaciones.length === 0) {
    return <p className="text-center">No hay evaluaciones</p>;
  }

  const sortedEvaluacionesByOrder = evaluaciones.sort((a, b) => a.correlativo - b.correlativo);

  const maxDescriptionLength = 40;

  const tableColumns: TableColumn<Evaluacion>[] = [
    {
      title: 'Nombre',
      accessor: 'nombre',
    },
    {
      title: 'Ponderación',
      accessor: (evaluacion) => `${evaluacion.peso * 100}%`,
    },
    {
      title: 'Descripción',
      accessor: (evaluacion) => {
        const { descripcion } = evaluacion;

        if (descripcion.length > maxDescriptionLength) {
          return `${descripcion.slice(0, maxDescriptionLength)}...`;
        }

        return descripcion;
      },
    },
  ];

  const goToEvaluacionPage = (evaluacion: Evaluacion): void => {
    history.push(`/cursos/${cursoId}/evaluaciones/${evaluacion.id}`);
  };

  return (
    <Table
      hover
      items={sortedEvaluacionesByOrder}
      columns={tableColumns}
      onItemClick={goToEvaluacionPage}
    />
  );
};

export default CursoEvaluacionesTable;
