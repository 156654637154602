import React, { ReactElement, useContext, useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import LayoutContext from '../../contexts/LayoutContext';
import Rol from '../../models/usuarios/Rol';
import AuthContext from '../../contexts/AuthContext';
import UnauthorizedPage from '../../pages/misc/UnauthorizedPage';

interface AppRouteProps extends RouteProps {
  title: string;
  roles?: Rol[];
}

const AppRoute = ({ title, roles, ...props }: AppRouteProps): ReactElement => {
  const { setHeaderTitle } = useContext(LayoutContext);

  const { jwtData, userRoles, hasJwt } = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    setHeaderTitle(title);
  }, [title, setHeaderTitle]);
  
  useEffect(() => {
    if (!hasJwt) {
      history.push('/login');
    } else if (jwtData && jwtData.exp <= Date.now()) {
      history.push('/login');

      cogoToast.info('Sesión expirada. Ingrese nuevamente.', { hideAfter: 5000 });
    }
  }, [jwtData, history]);

  if (roles && !roles?.some((routeRol) => userRoles.includes(routeRol)))
    return <UnauthorizedPage />;

  return <Route {...props} />;
};

export default AppRoute;
