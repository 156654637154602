import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { Button, Col, Modal } from 'react-bootstrap';
import { SelectOption } from '../../models/form/Select';
import { EstudianteForm } from '../../models/estudiantes/Estudiante';
import useFormState from '../../hooks/useFormState';
import { formatEstudiantePostBody, getEmptyEstudianteForm } from '../../utils/estudiantes';
import { postEstudiante } from '../../api/estudiantes/estudiantesApi';
import EstudianteFormComponent from './EstudianteFormComponent';
import BasePrograma from '../../models/programas/Programa';
import { BaseEstablecimiento } from '../../models/establecimientos/Establecimiento';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';
import Estado from '../../models/estados/Estado';

interface NewEstudianteButtonProps {
  sedesOptions: SelectOption<Sede>[];
  nivelesOptions: SelectOption<Nivel>[];
  programasOptions: SelectOption<BasePrograma>[];
  establecimientosOptions: SelectOption<BaseEstablecimiento>[];
  estadosOptions: SelectOption<Estado>[];
}

const NewEstudianteButton = ({
  sedesOptions,
  nivelesOptions,
  programasOptions,
  establecimientosOptions,
  estadosOptions,
}: NewEstudianteButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyEstudianteForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (estudianteForm: EstudianteForm) => {
    postEstudiante(formatEstudiantePostBody(estudianteForm))
      .then((response) => {
        const createdEstudianteId = response.data.id;

        history.push(`/estudiantes/${createdEstudianteId}`);

        cogoToast.success('Estudiante creado');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar Estudiante
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Estudiante</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <EstudianteFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            nivelesOptions={nivelesOptions}
            programasOptions={programasOptions}
            establecimientosOptions={establecimientosOptions}
            estadosOptions={estadosOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default NewEstudianteButton;
