import cogoToast from 'cogo-toast';
import React, { ReactElement, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getEstudianteEmail } from '../../api/estudiantes/estudiantesApi';
import AuthContext from '../../contexts/AuthContext';
import LayoutContext from '../../contexts/LayoutContext';
import ThemeContext from '../../contexts/ThemeContext';
import Rol from '../../models/usuarios/Rol';
import RolProtectedElement from '../roles/RolProtectedElement';
import HeaderBreadCrumb from './HeaderBreadCrumb';

const Header = (): ReactElement => {
  const { headerTitle, toggleSidebar } = useContext(LayoutContext);

  const { toggleColorTheme } = useContext(ThemeContext);

  const { userName, userRoles, email, logout } = useContext(AuthContext);
  
  const history = useHistory();

  const infoEstudiante = (): void => {
    getEstudianteEmail(email)
    .then((response) => {
      history.push(`/estudiantePerfil/${response.data.id}/perfil`)
    })
    .catch(() => {
      cogoToast.error('No se pudo cargar la información del estudiante');
    });
    
  }

  return (
    <div className="header-container">
      <div className="header">
        <h1 className="header-title">{headerTitle}</h1>
        <div className="user-info">
          <h5 className="user-name">{userName}</h5>
          <h6 className="user-role">{userRoles.join(', ')}</h6>
        </div>
        <button
          className="d-block d-md-none btn btn-icon text-white sidebar-button"
          type="button"
          onClick={toggleSidebar}>
          <i className="mdi mdi-menu" />
        </button>
      </div>
      <div className="sub-header">
        <HeaderBreadCrumb />
        <div className="config-buttons">
        <RolProtectedElement roles={[Rol.Estudiante]}>
        <button className="btn btn-icon text-white" type="button" onClick={infoEstudiante}>
            <i className="mdi mdi-account" />
          </button>
          </RolProtectedElement> 
          <button className="btn btn-icon text-white" type="button" onClick={logout}>
            <i className="mdi mdi-exit-to-app" />
          </button>
          <button onClick={toggleColorTheme} className="btn btn-icon text-white" type="button">
            <i className="mdi mdi-theme-light-dark" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
