import React, { ReactElement, ReactNode } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface FormButtonsProps {
  showDeactivationButton?: boolean;
  submitButtonContent?: ReactNode;
  cancelButtonContent?: ReactNode;
  deactivateButtonContent?: ReactNode
  onSubmitClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancelClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDeleteClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDeactivateClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDeleteConfirm?: () => void;
  onDeactivateConfirm?: () => void;
  isConfirmingDelete?: boolean;
  isConfirmingDeactivation?: boolean;
  onCanceleDelete?: () => void;
  onCancelDeactivation?: () => void;
  deleteButtonContent?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const FormButtons = ({
  submitButtonContent = 'Guardar',
  cancelButtonContent = 'Cancelar',
  deleteButtonContent = 'Eliminar',
  deactivateButtonContent = "Desactivar",
  isConfirmingDelete,
  isConfirmingDeactivation,
  isDisabled,
  isLoading,
  onCancelClick,
  onDeleteClick,
  onSubmitClick,
  onDeactivateClick,
  onDeleteConfirm,
  onCanceleDelete,
  onCancelDeactivation,
  onDeactivateConfirm,
  showDeactivationButton,
}: FormButtonsProps): ReactElement => {
  if (isConfirmingDelete)
    return (
      <div className="text-center">
        <Button
          className="mr-3"
          variant="secondary"
          onClick={onCanceleDelete}
          disabled={isDisabled}>
          No, Seguir editando
        </Button>
        <Button variant="danger" onClick={onDeleteConfirm} disabled={isDisabled}>
          {isLoading ? <Spinner animation="border" size="sm" /> : 'Sí. Eliminar'}
        </Button>
      </div>
    );

  if (isConfirmingDeactivation) {
    return (
      <div className="text-center">
        <Button
          className="mr-3"
          variant="secondary"
          onClick={onCancelDeactivation}
          disabled={isDisabled}>
          No, Seguir editando
        </Button>
        <Button variant="warning" onClick={onDeactivateConfirm} disabled={isDisabled}>
          {isLoading ? <Spinner animation="border" size="sm" /> : 'Sí. Confirmar'}
        </Button>
      </div>
    );
  }

  return (
    <div>
      {!!onDeleteClick && (
        <Button variant="danger" onClick={onDeleteClick} disabled={isDisabled} className="mr-3 float-left">
          <span className="d-sm-none">Eliminar</span>
          <span className="d-none d-sm-block">{deleteButtonContent}</span>
        </Button>
      )}
      {!!onDeactivateClick && showDeactivationButton && (
        <Button variant='warning' onClick={onDeactivateClick} disabled={isDisabled}>
          <span className="d-sm-none">Desactivar</span>
          <span className="d-none d-sm-block">{deactivateButtonContent}</span>
        </Button>
      )}
      <Button
        className="float-right"
        variant="success"
        type="submit"
        onClick={onSubmitClick}
        disabled={isDisabled}>
        {isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <>
            <span className="d-lg-none">{onDeleteClick ? 'Guardar' : submitButtonContent}</span>
            <span className="d-none d-lg-block">{submitButtonContent}</span>
          </>
        )}
      </Button>
      <Button
        className="mr-3 float-right"
        variant="secondary"
        disabled={isDisabled}
        onClick={onCancelClick}>
        {cancelButtonContent}
      </Button>
    </div>
  );
};

export default FormButtons;
