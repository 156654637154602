import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import { Coordinador, CoordinadorForm } from '../../models/coordinadores/Coordinador';
import CoordinadorFormComponent from './CoordinadorFormComponent';
import { deleteCoordinador, putCoordinador } from '../../api/coordinadores/coordinadoresApi';
import { formatCoordinadorForm, formatCoordinadorPostBody } from '../../utils/coordinadores';
import useFormState from '../../hooks/useFormState';
import useSedesOptions from '../../hooks/useSedes';
import { formatRut } from '../../utils/rut';

interface CoordinadoresInfoProps {
  coordinador: Coordinador;
  onCoordinadorChange: (editedCoordinador: Coordinador) => void;
}

const CoordinadorInfo = ({
  coordinador,
  onCoordinadorChange,
}: CoordinadoresInfoProps): ReactElement => {
  const { id, nombre, apellidos, rut, email, telefono, direccion } = coordinador;

  const sedesOptions = useSedesOptions();

  const formState = useFormState(formatCoordinadorForm(coordinador, sedesOptions));

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const submitForm = (coordinadorForm: CoordinadorForm): void => {
    putCoordinador(id, formatCoordinadorPostBody(coordinadorForm))
      .then((response) => {
        formState.setSubmitting(false);

        setShow(false);

        onCoordinadorChange(response);

        cogoToast.success('Coordinador actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentCoordinador = (): void => {
    formState.setSubmitting(true);

    deleteCoordinador(id).then(() => {
      cogoToast.success('Coordinador eliminado!');

      history.replace('/coordinadores');
    });
  };

  return (
    <>
      <div className="d-flex">
        <h3> {`${nombre} ${apellidos}`} </h3>
        <Button
          style={{ margin: '0 0 auto auto' }}
          variant="warning"
          size="sm"
          onClick={handleShow}>
          Editar
        </Button>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="RUT" value={rut ? formatRut(rut.toString()) : '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Email" value={email || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Teléfono" value={telefono || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Dirección" value={direccion || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Sede" value={coordinador.sede?.nombre || '-'} />
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Coordinador</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <CoordinadorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            onCancel={handleClose}
            onDelete={deleteCurrentCoordinador}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CoordinadorInfo;
