import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';

interface ClosedEntitiesToggleButtonProps {
  areInactivosEnabled?: boolean;
  setInactivosEnabled: (nveValue: boolean) => void;
}

const ClosedEntitiesToggleButton = ({
  areInactivosEnabled,
  setInactivosEnabled,
}: ClosedEntitiesToggleButtonProps): ReactElement => {
  const toggleClosedCursos = (): void => {
    setInactivosEnabled(!areInactivosEnabled);
  };

  return (
    <Button
      className="mr-2"
      variant={areInactivosEnabled ? 'secondary' : 'info'}
      onClick={toggleClosedCursos}>
      {areInactivosEnabled ? 'Ocultar inactivos' : 'Mostrar inactivos'}
    </Button>
  );
};

export default ClosedEntitiesToggleButton;
