import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormState } from '../../hooks/useFormState';
import { EvaluacionForm } from '../../models/evaluaciones/Evaluacion';
import FormButtons from '../form/form-buttons/FormButtons';
import { Input } from '../form/input/Input';
import NumberInput from '../form/input/NumberInput';

interface EvaluacionFormComponentProps {
  formState: FormState<EvaluacionForm>;
  submitForm: (evaluacionForm: EvaluacionForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
}

const EvaluacionFormComponent = ({
  formState,
  submitForm,
  onDelete,
  onCancel,
}: EvaluacionFormComponentProps): ReactElement => {
  const {
    value,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const { nombre, peso, descripcion } = value;

  const isFormInvalid = !nombre || (Number(peso) < 0 || Number(peso) > 1) ;

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    submitForm(value);
  };

  return (
    <Form>
      <Row>
        <Col xs={12} md={6}>
          <Input
            name="nombre"
            title="Nombre"
            placeholder="Nombre"
            value={nombre}
            onChangeWithName={handleFieldChange}
            invalid={areValidationsEnabled && !nombre}
            invalidText="Debe ingresar el nombre"
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <NumberInput
            name="peso"
            title="Ponderación (entre 0 y 1)"
            positive
            max={1}
            placeholder="0.5"
            value={peso}
            onChangeWithName={handleFieldChange}
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12}>
          <Input
            name="descripcion"
            title="Descripción"
            placeholder="Descripción"
            type="textarea"
            value={descripcion}
            onChangeWithName={handleFieldChange}
            disabled={isSubmitting}
          />
        </Col>
      </Row>
      <hr />
      {isFormInvalid && areValidationsEnabled && (
        <div className="text-danger text-center mb-3">
          <small>Hay campos obligatorios sin ingresar</small>
        </div>
      )}
      {isConfirmingDelete && (
        <h6 className="text-center">
          ¿Está segura/o de que desea <span className="text-danger font-weight-bold">eliminar</span>{' '}
          la evaluación?
        </h6>
      )}
      <FormButtons
        submitButtonContent="Guardar evaluación"
        onSubmitClick={handleSubmitClick}
        onCancelClick={onCancel}
        isConfirmingDelete={isConfirmingDelete}
        onDeleteClick={onDelete ? handleDeleteClick : undefined}
        onDeleteConfirm={onDelete}
        onCanceleDelete={cancelDelete}
        deleteButtonContent="Eliminar evaluación"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      />
    </Form>
  );
};

export default EvaluacionFormComponent;
