export const formatRut = (rut: string): string => {
  const actual = rut
    .replace(/^0K+/, '')
    .replace(/[^0-9K]+/g, '')
    .substring(0, 9);
  let formattedRut = '';
  if (actual.length > 0) {
    const digitsOnly = actual.replace(/\./g, '').replace(/-/g, '');
    const inicio = digitsOnly.substring(0, digitsOnly.length - 1);
    let j = 1;
    for (let i = inicio.length - 1; i >= 0; i--) {
      const letra = inicio.charAt(i);
      formattedRut = letra + formattedRut;
      if (j % 3 === 0 && j <= inicio.length - 1) {
        formattedRut = `.${formattedRut}`;
      }
      j += 1;
    }
    const dv = digitsOnly.substring(digitsOnly.length - 1);
    formattedRut = `${formattedRut}-${dv}`;
  }
  return formattedRut;
};

export const getRutNumericValue = (rut: string): string => {
    const a =  rut
      .toUpperCase()
      .replace(/[^0-9K]+/g, '')
      .slice(0, -1)
    return a;
}

export const getRutWithoutSeparators = (rut: string): string =>
  rut.toUpperCase().split('-').join('').split('.').join('').substring(0, 9);

  export const validateRut = (rut: string): boolean => {
    if (rut.length < 2) return false;
  
    const verifier = rut.charAt(rut.length - 1);
  
    const rutDigits = Number(rut.slice(0, -1));
  
    if (Number.isNaN(rutDigits)) return false;
  
    return verifier.toLowerCase() === getRutVerifier(rutDigits);
  }

export const getRutVerifier = (rutDigits: number): string => {
  let tempRutDigits = rutDigits;

  let M = 0;

  let S = 1;

  for (; tempRutDigits; tempRutDigits = Math.floor(tempRutDigits / 10)) {
    S = (S + (tempRutDigits % 10) * (9 - (M % 6))) % 11;

    M += 1;
  }

  return S ? `${S - 1}` : 'k';
};
