import cogoToast from 'cogo-toast';
import { useEffect, useState } from 'react';
import { getEstudiantes } from '../api/estudiantes/estudiantesApi';
import { BaseEstudiante } from '../models/estudiantes/Estudiante';
import { SelectOption } from '../models/form/Select';
import { formatRut } from '../utils/rut';


const useEstudiantesOptions = (programaId: number): SelectOption<BaseEstudiante>[] => {
  const [estudiantesOptions, setEstudiantesOptions] = useState<SelectOption<BaseEstudiante>[]>([]);

  useEffect(() => {
    getEstudiantes({
      programaId,
      page: 1,
      itemsPerPage: 9999999,
    })
    .then((response) => 
      setEstudiantesOptions(response.data.items.map((baseEstudiante) => ({
        label: `${baseEstudiante.nombre} ${baseEstudiante.apellidos} (${formatRut(baseEstudiante.rut || '')})`,
        value: baseEstudiante,
      })))
    )
    .catch(() => {
      cogoToast.error('No se pudieron cargar los estudiantes.');
    });
  }, [setEstudiantesOptions, programaId]);

  return estudiantesOptions;
};

export default useEstudiantesOptions;