import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { LayoutContextState, LayoutContextValue } from '../models/layout/LayoutContext';
import LayoutContext from '../contexts/LayoutContext';
import { BreadcrumbItem } from '../models/layout/BreadcrumbItem';

interface LayoutStoreProps {
  children: ReactNode;
}

const LayoutStore = ({ children }: LayoutStoreProps): ReactElement => {
  const [layoutContextState, setLayoutContextState] = useState<LayoutContextState>({
    headerTitle: 'Título de la página',
    breadcrumbItems: [
      {
        label: 'Inicio',
        href: '/',
      },
      {
        label: 'Página de ejemplo',
      },
    ],
    isSidebarActive: false,
  });

  const setHeaderTitle = useCallback(
    (title: string): void =>
      setLayoutContextState((prevState) => ({
        ...prevState,
        headerTitle: title,
      })),
    [],
  );

  const setBreadcrumbItems = useCallback(
    (items: Array<BreadcrumbItem>): void =>
      setLayoutContextState((prevState) => ({
        ...prevState,
        breadcrumbItems: items,
      })),
    [],
  );

  const toggleSidebar = (): void =>
    setLayoutContextState((prevState) => ({
      ...prevState,
      isSidebarActive: !prevState.isSidebarActive,
    }));

  const layoutContextValue: LayoutContextValue = {
    ...layoutContextState,
    setHeaderTitle,
    setBreadcrumbItems,
    toggleSidebar,
  };

  return <LayoutContext.Provider value={layoutContextValue}>{children}</LayoutContext.Provider>;
};

export default LayoutStore;
