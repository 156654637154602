import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MaterialInventarioForm from '../../models/inventario/MaterialInventarioForm';
import { FormState } from '../../hooks/useFormState';
import { Input } from '../form/input/Input';
import { SelectOption } from '../../models/form/Select';
import Sede from '../../models/sedes/Sede';
import NumberInput from '../form/input/NumberInput';
import Select from '../form/select/Select';
import FormButtons from '../form/form-buttons/FormButtons';
import BasePrograma from '../../models/programas/Programa';

interface MaterialInventarioFormComponentProps {
  formState: FormState<MaterialInventarioForm>;
  onCancel: () => void;
  onDelete?: () => void;
  submit: (materialInventarioForm: MaterialInventarioForm) => void;
  sedesOptions: SelectOption<Sede>[];
  programasOption: SelectOption<BasePrograma>[];
}

const MaterialInventarioFormComponent = ({
  formState,
  onCancel,
  onDelete,
  submit,
  sedesOptions,
  programasOption,
}: MaterialInventarioFormComponentProps): ReactElement => {
  const {
    value,
    isSubmitting,
    areValidationsEnabled,
    setAreValidationsEnabled,
    handleFieldChange,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const { nombre, lugarFisico, cantidad, descripcion, sede, programa } = value;

  const isFormInvalid = !nombre || !sede || !programa;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    submit(value);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <Form>
      <Row>
        <Col xs={12} md={6}>
          <Input
            name="nombre"
            title="Nombre del material"
            value={nombre}
            onChangeWithName={handleFieldChange}
            placeholder="Nombre"
            invalid={areValidationsEnabled && !nombre}
            invalidText="El material debe tener nombre"
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <NumberInput
            name="cantidad"
            title="Cantidad disponible"
            value={cantidad}
            onChangeWithName={handleFieldChange}
            disabled={isSubmitting}
            positive
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            name="descripcion"
            title="Descripción"
            value={descripcion}
            placeholder="Descripción"
            onChangeWithName={handleFieldChange}
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Select
            name="sede"
            title="Sede"
            placeholder="Sede donde se ubica"
            options={sedesOptions}
            value={sede}
            onSelectWithName={handleFieldChange}
            invalid={areValidationsEnabled && !sede}
            invalidText="Seleccione una sede"
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Select
            name="programa"
            title="Programa"
            placeholder="Programa"
            options={programasOption}
            value={programa}
            onSelectWithName={handleFieldChange}
            invalid={areValidationsEnabled && !programa}
            invalidText="Seleccione un programa"
            disabled={isSubmitting}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            name="lugarFisico"
            title="Ubicación"
            placeholder="Lugar de ubicación"
            value={lugarFisico}
            onChangeWithName={handleFieldChange}
            disabled={isSubmitting}
          />
        </Col>
      </Row>
      <hr />
      {isFormInvalid && areValidationsEnabled && (
        <div className="text-danger text-center mb-3">
          <small>Hay campos obligatorios sin ingresar</small>
        </div>
      )}
      {isConfirmingDelete && (
        <h6 className="text-center">
          ¿Está segura/o de que desea <span className="text-danger font-weight-bold">eliminar</span>{' '}
          el material?
        </h6>
      )}
      <FormButtons
        submitButtonContent="Guardar material"
        onSubmitClick={handleSubmitClick}
        onCancelClick={onCancel}
        isConfirmingDelete={isConfirmingDelete}
        onDeleteClick={onDelete ? handleDeleteClick : undefined}
        onDeleteConfirm={onDelete}
        onCanceleDelete={cancelDelete}
        deleteButtonContent="Eliminar material"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      />
    </Form>
  );
};

export default MaterialInventarioFormComponent;
