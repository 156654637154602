import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import EstablecimientoInfoCard from '../../components/establecimientos/EstablecimientoInfoCard';
import { getEstablecimiento } from '../../api/establecimientos/establecimientosApi';
import { Establecimiento } from '../../models/establecimientos/Establecimiento';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import ColaboradoresInfoList from '../../components/colaboradores/ColaboradoresInfoList';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';
import EstudiantesFilters from '../../models/estudiantes/EstudiantesFilters';
import EstudiantesList from '../../components/estudiantes/EstudiantesList';

const EstablecimientoPage = (): ReactElement => {
  const [establecimiento, setEstablecimiento] = useState<Establecimiento>();

  const [isLoading, setLoading] = useState(true);

  const { establecimientoId } = useParams();

  const breadcrumb = useBreadcrumb();

  const [estudiantesFilters, setEstudiantesFilters] = useState<EstudiantesFilters>({
    nombreORut: '',
    establecimientoId: establecimientoId,
    page: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    getEstablecimiento(establecimientoId)
      .then((response): void => {
        setEstablecimiento(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del establecimiento');
      })
      .then(() => setLoading(false));
  }, [establecimientoId, setEstablecimiento]);

  useEffect(() => {
    if (establecimiento) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Establecimientos',
          href: '/establecimientos',
        },
        {
          label: establecimiento.nombre,
          active: true,
        },
      ]);
    }
  }, [establecimiento, breadcrumb]);

  if (isLoading)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  if (!establecimiento)
    return (
      <Card>
        <Card.Body className="text-center">
          {isLoading ? (
            <Spinner animation="border" />
          ) : (
            <p className="m-0">El establecimiento no existe</p>
          )}
        </Card.Body>
      </Card>
    );

  const handleEstablecimientoChange = (changedEstablecimiento: Establecimiento): void =>
    setEstablecimiento(changedEstablecimiento);

  return (
    <>
      <EstablecimientoInfoCard
        establecimiento={establecimiento}
        onEstablecimientoChange={handleEstablecimientoChange}
      />
      <Card className="mt-3">
        <Card.Header>
          <h5 className="m-0">Colaboradores</h5>
        </Card.Header>
        <Card.Body>
          {establecimiento.colaboradores ? (
            <ColaboradoresInfoList colaboradores={establecimiento.colaboradores} />
          ) : (
            <p className="center">No hay colaboradores</p>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header>
          Lista de estudiantes
        </Card.Header>
        <Card.Body>
          <EstudiantesList
            setEstudiantesFilters={setEstudiantesFilters}
            estudiantesFilters={estudiantesFilters}
          />
        </Card.Body>
      </Card>
      <EntityDocumentsCard entityTypeEndpoint="establecimientos" entityId={establecimiento.id} />
    </>
  );
};

export default EstablecimientoPage;
