import React, { Dispatch, ReactElement, ReactText, SetStateAction } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import InventarioListFilters from '../../models/inventario/InventarioListFilters';
import { SelectOption } from '../../models/form/Select';
import Sede from '../../models/sedes/Sede';
import { Input } from '../form/input/Input';
import Select from '../form/select/Select';
import BasePrograma from '../../models/programas/Programa';

interface InventarioListFiltersProps {
  inventarioFilters: InventarioListFilters;
  onInventarioFiltersChange: Dispatch<SetStateAction<InventarioListFilters>>;
  sedesOptions: SelectOption<Sede>[];
  programasOptions: SelectOption<BasePrograma>[];
  createButton: ReactElement;
  editButton?: ReactElement;
}

const InventarioListFiltersComponent = ({
  inventarioFilters,
  onInventarioFiltersChange,
  sedesOptions,
  programasOptions,
  createButton,
  editButton,
}: InventarioListFiltersProps): ReactElement => {
  const handleFilterChange = (name: string, value?: ReactText): void =>
    onInventarioFiltersChange((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

  return (
    <>
      <Row>
        <Col md={6} xl={3}>
          <Input
            name="nombre"
            value={inventarioFilters.nombre || ''}
            onChangeWithName={handleFilterChange}
            type="text"
            placeholder="Nombre"
            title="Nombre"
          />
        </Col>
        <Col md={6} xl={3}>
          <Select
            title="Sede"
            name="sedeId"
            options={sedesOptions}
            onSelectWithName={(name, sede): void => {
              handleFilterChange(name, sede.value.id);
            }}
            onClearWithName={(name): void => handleFilterChange(name, undefined)}
            placeholder="Sede"
          />
        </Col>
        <Col md={6} xl={3}>
          <Select
            title="Programa"
            name="programaId"
            options={programasOptions}
            onSelectWithName={(name, programa): void => {
              handleFilterChange(name, programa.value.id);
            }}
            onClearWithName={(name): void => handleFilterChange(name, undefined)}
            placeholder="Programa"
          />
        </Col>
        <Col xs={12}>
          <FormGroup className="pt-3">
            {createButton}
            {editButton}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default InventarioListFiltersComponent;
