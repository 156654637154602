import React, { ReactElement, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { ParaleloForm } from '../../models/paralelo/Paralelo';
import useProfesoresOptions from '../../hooks/useProfesoresOptions';
import ParaleloFormComponent from './ParaleloFormComponent';
import useFormState from '../../hooks/useFormState';
import { formatParaleloPostBody, getEmptyParaleloForm } from '../../utils/paralelos';
import { postParalelo } from '../../api/paralelos/paralelosApi';

interface NewParaleloButtonProps {
  programaId: number;
  cursoId: number;
}

const NewParaleloButton = ({ programaId, cursoId }: NewParaleloButtonProps): ReactElement => {
  const profesorOptions = useProfesoresOptions(programaId);

  const [isModalActive, setModalActive] = useState(false);

  const formState = useFormState(getEmptyParaleloForm());

  const history = useHistory();

  const openFormModal = (): void => {
    setModalActive(true);
  };

  const closeFormModal = (): void => {
    setModalActive(false);
  };

  const submitForm = (paraleloForm: ParaleloForm): void => {
    formState.setSubmitting(true);

    postParalelo(formatParaleloPostBody(paraleloForm, cursoId))
      .then((createdParalelo) => {
        cogoToast.success('Paralelo creado.');

        history.push(`/paralelos/${createdParalelo.id}`);
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const cancelForm = (): void => {
    closeFormModal();

    formState.reset();
  };

  return (
    <>
      <Button variant="success" size="sm" onClick={openFormModal}>
        Agregar paralelo
      </Button>
      <Modal show={isModalActive} onHide={closeFormModal}>
        <Modal.Header>
          <div className="text-sm-center">
            <Modal.Title>Agregar Curso</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ParaleloFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={cancelForm}
            profesorOptions={profesorOptions}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewParaleloButton;
