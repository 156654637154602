import {
  ApiBaseTutor,
  ApiTutor,
  BaseTutor,
  Tutor,
  TutorForm,
  TutorRequestBody,
} from '../models/tutores/Tutor';
import { SelectGroup, SelectOption } from '../models/form/Select';
import Sede from '../models/sedes/Sede';
import BasePrograma from '../models/programas/Programa';
import { getRutNumericValue } from './rut';
import { transformApiBasePersonaIntoBasePersona } from './personas';

export const transformApiTutorIntoTutor = (tutor: ApiTutor): Tutor => {
  return {
    ...tutor,
    ...transformApiBasePersonaIntoBasePersona(tutor),
  };
};

export const transformApiBaseTutorIntoBaseTutor = (baseTutor: ApiBaseTutor): BaseTutor => {
  return {
    ...baseTutor,
    ...transformApiBasePersonaIntoBasePersona(baseTutor),
  };
};

export const getEmptyTutorForm = (): TutorForm => ({
  nombre: '',
  apellidos: '',
  rut: '',
  email: '',
  telefono: '',
  direccion: '',
  semestre: '',
  horasAsignadas: '',
});

export const formatTutorPostBody = (tutorForm: TutorForm): TutorRequestBody => {
  const {
    nombre,
    apellidos,
    rut,
    email,
    telefono,
    direccion,
    carrera,
    semestre,
    horasAsignadas,
    sede,
    programa,
    isActive,
  } = tutorForm;

  return {
    nombre,
    apellidos,
    rut: Number(getRutNumericValue(rut)),
    email,
    telefono,
    direccion,
    carrera: carrera?.value,
    semestre: Number(semestre),
    horasAsignadas: Number(horasAsignadas),
    sedeId: sede?.value.id ? sede?.value.id : undefined,
    programaId: programa?.value.id ? programa?.value.id : undefined,
    isActive,
  };
};

export const formatTutorForm = (
  tutor: Tutor,
  programaOptions: SelectOption<BasePrograma>[],
  sedeOptions: SelectOption<Sede>[],
  carreraGroups: SelectGroup<string>[],
): TutorForm => {
  const {
    nombre,
    apellidos = '',
    rut = '',
    email = '',
    telefono = '',
    direccion = '',
    carrera = '',
    semestre = '',
    horasAsignadas = '',
    isActive,
  } = tutor;

  let carreraSelectOption: SelectOption<string> | undefined;
  carreraGroups.forEach((group) => {
    const currentSelectOption = group.options.find((option) => {
      return option.value === carrera;
    });
    if (!currentSelectOption) return;
    carreraSelectOption = currentSelectOption;
  });

  const sedeSelectOption = sedeOptions.find((option) => option.value.id === tutor.sede.id);
  const programaSelectOption = programaOptions.find(
    (option) => option.value.id === tutor.programa.id,
  );

  return {
    nombre,
    apellidos,
    rut: rut ? rut.toString() : '',
    email,
    telefono,
    direccion,
    carrera: carreraSelectOption,
    semestre: semestre ? semestre.toString() : '',
    horasAsignadas: horasAsignadas ? horasAsignadas.toString() : '',
    programa: programaSelectOption,
    sede: sedeSelectOption,
    isActive,
  };
};
