import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import axios from '../api/axiosWithAuth';
import DocumentFile from '../models/files/DocumentFile';

interface UseEntityFilesHookValue {
  files?: DocumentFile[];
  setFiles: Dispatch<SetStateAction<DocumentFile[] | undefined>>;
  isLoading: boolean;
}

const useEntityFiles = (
  entityTypeEndpoint: string,
  entityId: string | number,
): UseEntityFilesHookValue => {
  const [files, setFiles] = useState<DocumentFile[]>();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${entityTypeEndpoint}/${entityId}/documentos`)
      .then((response) => {
        setFiles(response.data);
      })
      .catch(() => cogoToast.error('No se pudieron obtener los documentos asociados.'))
      .finally(() => setLoading(false));
  }, [setFiles, setLoading, entityId, entityTypeEndpoint]);

  return {
    files,
    setFiles,
    isLoading,
  };
};

export default useEntityFiles;
