import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import TutorInfo from '../../components/tutores/TutorInfo';
import { getTutor } from '../../api/tutores/tutoresApi';
import { Tutor } from '../../models/tutores/Tutor';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';

const TutorPage = (): ReactElement => {
  const [tutor, setTutor] = useState<Tutor>();

  const { tutorId } = useParams<{ tutorId: string }>();

  const breadcrumb = useBreadcrumb();

  const history = useHistory();

  useEffect(() => {
    getTutor(tutorId)
      .then((obtainedTutor): void => {
        setTutor(obtainedTutor);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del tutor');
      });
  }, [tutorId, setTutor]);

  useEffect(() => {
    if (tutor) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Tutores',
          href: '/tutores',
        },
        {
          label: `${tutor.nombre} ${tutor.apellidos}`,
          active: true,
        },
      ]);
    }
  }, [tutor, breadcrumb]);

  if (!tutor)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleTutorChange = (changedTutor: Tutor): void => setTutor(changedTutor);

  const goToBitacora = (): void => {
    history.push(`/tutores/${tutorId}/bitacora`);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <TutorInfo tutor={tutor} onTutorChange={handleTutorChange} />
        </Card.Body>
      </Card>
      <EntityDocumentsCard entityTypeEndpoint="tutores" entityId={tutor.id} />
      <div className="mt-3 text-center">
        <Button variant="info" onClick={goToBitacora}>Ver bitácora</Button>
      </div>
    </>
  );
};

export default TutorPage;
