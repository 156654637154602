import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import TutoresFilters from '../../models/tutores/TutoresFilters';
import { BaseTutor } from '../../models/tutores/Tutor';
import { getTutores } from '../../api/tutores/tutoresApi';
import Table, { TableColumn } from '../table/Table';
import { formatRut } from '../../utils/rut';

interface TutoresListProps {
  tutoresfilters: TutoresFilters;
  setTutoresFilters: Dispatch<SetStateAction<TutoresFilters>>;
}

const TutorList = ({ tutoresfilters, setTutoresFilters }: TutoresListProps): ReactElement => {
  const [tutores, setTutores] = useState<BaseTutor[]>();
  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getTutores(tutoresfilters)
      .then((tutoresList): void => {
        setTutores(tutoresList.items);
        setPages(tutoresList.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los tutores.');
      });
  }, [tutoresfilters, setTutores]);

  const handleTutorClick = (tutor: BaseTutor): void => {
    history.push(`/tutores/${tutor.id}`);
  };

  const tableColumns: TableColumn<BaseTutor>[] = [
    {
      title: 'Nombre',
      accessor: (tutor) => `${tutor.nombre} ${tutor.apellidos}`,
    },
    {
      title: 'Rut',
      accessor: (tutor) => tutor.rut ? formatRut(tutor.rut.toString()) : '',
    },
    {
      title: 'Programa',
      accessor: (tutor) => tutor.programa.nombre,
    },
    {
      title: 'Email',
      accessor: (tutor) => tutor.email,
    },
    {
      title: 'Teléfono',
      accessor: (tutor) => tutor.telefono,
    },
  ];

  if (tutoresfilters.includeInactive) {
    tableColumns.push({
      title: 'Estado',
      accessor: (tutor) => {return tutor.isActive? 'Activo': 'Inactivo'},
      hideUntil: 'md'
    })
  }

  if (!tutores)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <Table
        items={tutores}
        columns={tableColumns}
        hover
        onItemClick={handleTutorClick}
        minRowsCount={tutoresfilters.itemsPerPage}
        pages={pages}
        activePage={tutoresfilters.page}
        onPageSelect={(page): void => setTutoresFilters({ ...tutoresfilters, page })}
      />
    </>
  );
};
export default TutorList;
