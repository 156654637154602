import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import { Administrador, AdministradorForm } from '../../models/administradores/Administrador';
import AdministradorFormComponent from './AdministradorFormComponent';
import {
  deleteAdministrador,
  putAdministrador,
} from '../../api/administradores/administradoresApi';
import { formatAdministradorForm, formatAdministradorPostBody } from '../../utils/administradores';
import useFormState from '../../hooks/useFormState';
import useSedesOptions from '../../hooks/useSedes';
import { formatRut } from '../../utils/rut';

interface AdministradoresInfoProps {
  administrador: Administrador;
  onAdministradorChange: (editedAdministrador: Administrador) => void;
}

const AdministradorInfo = ({
  administrador,
  onAdministradorChange,
}: AdministradoresInfoProps): ReactElement => {
  const { id, nombre, apellidos, rut, email, telefono, direccion } = administrador;

  const sedesOptions = useSedesOptions();

  const formState = useFormState(formatAdministradorForm(administrador, sedesOptions));

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const submitForm = (administradorForm: AdministradorForm): void => {
    putAdministrador(id, formatAdministradorPostBody(administradorForm))
      .then((response) => {
        formState.setSubmitting(false);

        setShow(false);

        onAdministradorChange(response);

        cogoToast.success('Administrador actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentAdministrador = (): void => {
    formState.setSubmitting(true);

    deleteAdministrador(id).then(() => {
      cogoToast.success('Administrador eliminado!');

      history.replace('/administradores');
    });
  };

  return (
    <>
      <div className="d-flex">
        <h3> {`${nombre} ${apellidos}`} </h3>
        <Button
          style={{ margin: '0 0 auto auto' }}
          variant="warning"
          size="sm"
          onClick={handleShow}>
          Editar
        </Button>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="RUT" value={rut ? formatRut(rut.toString()) : '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Email" value={email || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Teléfono" value={telefono || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Dirección" value={direccion || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Sede" value={administrador.sede?.nombre || '-'} />
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Administrador</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <AdministradorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            onCancel={handleClose}
            onDelete={deleteCurrentAdministrador}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AdministradorInfo;
