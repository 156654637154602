import {AxiosPromise} from 'axios';
import axios from '../axiosWithAuth';

export function resendInvitacion(body: {rut: number | string}): AxiosPromise<void> {
  return axios.post('invitaciones/renew', body);
}

export function createInvitacion(body: {rut: number | string}): AxiosPromise<void> {
  return axios.post('invitaciones/create', body);
}

export function revokeInvitacion(body: {rut: number | string}): AxiosPromise<void> {
  return axios.post('invitaciones/revoke', body);
}