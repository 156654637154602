import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import FormButtons from '../form/form-buttons/FormButtons';
import { BitacoraForm } from '../../models/bitacoras/Bitacora';
import NumberInput from '../form/input/NumberInput';
import { format } from 'date-fns';

interface BitacoraFormComponentProps {
  formState: FormState<BitacoraForm>;
  submitForm: (bitacoraForm: BitacoraForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
}

const BitacoraFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
}: BitacoraFormComponentProps): ReactElement => {
  const {
    value: bitacoraForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !bitacoraForm.informacion || !bitacoraForm.fecha;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(bitacoraForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} lg={6}>
            <Input
              title="Fecha"
              name="fecha"
              placeholder="Fecha"
              value={bitacoraForm.fecha}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !bitacoraForm.fecha}
              invalidText="Debe ingresar una fecha"
              disabled={isSubmitting}
              type="date"
              maxDate={format(new Date(), 'dd/MM/yyyy')}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={5}>
                <NumberInput
                  positive
                  title="Tiempo dedicado"
                  name="horas"
                  placeholder="Hora"
                  value={bitacoraForm.horas}
                  onChangeWithName={handleFieldChange}
                  disabled={isSubmitting}
                />
              </Col>
              <Col className="pt-3" xs={1}>
                <p className="mt-4 text-center">:</p>
              </Col>
              <Col className="pt-2" xs={5}>
                <NumberInput
                  positive
                  max={59}
                  formGroupClassName="mt-4"
                  name="minutos"
                  placeholder="Minutos"
                  value={bitacoraForm.minutos}
                  onChangeWithName={handleFieldChange}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <Input
              title="Información"
              name="informacion"
              placeholder="Información"
              value={bitacoraForm.informacion}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !bitacoraForm.informacion}
              invalidText="Debe ingresar información a la bitácora"
              disabled={isSubmitting}
              type="textarea"
            />
          </Col>
        </Row>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> la bitácora?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar registro"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar registro"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};

export default BitacoraFormComponent;
