import { parse } from 'date-fns/esm';
import { format } from 'date-fns';
import {
  BitacoraForm,
  RegistroBitacora,
  RegistroBitacoraApi,
  RequestBodyBitacora,
} from '../models/bitacoras/Bitacora';
import { ProfesorForm, ProfesorRequestBody } from '../models/profesores/Profesor';

export function transformRegistroBitacoraApiIntoRegistroBitacora(
  bitacora: RegistroBitacoraApi,
): RegistroBitacora {
  const { id, timestamp, horas, minutos, informacion } = bitacora;
  const fecha = new Date(timestamp);

  return {
    id,
    fecha,
    informacion,
    horas,
    minutos,
  };
}
export const getEmptyBitacoraForm = (): BitacoraForm => ({
  fecha: format(new Date(), 'dd/MM/yyyy'),
  informacion: '',
  horas: '',
  minutos: '',
});

export const formatBitacoraPostBody = (bitacoraForm: BitacoraForm): RequestBodyBitacora => {
  const { fecha, informacion, horas, minutos } = bitacoraForm;
  return {
    timestamp: parse(fecha.toString(), 'dd/MM/yyyy', new Date()).getTime(),
    informacion,
    horas: Number(horas),
    minutos: Number(minutos),
  };
};

export const formatBitacoraForm = (registroBitacora: RegistroBitacora): BitacoraForm => {
  const { fecha, informacion, horas, minutos } = registroBitacora;
  return {
    fecha: format(fecha, 'dd/MM/yyyy'),
    informacion,
    horas: horas === undefined ? '' : horas.toString(),
    minutos: minutos === undefined ? '' : minutos.toString(),
  };
};
