import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';
import { BaseMovimiento } from '../../models/movimientos/Movimiento';
import Table, { TableColumn } from '../table/Table';
import { BasePresupuesto } from '../../models/presupuestos/Presupuesto';
import { getPresupuesto } from '../../api/presupuestos/presupuestosApi';
import Persona from '../../models/personas/Persona';
import { format } from 'date-fns';
import CurrencyFormat from 'react-currency-format';

interface MovimientosListProps {
  movimientosfilters: MovimientosFilters;
  setMovimientosFilters: Dispatch<SetStateAction<MovimientosFilters>>;
}

const MovimientoList = ({
  movimientosfilters,
  setMovimientosFilters,
}: MovimientosListProps): ReactElement => {
  const [movimientos, setMovimientos] = useState<BaseMovimiento[]>();

  const [prsupuesto, setPresupuesto] = useState<BasePresupuesto>();

  const [isLoading, setLoading] = useState(true);

  const { presupuestoId } = useParams();

  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getPresupuesto(presupuestoId, movimientosfilters)
      .then((response): void => {
        setPresupuesto(response.data);
        setMovimientos(response.data.movimientos)
        setPages(response.data.movimientos.length/movimientosfilters.itemsPerPage);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los movimientos.');
      })
      .finally(() => setLoading(false));
  }, [movimientosfilters, setPresupuesto]);

  const handleMovimientoClick = (movimiento: BaseMovimiento): void => {
    history.push(`/movimientos/${movimiento.id}`);
  };

  const formatAutor = (persona?: Persona): string =>{
    if(persona){
      if(persona.nombre && persona.apellidos) {
        return persona.nombre + ' ' + persona.apellidos; 
      }
      else if(persona.nombre){
        return persona.nombre;
      }
    }
    return ' - ';
  };

  const tableColumns: TableColumn<BaseMovimiento>[] = [
    {
      title: 'Fecha',
      accessor: (movimiento) => `${format(movimiento.fecha, 'dd/MM/yyyy')}`,
    },
    {
      title: 'Autor',
      accessor: (movimiento) =>
        formatAutor(movimiento.persona),
      hideUntil: 'lg',
    },
    {
      title: 'Motivo del Movimiento',
      accessor: 'motivo',
    },
    {
      title: 'Tipo de Movimiento',
      accessor: (movimiento) =>
        movimiento.tipo?.nombre,
      hideUntil: 'md',
    },
    {
      title: 'Monto',
      accessor: (movimiento) =>
        <CurrencyFormat value={movimiento.monto} displayType={'text'} thousandSeparator={true} prefix={'$'} />  
    },
  ];

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!movimientos)
    return <p className="text-center">No se pudo cargar el listado de movimientos</p>;

  return (
    <>
      <Table
        items={movimientos}
        columns={tableColumns}
        minRowsCount={movimientosfilters.itemsPerPage}
        pages={pages}
        activePage={movimientosfilters.page}
        onPageSelect={(page): void =>
          setMovimientosFilters({ ...movimientosfilters, page })
        }
      />
    </>
  );
};
export default MovimientoList;
