import React, { ReactElement } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/layout/Layout';
import AppRoute from './components/route/AppRoute';
import ThemeStore from './stores/ThemeStore';
import EstablecimientosPage from './pages/establecimientos/EstablecimientosPage';
import EstablecimientoPage from './pages/establecimientos/EstablecimientoPage';
import ProfesoresPage from './pages/profesores/ProfesoresPage';
import ProfesorPage from './pages/profesores/ProfesorPage';
import TutoresPage from './pages/tutores/TutoresPage';
import TutorPage from './pages/tutores/TutorPage';
import LoginPage from './pages/usuarios/LoginPage';
import InvitationAcceptPage from './pages/usuarios/InvitationAcceptPage';
import AuthStore from './stores/AuthStore';
import InventarioPage from './pages/inventario/InventarioPage';
import ManageUsersPage from './pages/usuarios/ManageUsersPager';
import AdministradorPage from './pages/administradores/AdministradorPage';
import CoordinadorPage from './pages/coordinadores/CoordinadorPage';
import EstudiantesPage from './pages/estudiantes/EstudiantesPage';
import EstudiantePage from './pages/estudiantes/EstudiantePage';
import EstudiantePagePerfil from './pages/estudiantes/EstudiantePagePerfil';
import UsuarioPage from './pages/usuarios/UsuarioPage';
import ResetPasswordRequestPage from './pages/usuarios/ResetPasswordRequestPage';
import ResetPasswordPage from './pages/usuarios/ResetPasswordPage';
import CursosPage from './pages/cursos/CursosPage';
import CursoPage from './pages/cursos/CursoPage';
import ParaleloPage from './pages/paralelos/ParaleloPage';
import ReportesPage from './pages/reportes/ReportesPage';
import BitacoraPage from './pages/bitacoras/BitacoraPage';
import EvaluacionPage from './pages/cursos/evaluaciones/EvaluacionPage';
import SesionPage from './pages/sesiones/SesionPage';
import BitacoraProfesorPage from './pages/profesores/BitacoraProfesorPage';
import BitacoraTutorPage from './pages/tutores/BitacoraTutorPage';
import HomePage from './pages/home/HomePage';
import CursosEstudiantePage from './pages/cursos/CursosEstudiantePage';
import ParaleloEstudiantePage from './pages/paralelos/ParaleloEstudiantePage';
import EntrevistaPage from './pages/entrevistas/EntrevistaPage';
import InstitucionesPage from './pages/instituciones/InstitucionesPage';
import EgresadosPage from './pages/egresados/EgresadosPage';
import InstitucionPage from './pages/instituciones/InstitucionPage';
import EgresadoPage from './pages/egresados/EgresadoPage';
import CarreraPage from './pages/carreras/CarreraPage';
import CarrerasPage from './pages/carreras/CarrerasPage';
import PresupuestosPage from './pages/presupuestos/PresupuestosPage';
import PresupuestoPage from './pages/presupuestos/PresupuestoPage';
import MovimientoPage from './pages/movimientos/MovimientoPage';


const App = (): ReactElement => {
  return (
    <ThemeStore>
      <BrowserRouter>
        <AuthStore>
          <Switch>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/reiniciar-clave">
              <ResetPasswordRequestPage />
            </Route>
            <Route exact path="/reiniciar-clave/:passwordResetToken">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/inscripciones/:invitationToken">
              <InvitationAcceptPage />
            </Route>
            <Route>
              <Layout>
                <Switch>
                  <AppRoute title="Inicio" exact path="/home">
                    <HomePage />
                  </AppRoute>
                  <AppRoute title="Gestión de Usuarios" exact path="/usuarios">
                    <ManageUsersPage />
                  </AppRoute>
                  <AppRoute title="Ver Usuario" exact path="/usuarios/:usuarioId">
                    <UsuarioPage />
                  </AppRoute>
                  <AppRoute title="Lista de Establecimientos" exact path="/establecimientos">
                    <EstablecimientosPage />
                  </AppRoute>
                  <AppRoute title="Lista de Instituciones" exact path="/instituciones">
                    <InstitucionesPage />
                  </AppRoute>
                  <AppRoute title="Ver Institución" exact path="/instituciones/:institucionId">
                    <InstitucionPage />
                  </AppRoute>
                  <AppRoute
                    title="Ver Establecimiento"
                    exact
                    path="/establecimientos/:establecimientoId">
                    <EstablecimientoPage />
                  </AppRoute>
                  <AppRoute title="Lista de Egresados" exact path="/egresados">
                    <EgresadosPage />
                  </AppRoute>
                  <AppRoute title="Ver Egresado" exact path="/egresados/:egresadoId">
                    <EgresadoPage />
                  </AppRoute>
                  <AppRoute title="Lista de Estudiantes" exact path="/estudiantes">
                    <EstudiantesPage />
                  </AppRoute>
                  <AppRoute title="Ver Estudiante" exact path="/estudiantes/:estudianteId">
                    <EstudiantePage />
                  </AppRoute>
                  <AppRoute title="Mis Datos" exact path="/estudiantePerfil/:estudianteId/:perfil">
                    <EstudiantePage />
                  </AppRoute>
                  <AppRoute title="Lista de Profesores" exact path="/profesores">
                    <ProfesoresPage />
                  </AppRoute>
                  <AppRoute title="Ver Profesor" exact path="/profesores/:profesorId">
                    <ProfesorPage />
                  </AppRoute>
                  <AppRoute title="Ver Bitácora" exact path="/profesores/:profesorId/bitacora">
                    <BitacoraProfesorPage />
                  </AppRoute>
                  <AppRoute
                    title="Ver Administrador"
                    exact
                    path="/administradores/:administradorId">
                    <AdministradorPage />
                  </AppRoute>
                  <AppRoute title="Ver Coordinador" exact path="/coordinadores/:coordinadorId">
                    <CoordinadorPage />
                  </AppRoute>
                  <AppRoute title="Lista de Tutores" exact path="/tutores">
                    <TutoresPage />
                  </AppRoute>
                  <AppRoute title="Ver Tutor" exact path="/tutores/:tutorId">
                    <TutorPage />
                  </AppRoute>
                  <AppRoute title="Ver Bitácora" exact path="/tutores/:tutorId/bitacora">
                    <BitacoraTutorPage />
                  </AppRoute>
                  <AppRoute title="Listado de Presupuestos" exact path="/presupuestos">
                    <PresupuestosPage />
                  </AppRoute>
                  <AppRoute title="Registro de Movimientos" exact path="/presupuestos/:presupuestoId">
                    <PresupuestoPage />
                  </AppRoute>
                  <AppRoute title="Datos del Movimiento" exact path="/presupuestos/:presupuestoId/:movimientoId">
                    <MovimientoPage />
                  </AppRoute>
                  <AppRoute title="Inventario" exact path="/inventario">
                    <InventarioPage />
                  </AppRoute>
                  <AppRoute title="Cursos" exact path="/cursos">
                    <CursosPage />
                  </AppRoute>
                  <AppRoute title="Ver Curso" exact path="/cursos/:cursoId">
                    <CursoPage />
                  </AppRoute>
                  <AppRoute title="Ver Curso Estudiante" exact path="/paralelosInscritos">
                    <CursosEstudiantePage />
                  </AppRoute>
                  <AppRoute title="Ver Curso Estudiante" exact path="/paralelosInscritos/:paraleloId/:estudianteId">
                    <ParaleloEstudiantePage />
                  </AppRoute>
                  <AppRoute
                    title="Ver Evaluación"
                    exact
                    path="/cursos/:cursoId/evaluaciones/:evaluacionId">
                    <EvaluacionPage />
                  </AppRoute>
                  <AppRoute title="Ver Paralelo" exact path="/cursos/paralelos/:paraleloId">
                    <ParaleloPage />
                  </AppRoute>
                  <AppRoute title="Ver Sesión" exact path="/cursos/sesiones/:sesionId">
                    <SesionPage />
                  </AppRoute>
                  <AppRoute title="Ver Entrevista" exact path="/entrevistas/:entrevistaId">
                    <EntrevistaPage />
                  </AppRoute>
                  <AppRoute title="Generar Reporte" exact path="/reportes">
                    <ReportesPage />
                  </AppRoute>
                  <AppRoute title="Bitácora" path="/bitacora">
                    <BitacoraPage />
                  </AppRoute>
                  <AppRoute title="Carreras" path="/carreras">
                    <CarrerasPage />
                  </AppRoute>
                  <AppRoute title="Carrera" path="/carrera/:carreraId">
                    <CarreraPage />
                  </AppRoute>
                  <Redirect to="/home" />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </AuthStore>
      </BrowserRouter>
    </ThemeStore>
  );
};

export default App;
