import React, { ReactElement, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import ucnLogo from '../../../assets/images/ucn.png';
import sigoMiVocacionLogo from '../../../assets/images/yo sigo mi vocacion.jpg';
import enlaceEscolarLogo from '../../../assets/images/enlace escolar.jpg';
import SidebarMenuItem from './SidebarMenuItem';
import LayoutContext from '../../../contexts/LayoutContext';
import getSidebarItems from '../../../utils/sidebar';
import AuthContext from '../../../contexts/AuthContext';
import { getEstudianteEmail } from '../../../api/estudiantes/estudiantesApi';
import cogoToast from 'cogo-toast';

const Sidebar = (): ReactElement => {
  const { isSidebarActive } = useContext(LayoutContext);

  const { userName, userRoles, email, logout } = useContext(AuthContext);

  const history = useHistory();

  const infoEstudiante = (): void => {
    getEstudianteEmail(email)
    .then((response) => {
      history.push(`/estudiantePerfil/${response.data.id}/perfil`)
    })
    .catch(() => {
      cogoToast.error('No se pudo cargar la información del estudiante');
    });
    
  }

  const sidebarClassNames = classNames('sidebar', {
    active: isSidebarActive,
  });

  return (
    <div className={sidebarClassNames}>
      <div className="sidebar-header">
        <img className="sidebar-logo" src={ucnLogo} alt="Logo UCN" />
        <h4 className="sidebar-title">ProVETA</h4>
      </div>
      <div className="sidebar-menu-container">
        <div className="sidebar-menu">
          <ul>
            {getSidebarItems(userRoles).map((sidebarItem) => (
              <SidebarMenuItem key={sidebarItem.name} sidebarItem={sidebarItem} />
            ))}
          </ul>
        </div>
      </div>
      <hr />
      <div className="sidebar-footer">
        <div className="icons-container">
          <img src={enlaceEscolarLogo} alt="Logo Enlace Escolar" />
          <img src={sigoMiVocacionLogo} alt="Logo Yo Sigo Mi Vocación" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
