import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import {Carrera} from '../models/carreras/Carrera';
import { getCarreras } from '../api/carreras/carrerasApi';

const useCarrerasOptions = (): SelectOption<Carrera>[] => {
  const [carrerasOptions, setCarrerasOptions] = useState<
    SelectOption<Carrera>[]
  >([]);

  useEffect(() => {
    getCarreras({page: 1,
      itemsPerPage: 9999999,})
      .then((response) => {
        setCarrerasOptions(
          response.data.items.map((carrera) => {
            return { label: carrera.nombre, value: carrera };
          }),
        );
      })
      .catch(() => {});
  }, [setCarrerasOptions]);
  return carrerasOptions;
};
export default useCarrerasOptions;
