import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import Usuario, { UsuarioForm } from '../../models/usuarios/Usuario';
import UsuarioFormComponent from './UsuarioFormComponent';
import { deleteUsuario, putUsuario } from '../../api/usuarios/usuariosApi';
import {
  formatUsuarioForm,
  formatUsuarioPutBody,
  getActionButtonFromUserStatus,
} from '../../utils/usuarios';
import useFormState from '../../hooks/useFormState';
import useProgramasOptions from '../../hooks/usePrograma';
import useSedesOptions from '../../hooks/useSedes';
import { formatRut } from '../../utils/rut';
import RolProtectedElement from '../roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';

interface UsuariosInfoProps {
  usuario: Usuario;
  onUsuarioChange: (editedUsuario: Usuario) => void;
}

const UsuarioInfo = ({ usuario, onUsuarioChange }: UsuariosInfoProps): ReactElement => {
  const { id, nombre, apellidos, rut, email, telefono, direccion, roles, status } = usuario;

  const programasOptions = useProgramasOptions();
  const sedesOptions = useSedesOptions();

  const formState = useFormState(formatUsuarioForm(usuario, sedesOptions));

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  useEffect(() => {
    formState.setForm(formatUsuarioForm(usuario, sedesOptions));
  }, [usuario, sedesOptions, formState.setForm]);

  const submitForm = (usuarioForm: UsuarioForm): void => {
    putUsuario(id, formatUsuarioPutBody(usuarioForm))
      .then((response) => {
        formState.setSubmitting(false);

        setShow(false);

        onUsuarioChange(response);

        cogoToast.success('Usuario actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentUsuario = (): void => {
    formState.setSubmitting(true);

    deleteUsuario(id)
      .then(() => {
        formState.setSubmitting(false);
        cogoToast.success('Usuario eliminado!');

        history.replace('/usuarios');
      })
      .catch((error) => {
        formState.setSubmitting(false);
        cogoToast.error(error.message);
      });
  };

  return (
    <>
      <div className="d-flex">
        <div>
          <h3> {`${nombre} ${apellidos}`}</h3>
          <h6>{`${roles.join(', ')}`}</h6>
        </div>
        <RolProtectedElement roles={[Rol.Administrador]}>
          <div className="flex-grow-1" style={{ whiteSpace: 'nowrap', display: 'flex' }}>
            <div className="mt-0 mb-auto mr-0 ml-auto">
              {getActionButtonFromUserStatus(usuario)}
              <Button className="ml-2" variant="warning" size="sm" onClick={handleShow}>
                Editar
              </Button>
            </div>
          </div>
        </RolProtectedElement>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="RUT" value={rut ? formatRut(rut.toString()) : '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Email" value={email || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Teléfono" value={telefono || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Dirección" value={direccion || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Sede" value={usuario.sede?.nombre || '-'} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Estado" value={status} />
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Usuario</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <UsuarioFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            onCancel={handleClose}
            onDelete={deleteCurrentUsuario}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UsuarioInfo;
