import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import TipoMovimiento from '../../models/movimientos/TipoMovimiento';
import Anios from '../../models/movimientos/Anios';
import Meses from '../../models/movimientos/Meses';

export function getAnios(): AxiosPromise<Anios[]> {
  return axios.get('anios');
}

export function getMeses(): AxiosPromise<Meses[]> {
  return axios.get('meses');
}
