import CursosFilters from '../../models/cursos/CursosFilters';
import axios from '../axiosWithAuth';
import { BaseCurso, CursoPostBody, CursoPutBody, ExtendedCurso } from '../../models/cursos/Curso';

export async function getCursos(filters: CursosFilters): Promise<BaseCurso[]> {
  const axiosResponse = await axios.get('cursos', { params: filters });

  return axiosResponse.data;
}

export async function getCursosByEstudianteEmail(email: string): Promise<BaseCurso[]> {

  const axiosResponse = await axios.get(`cursos/cursosEstudiante/${email}`);

  return axiosResponse.data;
}

export async function getCursoById(id: string): Promise<ExtendedCurso> {
  const axiosPromise = await axios.get(`cursos/${id}`);

  return axiosPromise.data;
}

export async function postCurso(postBody: CursoPostBody): Promise<ExtendedCurso> {
  const axiosResponse = await axios.post('cursos', postBody);

  return axiosResponse.data;
}

export async function updateCurso(
  cursoId: number | string,
  putBody: CursoPutBody,
): Promise<ExtendedCurso> {
  const axiosResponse = await axios.put(`cursos/${cursoId}`, putBody);

  return axiosResponse.data;
}

export async function deleteCursoById(id: number | string): Promise<void> {
  await axios.delete(`cursos/${id}`);
}
