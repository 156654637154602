/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { Input, InputProps } from './Input';
import { formatRut, getRutWithoutSeparators } from '../../../utils/rut';

const RutInput = ({
  onChangeWithName,
  onChange,
  value,
  ...inputGroupProps
}: InputProps): ReactElement => {
  const preHandleChange = (newValue: string): void => {
    if (onChange) onChange(getRutWithoutSeparators(newValue));
  };

  const preHandleChangeWithName = (name: string, newValue: string): void => {
    if (onChangeWithName) onChangeWithName(name, getRutWithoutSeparators(newValue));
  };

  return (
    <Input
      value={formatRut(value.toString())}
      onChangeWithName={preHandleChangeWithName}
      onChange={preHandleChange}
      {...inputGroupProps}
    />
  );
};

export default RutInput;
