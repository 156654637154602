import React, { Dispatch, ReactElement, ReactText, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import EstablecimientosFilters from '../../models/establecimientos/EstablecimientosFilters';
import getComunasSelectGroups from '../../resources/comunas';
import TipoEstablecimiento from '../../models/establecimientos/TipoEstablecimiento';
import { SelectOption } from '../../models/form/Select';
import Select from '../form/select/Select';
import { Input } from '../form/input/Input';

interface EstablecimientosFiltersProps {
  tiposEstablecimientosOptions: SelectOption<TipoEstablecimiento>[];
  establecimientosFilters: EstablecimientosFilters;
  onFiltersChange: Dispatch<SetStateAction<EstablecimientosFilters>>;
}

const EstablecimientosFiltersComponent = ({
  tiposEstablecimientosOptions,
  establecimientosFilters,
  onFiltersChange,
}: EstablecimientosFiltersProps): ReactElement => {
  const handleFilterChange = (name: string, value?: ReactText): void =>
    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

  return (
    <>
      <Row>
        <Col lg={4}>
          <Input
            value={establecimientosFilters.nombreOrRbd}
            name="nombreOrRbd"
            onChangeWithName={handleFilterChange}
            type="text"
            placeholder="Nombre o RBD"
            title="Nombre o RBD"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Tipo de Establecimiento"
            name="tipoEstablecimiento"
            options={tiposEstablecimientosOptions}
            onSelectWithName={(name, tipoEstablecimiento): void => {
              handleFilterChange(name, tipoEstablecimiento.value.id.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, undefined)}
            placeholder="Tipo de establecimiento"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Comuna"
            name="comuna"
            onSelectWithName={(name, comuna): void => handleFilterChange(name, comuna.value)}
            onClearWithName={(name): void => handleFilterChange(name, undefined)}
            options={getComunasSelectGroups()}
            placeholder="Comuna"
          />
        </Col>
      </Row>
    </>
  );
};

export default EstablecimientosFiltersComponent;
