import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import NumberInput, { NumberInputProps } from './NumberInput';

interface CountInputProps extends NumberInputProps {
  isLoading?: boolean;
  onChangeByButton?: (value: string) => void;
  onChangeByButtonWithName?: (name: string, value: string) => void;
}

const CountInput = ({
  value,
  onChangeByButton,
  onChangeByButtonWithName,
  name,
  isLoading,
  ...props
}: CountInputProps): ReactElement => {
  const handleChangeByButton = (newValue: string): void => {
    if (onChangeByButton) onChangeByButton(newValue);
    if (onChangeByButtonWithName && name) onChangeByButtonWithName(name, newValue);
  };

  const changeValueBy = (amount: number): void => {
    const numericValue = Number(value);

    if (Number.isNaN(numericValue)) handleChangeByButton('0');
    else handleChangeByButton((numericValue + amount).toString());
  };

  return (
    <NumberInput
      {...props}
      name={name}
      value={value}
      append={{
        content: isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <i className="mdi mdi-plus" />
        ),
        backgroundColor: 'success',
        onClick: (): void => changeValueBy(1),
      }}
      prepend={{
        content: isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <i className="mdi mdi-minus" />
        ),
        backgroundColor: 'danger',
        onClick: (): void => changeValueBy(-1),
      }}
    />
  );
};

export default CountInput;
