import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import Anios from '../models/movimientos/Anios';
import { getAnios } from '../api/movimientos/fechaMovimientosApi';

const useAniosOptions = (): SelectOption<Anios>[] => {
  const [aniosOptions, setAniosOptions] = useState<
    SelectOption<Anios>[]
  >([]);

  useEffect((): void => {
    getAnios()
      .then((response): void => {
        setAniosOptions(
          response.data.map((tipo) => {
            let aux = tipo.numero.toString();
            return { label: aux, value: tipo };
          }),
        );
      })
      .catch(() => {});
  }, [setAniosOptions]);

  return aniosOptions;
};

export default useAniosOptions;
