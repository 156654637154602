import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import InstitucionesFilters from '../../models/instituciones/InstitucionesFilters';
import { BaseInstitucion, Institucion, InstitucionesGetResponse, InstitucionRequestBody } from '../../models/instituciones/Institucion';

export function getInstituciones(
  filters: InstitucionesFilters,
): AxiosPromise<InstitucionesGetResponse> {
  return axios.get('instituciones', { params: filters });
}

export function postInstitucion(
  requestBody: InstitucionRequestBody,
): AxiosPromise<Institucion> {
  return axios.post('instituciones', requestBody);
}

export function getInstitucion(institucionId: string): AxiosPromise<Institucion> {
  return axios.get(`instituciones/${institucionId}`);
}

export function putInstitucion(
  institucionId: number,
  requestBody: InstitucionRequestBody,
): AxiosPromise<Institucion> {
  return axios.put(`instituciones/${institucionId}`, requestBody);
}

export function deleteInstitucion(institucionId: number): AxiosPromise<void> {
  return axios.delete(`instituciones/${institucionId}`);
}
