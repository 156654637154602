import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import UsuarioInfo from '../../components/usuarios/UsuarioInfo';
import { getUsuario } from '../../api/usuarios/usuariosApi';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';
import Usuario from '../../models/usuarios/Usuario';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';

const UsuarioPage = (): ReactElement => {
  const [usuario, setUsuario] = useState<Usuario>();

  const { usuarioId } = useParams<{ usuarioId: string }>();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    getUsuario(usuarioId)
      .then((response): void => {
        setUsuario(response);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del usuario');
      });
  }, [usuarioId, setUsuario]);

  useEffect(() => {
    if (usuario) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Usuarios',
          href: '/usuarios',
        },
        {
          label: usuario.nombre,
          active: true,
        },
      ]);
    }
  }, [usuario, breadcrumb]);

  if (!usuario)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleUsuarioChange = (changedUsuario: Usuario): void => setUsuario(changedUsuario);

  return (
    <>
      <Card>
        <Card.Body>
          <UsuarioInfo usuario={usuario} onUsuarioChange={handleUsuarioChange} />
        </Card.Body>
      </Card>
      <RolProtectedElement roles={[Rol.Administrador]}>
        <EntityDocumentsCard entityTypeEndpoint="usuarios" entityId={usuario.id} />
      </RolProtectedElement>
    </>
  );
};

export default UsuarioPage;
