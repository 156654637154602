import { parse, format } from 'date-fns';
import { SelectOption } from '../models/form/Select';
import { Entrevista, EntrevistaForm, EntrevistaRequestBody, ExtendedEntrevista } from '../models/entrevistas/Entrevista';
import { Coordinador } from '../models/coordinadores/Coordinador';

export const formatEntrevistaForm = (
  entrevista: ExtendedEntrevista,
  coordinadoresOptions: SelectOption<Coordinador>[],
): EntrevistaForm => {
  const {
    fecha = '',
    resumen = '',
    observacion = '',
    compromiso = '',
    tema,
    id = entrevista.id,
  } = entrevista;

  const coordinadoresSelectOptions = coordinadoresOptions.find((option) => option.value.id === entrevista.coordinador.id);

  return {
    id,
    fecha: fecha ? format(new Date(fecha), 'dd/MM/yyyy') : '',
    resumen,
    observacion,
    compromiso,
    coordinador: coordinadoresSelectOptions,
    tema,
  };
};

export const formatEntrevistaPostBody = (entrevistaForm: EntrevistaForm): EntrevistaRequestBody => {
  const {
    id,
    fecha,
    resumen,
    observacion,
    compromiso,
    coordinador,
    estudiante,
    tema,
  } = entrevistaForm;

  let cooID = coordinador ? coordinador.value.id : undefined;

  return {
    fecha: fecha ? parse(fecha, 'dd/MM/yyyy', new Date()).getTime() : undefined,
    resumen: resumen || undefined,
    observacion: observacion || undefined,
    compromiso: compromiso || undefined,
    coordinadorId: cooID,
    estudianteId: estudiante ? estudiante.id : undefined,
    tema,
    id,
  };
};

export const getEmptyEntrevistaForm = (): EntrevistaForm => ({
  fecha: '',
  tema: '',
  resumen: '',
  observacion: '',
  compromiso: '',
  id: 0,
});

