import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { BaseCurso } from '../../models/cursos/Curso';
import Table, { TableColumn } from '../table/Table';
import CursosFilters from '../../models/cursos/CursosFilters';
import useCursos from '../../hooks/useCursos';

interface CursosListProps {
  cursosFilters: CursosFilters;
}

const CursosList = ({ cursosFilters }: CursosListProps): ReactElement => {
  const { cursos, isLoading } = useCursos(cursosFilters);

  const history = useHistory();

  const onCursoSelect = (curso: BaseCurso): void => {
    history.push(`/cursos/${curso.id}`);
  };

  const tableColumns: TableColumn<BaseCurso>[] = [
    {
      title: 'Nombre',
      accessor: 'nombre',
    },
    {
      title: 'Código',
      accessor: 'codigo',
    },
    {
      title: 'Periodo',
      accessor: (curso) => `${curso.year}S${curso.semestre}`,
    },
    {
      title: 'Programa',
      accessor: (curso) => {return curso.programa.nombre}
    },
    {
      title: 'Sede',
      hideUntil: 'md',
      accessor: (curso) => {return curso.sede.nombre}
    }
  ];

  if (cursosFilters.includeInactive) {
    tableColumns.push({
      title: 'Estado',
      accessor: (curso) => {return curso.isActive? 'Activo': 'Inactivo'}
    })
  }

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Table responsive items={cursos} columns={tableColumns} onItemClick={onCursoSelect} hover />
  );
};

export default CursosList;
