import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import { InstitucionesGetResponse } from '../../models/instituciones/Institucion';
import {
  BaseParaleloEgresado,
  Egresado,
  EgresadoRequestBody,
  EgresadosGetResponse,
  ExtendedParaleloEgresado,
} from '../../models/egresados/Egresado';
import EgresadosFilters from '../../models/egresados/EgresadosFilters';
import BasePrograma from '../../models/programas/Programa';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';

export function getInstituciones(): AxiosPromise<InstitucionesGetResponse> {
  return axios.get('instituciones');
}

export function getSedes(): AxiosPromise<Sede[]> {
  return axios.get('sedes');
}

export function getProgramas(): AxiosPromise<BasePrograma[]> {
  return axios.get('programas');
}

export function getNivel(): AxiosPromise<Nivel[]> {
  return axios.get('niveles');
}

export function getEgresados(filters: EgresadosFilters): AxiosPromise<EgresadosGetResponse> {
  return axios.get('egresados', { params: filters });
}

export function postEgresado(requestBody: EgresadoRequestBody): AxiosPromise<Egresado> {
  return axios.post('egresados', requestBody);
}
export function getEgresado(egresadoId: string): AxiosPromise<Egresado> {
  return axios.get(`egresados/${egresadoId}`);
}

export function getEgresadoEmail(egresadoEmail: string): AxiosPromise<Egresado> {
  return axios.get(`egresados/perfil/${egresadoEmail}`);
}

export function putEgresado(
  egresadoId: number,
  requestBody: EgresadoRequestBody,
): AxiosPromise<Egresado> {
  return axios.put(`egresados/${egresadoId}`, requestBody);
}

export function deleteEgresado(egresadoId: number): AxiosPromise<void> {
  return axios.delete(`egresados/${egresadoId}`);
}

export async function getParalelosByEgresadoEmail(email: string): Promise<BaseParaleloEgresado[]> {

  const axiosResponse = await axios.get(`egresados/paralelosInscritos/${email}`);

  return axiosResponse.data;
}

export async function getParaleloEgresadoDetail(paraleloId: string, egresadoId: string): Promise<ExtendedParaleloEgresado> {

  const axiosResponse = await axios.get(`egresados/paraleloDetalle/${paraleloId}/${egresadoId}`);

  return axiosResponse.data;
}
