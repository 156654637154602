import {
  ApiBaseProfesor,
  ApiProfesor,
  BaseProfesor,
  Profesor,
  ProfesorForm,
  ProfesorRequestBody,
} from '../models/profesores/Profesor';
import { SelectOption } from '../models/form/Select';
import Sede from '../models/sedes/Sede';
import BasePrograma from '../models/programas/Programa';
import { transformApiBasePersonaIntoBasePersona } from './personas';

export const transformApiProfesorIntoProfesor = (profesor: ApiProfesor): Profesor => {
  return {
    ...profesor,
    ...transformApiBasePersonaIntoBasePersona(profesor),
  };
};

export const transformApiBaseProfesorIntoBaseProfesor = (
  baseProfesor: ApiBaseProfesor,
): BaseProfesor => {
  return {
    ...baseProfesor,
    ...transformApiBasePersonaIntoBasePersona(baseProfesor),
  };
};

export const getEmptyProfesorForm = (): ProfesorForm => ({
  nombre: '',
  apellidos: '',
  rut: '',
  email: '',
  telefono: '',
  direccion: '',
  horasAsignadas: '',
  sede: undefined,
  programa: undefined,
});

export const formatProfesorPostBody = (profesorForm: ProfesorForm): ProfesorRequestBody => {
  const {
    nombre,
    apellidos,
    rut,
    email,
    telefono,
    direccion,
    horasAsignadas,
    sede,
    programa,
  } = profesorForm;

  return {
    nombre,
    apellidos,
    rut: Number(rut.slice(0, -1)),
    email,
    telefono,
    direccion,
    horasAsignadas: Number(horasAsignadas),
    sedeId: sede?.value.id,
    programaId: programa?.value.id,
  };
};

export const formatProfesorForm = (
  profesor: Profesor,
  programaOptions: SelectOption<BasePrograma>[],
  sedeOptions: SelectOption<Sede>[],
): ProfesorForm => {
  const {
    nombre,
    apellidos = '',
    rut = '',
    email = '',
    telefono = '',
    direccion = '',
    horasAsignadas = '',
    isActive,
  } = profesor;

  const sedeSelectOption = sedeOptions.find((option) => option.value.id === profesor.sede.id);
  const programaSelectOption = programaOptions.find(
    (option) => option.value.id === profesor.programa.id,
  );

  return {
    nombre,
    apellidos,
    rut: rut ? rut.toString() : '',
    email,
    telefono,
    direccion,
    horasAsignadas: horasAsignadas ? horasAsignadas.toString() : '',
    programa: programaSelectOption,
    sede: sedeSelectOption,
    isActive,
  };
};
