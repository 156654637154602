import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormState } from '../../hooks/useFormState';
import { ParaleloForm } from '../../models/paralelo/Paralelo';
import { SelectOption } from '../../models/form/Select';
import { BaseProfesor } from '../../models/profesores/Profesor';
import Select from '../form/select/Select';
import FormButtons from '../form/form-buttons/FormButtons';

interface ParaleloFormComponentProps {
  formState: FormState<ParaleloForm>;
  submitForm: (paraleloForm: ParaleloForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  profesorOptions: SelectOption<BaseProfesor>[];
}

const ParaleloFormComponent = ({
  formState,
  onCancel,
  onDelete,
  profesorOptions,
  submitForm,
}: ParaleloFormComponentProps): ReactElement => {
  const {
    value,
    handleFieldChange,
    areValidationsEnabled,
    isConfirmingDelete,
    isSubmitting,
    setAreValidationsEnabled,
    setConfirmingDelete,
  } = formState;

  const { profesor } = value;

  const isFormInvalid = !profesor;

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    submitForm(value);
  };

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <Select
            name="profesor"
            title="Profesor"
            placeholder="Profesor"
            value={profesor}
            options={profesorOptions}
            onSelectWithName={handleFieldChange}
            invalid={areValidationsEnabled && !profesor}
            invalidText="Debe seleccionar a un profesor"
            disabled={isSubmitting}
          />
        </Col>
      </Row>
      <hr />
      {isFormInvalid && areValidationsEnabled && (
        <div className="text-danger text-center mb-3">
          <small>Hay campos obligatorios sin ingresar</small>
        </div>
      )}
      {isConfirmingDelete && (
        <h6 className="text-center">
          ¿Está segura/o de que desea <span className="text-danger font-weight-bold">eliminar</span>{' '}
          el paralelo?
        </h6>
      )}
      <FormButtons
        submitButtonContent="Guardar paralelo"
        onSubmitClick={handleSubmitClick}
        onCancelClick={onCancel}
        isConfirmingDelete={isConfirmingDelete}
        onDeleteClick={onDelete ? handleDeleteClick : undefined}
        onDeleteConfirm={onDelete}
        onCanceleDelete={cancelDelete}
        deleteButtonContent="Eliminar paralelo"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      />
    </Form>
  );
};

export default ParaleloFormComponent;
