import React, { ReactElement, useState } from 'react';
import { Card, Col, Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { BitacoraForm, RegistroBitacora } from '../../models/bitacoras/Bitacora';
import BitacoraFormComponent from './BitacoraFormComponent';
import useFormState from '../../hooks/useFormState';
import { formatBitacoraForm, formatBitacoraPostBody } from '../../utils/bitacoras';
import { deleteBitacora, putBitacora } from '../../api/bitacoras/bitacorasApi';

interface RenderBitacoraProps {
  bitacora: RegistroBitacora;
  readonly?: boolean;
  onDelete?: () => void;
}
const RegistroBitacoraCard = ({ bitacora, readonly, onDelete }: RenderBitacoraProps): ReactElement => {
  const { fecha, informacion, horas, minutos, id } = bitacora;
  const [show, setShow] = useState(false);

  const history = useHistory();
  const formState = useFormState(formatBitacoraForm(bitacora));

  const handleClose = () => setShow(false);
  const openModal = () => {
    if (!readonly) {
      setShow(true);
    }
  };

  const submitForm = (bitacoraForm: BitacoraForm): void => {
    formState.setSubmitting(true);
    putBitacora(id, formatBitacoraPostBody(bitacoraForm))
      .then(() => {
        handleClose();
        cogoToast.success('Bitacora modificada!');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  const deleteCurrentRegistroBitacora = (): void => {
    formState.setSubmitting(true);

    deleteBitacora(id).then(() => {
      cogoToast.success('Bitacora eliminada!');

      if (onDelete) {
        onDelete();
      }

      history.replace('/bitacora');
      handleClose();
    });
  };
  return (
    <>
      <Card style={{ cursor: readonly ? undefined : 'pointer' }} onClick={openModal}>
        <Card.Body>
          <h5>
            {`${format(fecha, 'd')} de ${format(fecha, 'MMMM', { locale: esLocale })} del ${format(
              fecha,
              'yyyy',
            )}`}{' '}
          </h5>
          <p>{informacion}</p>
        </Card.Body>
        {(!!horas || !!minutos) && (
          <Card.Footer className="py-0">
            <p className="my-1">
              Tiempo dedicado: {horas} {horas !== 1 ? 'horas' : 'hora'} y {minutos}{' '}
              {minutos !== 1 ? 'minutos' : 'minuto'}
            </p>
          </Card.Footer>
        )}
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title> Modificar registro de la bitácora </Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <BitacoraFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={handleClose}
            onDelete={deleteCurrentRegistroBitacora}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RegistroBitacoraCard;
