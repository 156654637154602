import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import InstitucionesFilters from '../../models/instituciones/InstitucionesFilters';
import useTiposInstitucionesOptions from '../../hooks/useTiposInstitucion';
import InstitucionesFiltersComponent from '../../components/instituciones/InstitucionesFiltersComponent';
import NewInstitucionButton from '../../components/instituciones/NewInstitucionButton';
import InstitucionList from '../../components/instituciones/InstitucionesList';
import useRegionesOptions from '../../hooks/useRegionesOptions';

const InstitucionesPage = (): ReactElement => {
  const [institucionesfilters, setInstitucionesFilters] = useState<InstitucionesFilters>({
    nombreOrRbd: '',
    page: 1,
    itemsPerPage: 8,
  });

  const tiposInstitucionesOptions = useTiposInstitucionesOptions();
  const regionesOptions = useRegionesOptions();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Instituciones',
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <InstitucionesFiltersComponent
            tiposInstitucionesOptions={tiposInstitucionesOptions}
            institucionesFilters={institucionesfilters}
            regionesOptions={regionesOptions}
            onFiltersChange={setInstitucionesFilters}
          />
          <div className="text-right">
            <NewInstitucionButton 
            tiposInstitucionesOptions={tiposInstitucionesOptions}
            regionesOptions={regionesOptions}
            />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <InstitucionList
            setInstitucionesFilters={setInstitucionesFilters}
            institucionesfilters={institucionesfilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default InstitucionesPage;