import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import {Carrera, CarreraRequestBody, CarrerasGetResponse} from '../../models/carreras/Carrera';
import CarreraFilters from '../../models/carreras/CarreraFilters';


export function getCarreras(filters: CarreraFilters): AxiosPromise<CarrerasGetResponse> {
  return axios.get('carreras');
}

export function getCarrera(carreraId: number): AxiosPromise<Carrera> {
  return axios.get(`carreras/${carreraId}`);
}

export function putCarrera(carreraId: number, requestBody: CarreraRequestBody): AxiosPromise<Carrera> {
  return axios.put(`carreras/${carreraId}`, requestBody);
}

export function postCarrera(requestBody: CarreraRequestBody): AxiosPromise<Carrera> {
  return axios.post('carreras', requestBody);
}

export function deleteCarrera(carreraId: number): AxiosPromise<void> {
  return axios.delete(`carreras/${carreraId}`);
}