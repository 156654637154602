import { BaseCurso } from '../models/cursos/Curso';
import { Evaluacion, EvaluacionForm, EvaluacionPostBody } from '../models/evaluaciones/Evaluacion';

export function getEmptyEvaluacionForm(curso: BaseCurso): EvaluacionForm {
  return {
    nombre: '',
    peso: '0',
    descripcion: '',
    curso,
  };
}

export function formatEvaluacionForm(evaluacion: Evaluacion): EvaluacionForm {
  const { nombre, peso, descripcion, curso } = evaluacion;

  return {
    nombre,
    peso: peso.toString(),
    descripcion,
    curso,
  };
}

export function formatEvaluacionPostBody(evaluacionForm: EvaluacionForm): EvaluacionPostBody {
  const { nombre, peso, descripcion, curso } = evaluacionForm;

  if (!curso) {
    throw new Error('Curso cannot be undefined.');
  }

  return {
    nombre,
    peso: Number(peso),
    descripcion,
    cursoId: curso.id,
  };
}
