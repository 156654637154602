import React, { Dispatch, ReactElement, ReactText, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SelectOption } from '../../models/form/Select';
import Select from '../form/select/Select';
import { Input } from '../form/input/Input';
import TipoInstitucion from '../../models/instituciones/TipoInstitucion';
import InstitucionesFilters from '../../models/instituciones/InstitucionesFilters';
import Region from '../../models/regiones/Region';

interface InstitucionesFiltersProps {
  tiposInstitucionesOptions: SelectOption<TipoInstitucion>[];
  institucionesFilters: InstitucionesFilters;
  regionesOptions: SelectOption<Region>[];
  onFiltersChange: Dispatch<SetStateAction<InstitucionesFilters>>;
}

const InstitucionesFiltersComponent = ({
  tiposInstitucionesOptions,
  institucionesFilters,
  regionesOptions,
  onFiltersChange,
}: InstitucionesFiltersProps): ReactElement => {

  return (
    <>
      <Row>
        <Col lg={4}>
          <Input
            value={institucionesFilters.nombreOrRbd}
            name="nombreOrRbd"
            onChange={(value) => onFiltersChange({ ...institucionesFilters, nombreOrRbd: value })}
            type="text"
            placeholder="Nombre de Institución"
            title="Nombre de Institución"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Tipo de Institución"
            options={tiposInstitucionesOptions}
            onSelect={(tipoInstitucion) => {
              if (tipoInstitucion)
                onFiltersChange({
                  ...institucionesFilters,
                  tipoId: (tipoInstitucion as SelectOption<TipoInstitucion>).value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...institucionesFilters,
                tipoId: undefined,
              });
            }}
            placeholder="Tipo de institución"
          />
        </Col>
      </Row>
    </>
  );
};

export default InstitucionesFiltersComponent;
