import React, { ReactElement } from 'react';
import { Card } from 'react-bootstrap';

const UnauthorizedPage = (): ReactElement => {
  return (
    <Card>
      <Card.Body className="text-center">No está autorizado para ver esta página</Card.Body>
    </Card>
  );
};

export default UnauthorizedPage;
