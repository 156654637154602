import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';

import FormButtons from '../form/form-buttons/FormButtons';
import { CarreraForm } from '../../models/carreras/Carrera';

interface CarreraFormComponentProps {
  formState: FormState<CarreraForm>;
  submitForm: (carreraForm: CarreraForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
}

const CarreraFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
}: CarreraFormComponentProps): ReactElement => {
  const {
    value: carreraForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !carreraForm.nombre;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(carreraForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);

  const cancelDelete = (): void => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={12}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={carreraForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !carreraForm.nombre}
              invalidText="Debe ingresar el nombre de la carrera"
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        <hr />
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> la carrera?
          </h6>
        )}
        <FormButtons
          submitButtonContent="Guardar Carrera"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar Carrera"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default CarreraFormComponent;
