import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card, Spinner } from 'react-bootstrap';
import useParalelo from '../../hooks/useParalelo';
import ParaleloInfo from '../../components/paralelos/ParaleloInfo';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import ParaleloSesiones from '../../components/paralelos/ParaleloSesiones';
import ParaleloEstudiantes from '../../components/paralelos/ParaleloEstudiantes';
import ParaleloNotas from '../../components/paralelos/ParaleloNotas';
import EditNotasButton from '../../components/paralelos/EditNotasButton';
import SesionesFilters from '../../models/sesiones/SesionesFilters';
import ParaleloSesionesLista from '../../components/paralelos/ParaleloSesionesLista';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import Rol from '../../models/usuarios/Rol';

const ParaleloPage = (): ReactElement => {
  const { paraleloId } = useParams();

  const { paralelo, setParalelo, isLoading, reloadParalelo } = useParalelo(paraleloId);

  const breadCrumb = useBreadcrumb();

  const [sesionesFilters, setSesionesFilters] = useState<SesionesFilters>({
    paraleloId,
    page: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    breadCrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Cursos',
      },
      {
        label: paralelo?.curso.nombre || 'Ver curso',
      },
      {
        label: `Paralelo ${paralelo?.correlativo !== undefined ? paralelo.correlativo : ''}`,
      },
    ]);
  }, [breadCrumb, paralelo]);

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!paralelo) {
    return <p className="text-center">El paralelo no existe</p>;
  }

  return (
    <>
    <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
      <Card>
        <Card.Body>
          <ParaleloInfo paralelo={paralelo} onParaleloChange={setParalelo} />
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <ParaleloSesiones paralelo={paralelo} />
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <ParaleloEstudiantes paralelo={paralelo} onAdd={reloadParalelo} />
        </Card.Body>
      </Card>
      </RolProtectedElement>
      <Card className="mt-3">
        <Card.Header className="d-flex">
          <h4 className="m-0">Notas</h4>
          <div className="mr-0 ml-auto">
          <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
            <EditNotasButton
              paraleloId={paralelo.id}
              notas={paralelo.notasEstudiantes}
              onUpdate={reloadParalelo}
            />
            </RolProtectedElement>
          </div>
        </Card.Header>
        <Card.Body>
          <ParaleloNotas paralelo={paralelo} />
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header className="d-flex">
          <h4 className="m-0">Sesiones</h4>
        </Card.Header>
        <Card.Body>
          <ParaleloSesionesLista
            sesionesFilters={sesionesFilters}
            setSesionesFilters={setSesionesFilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ParaleloPage;
