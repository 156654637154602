import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import { Card, Spinner } from 'react-bootstrap';
import { Estudiante } from '../../models/estudiantes/Estudiante';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { getEstudiante } from '../../api/estudiantes/estudiantesApi';
import EstudianteInfoCard from '../../components/estudiantes/EstudianteInfoCard';
import ApoderadosInfoList from '../../components/apoderados/ApoderadosInfoList';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import Rol from '../../models/usuarios/Rol';
import RolProtectedElement from '../../components/roles/RolProtectedElement';
import EntrevistaLista from '../../components/entrevistas/EntrevistaLista';
import EntrevistasFilters from '../../models/entrevistas/EntrevistasFilters';
import EstudianteEntrevistas from '../../components/entrevistas/EstudianteEntrevistas';

const EstudiantePage = (): ReactElement => {
  const [estudiante, setEstudiante] = useState<Estudiante>();
  const { estudianteId, perfil } = useParams();
  
  const breadcrumb = useBreadcrumb();

  const userCanEdit = useUserHasAnyRole([Rol.Administrador, Rol.Coordinador]);
  
  const [entrevistasFilters, setEntrevistasFilters] = useState<EntrevistasFilters>({
    estudianteId,
    page: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    getEstudiante(estudianteId)
      .then((response) => {
        setEstudiante(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del estudiante');
      });
  }, [estudianteId, setEstudiante]);

  useEffect(() => {
    if (estudiante) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Estudiantes',
        },
        {
          label: `${estudiante.nombreSocial || estudiante.nombre} ${estudiante.apellidos}`,
          active: true,
        },
      ]);
    }
  }, [estudiante, breadcrumb]);

  if (!estudiante)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleEstudianteChange = (changedEstudiante: Estudiante): void => {
    setEstudiante(changedEstudiante);
  }

  return (
    <>
      <EstudianteInfoCard estudiante={estudiante} perfil={perfil} onEstudianteChange={handleEstudianteChange} />
      <Card className="mt-3">
        <Card.Header>
          <h5 className="m-0">Apoderados</h5>
        </Card.Header>
        <Card.Body>
          {estudiante.apoderados ? (
            <ApoderadosInfoList apoderados={estudiante.apoderados} />
          ) : (
            <p className="center">No hay apoderados</p>
          )}
        </Card.Body>
      </Card>
      <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
      <Card className="mt-3">
        <Card.Body>
          <EstudianteEntrevistas estudiante={estudiante} />
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header className="d-flex">
          <h4 className="m-0">Entrevistas</h4>
        </Card.Header>
      <Card.Body>
          <EntrevistaLista
            entrevistasFilters={entrevistasFilters}
            setEntrevistasFilters={setEntrevistasFilters}
          />
        </Card.Body>
      </Card>
      </RolProtectedElement>
      <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
        <EntityDocumentsCard entityTypeEndpoint="estudiantes" entityId={estudiante.id} readonly={!userCanEdit} />
      </RolProtectedElement>
    </>
  );
};
export default EstudiantePage;
