import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import { Card, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { Sesion } from '../../models/sesiones/Sesion';
import { getSesion } from '../../api/sesiones/sesionesPageRequests';
import EntityDocumentsCard from '../../components/documents/EntityDocumentsCard';
import SesionInfoCard from '../../components/sesiones/SesionInfoCard';
import AsistenciaFilters from '../../models/sesiones/AsistenciaFilters';
import AsistenciaList from '../../components/sesiones/AsistenciasList';

const SesionesPage = (): ReactElement => {
  const [sesion, setSesion] = useState<Sesion>();
  const { sesionId } = useParams();
  const breadcrumb = useBreadcrumb();

  const [asistenciaFilters, setAsistenciaFilters] = useState<AsistenciaFilters>({
    id: sesionId,
    page: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    getSesion(sesionId)
      .then((response) => {
        setSesion(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de la sesión');
      });
  }, [sesionId, setSesion]);

  useEffect(() => {
    if (sesion) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Cursos',
          href: '/cursos',
        },
        {
          label: sesion.curso.nombre,
          href: `/cursos/${sesion.curso.id}`,
        },
        {
          label: `Paralelo ${sesion.paralelo.correlativo}`,
          href: `/cursos/paralelos/${sesion.paraleloId}`,
        },
        {
          label: `Sesión: ${format(sesion.fecha, 'dd/MM/yyyy')}`,
        },
      ]);
    }
  }, [breadcrumb, sesion]);

  if (!sesion)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  const handleSesionChange = (changedSesion: Sesion): void => setSesion(changedSesion);

  return (
    <>
      <SesionInfoCard sesion={sesion} onSesionChange={handleSesionChange} />
      <EntityDocumentsCard
        entityTypeEndpoint="sesiones"
        entityId={sesion.id}
        docsUrl="documentos"
      />
      <AsistenciaList
        asistenciaFilters={asistenciaFilters}
        setAsistenciaFilters={setAsistenciaFilters}
        sesionId={sesionId}
      />
    </>
  );
};
export default SesionesPage;
