import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { formatTutorPostBody, getEmptyTutorForm } from '../../utils/tutores';
import { TutorForm } from '../../models/tutores/Tutor';
import { postTutor } from '../../api/tutores/tutoresApi';
import TutorFormComponent from './TutorFormComponent';
import { SelectOption } from '../../models/form/Select';
import Sede from '../../models/sedes/Sede';
import BasePrograma from '../../models/programas/Programa';

interface NewTutorButtonProps {
  programasOptions: SelectOption<BasePrograma>[];
  sedesOptions: SelectOption<Sede>[];
}

const NewTutorButton = ({ programasOptions, sedesOptions }: NewTutorButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyTutorForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (tutorForm: TutorForm): void => {
    postTutor(formatTutorPostBody(tutorForm))
      .then((createdTutor) => {
        history.push(`/tutores/${createdTutor.id}`);

        cogoToast.success('Tutor creado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar Tutor
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Tutor</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <TutorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewTutorButton;
