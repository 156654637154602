import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import TipoAsistencia from '../models/sesiones/TipoAsistencia';
import { getTipoAsistencia } from '../api/sesiones/sesionesPageRequests';

const useTipoAsistenciaOptions = (): SelectOption<TipoAsistencia>[] => {
  const [tipoAsistenciaOptions, setTipoAsistenciaOptions] = useState<
    SelectOption<TipoAsistencia>[]
  >([]);

  useEffect(() => {
    getTipoAsistencia()
      .then((response) => {
        setTipoAsistenciaOptions(
          response.data.map((tipo) => {
            return { label: tipo.descripcion, value: tipo };
          }),
        );
      })
      .catch(() => {});
  }, [setTipoAsistenciaOptions]);
  return tipoAsistenciaOptions;
};
export default useTipoAsistenciaOptions;
