import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { Button, Col, Modal } from 'react-bootstrap';
import { Asistencia, AsistenciaForm } from '../../models/sesiones/Sesion';
import useTipoAsistenciaOptions from '../../hooks/useTipoAsistenciaOptions';
import { formatAsistenciaForm, formatAsistenciasPutBodies } from '../../utils/sesion';
import useFormState from '../../hooks/useFormState';
import { updateAsistencia } from '../../api/sesiones/sesionesPageRequests';
import EditarAsistencia from './EditarAsistencia';

interface EditarAsistenciaAuxProps {
  asistencia: Asistencia[];
  onUpdate: () => void;
}

const EditarAsistenciaAux = ({ asistencia, onUpdate }: EditarAsistenciaAuxProps): ReactElement => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const tipoAsistenciaOptions = useTipoAsistenciaOptions();

  const asists = asistencia.map((asist) => {
    return formatAsistenciaForm(asist, tipoAsistenciaOptions);
  });

  const formState = useFormState(asists);

  useEffect(() => {
    const asistsUpdated = asistencia.map((asist) => {
      return formatAsistenciaForm(asist, tipoAsistenciaOptions);
    });
    formState.setForm(asistsUpdated);
  }, [asistencia, formState.setForm, tipoAsistenciaOptions]);

  const submitForm = (asistenciasForm: AsistenciaForm[]) => {
    updateAsistencia(formatAsistenciasPutBodies(asistenciasForm))
      .then(() => {
        formState.setSubmitting(false);
        setShow(false);
        cogoToast.success('Asistencia actualizada');
        onUpdate();
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button className="m-auto float-right" size="sm" variant="warning" onClick={handleShow}>
        Editar asistencia
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Asistencia</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <EditarAsistencia
            formState={formState}
            submitForm={submitForm}
            onCancel={handleClose}
            tipoAsistenciasOptions={tipoAsistenciaOptions}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditarAsistenciaAux;
