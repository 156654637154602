import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, Spinner } from 'react-bootstrap';
import useBreadcrumb from '../../../hooks/useBreadcrumb';
import useEvaluacion from '../../../hooks/useEvaluacion';
import EvaluacionInfo from '../../../components/evaluaciones/EvaluacionInfo';

const EvaluacionPage = (): ReactElement => {
  const { evaluacionId, cursoId } = useParams();

  const { evaluacion, setEvaluacion, isLoading } = useEvaluacion(evaluacionId);

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Cursos',
        href: '/cursos',
      },
      {
        label: evaluacion?.curso.nombre || '',
        href: `/cursos/${evaluacion?.curso.id !== undefined ? evaluacion.curso.id : ''}`,
      },
      {
        label: 'Evaluaciones',
      },
      {
        label: evaluacion?.nombre || '',
        active: true,
      },
    ]);
  }, [breadcrumb, evaluacion]);

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!evaluacion) {
    return <p className="text-center">La evaluación no existe</p>;
  }

  return (
    <Card>
      <Card.Body>
        <EvaluacionInfo evaluacion={evaluacion} onEvaluacionChange={setEvaluacion} />
      </Card.Body>
    </Card>
  );
};

export default EvaluacionPage;
