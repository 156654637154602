import Persona, { ApiPersona } from "../models/personas/Persona"
import { getRutVerifier } from "./rut"

export const transformApiBasePersonaIntoBasePersona = (persona: ApiPersona): Persona => {
  if (persona.nombre === 'Adeline') {
  }

  return {
    ...persona,
    rut: `${persona.rut}${getRutVerifier(persona.rut)}`,
  }
}

export const transformApiPersonaIntoPersona = transformApiBasePersonaIntoBasePersona;