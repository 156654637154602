import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { Input } from '../../components/form/input/Input';
import { resetPassword } from '../../api/usuarios/usuariosApi';
import ucnLogo from '../../assets/images/ucn.png';
import useFormState from '../../hooks/useFormState';

const ResetPasswordPage = (): ReactElement => {
  const {
    value,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
  } = useFormState({
    password: '',
    passwordVerification: '',
  });

  const { password, passwordVerification } = value;

  const { passwordResetToken } = useParams();

  const history = useHistory();

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (!password || !passwordVerification || password !== passwordVerification) return;

    setSubmitting(true);

    resetPassword(passwordResetToken, password)
      .then(() => {
        cogoToast.info('El cambio de contraseña fue exitoso')
        history.replace('/login');
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="login-container">
      <div className="background" />
      <div className="head d-flex">
        <div className="text-center m-auto d-flex">
          <h4 className="text-center my-auto mr-2 text-dark">ProVETA</h4>
          <img className="mx-auto" width={50} height={50} src={ucnLogo} alt="Logo UCN" />
        </div>
      </div>
      <Card className="m-auto">
        <Card.Body>
          <h4 className="text-center mb-4 text-white">Reiniciar contraseña</h4>
          <hr />
          <Form>
            <Input
              title="Ingrese su nueva contraseña"
              name="password"
              value={password}
              type="password"
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !password}
              invalidText="Debe ingresar su nueva contraseña"
              invalidTextColor="#9a0024"
              disabled={isSubmitting}
            />
            <Input
              title="Reescriba su nueva contraseña"
              name="passwordVerification"
              value={passwordVerification}
              type="password"
              onChangeWithName={handleFieldChange}
              invalid={
                areValidationsEnabled &&
                (!passwordVerification || password !== passwordVerification)
              }
              invalidText={
                passwordVerification
                  ? 'Las contraseñas no coinciden'
                  : 'Debe ingresar su nueva contraseña'
              }
              invalidTextColor="#9a0024"
              disabled={isSubmitting}
            />
            <Button
              type="submit"
              onClick={handleSubmitClick}
              className="w-100 mt-4"
              variant="outline-light"
              disabled={isSubmitting}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Reiniciar contraseña'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResetPasswordPage;
