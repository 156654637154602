import { createContext } from 'react';
import { AuthContextValue } from '../models/usuarios/AuthContext';

const AuthContext = createContext<AuthContextValue>({
  hasJwt: false,
  userName: '',
  userRoles: [],
  email: '',
  id: '',
  setJWT: () => {},
  logout: () => {},
});

export default AuthContext;
