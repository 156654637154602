import React, { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ExtendedParalelo } from '../../models/paralelo/Paralelo';
import Rol from '../../models/usuarios/Rol';
import LabelValue from '../LabelValue';
import RolProtectedElement from '../roles/RolProtectedElement';
import EditParaleloButton from './EditParaleloButton';

interface ParaleloInfoProps {
  paralelo: ExtendedParalelo;
  onParaleloChange: (editedParalelo: ExtendedParalelo) => void;
}

const ParaleloInfo = ({ paralelo, onParaleloChange }: ParaleloInfoProps): ReactElement => {
  const { correlativo, curso, profesor } = paralelo;

  return (
    <>
      <div className="d-flex">
        <h3>{`${curso.nombre} - Paralelo ${correlativo}`}</h3>
        <RolProtectedElement roles={[Rol.Administrador, Rol.Coordinador]}>
          <EditParaleloButton paralelo={paralelo} onParaleloChange={onParaleloChange} />
        </RolProtectedElement>
      </div>
      <hr className="mt-2" />
      <Row>
        <Col xs={6} lg={3}>
          <LabelValue label="Profesor" value={`${profesor.nombre} ${profesor.apellidos}`} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Periodo académico" value={`${curso.year}`} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Programa" value={curso.programa.nombre} />
        </Col>
        <Col xs={6} lg={3}>
          <LabelValue label="Sede" value={curso.sede.nombre} />
        </Col>
      </Row>
    </>
  );
};

export default ParaleloInfo;
