import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Input } from '../form/input/Input';
import { FormState } from '../../hooks/useFormState';
import Select from '../form/select/Select';
import RutInput from '../form/input/RutInput';
import { SelectOption } from '../../models/form/Select';
import { ProfesorForm } from '../../models/profesores/Profesor';
import FormButtons from '../form/form-buttons/FormButtons';
import Sede from '../../models/sedes/Sede';
import BasePrograma from '../../models/programas/Programa';
import { validateRut } from '../../utils/rut';
import { validateEmail } from '../../utils/email';

interface ProfesorFormComponentProps {
  formState: FormState<ProfesorForm>;
  submitForm: (profesorForm: ProfesorForm) => void;
  onDelete?: () => void;
  onDeactivation?: () => void;
  onCancel: () => void;
  sedesOptions: SelectOption<Sede>[];
  programasOptions: SelectOption<BasePrograma>[];
}

const ProfesorFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  onDeactivation,
  sedesOptions,
  programasOptions,
}: ProfesorFormComponentProps): ReactElement => {
  const {
    value: profesorForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
    setConfirmingDeactivation,
    isConfirmingDeactivation, 
  } = formState;

  const isFormInvalid =
    !profesorForm.nombre ||
    !validateEmail(profesorForm.email) ||
    !validateRut(profesorForm.rut) ||
    !profesorForm.sede ||
    !profesorForm.programa;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault();

    setAreValidationsEnabled(true);

    if (isFormInvalid) return;

    setSubmitting(true);

    setAreValidationsEnabled(false);

    submitForm(profesorForm);
  };

  const handleDeleteClick = (): void => setConfirmingDelete(true);
  const handleDeactivationClick = () => setConfirmingDeactivation(true);
  const cancelDelete = () => setConfirmingDelete(false);
  const cancelDeactivation = () => setConfirmingDeactivation(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={profesorForm.nombre}
              onChangeWithName={handleFieldChange}
              invalid={areValidationsEnabled && !profesorForm.nombre}
              invalidText="Debe ingresar el nombre del profesor"
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={profesorForm.apellidos}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <RutInput
              title="Rut"
              name="rut"
              placeholder="Rut"
              value={profesorForm.rut}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !validateRut(profesorForm.rut)}
              invalidText="Rut invalido"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Email"
              name="email"
              placeholder="Email"
              value={profesorForm.email}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !validateEmail(profesorForm.email)}
              invalidText="Email invalido"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Dirección"
              name="direccion"
              placeholder="Dirección"
              value={profesorForm.direccion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={profesorForm.telefono}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Horas asignadas"
              name="horasAsignadas"
              placeholder="Horas asignadas"
              value={profesorForm.horasAsignadas}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Programa"
              name="programa"
              placeholder="Seleccione un programa"
              value={profesorForm.programa}
              options={programasOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalidText="Debe seleccionar el programa"
              invalid={areValidationsEnabled && !profesorForm.programa}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Sede"
              name="sede"
              placeholder="Seleccione una sede"
              value={profesorForm.sede}
              options={sedesOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalidText="Debe seleccionar la sede"
              invalid={areValidationsEnabled && !profesorForm.sede}
            />
          </Col>
        </Row>
        {isFormInvalid && areValidationsEnabled && (
          <div className="text-danger text-center mb-3">
            <small>Hay campos obligatorios sin ingresar</small>
          </div>
        )}
        {isConfirmingDelete && (
          <h6 className="text-center">
            ¿Está segura/o de que desea{' '}
            <span className="text-danger font-weight-bold">eliminar</span> el profesor?
          </h6>
        )}
        {isConfirmingDeactivation && (
          <h6 className='text-center'>
            ¿Está segura/o de que desea <span className='text-danger font-weight-bold'>{profesorForm.isActive? 'Desactivar': 'Activar'}</span>{' '}
            el profesor?
          </h6>
        )}
        <FormButtons
          deactivateButtonContent={profesorForm.isActive? 'Desactivar': 'Activar'}
          onCancelDeactivation={cancelDeactivation}
          onDeactivateClick={onDeactivation ? handleDeactivationClick : undefined }
          onDeactivateConfirm={onDeactivation}
          isConfirmingDeactivation={isConfirmingDeactivation}
          showDeactivationButton
          submitButtonContent="Guardar profesor"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar profesor"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default ProfesorFormComponent;
