import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import CarreraList from '../../components/carreras/CarrerasList';
import NewCarreraButton from '../../components/carreras/NewCarreraButton';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import CarrerasFilters from '../../models/carreras/CarreraFilters';


const CarrerasPage = (): ReactElement => {
  const [carrerasfilters, setCarrerasFilters] = useState<CarrerasFilters>({
    page: 1,
    itemsPerPage: 8,
  });

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Carreras',
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h4 className="m-0">Listado de Carreras</h4>
          <div className="text-right"  style={{ margin: '0 0 auto auto' }} >
            <NewCarreraButton />
          </div>
        </Card.Header>
        <Card.Body>
          <CarreraList
            setCarrerasFilters={setCarrerasFilters}
            carrerasfilters={carrerasfilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default CarrerasPage;
