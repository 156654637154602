import cogoToast from 'cogo-toast';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { getBitacoraTutor, getTutor } from '../../api/tutores/tutoresApi';
import RegistroBitacoraCard from '../../components/bitacoras/RegistroBitacoraCard';
import PaginationComponent from '../../components/pagination/PaginationComponent';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { RegistroBitacora } from '../../models/bitacoras/Bitacora';
import { Tutor } from '../../models/tutores/Tutor';


const BitacoraTutorPage = (): ReactElement => {
  const [tutor, setTutor] = useState<Tutor>();

  const [pages, setPages] = useState(1);

  const [items, setItems] = useState<RegistroBitacora[]>([]);

  const itemsPerPage = 5;

  const { tutorId } = useParams<{ tutorId: string }>();

  const breadcrumb = useBreadcrumb();

  const history = useHistory();

  const { search } = useLocation();

  const { page } = queryString.parse(search);

  useEffect(() => {
    getTutor(tutorId)
      .then((responseTutor): void => {
        setTutor(responseTutor);
      })
      .catch((error) => {
        cogoToast.error(error.message);

   });
  }, [tutorId, setTutor]);

 
  
  useEffect(() => {
    if (tutor) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Tutores',
          href: '/tutores',
        },
        {
          label: `${tutor?.nombre} ${tutor?.apellidos}`,
          href: `/tutores/${tutorId}`,
        },
        {
          label: 'Ver Bitácora',
          active: true,
        }
      ]);
    }
  }, [tutor, breadcrumb]);

  useEffect(() => {
    getBitacoraTutor(Number(tutorId), { page: Number(page || 1), itemsPerPage })
        .then((bitacoraList): void => {
          setPages(bitacoraList.pages);
          setItems(bitacoraList.items);
        })
        .catch((error) => {
          cogoToast.error(error.message);
        });
  } ,[page, itemsPerPage, setPages]);

  const goToPage = (page: number): void => {
    history.push(`/tutores/${tutorId}/bitacora?page=${page}`);
  };

  return (
    <>
      <Card className="text-right mb-3">
        <Card.Body>
          <Row>
            <Col className="text-left">
              <h4>{`Bitácora de ${tutor?.nombre || ''}`}</h4>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {items.map(
        (item): ReactElement => (
          <Card className="mb-3">
            <RegistroBitacoraCard bitacora={item} readonly />
          </Card>
        ),
      )}
      <PaginationComponent pageCount={pages} onChange={goToPage} />
    </>
  )
};

export default BitacoraTutorPage;