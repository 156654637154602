import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import { SesionForm } from '../../models/sesiones/Sesion';
import { FormState } from '../../hooks/useFormState';
import { SelectOption } from '../../models/form/Select';
import { BaseTutor } from '../../models/tutores/Tutor';
import { Input } from '../form/input/Input';
import Select from '../form/select/Select';
import FormButtons from '../form/form-buttons/FormButtons';
import { ExtendedParalelo } from '../../models/paralelo/Paralelo';

interface SesionFormComponentProps {
  formState: FormState<SesionForm>;
  submitForm: (sesionForm: SesionForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  tutoresOptions: SelectOption<BaseTutor>[];
  paralelo: ExtendedParalelo;
}
const SesionFormComponent = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
  tutoresOptions,
  paralelo,
}: SesionFormComponentProps): ReactElement => {
  const {
    value: sesionForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !sesionForm.fecha || !sesionForm.tutor || !sesionForm.tema;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    sesionForm.paralelo = paralelo;
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(sesionForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const cancelDelete = () => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Input
              title="Fecha"
              name="fecha"
              placeholder="Fecha"
              value={sesionForm.fecha}
              onChangeWithName={handleFieldChange}
              type="date"
              disabled={isSubmitting}
              invalidText="Debe ingresar la fecha"
              invalid={areValidationsEnabled && !sesionForm.fecha}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Tema"
              name="tema"
              placeholder="Tema"
              value={sesionForm.tema}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              invalidText="Debe ingresar el tema"
              invalid={areValidationsEnabled && !sesionForm.tema}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Descripción"
              name="descripcion"
              placeholder="Descripción"
              value={sesionForm.descripcion}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              title="Observaciones"
              name="observaciones"
              placeholder="observaciones"
              value={sesionForm.observaciones}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              title="Tutor"
              name="tutor"
              placeholder="Tutor"
              value={sesionForm.tutor}
              options={tutoresOptions}
              onSelectWithName={handleFieldChange}
              disabled={isSubmitting}
              invalid={areValidationsEnabled && !sesionForm.tutor}
              invalidText="Debe seleccionar un tutor"
            />
          </Col>
        </Row>
        <FormButtons
          submitButtonContent="Guardar sesión"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCanceleDelete={cancelDelete}
          deleteButtonContent="Eliminar sesión"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default SesionFormComponent;
