import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import PresupuestosFilters from '../../models/presupuestos/PresupuestosFilters';
import NewPresupuestoButton from '../../components/presupuestos/NewPresupuestoButton';
import useRegionesOptions from '../../hooks/useRegionesOptions';
import useSedesOptions from '../../hooks/useSedesOptions';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import PresupuestoList from '../../components/presupuestos/PresupuestosList';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';

const PresupuestosPage = (): ReactElement => {
  const [presupuestosfilters, setPresupuestosFilters] = useState<PresupuestosFilters>({
    page: 1,
    itemsPerPage: 8,
  });

  const regionesOptions = useRegionesOptions();
  const sedesOptions = useSedesOptions();
  const programasOptions = useProgramasOptions();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Presupuestos',
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
        <div className="d-flex">
            <div>
              <h4 className="float-left">
                <b>Listado de Presupuestos</b>
              </h4>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <PresupuestoList
            setPresupuestosFilters={setPresupuestosFilters}
            presupuestosfilters={presupuestosfilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default PresupuestosPage;