import {
  ApiBaseCoordinador,
  BaseCoordinador,
  Coordinador,
  CoordinadorForm,
  CoordinadorRequestBody,
} from '../models/coordinadores/Coordinador';
import { SelectOption } from '../models/form/Select';
import Sede from '../models/sedes/Sede';
import { transformApiBasePersonaIntoBasePersona } from './personas';

export const transformApiCoordinadorIntoCoordinador = (coordinador: ApiBaseCoordinador): Coordinador => {
  return {
    ...coordinador,
    ...transformApiBasePersonaIntoBasePersona(coordinador),
  }
}

export const getEmptyCoordinadorForm = (): CoordinadorForm => ({
  nombre: '',
  apellidos: '',
  rut: '',
  email: '',
  telefono: '',
  direccion: '',
  sede: undefined,
});

export const formatCoordinadorPostBody = (
  coordinadorForm: CoordinadorForm,
): CoordinadorRequestBody => {
  const {
    nombre,
    apellidos,
    rut,
    email,
    telefono,
    direccion,
    sede,
  } = coordinadorForm;

  return {
    nombre,
    apellidos,
    rut: Number(rut.slice(0, -1)),
    email,
    telefono,
    direccion,
    sedeId: sede?.value.id,
  };
};

export const formatCoordinadorForm = (
  coordinador: Coordinador,
  sedeOptions: SelectOption<Sede>[],
): CoordinadorForm => {
  const {
    nombre,
    apellidos = '',
    rut = '',
    email = '',
    telefono = '',
    direccion = '',
  } = coordinador;

  const sedeSelectOption = sedeOptions.find((option) => option.value.id === coordinador.sede.id);

  return {
    nombre,
    apellidos,
    rut: rut ? rut.toString() : '',
    email,
    telefono,
    direccion,
    sede: sedeSelectOption,
  };
};
