import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getEstablecimientos } from '../api/estudiantes/estudiantesApi';
import { BaseEstablecimiento } from '../models/establecimientos/Establecimiento';

const useEstablecimientosOptions = (): SelectOption<BaseEstablecimiento>[] => {
  const [establecimientosOptions, setEstablecimientosOptions] = useState<
    SelectOption<BaseEstablecimiento>[]
  >([]);

  useEffect(() => {
    getEstablecimientos()
      .then((response) => {
        setEstablecimientosOptions(
          response.data.establecimientos.map((establecimiento) => {
            return { label: establecimiento.nombre, value: establecimiento };
          }),
        );
      })
      .catch(() => {});
  }, [setEstablecimientosOptions]);
  return establecimientosOptions;
};
export default useEstablecimientosOptions;
