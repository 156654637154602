import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { Spinner } from 'react-bootstrap';
import EstudiantesFilters from '../../models/estudiantes/EstudiantesFilters';
import { BaseEstudiante } from '../../models/estudiantes/Estudiante';
import { getEstudiantes } from '../../api/estudiantes/estudiantesApi';
import Table, { TableColumn } from '../table/Table';
import { formatRut } from '../../utils/rut';

interface EstudiantesListProps {
  estudiantesFilters: EstudiantesFilters;
  setEstudiantesFilters: Dispatch<SetStateAction<EstudiantesFilters>>;
}

const EstudiantesList = ({
  estudiantesFilters,
  setEstudiantesFilters,
}: EstudiantesListProps): ReactElement => {
  const [estudiantes, setEstudiantes] = useState<BaseEstudiante[]>();
  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getEstudiantes(estudiantesFilters)
      .then((response) => {
        setEstudiantes(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de los estudiantes.');
      });
  }, [estudiantesFilters, setEstudiantes]);

  // eslint-disable-next-line no-shadow
  const handleEstudiantesClick = (estudiantes: BaseEstudiante) => {
    history.push(`/estudiantes/${estudiantes.id}`);
  };

  const tableColumns: TableColumn<BaseEstudiante>[] = [
    {
      title: 'Nombre',
      accessor: (estudiante) => `${estudiante.nombre} ${estudiante.apellidos}`,
    },
    {
      title: 'Rut',
      accessor: (estudiante) => (estudiante.rut ? formatRut(estudiante.rut.toString()) : ''),
      hideUntil: 'lg',
    },
    {
      title: 'Establecimiento',
      accessor: (estudiante) => {return estudiante.establecimiento?.nombre},
      hideUntil: 'md',
    },
    {
      title: 'Sede',
      accessor: (estudiante) => {return estudiante.sede.nombre},
      hideUntil: 'md',
    },
    {
      title: 'IVE',
      accessor: (estudiante) => {return estudiante.ive || '-'},
      hideUntil: 'md',
    },
  ];

  if (estudiantesFilters.includeInactive) {
    tableColumns.push({
      title: 'Estado',
      accessor: (estudiante) => {return estudiante.isActive? 'Activo': 'Inactivo'},
      hideUntil: 'md',
    })
  }

  if (!estudiantes)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <Table
        items={estudiantes}
        columns={tableColumns}
        hover
        onItemClick={handleEstudiantesClick}
        minRowsCount={estudiantesFilters.itemsPerPage}
        pages={pages}
        activePage={estudiantesFilters.page}
        onPageSelect={(page) => setEstudiantesFilters({ ...estudiantesFilters, page })}
      />
    </>
  );
};
export default EstudiantesList;
