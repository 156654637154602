import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import InstitucionInfoCard from '../../components/instituciones/InstitucionInfoCard';
import { getInstitucion } from '../../api/instituciones/institucionesApi';
import { Institucion } from '../../models/instituciones/Institucion';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import EgresadosFilters from '../../models/egresados/EgresadosFilters';
import EgresadosList from '../../components/egresados/EgresadosList';

const InstitucionPage = (): ReactElement => {
  const [institucion, setInstitucion] = useState<Institucion>();

  const [isLoading, setLoading] = useState(true);

  const { institucionId } = useParams();

  const breadcrumb = useBreadcrumb();

  const [egresadosFilters, setEgresadosFilters] = useState<EgresadosFilters>({
    nombreORut: '',
    institucionId: institucionId,
    page: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    getInstitucion(institucionId)
      .then((response): void => {
        setInstitucion(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del institucion');
      })
      .then(() => setLoading(false));
  }, [institucionId, setInstitucion]);

  useEffect(() => {
    if (institucion) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Instituciones',
          href: '/instituciones',
        },
        {
          label: institucion.nombre,
          active: true,
        },
      ]);
    }
  }, [institucion, breadcrumb]);

  if (isLoading)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  if (!institucion)
    return (
      <Card>
        <Card.Body className="text-center">
          {isLoading ? (
            <Spinner animation="border" />
          ) : (
            <p className="m-0">La institución no existe</p>
          )}
        </Card.Body>
      </Card>
    );

  const handleInstitucionChange = (changedInstitucion: Institucion): void =>
    setInstitucion(changedInstitucion);

  return (
    <>
      <InstitucionInfoCard
        institucion={institucion}
        onInstitucionChange={handleInstitucionChange}
      />
      <Card className="mt-3">
        <Card.Header>
          Lista de Egresados
        </Card.Header>
        <Card.Body>
          <EgresadosList
            setEgresadosFilters={setEgresadosFilters}
            egresadosFilters={egresadosFilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default InstitucionPage;
