import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import BasePrograma from '../../models/programas/Programa';

export function getProgramas(): AxiosPromise<BasePrograma[]> {
  return axios.get('programas');
}

export async function getProgramasReporte(): Promise<BasePrograma[]> {
  const axiosResponse = await axios.get('programas');
  return axiosResponse.data;
}
