import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getInstituciones } from '../api/egresados/egresadosApi';
import { BaseInstitucion } from '../models/instituciones/Institucion';

const useInstitucionesOptions = (): SelectOption<BaseInstitucion>[] => {
  const [institucionesOptions, setInstitucionesOptions] = useState<
    SelectOption<BaseInstitucion>[]
  >([]);

  useEffect(() => {
    getInstituciones()
      .then((response) => {
        setInstitucionesOptions(
          response.data.items.map((institucion) => {
            return { label: institucion.nombre, value: institucion };
          }),
        );
      })
      .catch(() => {});
  }, [setInstitucionesOptions]);
  return institucionesOptions;
};
export default useInstitucionesOptions;
