import {
  Administrador,
  AdministradorForm,
  AdministradorRequestBody,
  ApiBaseAdministrador,
  BaseAdministrador,
} from '../models/administradores/Administrador';
import { SelectOption } from '../models/form/Select';
import Sede from '../models/sedes/Sede';
import { transformApiBasePersonaIntoBasePersona } from './personas';

export const transformApiAdministradorIntoAdministrador = (administrador: ApiBaseAdministrador): Administrador => {
  return {
    ...administrador,
    ...transformApiBasePersonaIntoBasePersona(administrador),
  }
}

export const getEmptyAdministradorForm = (): AdministradorForm => ({
  nombre: '',
  apellidos: '',
  rut: '',
  email: '',
  telefono: '',
  direccion: '',
  sede: undefined,
});

export const formatAdministradorPostBody = (
  administradorForm: AdministradorForm,
): AdministradorRequestBody => {
  const { nombre, apellidos, rut, email, telefono, direccion, sede } = administradorForm;

  return {
    nombre,
    apellidos,
    rut: Number(rut.slice(0, -1)),
    email,
    telefono,
    direccion,
    sedeId: sede?.value.id,
  };
};

export const formatAdministradorForm = (
  administrador: Administrador,
  sedeOptions: SelectOption<Sede>[],
): AdministradorForm => {
  const {
    nombre,
    apellidos = '',
    rut = '',
    email = '',
    telefono = '',
    direccion = '',
  } = administrador;

  const sedeSelectOption = sedeOptions.find((option) => option.value.id === administrador.sede.id);

  return {
    nombre,
    apellidos,
    rut: rut ? rut.toString() : '',
    email,
    telefono,
    direccion,
    sede: sedeSelectOption,
  };
};
