import axios from '../axiosWithAuth';
import { base64, DocumentWithData } from '../../models/files/DocumentFile';

export async function getDocumento(documentoId: number): Promise<DocumentWithData> {
  const response = await axios.get<Blob>(
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_DOCUMENTS_ENDPOINT}/${documentoId}`,
    {responseType: 'blob'}
  )

  return {
    mime: response.headers['content-type'],
    name: response.headers['filename'],
    file: response.data,
  }
}