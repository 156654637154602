import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Col, Modal, ModalBody, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import {
  Sesion,
  SesionBitacoras,
  SesionBitacorasForm,
  SesionForm,
} from '../../models/sesiones/Sesion';
import LabelValue from '../LabelValue';
import useTutoresOptions from '../../hooks/useTutoresOptions';
import {
  formatSesionBitacoraForm,
  formatSesionBitacoraPutBody,
  formatSesionForm,
  formatSesionPostBody,
} from '../../utils/sesion';
import { deleteSesion, putSesion } from '../../api/sesiones/sesionesPageRequests';
import EditarSesionForm from './EditarSesionForm';
import EditarBitacoraProfesorForm from './EditarBitacoraProfesorForm';
import EditarBitacoraTutorForm from './EditarBitacoraTutorForm';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import Rol from '../../models/usuarios/Rol';

interface SesionInfoCardProps {
  sesion: Sesion;
  onSesionChange: (editedSesion: Sesion) => void;
}

const SesionInfoCard = ({ sesion, onSesionChange }: SesionInfoCardProps): ReactElement => {
  const {
    id,
    fecha,
    tema,
    descripcion,
    observaciones,
    paraleloId,
    bitacoraProfesor,
    bitacoraTutor,
    profesorNombre,
    profesorApellidos,
    tutorNombre,
    tutorApellidos,
  } = sesion;

  const sesionBitacoras: SesionBitacoras = {
    id,
    bitacoraTutor,
    bitacoraProfesor,
  };

  sesionBitacoras.id = sesion.id;
  sesionBitacoras.bitacoraProfesor = sesion.bitacoraProfesor;
  sesionBitacoras.bitacoraTutor = sesion.bitacoraTutor;

  const tutoresSelectOptions = useTutoresOptions(sesion.curso.programa.id);

  const formState = useFormState(formatSesionForm(sesion, tutoresSelectOptions));
  const formStateBitacora = useFormState(formatSesionBitacoraForm(sesionBitacoras));

  const userIsTutor = useUserHasAnyRole([Rol.Tutor]);

  const userIsProfesor = useUserHasAnyRole([Rol.Profesor]);

  useEffect(() => {
    formState.setForm(formatSesionForm(sesion, tutoresSelectOptions));
  }, [sesion, tutoresSelectOptions]);

  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showBitacoraProfesor, setShowBitacoraProfesor] = useState(false);
  const handleCloseBitacoraProfesor = () => setShowBitacoraProfesor(false);
  const handleShowBitacoraProfesor = () => setShowBitacoraProfesor(true);

  const [showBitacoraTutor, setShowBitacoraTutor] = useState(false);
  const handleCloseBitacoraTutor = () => setShowBitacoraTutor(false);
  const handleShowBitacoraTutor = () => setShowBitacoraTutor(true);

  const tutoresOptions = useTutoresOptions(sesion.curso.programa.id);

  const deleteCurrentSesion = (): void => {
    formState.setSubmitting(true);
    deleteSesion(id)
      .then(() => {
        cogoToast.success('Sesion eliminada');
        history.replace(`paralelos/${paraleloId}`);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  let profesorNombreCompleto;
  let tutorNombreCompleto;

  if (profesorNombre) {
    profesorNombreCompleto = profesorNombre;
    if (profesorApellidos) {
      profesorNombreCompleto += ' ';
      profesorNombreCompleto += profesorApellidos;
    }
  }

  if (tutorNombre) {
    tutorNombreCompleto = tutorNombre;
    if (tutorApellidos) {
      tutorNombreCompleto += ' ';
      tutorNombreCompleto += tutorApellidos;
    }
  }

  const submitForm = (sesionForm: SesionForm) => {
    putSesion(id, formatSesionPostBody(sesionForm))
      .then((response) => {
        formState.setSubmitting(false);
        handleClose();
        onSesionChange(response.data);
        cogoToast.success('Sesión Actualizada!');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  const submitFormBitacora = (sesionBitacorasForm: SesionBitacorasForm) => {
    putSesion(id, formatSesionBitacoraPutBody(sesionBitacorasForm))
      .then((response) => {
        formStateBitacora.setSubmitting(false);
        setShowBitacoraProfesor(false);
        setShowBitacoraTutor(false);
        onSesionChange(response.data);
        cogoToast.success('Sesión Actualizada!');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex">
          <h3 className="m-0">{`Sesion del ${format(sesion.fecha, 'dd/MM/yyyy')}: ${sesion.tema}`}</h3>
          <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={handleShow}>
            Editar
          </Button>
          <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <Col className="text-sm-center">
                <Modal.Title>Editar Sesión</Modal.Title>
              </Col>
            </Modal.Header>
            <ModalBody>
              <EditarSesionForm
                formState={formState}
                submitForm={submitForm}
                onCancel={handleClose}
                tutoresOptions={tutoresOptions}
                onDelete={deleteCurrentSesion}
              />
            </ModalBody>
          </Modal>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} lg={6}>
              <LabelValue label="Tema" value={tema || '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Fecha" value={fecha ? format(fecha, 'dd/MM/yyyy') : '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Profesor" value={profesorNombreCompleto || '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Tutor" value={tutorNombreCompleto || '-'} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue label="Descripción" value={descripcion || '-'} />
            </Col>
            <Col xs={12}>
              <LabelValue label="Observaciones" value={observaciones || '-'} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header className="d-flex">
          <h5 className="m-0">Bitácora del profesor</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <LabelValue label="" value={bitacoraProfesor || '-'} />
            </Col>
          </Row>
          { userIsProfesor && (
            <Button className="mt-2 mt-0" variant="success" onClick={handleShowBitacoraProfesor}>
              Editar bitácora
            </Button>
          )}
          <Modal show={showBitacoraProfesor} onHide={handleCloseBitacoraProfesor} size="xl">
            <Modal.Header closeButton>
              <Col className="text-sm-center">
                <Modal.Title>Editar bitácora del profesor</Modal.Title>
              </Col>
            </Modal.Header>
            <ModalBody>
              <EditarBitacoraProfesorForm
                formState={formStateBitacora}
                submitForm={submitFormBitacora}
                onCancel={handleCloseBitacoraProfesor}
              />
            </ModalBody>
          </Modal>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header className="d-flex">
          <h5 className="m-0">Bitácora del tutor</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <LabelValue label="" value={bitacoraTutor || '-'} />
              {userIsTutor && (
                <Button className="mt-2 mt-0" variant="success" onClick={handleShowBitacoraTutor}>
                  Editar bitácora
                </Button>
              )}
              <Modal show={showBitacoraTutor} onHide={handleCloseBitacoraTutor} size="xl">
                <Modal.Header closeButton>
                  <Col className="text-sm-center">
                    <Modal.Title>Editar bitácora tutor</Modal.Title>
                  </Col>
                </Modal.Header>
                <ModalBody>
                  <EditarBitacoraTutorForm
                    formState={formStateBitacora}
                    submitForm={submitFormBitacora}
                    onCancel={handleClose}
                  />
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default SesionInfoCard;
