import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { formatProfesorPostBody, getEmptyProfesorForm } from '../../utils/profesores';
import { ProfesorForm } from '../../models/profesores/Profesor';
import { postProfesor } from '../../api/profesores/profesoresApi';
import ProfesorFormComponent from './ProfesorFormComponent';
import { SelectOption } from '../../models/form/Select';
import Sede from '../../models/sedes/Sede';
import BasePrograma from '../../models/programas/Programa';

interface NewProfesorButtonProps {
  programasOptions: SelectOption<BasePrograma>[];
  sedesOptions: SelectOption<Sede>[];
}

const NewProfesorButton = ({
  programasOptions,
  sedesOptions,
}: NewProfesorButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyProfesorForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (profesorForm: ProfesorForm): void => {
    postProfesor(formatProfesorPostBody(profesorForm))
      .then((createdProfesor) => {
        const createdProfesorId = createdProfesor.id;

        history.push(`/profesores/${createdProfesorId}`);

        cogoToast.success('Profesor creado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar Profesor
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Profesor</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <ProfesorFormComponent
            formState={formState}
            submitForm={submitForm}
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewProfesorButton;
