import { format, parse } from 'date-fns';
import {
  Egresado,
  EgresadoForm,
  EgresadoRequestBody,
} from '../models/egresados/Egresado';
import { SelectOption } from '../models/form/Select';
import BasePrograma from '../models/programas/Programa';
import { BaseInstitucion } from '../models/instituciones/Institucion';
import Sede from '../models/sedes/Sede';
import Nivel from '../models/niveles/Nivel';
import { getRutVerifier } from './rut';
import Estado from '../models/estados/Estado';
import { Estudiante } from '../models/estudiantes/Estudiante';
import Ingreso from '../models/egresados/Ingreso';
import Beneficio from '../models/egresados/Beneficio';
import {Carrera} from '../models/carreras/Carrera';

export const getEmptyEgresadoForm = (): EgresadoForm => ({
  nombre: '',
  apellidos: '',
  nombreSocial: '',
  rut: '',
  fechaNacimiento: '',
  direccion: '',
  telefono: '',
  email: '',
  ive: '',
  tasaTitulacionOportuna: '',
  tasaAprobacion: '',
  tasaRetencionPrimerAno: '',
  tasaRetencionSegundoAno: '',
  tasaRetencionTercerAno: '', 
  genero: '',
});

export const formatEgresadoPostBody = (egresadoForm: EgresadoForm): EgresadoRequestBody => {
  const {
    institucion,
    carrera,
    tasaTitulacionOportuna,
    tasaAprobacion,
    tasaRetencionPrimerAno,
    tasaRetencionSegundoAno,
    tasaRetencionTercerAno,
    tipoBeneficio,
    tipoIngreso,
  } = egresadoForm;

  return {
    carreraId: carrera ? carrera.value.id : undefined,
    institucionId: institucion ? institucion.value.id : undefined,
    tipoBeneficioId: tipoBeneficio ? tipoBeneficio.value.id : undefined,
    tipoIngresoId: tipoIngreso ? tipoIngreso.value.id : undefined,
    tasaTitulacionOportuna: tasaTitulacionOportuna.length > 0 ? Number(tasaTitulacionOportuna) : undefined,
    tasaAprobacion: tasaAprobacion.length > 0 ? Number(tasaAprobacion) : undefined,
    tasaRetencionPrimerAno: tasaRetencionPrimerAno.length > 0 ? Number(tasaRetencionPrimerAno) : undefined,
    tasaRetencionSegundoAno: tasaRetencionSegundoAno.length > 0 ? Number(tasaRetencionSegundoAno) : undefined,
    tasaRetencionTercerAno: tasaRetencionTercerAno.length > 0 ? Number(tasaRetencionTercerAno) : undefined,
};

};

export const formatEgresadoForm = (
  egresado: Egresado,
  sedesOptions: SelectOption<Sede>[],
  programasOptions: SelectOption<BasePrograma>[],
  nivelesOptions: SelectOption<Nivel>[],
  institucionesOptions: SelectOption<BaseInstitucion>[],
  estadoOptions: SelectOption<Estado>[],
  ingresosOptions: SelectOption<Ingreso>[],
  beneficiosOptions: SelectOption<Beneficio>[],
  carrerasOptions: SelectOption<Carrera>[],
): EgresadoForm => {
  const {
    nombre,
    apellidos = '',
    rut = '',
    fechaNacimiento = '',
    ive = '',
    telefono = '',
    email = '',
    nombreSocial = '',
    genero = '',
    direccion = '',
    tasaTitulacionOportuna = '',
    tasaAprobacion = '',
    tasaRetencionPrimerAno = '',
    tasaRetencionSegundoAno = '',
    tasaRetencionTercerAno = '',
  } = egresado;

  const institucionesSelectOption = institucionesOptions.find(
    (option) => option.value.id === egresado.institucion?.id,
  );

  const carrerasSelectOption = carrerasOptions.find(
    (option) => option.value.id === egresado.carrera?.id,
  );

  return {
    nombre,
    apellidos,
    rut: `${rut}${getRutVerifier(Number(rut))}`,
    fechaNacimiento: fechaNacimiento ? format(new Date(fechaNacimiento), 'dd/MM/yyyy') : '',
    ive: ive ? ive.toString() : '',
    telefono: telefono ? telefono.toString() : '',
    nombreSocial,
    genero,
    direccion,
    email,
    tasaTitulacionOportuna,
    tasaAprobacion,
    tasaRetencionPrimerAno,
    tasaRetencionSegundoAno,
    tasaRetencionTercerAno,
    institucion: institucionesSelectOption,
    carrera: carrerasSelectOption,
  };
};