import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import { SelectOption } from '../../models/form/Select';
import TipoInstitucion from '../../models/instituciones/TipoInstitucion';
import { formatInstitucionPostBody, getEmptyInstitucionForm } from '../../utils/instituciones';
import { InstitucionForm } from '../../models/instituciones/Institucion';
import { postInstitucion } from '../../api/instituciones/institucionesApi';
import InstitucionFormComponent from './InstitucionFormComponent';
import Region from '../../models/regiones/Region';

interface NewInstitucionButtonProps {
  tiposInstitucionesOptions: SelectOption<TipoInstitucion>[];
  regionesOptions: SelectOption<Region>[];
}

const NewInstitucionButton = ({
  tiposInstitucionesOptions,
  regionesOptions,
}: NewInstitucionButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyInstitucionForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (institucionForm: InstitucionForm): void => {
    postInstitucion(formatInstitucionPostBody(institucionForm))
      .then((response) => {
        const createdInstitucionId = response.data.id;

        history.push(`/instituciones/${createdInstitucionId}`);

        cogoToast.success('Institución creada!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar Institución
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Institución</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <InstitucionFormComponent
            formState={formState}
            submitForm={submitForm}
            regionesOptions={regionesOptions}
            tiposInstitucionOptions={tiposInstitucionesOptions}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewInstitucionButton;
