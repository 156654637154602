import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import Region from '../models/regiones/Region';
import getRegiones from '../api/regiones/regionesApi';

const useRegionesOptions = (): SelectOption<Region>[] => {
  const [regionesOptions, setRegionesOptions] = useState<
    SelectOption<Region>[]
  >([]);

  useEffect((): void => {
    getRegiones()
      .then((response): void => {
        setRegionesOptions(
          response.data.map((tipo) => {
            return { label: tipo.nombre, value: tipo };
          }),
        );
      })
      .catch(() => {});
  }, [setRegionesOptions]);

  return regionesOptions;
};

export default useRegionesOptions;
