import { createContext } from 'react';
import { LayoutContextValue } from '../models/layout/LayoutContext';

const LayoutContext = createContext<LayoutContextValue>({
  headerTitle: '',
  breadcrumbItems: [],
  isSidebarActive: false,
  setHeaderTitle: () => {},
  setBreadcrumbItems: () => {},
  toggleSidebar: () => {},
});

export default LayoutContext;
