import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import CursosFilters from '../../models/cursos/CursosFilters';
import { SelectOption } from '../../models/form/Select';
import BasePrograma from '../../models/programas/Programa';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';
import Select from '../../components/form/select/Select';
import { Input } from '../../components/form/input/Input';

interface CursosFiltersBonoLocomocionProps {
  cursosFilters: CursosFilters;
  setCursosFilters: Dispatch<SetStateAction<CursosFilters>>;
  programasOptions: SelectOption<BasePrograma>[];
  sedesOptions: SelectOption<Sede>[];
  nivelesOptions: SelectOption<Nivel>[];
}

const CursosFiltersBonoLocomocion = ({
  cursosFilters,
  nivelesOptions,
  programasOptions,
  sedesOptions,
  setCursosFilters,
}: CursosFiltersBonoLocomocionProps): ReactElement => {
  const { programaId, nivelId, sedeId } = cursosFilters;

  const handleFilterChange = (filterName: string, value?: number): void => {
    if (filterName === 'year' && Number.isNaN(value)) {
      return;
    }
    if (filterName === 'semestre' && Number.isNaN(value)) {
      return;
    }
    setCursosFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  }

  return (
    <Row>
      <Col lg={4}>
        <Select
          title="Sede"
          placeholder="Sede"
          value={sedesOptions.find((sedeOption) => sedeOption.value.id === sedeId)}
          options={sedesOptions}
          onSelect={(selectedSede): void => {
            handleFilterChange('sedeId', selectedSede.value.id);
          }}
          onClear={() => handleFilterChange('sedeId', undefined)}
        />
      </Col>
      <Col lg={4}>
        <Select
          title="Nivel"
          placeholder="Nivel"
          value={nivelesOptions.find((nivelOption) => nivelOption.value.id === nivelId)}
          options={nivelesOptions}
          onSelect={(selectedNivel): void => {
            handleFilterChange('nivelId', selectedNivel.value.id);
          }}
          onClear={() => handleFilterChange('nivelId', undefined)}
        />
      </Col>
    </Row>
  );
};

export default CursosFiltersBonoLocomocion;
