import { useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { BaseCurso } from '../models/cursos/Curso';
import CursosFilters from '../models/cursos/CursosFilters';
import { getCursos } from '../api/cursos/cursosApi';
import BasePrograma from '../models/programas/Programa';
import { getProgramas, getProgramasReporte } from '../api/programas/programasApi';

interface UseCursosHookValue {
  programas: BasePrograma[];
  isLoading: boolean;
}

const useProgramas = (): UseCursosHookValue => {
  const [programas, setProgramas] = useState<BasePrograma[]>([]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getProgramasReporte()
      .then((requestProgramas) => {
        setProgramas(requestProgramas);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setProgramas]);

  return {
    programas,
    isLoading,
  };
};

export default useProgramas;
