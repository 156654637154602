import {Carrera,CarreraForm, CarreraRequestBody,
} from '../models/carreras/Carrera';

export const getEmptyCarreraForm = (): CarreraForm => ({
  nombre: '',
});

export const formatCarreraPostBody = (
  carreraForm: CarreraForm,
): CarreraRequestBody => {
  const {
    nombre,
  } = carreraForm;

  return {
    nombre,
  };
};

export const formatCarreraForm = (
  carrera: Carrera,
): CarreraForm => {
  const {
    nombre,
  } = carrera;

  return {
    nombre,
  };
};
