import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import { getSesiones } from '../../api/sesiones/sesionesPageRequests';
import { BaseSesion } from '../../models/sesiones/Sesion';
import Table, { TableColumn } from '../table/Table';
import SesionesFilters from '../../models/sesiones/SesionesFilters';
import EntrevistasFilters from '../../models/entrevistas/EntrevistasFilters';
import { getEntrevistas } from '../../api/entrevistas/entrevistasPageRequests';
import { BaseEntrevista } from '../../models/entrevistas/Entrevista';

interface EntrevistaListaProps {
  entrevistasFilters: EntrevistasFilters;
  setEntrevistasFilters: Dispatch<SetStateAction<EntrevistasFilters>>;
}

const EntrevistaLista = ({
  entrevistasFilters,
  setEntrevistasFilters,
}: EntrevistaListaProps): ReactElement => {

  const [entrevistas, setEntrevistas] = useState<BaseEntrevista[]>();
  const [pages, setPages] = useState(1);
  const history = useHistory();

  useEffect(() => {
    getEntrevistas(entrevistasFilters)
      .then((response) => {
        setEntrevistas(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de las entrevistas');
      });
  }, [entrevistasFilters, setEntrevistas]);

  const handleEntrevistasClick = (entrevista: BaseEntrevista) => {
    history.push(`/entrevistas/${entrevista.id}`);
  };

  const tableColumns: TableColumn<BaseEntrevista>[] = [
    {
      title: 'Fecha',
      accessor: (entrevista) => `${format(entrevista.fecha, 'dd/MM/yyyy')}`,
    },
    {
      title: 'Coordinador',
      accessor: (entrevista) => { 
        if (entrevista.coordinador) { 
          return `${entrevista.coordinador.nombre} ${entrevista.coordinador.apellidos}` 
        } 
          return 'Coordinador eliminado del sistema';
        
      },
    },
    {
      title: 'Tema',
      accessor: (entrevista) => `${entrevista.tema}`, 
    },
  ];

  if (!entrevistas) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  const sortedEntrevistas = entrevistas.sort((s1, s2) => {
    return s2.fecha - s1.fecha 
  })
  return (
    <>
      <Table
        items={sortedEntrevistas}
        columns={tableColumns}
        hover
        onItemClick={handleEntrevistasClick}
        minRowsCount={entrevistasFilters.itemsPerPage}
        pages={pages}
        activePage={entrevistasFilters.page}
        onPageSelect={(page) => setEntrevistasFilters({ ...entrevistasFilters, page })}
      />
    </>
  );
};
export default EntrevistaLista;