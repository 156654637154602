import jwt from 'jsonwebtoken';
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AuthContext from '../contexts/AuthContext';
import { AuthContextState, AuthContextValue } from '../models/usuarios/AuthContext';
import JWT from '../models/usuarios/JWT';
import localStorageKeys from '../utils/localStorageKeys';

interface AuthStoreProps {
  children?: ReactNode;
}

const AuthStore = ({ children }: AuthStoreProps): ReactElement => {
  const [authState, setAuthState] = useState<AuthContextState>({
    jwtString: '',
    hasJWT: true,
  });

  const history = useHistory();

  const setToken = useCallback(
    (jwtString: string): void => {
      localStorage.setItem(localStorageKeys.jwt, jwtString);

      const jwttest = jwt.decode(jwtString) as JWT

      setAuthState({
        jwtString,
        hasJWT: true,
        jwtData: jwttest,
      });
    },
    [setAuthState],
  );

  const logout = (): void => {
    localStorage.removeItem(localStorageKeys.jwt);

    history.push('/login');
  };

  useEffect(() => {
    const loadedToken = localStorage.getItem(localStorageKeys.jwt);

    if (loadedToken) setToken(loadedToken);
    else
      setAuthState((prevState) => ({
        ...prevState,
        hasJWT: false,
      }));
  }, [setToken]);

  const authContextValue: AuthContextValue = {
    setJWT: setToken,
    hasJwt: authState.hasJWT,
    userName: authState.jwtData?.sub || '',
    userRoles: authState.jwtData?.roles || [],
    email: authState.jwtData?.email || '',
    id: authState.jwtData?.id || '',
    jwtData: authState.jwtData,
    logout,
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export default AuthStore;
