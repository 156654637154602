import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import cogoToast from 'cogo-toast';
import CarreraInfoCard from '../../components/carreras/CarreraInfoCard';
import { getCarrera } from '../../api/carreras/carrerasApi';
import { Carrera } from '../../models/carreras/Carrera';
import useBreadcrumb from '../../hooks/useBreadcrumb';

const CarreraPage = (): ReactElement => {
  const [carrera, setCarrera] = useState<Carrera>();

  const [isLoading, setLoading] = useState(true);

  const { carreraId } = useParams();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    getCarrera(carreraId)
      .then((response): void => {
        setCarrera(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del carrera');
      })
      .then(() => setLoading(false));
  }, [carreraId, setCarrera]);

  useEffect(() => {
    if (carrera) {
      breadcrumb.setItems([
        {
          label: 'Inicio',
        },
        {
          label: 'Carreras',
          href: '/carreras',
        },
        {
          label: carrera.nombre,
          active: true,
        },
      ]);
    }
  }, [carrera, breadcrumb]);

  if (isLoading)
    return (
      <Card>
        <Card.Body className="text-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );

  if (!carrera)
    return (
      <Card>
        <Card.Body className="text-center">
          {isLoading ? (
            <Spinner animation="border" />
          ) : (
            <p className="m-0">La carrera no existe</p>
          )}
        </Card.Body>
      </Card>
    );

  const handleCarreraChange = (changedCarrera: Carrera): void =>
    setCarrera(changedCarrera);

  return (
    <>
      <CarreraInfoCard
        carrera={carrera}
        onCarreraChange={handleCarreraChange}
      />
    </>
  );
};

export default CarreraPage;
