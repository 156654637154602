import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { RegistroBitacora } from '../../models/bitacoras/Bitacora';
import { getBitacoras } from '../../api/bitacoras/bitacorasApi';
import NewBitacoraButton from '../../components/bitacoras/NewBitacoraButton';
import RegistroBitacoraCard from '../../components/bitacoras/RegistroBitacoraCard';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import PaginationComponent from '../../components/pagination/PaginationComponent';
import Usuario from '../../models/usuarios/Usuario';

const BitacoraPage = (): ReactElement => {
  const [pages, setPages] = useState(1);
  const [items, setItems] = useState<RegistroBitacora[]>([]);
  const itemsPerPage = 5;

  const breadcrumb = useBreadcrumb();
  const history = useHistory();

  const { search } = useLocation();
  const { page } = queryString.parse(search);

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Bitacora',
      },
    ]);
  }, [breadcrumb]);

  const loadBitacora = useCallback(
    (page: number): void => {
      getBitacoras({ page, itemsPerPage })
        .then((bitacoraList): void => {
          setPages(bitacoraList.pages);
          setItems(bitacoraList.items);
        })
        .catch((error) => {
          cogoToast.error(error.message);
        });
    },
    [setPages, setItems],
  );

  const reloadBitacora = (): void => loadBitacora(Number(page || 0));

  useEffect(() => {
    loadBitacora(Number(page) || 1);
  }, [loadBitacora, page]);

  const goToPage = (page: number): void => {
    history.push(`/bitacora?page=${page}`);
  };

  return (
    <>
      <Card className="text-right mb-3">
        <Card.Body>
          <Row>
            <Col className="text-left">
              <h4> Registros de la bitácora </h4>
            </Col>
            <Col className="text-right">
              <NewBitacoraButton onCreate={reloadBitacora} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {items.map(
        (item): ReactElement => (
          <Card className="mb-3">
            <RegistroBitacoraCard bitacora={item} onDelete={reloadBitacora} />
          </Card>
        ),
      )}
      <PaginationComponent pageCount={pages} onChange={goToPage} />
    </>
  );
};

export default BitacoraPage;
