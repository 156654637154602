import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import InventarioListFilters from '../../models/inventario/InventarioListFilters';
import InventarioListFiltersComponent from '../../components/inventario/InventarioListFiltersComponent';
import useSedesOptions from '../../hooks/useSedes';
import NewMaterialButton from '../../components/inventario/NewMaterialButton';
import { MaterialInventario } from '../../models/inventario/MaterialInventario';
import { getMaterialesInventario } from '../../api/inventario/inventarioApi';
import InventarioList from '../../components/inventario/InventarioList';
import useProgramasOptions from '../../hooks/useProgramasOptions';
import useBreadcrumb from '../../hooks/useBreadcrumb';

const InventarioPage = (): ReactElement => {
  const [filters, setFilters] = useState<InventarioListFilters>({
    nombre: '',
    sedeId: '',
    page: 1,
    itemsPerPage: 8,
  });

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Inventario',
      },
    ]);
  }, [breadcrumb]);
  const [materiales, setMateriales] = useState<MaterialInventario[]>([]);

  const [isEditing, setEditing] = useState(false);

  const sedesOptions = useSedesOptions();

  const programasOptions = useProgramasOptions();

  const updateMaterialesList = useCallback(() => {
    const cleanedFilters = {
      ...filters,
      nombre: filters.nombre || undefined,
      sedeId: filters.sedeId === '' ? undefined : filters.sedeId,
    };

    getMaterialesInventario(cleanedFilters)
      .then((response) => {
        setMateriales(response.data);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      });
  }, [filters, setMateriales]);

  useEffect(() => {
    updateMaterialesList();
  }, [updateMaterialesList]);

  return (
    <>
      <Card>
        <Card.Body>
          <InventarioListFiltersComponent
            inventarioFilters={filters}
            onInventarioFiltersChange={setFilters}
            sedesOptions={sedesOptions}
            programasOptions={programasOptions}
            createButton={
              <NewMaterialButton
                sedesOptions={sedesOptions}
                programasOptions={programasOptions}
                onMaterialCreated={updateMaterialesList}
              />
            }
            editButton={
              !isEditing ? (
                <Button className="mt-3 ml-2" onClick={() => setEditing(true)}>
                  Habilitar edición
                </Button>
              ) : undefined
            }
          />
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <InventarioList
            materiales={materiales}
            setMateriales={setMateriales}
            isEditing={isEditing}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default InventarioPage;
