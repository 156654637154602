import React, { ReactElement } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ColaboradorForm } from '../../models/establecimientos/Colaborador';
import { Input } from '../form/input/Input';
import RutInput from '../form/input/RutInput';

interface ColaboradorFormComponentProps {
  colaboradorForm: ColaboradorForm;
  onChange: (newValue: ColaboradorForm) => void;
  onDeleteClick?: () => void;
  areValidationsEnabled?: boolean;
  disabled: boolean;
}

const ColaboradorFormComponent = ({
  colaboradorForm,
  onChange,
  onDeleteClick,
  areValidationsEnabled,
  disabled,
}: ColaboradorFormComponentProps): ReactElement => {
  const handleFieldChange = (fieldName: string, value: string): void =>
    onChange({
      ...colaboradorForm,
      [fieldName]: value,
    });

  return (
    <Row>
      <Col md={6}>
        <Input
          title="Nombre"
          name="nombre"
          placeholder="Nombre del colaborador"
          value={colaboradorForm.nombre}
          onChangeWithName={handleFieldChange}
          invalid={areValidationsEnabled && !colaboradorForm.nombre}
          invalidText="Debe ingresar el nombre del colaborador"
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <RutInput
          title="Rut"
          name="rut"
          placeholder="Rut del colaborador"
          value={colaboradorForm.rut}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Input
          title="Correo"
          name="email"
          placeholder="Correo del colaborador"
          value={colaboradorForm.email}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Input
          title="Teléfono"
          name="telefono"
          placeholder="Teléfono del colaborador"
          value={colaboradorForm.telefono}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Input
          title="Cargo"
          name="cargo"
          placeholder="Cargo del colaborador"
          value={colaboradorForm.cargo}
          onChangeWithName={handleFieldChange}
          disabled={disabled}
        />
      </Col>
      {!!onDeleteClick && (
        <Col md={6}>
          <div className="mt-md-3 text-center text-md-left">
            <Button
              className=" mt-md-3"
              variant="danger"
              onClick={onDeleteClick}
              disabled={disabled}>
              Eliminar Colaborador
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default ColaboradorFormComponent;
