import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getEstados } from '../api/estados/estadosApi';
import Estado from '../models/estados/Estado';

const useEstadosOptions = (): SelectOption<Estado>[] => {
  const [estadoOptions, setEstadosOptions] = useState<
    SelectOption<Estado>[]
  >([]);

  useEffect(() => {
    getEstados()
      .then((response) => {
        setEstadosOptions(
          response.data.map((estado) => {
            return { label: estado.nombre, value: estado };
          }),
        );
      })
      .catch(() => {});
  }, [setEstadosOptions]);
  return estadoOptions;
};
export default useEstadosOptions;
