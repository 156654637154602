import { useEffect, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { getProgramas } from '../api/estudiantes/estudiantesApi';
import BasePrograma from '../models/programas/Programa';

const useProgramasOptions = (): SelectOption<BasePrograma>[] => {
  const [programasOptions, setProgramasOptions] = useState<SelectOption<BasePrograma>[]>([]);

  useEffect(() => {
    getProgramas()
      .then((response) => {
        setProgramasOptions(
          response.data.map((programa) => {
            return { label: programa.nombre, value: programa };
          }),
        );
      })
      .catch(() => {});
  }, [setProgramasOptions]);
  return programasOptions;
};
export default useProgramasOptions;
