import { useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { BaseCurso } from '../models/cursos/Curso';
import CursosFilters from '../models/cursos/CursosFilters';
import { getCursos } from '../api/cursos/cursosApi';

interface UseCursosHookValue {
  cursos: BaseCurso[];
  isLoading: boolean;
}

const useCursos = (filters: CursosFilters): UseCursosHookValue => {
  const [cursos, setCursos] = useState<BaseCurso[]>([]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getCursos(filters)
      .then((requestCursos) => {
        setCursos(requestCursos);
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters, setCursos]);

  return {
    cursos,
    isLoading,
  };
};

export default useCursos;
