import React from 'react';
import DisableUserAccessButton from '../components/usuarios/DisableUserAccessButton';
import EnableUserAccessButton from '../components/usuarios/EnableUserAccessButton';
import RevokeUserInvitationButton from '../components/usuarios/RevokeUserInvitationButton';
import SendUserInvitationButton from '../components/usuarios/SendUserInvitationButton';
import { SelectOption } from '../models/form/Select';
import Sede from '../models/sedes/Sede';
import Usuario, {
  UsuarioForm,
  UsuarioRequestBody,
  UsuarioStatus,
} from '../models/usuarios/Usuario';
import { transformApiBasePersonaIntoBasePersona, transformApiPersonaIntoPersona } from './personas';
import { getRutNumericValue } from './rut';

export const transformApiBaseUsuarioIntoBaseUsuario = transformApiBasePersonaIntoBasePersona;

export const transformApiUsuarioIntoUsuario = transformApiPersonaIntoPersona;

export const getActionButtonFromUserStatus = (usuario: Usuario, onChange?: () => void) => {
  const usuarioCopy = {
    ...usuario,
    rut: getRutNumericValue(usuario.rut.toString()),
  };
  switch (usuarioCopy.status) {
    case UsuarioStatus.Active:
      return <DisableUserAccessButton usuarioId={usuarioCopy.id} onChange={onChange} />;
    case UsuarioStatus.Disabled:
      return <EnableUserAccessButton userId={usuarioCopy.id} onChange={onChange} />;
    case UsuarioStatus.InvitationExpired:
      return (
        <SendUserInvitationButton
          usuario={usuarioCopy}
          text="Renovar Invitación"
          onChange={onChange}
        />
      );
    case UsuarioStatus.Invited:
      return (
        <>
          <RevokeUserInvitationButton className="mr-2" usuario={usuarioCopy} onChange={onChange} />
          <SendUserInvitationButton
            usuario={usuarioCopy}
            text="Reenviar invitación"
            onChange={onChange}
          />
        </>
      );
    case UsuarioStatus.NotActivated:
      return (
        <SendUserInvitationButton
          usuario={usuarioCopy}
          text="Enviar invitación"
          onChange={onChange}
        />
      );
    default:
      return '';
  }
};

export const formatUsuarioPutBody = (usuarioForm: UsuarioForm): UsuarioRequestBody => {
  const { nombre, apellidos, rut, email, telefono, direccion, sede } = usuarioForm;

  return {
    nombre,
    apellidos,
    rut: Number(rut.slice(0, -1)),
    email,
    telefono,
    direccion,
    sedeId: sede?.value.id,
  };
};

export const formatUsuarioForm = (
  usuario: Usuario,
  sedeOptions: SelectOption<Sede>[],
): UsuarioForm => {
  const {
    email = '',
    apellidos = '',
    direccion = '',
    nombre = '',
    rut = '',
    telefono = '',
    roles = [],
  } = usuario;

  const sedeSelectOption = sedeOptions.find((option) => option.value.id === usuario.sede.id);

  return {
    nombre,
    apellidos,
    rut: rut ? rut.toString() : '',
    email,
    telefono,
    direccion,
    roles,
    sede: sedeSelectOption,
  };
};
