import React, { ReactElement } from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.scss';

interface PaginationComponentProps {
  pageCount: number;
  activePage?: number;
  onChange: (page: number) => void;
}

const PaginationComponent = ({
  pageCount,
  activePage,
  onChange,
}: PaginationComponentProps): ReactElement => {
  const handlePageChange = ({ selected }: { selected: number }): void => {
    onChange(selected + 1);
  };

  return (
    <div className="d-flex">
      <ReactPaginate
        onPageChange={handlePageChange}
        pageCount={pageCount}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        containerClassName="pagination m-auto"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        activeClassName="active"
        disabledClassName="disabled"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      />
    </div>
  );
};

export default PaginationComponent;
