import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { Tutor } from '../models/tutores/Tutor';
import { getTutores } from '../api/tutores/tutoresApi';
import TutoresFilters from '../models/tutores/TutoresFilters';

const useTutoresOptions = (programaId?: number): SelectOption<Tutor>[] => {
  const [tutoresOptions, setTutoresOptions] = useState<SelectOption<Tutor>[]>([]);

  const fixedFilters = useMemo(() => {
    return {
      programaId,
      page: 1,
      itemsPerPage: 10000000,
    } as TutoresFilters;
  }, [programaId]);

  useEffect(() => {
    getTutores(fixedFilters)
      .then((response) => {
        setTutoresOptions(
          response.items.map((tutor) => {
            let nombreApellido: string;
            nombreApellido = tutor.nombre;
            nombreApellido += ' ';
            nombreApellido += tutor.apellidos;
            return { label: nombreApellido, value: tutor };
          }),
        );
      })
      .catch(() => {});
  }, [setTutoresOptions]);
  return tutoresOptions;
};
export default useTutoresOptions;
