import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import { Profesor, ProfesorRequestBody, BaseProfesor } from '../../models/profesores/Profesor';
import ProfesoresFilters from '../../models/profesores/ProfesoresFilters';
import {
  transformApiBaseProfesorIntoBaseProfesor,
  transformApiProfesorIntoProfesor,
} from '../../utils/profesores';
import { ListResponse } from '../../models/personas/Persona';
import { GetBitacoraParams, RegistroBitacora } from '../../models/bitacoras/Bitacora';
import { transformRegistroBitacoraApiIntoRegistroBitacora } from '../../utils/bitacoras';

export async function getProfesores(
  filters?: ProfesoresFilters,
): Promise<ListResponse<BaseProfesor>> {
  const axiosResponse = await axios.get('profesores', { params: filters });

  const { items, pages } = axiosResponse.data;

  return {
    items: items.map(transformApiBaseProfesorIntoBaseProfesor),
    pages,
  };
}

export async function postProfesor(requestBody: ProfesorRequestBody): Promise<Profesor> {
  const axiosResponse = await axios.post('profesores', requestBody);

  return transformApiProfesorIntoProfesor(axiosResponse.data);
}

export async function getProfesor(profesorId: string): Promise<Profesor> {
  const axiosResponse = await axios.get(`profesores/${profesorId}`);

  const apiProfesor = axiosResponse.data;

  return transformApiProfesorIntoProfesor(apiProfesor);
}

export async function putProfesor(
  profesorId: number,
  requestBody: ProfesorRequestBody,
): Promise<Profesor> {
  const axiosResponse = await axios.put(`profesores/${profesorId}`, requestBody);

  return transformApiProfesorIntoProfesor(axiosResponse.data);
}

export function deleteProfesor(profesorId: number): AxiosPromise<void> {
  return axios.delete(`profesores/${profesorId}`);
}

export async function getBitacoraProfesor(profesorId: number, params: GetBitacoraParams): Promise<ListResponse<RegistroBitacora>> {
  const response = await axios.get(`profesores/${profesorId}/bitacora`, {
    params,
  });

  const {items, pages} = response.data;

  return {
    items: items.map(transformRegistroBitacoraApiIntoRegistroBitacora),
    pages,
  };
}
