import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import {formatCarreraPostBody,getEmptyCarreraForm} from '../../utils/carreras';
import { CarreraForm } from '../../models/carreras/Carrera';
import { postCarrera } from '../../api/carreras/carrerasApi';
import CarreraFormComponent from './CarreraFormComponent';
import { SelectOption } from '../../models/form/Select';

interface NewCarreraButtonProps {
}

const NewCarreraButton = ({
}: NewCarreraButtonProps): ReactElement => {
  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptyCarreraForm());

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = (carreraForm: CarreraForm): void => {
    postCarrera(formatCarreraPostBody(carreraForm))
      .then((response) => {
        const createdCarreraId = response.data.id;
        window.location.reload();

        cogoToast.success('Carrera creado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Agregar Carrera
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Agregar Carrera</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <CarreraFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewCarreraButton;
