import {
  BaseParalelo,
  ExtendedParalelo,
  ParaleloPostBody,
  ParaleloPutBody,
  UpdateParaleloNotasBody,
} from '../../models/paralelo/Paralelo';
import axios from '../axiosWithAuth';

export async function getParaleloById(id: number | string, estudianteId?: number | string): Promise<ExtendedParalelo> {
  const axiosResponse = await axios.get(`paralelos/${id}`, {params: {aux: estudianteId}});

  const paralelo = axiosResponse.data as ExtendedParalelo;

  paralelo.notasEstudiantes = paralelo.notasEstudiantes.map((notasEstudiante) => (
    {
      ...notasEstudiante,
      evaluaciones: notasEstudiante.evaluaciones.sort((a, b) => (
        a.evaluacion.correlativo - b.evaluacion.correlativo
        ))
    }
  ));
  
  return paralelo;
}

export async function postParalelo(postBody: ParaleloPostBody): Promise<ExtendedParalelo> {
  const axiosResponse = await axios.post('paralelos', postBody);

  return axiosResponse.data;
}

export async function updateParalelo(
  id: number | string,
  putBody: ParaleloPutBody,
): Promise<ExtendedParalelo> {
  const axiosResponse = await axios.put(`paralelos/${id}`, putBody);

  return axiosResponse.data;
}

export async function deleteParaleloById(id: number): Promise<void> {
  await axios.delete(`paralelos/${id}`);
}

export async function updateParaleloEstudiantes(paraleloId: number, estudiantesIds: number[]): Promise<void> {
  await axios.put(`paralelos/${paraleloId}/estudiantes`, { estudiantesIds });
}

export async function removeParaleloEstudiante(paraleloId: number, estudianteId: number): Promise<void> {
  await axios.delete(`paralelos/${paraleloId}/estudiantes/${estudianteId}`);
}

export async function updateParaleloNotas(paraleloId: number, updateParaleloNotasBody: UpdateParaleloNotasBody): Promise<void> {
  await axios.put(`paralelos/${paraleloId}/notas`, updateParaleloNotasBody);
}



