import cogoToast from 'cogo-toast';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { updateParaleloNotas } from '../../api/paralelos/paralelosApi';
import useFormState from '../../hooks/useFormState';
import { NotasEstudiante } from '../../models/paralelo/Paralelo';
import { formatNotasEstudiantesForms, formatUpdateParaleloNotasBody } from '../../utils/paralelos';
import ParaleloNotasForm from './ParaleloNotasForm';

interface EditNotasButtonProps {
  paraleloId: number;
  notas: NotasEstudiante[];
  onUpdate: () => void;
}

const EditNotasButton = ({paraleloId, notas, onUpdate}: EditNotasButtonProps): ReactElement => {
  const formState = useFormState(formatNotasEstudiantesForms(notas));

  const [isEditModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    formState.setForm(formatNotasEstudiantesForms(notas));
  }, [formState.setForm, notas]);

  const openModal = (): void => {
    setEditModalOpen(true);
  };

  const closeModal = (): void => {
    setEditModalOpen(false);
  };

  const cancelEdition = (): void => {
    closeModal();

    formState.reset();
  }

  const submit = (): void => {
    formState.setSubmitting(true);

    updateParaleloNotas(paraleloId, formatUpdateParaleloNotasBody(formState.value))
      .then(() => {
        onUpdate();

        cogoToast.success('Notas actualizadas.');

        closeModal();
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      })
  }

  return (
    <>
      <Button variant="warning" disabled={formState.isSubmitting} onClick={openModal} size="sm">
        Editar notas
      </Button>
      <Modal show={isEditModalOpen} onHide={closeModal} size="xl">
        <Modal.Header>
          <Modal.Title>Editar notas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ParaleloNotasForm formState={formState} submit={submit} cancel={cancelEdition} />
        </Modal.Body>
      </Modal>
    </>
  )
};

export default EditNotasButton;
