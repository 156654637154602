import { useContext, useMemo } from 'react';
import AuthContext from '../contexts/AuthContext';
import Rol from '../models/usuarios/Rol';

export default function useUserHasAnyRole(roles: Rol[]): boolean {
  const { userRoles } = useContext(AuthContext);

  const result = useMemo(() => 
    (userRoles.some((userRole) => roles.includes(userRole)))
  , [userRoles]);

  return result;
}
