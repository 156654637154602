import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from '../models/form/Select';
import { Coordinador } from '../models/coordinadores/Coordinador';
import { getCoordinadores } from '../api/coordinadores/coordinadoresApi';
import CoordinadoresFilters from '../models/coordinadores/CoordinadoresFilters';

const useCoordinadoresOptions = (sedeId?: number): SelectOption<Coordinador>[] => {
  const [coordinadoresOptions, setCoordinadoresOptions] = useState<SelectOption<Coordinador>[]>([]);

  const fixedFilters = useMemo(() => {
    return {
      sedeId,
      page: 1,
      itemsPerPage: 10000000,
    } as CoordinadoresFilters;
  }, [sedeId]);

  useEffect(() => {
    getCoordinadores(fixedFilters)
      .then((response) => {
        setCoordinadoresOptions(
          response.items.map((coordinador) => {
            let nombreApellido: string;
            nombreApellido = coordinador.nombre;
            nombreApellido += ' ';
            nombreApellido += coordinador.apellidos;
            return { label: nombreApellido, value: coordinador };
          }),
        );
      })
      .catch(() => {console.log("intentado obtener coordinadores");});
  }, [setCoordinadoresOptions]);
  return coordinadoresOptions;
};
export default useCoordinadoresOptions;