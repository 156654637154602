import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { createEvaluacion } from '../../api/evaluaciones/evaluacionesApi';
import useFormState from '../../hooks/useFormState';
import { BaseCurso } from '../../models/cursos/Curso';
import { Evaluacion, EvaluacionForm } from '../../models/evaluaciones/Evaluacion';
import { formatEvaluacionPostBody, getEmptyEvaluacionForm } from '../../utils/evaluaciones';
import EvaluacionFormComponent from './EvaluacionFormComponent';

interface NewEvaluacionButtonProps {
  curso: BaseCurso;
  onCreate: (newEvaluacion: Evaluacion) => void;
}

const NewEvaluacionButton = ({ curso, onCreate }: NewEvaluacionButtonProps): ReactElement => {
  const [isModalActive, setModalActive] = useState(false);

  const formState = useFormState(getEmptyEvaluacionForm(curso));

  const history = useHistory();

  const openFormModal = (): void => {
    setModalActive(true);
  };

  const closeFormModal = (): void => {
    setModalActive(false);
  };

  const submitForm = (evaluacionForm: EvaluacionForm): void => {
    formState.setSubmitting(true);

    createEvaluacion(formatEvaluacionPostBody(evaluacionForm))
      .then((createdEvaluacion) => {
        cogoToast.success('Evaluación agregada.');

        onCreate(createdEvaluacion);

        closeFormModal();

        formState.setSubmitting(false);

        formState.reset();
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Button variant="success" onClick={openFormModal} size="sm">
        Agregar evaluación
      </Button>
      <Modal show={isModalActive} onHide={closeFormModal}>
        <Modal.Header>
          <div className="text-sm-center">
            <Modal.Title>Agregar evaluación</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <EvaluacionFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={closeFormModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewEvaluacionButton;
