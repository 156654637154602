
import { Colaborador, ColaboradorForm } from '../models/establecimientos/Colaborador';
import { SelectGroup, SelectOption } from '../models/form/Select';
import { Institucion, InstitucionForm, InstitucionRequestBody } from '../models/instituciones/Institucion';
import TipoInstitucion from '../models/instituciones/TipoInstitucion';
import Region from '../models/regiones/Region';
  
  export const getEmptyInstitucionForm = (): InstitucionForm => ({
    nombre: '',
  });
  
  export const getEmptyColaboradorForm = (): ColaboradorForm => ({
    nombre: '',
    rut: '',
    email: '',
    telefono: '',
    cargo: '',
  });
  
  export const formatInstitucionPostBody = (
    institucionForm: InstitucionForm,
  ): InstitucionRequestBody => {
    const {
      tipoInstitucion,
      region,
      nombre,
    } = institucionForm;
  
    return {
      nombre,
      tipoInstitucionId: tipoInstitucion ? tipoInstitucion.value.id : undefined,
      regionId: region ? region.value.id : undefined,
    };
  };
  
  const formatColaboradoresForms = (colaboradores: Colaborador[]): ColaboradorForm[] =>
    colaboradores.map(({ nombre = '', cargo = '', email = '', rut, telefono = '' }) => ({
      nombre,
      cargo,
      email,
      rut: rut ? rut.toString() : '',
      telefono,
    }));
  
  export const formatInstitucionForm = (
    institucion: Institucion,
    comunaGroups: SelectGroup<string>[],
    tipoInstitucionOptions: SelectOption<TipoInstitucion>[],
    regionesOptions: SelectOption<Region>[],
  ): InstitucionForm => {
    const {
      nombre,
      emailDirector = '',
      direccion = '',
      nombreDirector = '',
      ciudad = '',
      observaciones = '',
      telefono = '',
    } = institucion;
  
    const comunaSelectOption = comunaGroups
      .find((comunaGroup) => comunaGroup.label === ciudad)
      ?.options.find((option) => option.value === institucion.comuna);
  
    const tipoInstitucionSelectOption = tipoInstitucionOptions.find(
      (option) => option.value.id === institucion.tipoInstitucion?.id,
    );

    const regionSelectOption = regionesOptions.find(
      (option) => option.value.id === institucion.region?.id,
    );
  
    return {
      nombre,
      region: regionSelectOption,
      tipoInstitucion: tipoInstitucionSelectOption,
    };
  };