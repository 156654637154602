import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseParalelo } from '../../models/paralelo/Paralelo';
import Table, { TableColumn } from '../table/Table';

interface CursoParalelosTableProps {
  paralelos: BaseParalelo[];
}

const CursoParalelosTable = ({ paralelos }: CursoParalelosTableProps): ReactElement => {
  const history = useHistory();

  if (paralelos.length === 0) {
    return <p className="text-center">No hay paralelos</p>;
  }

  const orderedParalelos = paralelos.sort((a, b) => a.correlativo - b.correlativo);

  const tableColumns: TableColumn<BaseParalelo>[] = [
    {
      title: 'Número',
      accessor: 'correlativo',
    },
    {
      title: 'Nº estudiantes',
      accessor: 'cantidadEstudiantes',
    },
    {
      title: 'Profesor',
      accessor: (paralelo) => `${paralelo.profesor.nombre} ${paralelo.profesor.apellidos}`
    }
  ];

  const goToParaleloPage = (paralelo: BaseParalelo): void => {
    history.push(`/cursos/paralelos/${paralelo.id}`);
  };

  return <Table hover items={orderedParalelos} columns={tableColumns} onItemClick={goToParaleloPage} />;
};

export default CursoParalelosTable;
