import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Col, Modal, ModalBody, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import cogoToast from 'cogo-toast';
import useFormState from '../../hooks/useFormState';
import LabelValue from '../LabelValue';
import useUserHasAnyRole from '../../hooks/useUserHasAnyRol';
import Rol from '../../models/usuarios/Rol';
import { Entrevista, EntrevistaBitacoras, EntrevistaBitacorasForm, EntrevistaForm, ExtendedEntrevista } from '../../models/entrevistas/Entrevista';
import { formatEntrevistaForm, formatEntrevistaPostBody } from '../../utils/entrevista';
import { deleteEntrevista, putEntrevista } from '../../api/entrevistas/entrevistasPageRequests';
import EditarEntrevistaForm from './EditarEntrevistaForm';
import useCoordinadoresOptions from '../../hooks/useCoordinadoresOptions';

interface EntrevistaInfoCardProps {
  entrevista: ExtendedEntrevista;
  onEntrevistaChange: (editedEntrevista: ExtendedEntrevista) => void;
}

const EntrevistaInfoCard = ({ entrevista, onEntrevistaChange }: EntrevistaInfoCardProps): ReactElement => {
  const {
    id,
    fecha,
    tema,
    resumen,
    compromiso,
    observacion,
    estudiante,
    coordinador,
  } = entrevista;

  const coordinadoresSelectOptions = useCoordinadoresOptions(entrevista.estudiante.sede.id);

  const formState = useFormState(formatEntrevistaForm(entrevista, coordinadoresSelectOptions));

  const userIsTutor = useUserHasAnyRole([Rol.Coordinador]);

  useEffect(() => {
    formState.setForm(formatEntrevistaForm(entrevista, coordinadoresSelectOptions));
  }, [entrevista, coordinadoresSelectOptions]);

  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const coordinadoresOptions = useCoordinadoresOptions(entrevista.estudiante.sede.id);

  const deleteCurrentEntrevista = (): void => {
    formState.setSubmitting(true);
    deleteEntrevista(id)
      .then(() => {
        cogoToast.success('Entrevista eliminada');
        history.goBack();
      })
      .catch((error) => {
        cogoToast.error(error.message);
      })
      .finally(() => {
        formState.setSubmitting(false);
      });
  };

  let coordinadorNombreCompleto;

  if (coordinador.nombre) {
    coordinadorNombreCompleto = coordinador.nombre;
    if (coordinador.apellidos) {
      coordinadorNombreCompleto += ' ';
      coordinadorNombreCompleto += coordinador.apellidos;
    }
  }

  const submitForm = (entrevistaForm: EntrevistaForm) => {
    putEntrevista(id, formatEntrevistaPostBody(entrevistaForm))
      .then((response) => {
        formState.setSubmitting(false);
        handleClose();
        onEntrevistaChange(response.data);
        cogoToast.success('Entrevista Actualizada!');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex">
          <h3 className="m-0">{`Entrevista del ${format(entrevista.fecha, 'dd/MM/yyyy')}: ${entrevista.tema}`}</h3>
          <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={handleShow}>
            Editar
          </Button>
          <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <Col className="text-sm-center">
                <Modal.Title>Editar Entrevista</Modal.Title>
              </Col>
            </Modal.Header>
            <ModalBody>
              <EditarEntrevistaForm
                formState={formState}
                submitForm={submitForm}
                onCancel={handleClose}
                coordinadoresOptions={coordinadoresOptions}
                onDelete={deleteCurrentEntrevista}
              />
            </ModalBody>
          </Modal>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} lg={6}>
              <LabelValue label="Tema" value={tema || '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Fecha" value={fecha ? format(fecha, 'dd/MM/yyyy') : '-'} />
            </Col>
            <Col xs={12} lg={6}>
              <LabelValue label="Coordinador" value={coordinadorNombreCompleto || '-'} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <LabelValue label="Resumen" value={resumen || '-'} />
            </Col>
            <Col xs={12}>
              <LabelValue label="Observación" value={observacion || '-'} />
            </Col>
            <Col xs={12}>
              <LabelValue label="Compromiso" value={compromiso || '-'} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default EntrevistaInfoCard;