import { SelectGroup } from '../models/form/Select';

const getComunasSelectGroups = (): SelectGroup<string>[] => [
  {
    label: 'Antofagasta',
    options: [
      { label: 'Antofagasta', value: 'Antofagasta' },
      { label: 'Tocopilla', value: 'Tocopilla' },
      { label: 'Mejillones', value: 'Mejillones' },
      { label: 'Maria Elena', value: 'Maria Elena' },
      { label: 'Taltal', value: 'Taltal' },
      { label: 'Sierra Gorda', value: 'Sierra Gorda' },
      { label: 'San Pedro de Atacama', value: 'San Pedro de Atacama' },
      { label: 'Calama', value: 'Calama' },
      { label: 'Ollagüe', value: 'Ollagüe' },
    ],
  },
  {
    label: 'Coquimbo',
    options: [
      { label: 'Illapel', value: 'Illapel' },
      { label: 'Canela', value: 'Canela' },
      { label: 'Los Vilos', value: 'Los Vilos' },
      { label: 'Salamanca', value: 'Salamanca' },
      { label: 'Coquimbo', value: 'Coquimbo' },
      { label: 'Andacollo', value: 'Andacollo' },
      { label: 'La Higuera', value: 'La Higuera' },
      { label: 'La Serena', value: 'La Serena' },
      { label: 'Paihuano', value: 'Paihuano' },
      { label: 'Vicuña', value: 'Vicuña' },
      { label: 'Ovalle', value: 'Ovalle' },
      { label: 'Combarbalá', value: 'Combarbalá' },
      { label: 'Monte Patria', value: 'Monte Patria' },
      { label: 'Río Hurtado', value: 'Río Hurtado' },
    ],
  },
];

export default getComunasSelectGroups;
