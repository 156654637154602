import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from '../form/select/Select';
import EstudiantesFilters from '../../models/estudiantes/EstudiantesFilters';
import { Input } from '../form/input/Input';
import { SelectOption } from '../../models/form/Select';
import BasePrograma from '../../models/programas/Programa';
import {
  BaseEstablecimiento,
  Establecimiento,
} from '../../models/establecimientos/Establecimiento';
import Sede from '../../models/sedes/Sede';
import Nivel from '../../models/niveles/Nivel';
import Estado from '../../models/estados/Estado';

interface EstudiantesFiltersProps {
  establecimientosOptions: SelectOption<BaseEstablecimiento>[];
  nivelesOptions: SelectOption<Nivel>[];
  programasOptions: SelectOption<BasePrograma>[];
  sedesOptions: SelectOption<Sede>[];
  estadosOptions: SelectOption<Estado>[];
  estudiantesFilters: EstudiantesFilters;
  onFiltersChange: Dispatch<SetStateAction<EstudiantesFilters>>;
}

const EstudiantesFiltersComponent = ({
  establecimientosOptions,
  nivelesOptions,
  programasOptions,
  sedesOptions,
  estadosOptions,
  estudiantesFilters,
  onFiltersChange,
}: EstudiantesFiltersProps): ReactElement => {
  return (
    <>
      <Row>
        <Col lg={4}>
          <Input
            value={estudiantesFilters.nombreORut || ''}
            onChange={(value) => onFiltersChange({ ...estudiantesFilters, nombreORut: value })}
            type="text"
            placeholder="Nombre o Rut"
            title="Nombre o Rut"
          />
        </Col>
        <Col lg={4}>
        <Select
            title="Establecimiento"
            options={establecimientosOptions}
            onSelect={(establecimiento) => {
              if (establecimiento)
                onFiltersChange({
                  ...estudiantesFilters,
                  establecimientoId: establecimiento.value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...estudiantesFilters,
                establecimientoId: undefined,
              });
            }}
            placeholder="Establecimiento"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Sede"
            options={sedesOptions}
            onSelect={(sede) => {
              if (sede)
                onFiltersChange({
                  ...estudiantesFilters,
                  sedeId: sede.value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...estudiantesFilters,
                sedeId: undefined,
              });
            }}
            placeholder="Sede"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Select
            title="Programa"
            options={programasOptions}
            onSelect={(programa) => {
              if (programa)
                onFiltersChange({
                  ...estudiantesFilters,
                  programaId: programa.value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...estudiantesFilters,
                programaId: undefined,
              });
            }}
            placeholder="Programa"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Nivel"
            options={nivelesOptions}
            onSelect={(nivel) => {
              if (nivel)
                onFiltersChange({
                  ...estudiantesFilters,
                  nivelId: nivel.value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...estudiantesFilters,
                nivelId: undefined,
              });
            }}
            placeholder="Nivel"
          />
        </Col>
        <Col lg={4}>
          <Select
            title="Estado"
            options={estadosOptions}
            onSelect={(estado) => {
              if (estado)
                onFiltersChange({
                  ...estudiantesFilters,
                  estadoId: estado.value.id,
                });
            }}
            onClear={() => {
              onFiltersChange({
                ...estudiantesFilters,
                estadoId: undefined,
              });
            }}
            placeholder="Estado"
          />
        </Col>
      </Row>
    </>
  );
};

export default EstudiantesFiltersComponent;
