import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import EstablecimientosFilters from '../../models/establecimientos/EstablecimientosFilters';
import EstablecimientosFiltersComponent from '../../components/establecimientos/EstablecimientosFiltersComponent';
import EstablecimientoList from '../../components/establecimientos/EstablecimientosList';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import useTiposEstablecimientosOptions from '../../hooks/useTiposEstablecimiento';
import NewEstablecimientoButton from '../../components/establecimientos/NewEstablecimientoButton';

const EstablecimientosPage = (): ReactElement => {
  const [establecimientosfilters, setEstablecimientosFilters] = useState<EstablecimientosFilters>({
    nombreOrRbd: '',
    page: 1,
    itemsPerPage: 8,
  });

  const tiposEstablecimientosOptions = useTiposEstablecimientosOptions();

  const breadcrumb = useBreadcrumb();

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: 'Inicio',
      },
      {
        label: 'Establecimientos',
      },
    ]);
  }, [breadcrumb]);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <EstablecimientosFiltersComponent
            tiposEstablecimientosOptions={tiposEstablecimientosOptions}
            establecimientosFilters={establecimientosfilters}
            onFiltersChange={setEstablecimientosFilters}
          />
          <div className="text-right">
            <NewEstablecimientoButton tiposEstablecimientosOptions={tiposEstablecimientosOptions} />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <EstablecimientoList
            setEstablecimientosFilters={setEstablecimientosFilters}
            establecimientosfilters={establecimientosfilters}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default EstablecimientosPage;
