import cogoToast from 'cogo-toast';
import React, { ReactElement, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { createInvitacion } from '../../api/invitaciones/invitacionesApi';
import Usuario from '../../models/usuarios/Usuario';

interface SendUserInvitationButtonProps {
  usuario: Usuario;
  text: 'Reenviar invitación' | 'Enviar invitación' | 'Renovar Invitación';
  onChange?: () => void;
}

const SendUserInvitationButton = ({usuario, text, onChange}: SendUserInvitationButtonProps): ReactElement => {
  const [isDisabled, setIsDisabled] = useState(false);
  
  const handleClick = () => {
    setIsDisabled(true);
    createInvitacion({rut: usuario.rut})
    .then(() => {
      cogoToast.success('Invitación enviada');
      onChange && onChange();
    })
    .catch((error) => cogoToast.error(error.message));
  }

  return (
    <>
      <Button variant='info' size='sm' onClick={handleClick} disabled={isDisabled}>
        {text}
      </Button>
    </>
  )
}

export default SendUserInvitationButton;