import Rol from '../models/usuarios/Rol';
import SidebarItem from '../models/layout/SidebarItem';


const sidebarItems: SidebarItem[] = [
  {
    name: 'Inicio',
    href: '/home',
  },
  {
    name: 'Presupuestos',
    href: '/presupuestos',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Gestión de usuarios',
    href: '/usuarios',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Instituciones',
    href: '/instituciones',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Carreras',
    href: '/carreras',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Establecimientos',
    href: '/establecimientos',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Egresados',
    href: '/egresados',
    roles: [Rol.Administrador, Rol.Coordinador, Rol.Profesor, Rol.Tutor],
  },
  {
    name: 'Estudiantes',
    href: '/estudiantes',
    roles: [Rol.Administrador, Rol.Coordinador, Rol.Profesor, Rol.Tutor],
  },
  {
    name: 'Profesores',
    href: '/profesores',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Tutores',
    href: '/tutores',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Inventario',
    href: '/inventario',
    roles: [Rol.Administrador, Rol.Coordinador, Rol.Profesor, Rol.Tutor],
  },
  {
    name: 'Cursos',
    href: '/cursos',
    roles: [Rol.Administrador, Rol.Coordinador, Rol.Profesor, Rol.Tutor],
  },
  {
    name: 'Reportes',
    href: '/reportes',
    roles: [Rol.Administrador, Rol.Coordinador],
  },
  {
    name: 'Bitácora',
    href: '/bitacora',
    roles: [Rol.Administrador, Rol.Coordinador, Rol.Profesor, Rol.Tutor],
  },
  {
    name: 'Asistencia y Evaluaciones',
    href: '/paralelosInscritos',
    roles: [Rol.Estudiante],
  },
];

export default function getSidebarItems(userRoles: Rol[]): SidebarItem[] {
  return sidebarItems.filter(
    (sidebarItem) =>
      !sidebarItem.roles || sidebarItem.roles.some((itemRol) => userRoles.includes(itemRol)),
  );
}
