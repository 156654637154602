import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import {
  GetBitacoraParams,
  RegistroBitacora,
  RequestBodyBitacora,
} from '../../models/bitacoras/Bitacora';
import { ListResponse } from '../../models/personas/Persona';
import { transformRegistroBitacoraApiIntoRegistroBitacora } from '../../utils/bitacoras';

export async function getBitacoras(
  params: GetBitacoraParams,
): Promise<ListResponse<RegistroBitacora>> {
  const axiosResponse = await axios.get('bitacora', { params });
  const { items, pages } = axiosResponse.data;

  return {
    items: items.map(transformRegistroBitacoraApiIntoRegistroBitacora),
    pages,
  };
}

export async function postBitacora(requestBody: RequestBodyBitacora): Promise<RegistroBitacora> {
  const axiosResponse = await axios.post('bitacora', requestBody);

  return transformRegistroBitacoraApiIntoRegistroBitacora(axiosResponse.data);
}

export async function putBitacora(
  bitacoraId: number,
  requestBody: RequestBodyBitacora,
): Promise<RegistroBitacora> {
  const axiosResponse = await axios.put(`bitacora/${bitacoraId}`, requestBody);

  return transformRegistroBitacoraApiIntoRegistroBitacora(axiosResponse.data);
}

export function deleteBitacora(bitacoraId: number): AxiosPromise<void> {
  return axios.delete(`bitacora/${bitacoraId}`);
}
