import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import InstitucionesFilters from '../../models/instituciones/InstitucionesFilters';
import { BaseInstitucion } from '../../models/instituciones/Institucion';
import { getInstituciones } from '../../api/instituciones/institucionesApi';
import Table, { TableColumn } from '../table/Table';

interface InstitucionesListProps {
  institucionesfilters: InstitucionesFilters;
  setInstitucionesFilters: Dispatch<SetStateAction<InstitucionesFilters>>;
}

const InstitucionList = ({
  institucionesfilters,
  setInstitucionesFilters,
}: InstitucionesListProps): ReactElement => {
  const [instituciones, setInstituciones] = useState<BaseInstitucion[]>();

  const [isLoading, setLoading] = useState(true);

  const [pages, setPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getInstituciones(institucionesfilters)
      .then((response): void => {
        setInstituciones(response.data.items);
        setPages(response.data.pages);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información de las instituciones.');
      })
      .finally(() => setLoading(false));
  }, [institucionesfilters, setInstituciones]);

  const handleInstitucionClick = (institucion: BaseInstitucion): void => {
    history.push(`/instituciones/${institucion.id}`);
  };

  const tableColumns: TableColumn<BaseInstitucion>[] = [
    {
      title: 'Nombre de Institución',
      accessor: 'nombre',
    },
    {
      title: 'Región',
      accessor: (institucion) =>
        institucion.region?.nombre,
      hideUntil: 'lg',
    },
    {
      title: 'Tipo de Institución',
      accessor: (institucion) =>
        institucion.tipoInstitucion?.nombre,
      hideUntil: 'md',
    },
  ];

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (!instituciones)
    return <p className="text-center">No se pudo cargar el listado de instituciones</p>;

  return (
    <>
      <Table
        items={instituciones}
        columns={tableColumns}
        hover
        onItemClick={handleInstitucionClick}
        minRowsCount={institucionesfilters.itemsPerPage}
        pages={pages}
        activePage={institucionesfilters.page}
        onPageSelect={(page): void =>
          setInstitucionesFilters({ ...institucionesfilters, page })
        }
      />
    </>
  );
};
export default InstitucionList;
