import Persona, { PersonaForm, PersonaRequestBody } from "../personas/Persona";
import Rol from "./Rol";

export default interface Usuario extends Persona {
  roles: Rol[];
  active: boolean;
  status: UsuarioStatus,
}

export interface ChangeUserAccessRequestBody {
  isActive: boolean,
  id: number,
  rol: Rol,
}

export interface UsuarioForm extends PersonaForm {
  roles: Rol[];
}

export enum UsuarioStatus {
  Active = 'Activo',
  Disabled = 'Deshabilitado',
  NotActivated = 'No Activado',
  Invited = 'Invitado',
  InvitationExpired = 'Invitación Expirada',
}

export interface UsuarioRequestBody extends PersonaRequestBody {
  roles?: Rol[];
  isEnabled?: boolean;
}