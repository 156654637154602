import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { ThemeContextState, ThemeContextValue } from '../models/theme/ThemeContext';
import { ColorTheme } from '../models/theme/ColorTheme';
import ThemeContext from '../contexts/ThemeContext';
import localStorageKeys from '../utils/localStorageKeys';

interface ThemeStoreProps {
  children: ReactNode;
}

const ThemeStore = ({ children }: ThemeStoreProps): ReactElement => {
  const [themeContextState, setThemeContextState] = useState<ThemeContextState>({
    colorThemeIndex: 0,
  });

  useEffect(() => {
    const loadedThemeIndex = Number(localStorage.getItem(localStorageKeys.colorTheme));

    if (!Number.isNaN(loadedThemeIndex))
      setThemeContextState((prevState) => ({
        ...prevState,
        colorThemeIndex: loadedThemeIndex,
      }));
  }, [setThemeContextState]);

  const colorThemes: Array<ColorTheme> = ['light', 'red', 'dark'];

  const toggleColorTheme = (): void => {
    setThemeContextState((prevState) => {
      const newThemeIndex = (prevState.colorThemeIndex + 1) % colorThemes.length;

      localStorage.setItem(localStorageKeys.colorTheme, newThemeIndex.toString());

      return {
        ...prevState,
        colorThemeIndex: newThemeIndex,
      };
    });
  };

  const themeContextValue: ThemeContextValue = {
    toggleColorTheme,
  };

  useEffect(() => {
    document.body.setAttribute('theme', colorThemes[themeContextState.colorThemeIndex]);
  }, [themeContextState.colorThemeIndex, colorThemes]);

  return <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>;
};

export default ThemeStore;
