import React, { ReactElement } from 'react';
import { Colaborador } from '../../models/establecimientos/Colaborador';
import { formatRut } from '../../utils/rut';
import Table, { TableColumn } from '../table/Table';

interface ColaboradoresInfoListProps {
  colaboradores: Colaborador[];
}

const ColaboradoresInfoList = ({ colaboradores }: ColaboradoresInfoListProps): ReactElement => {
  const tableColumns: TableColumn<Colaborador>[] = [
    {
      title: 'Nombre',
      accessor: 'nombre',
    },
    {
      title: 'Rut',
      accessor: (colaborador) => (colaborador.rut ? formatRut(colaborador.rut.toString()) : ''),
      hideUntil: 'lg',
    },
    {
      title: 'Email',
      accessor: (colaborador) =>
        colaborador.email ? (
          <a rel="noopener noreferrer" target="_blank" href={`mailto:${colaborador.email}`}>
            {colaborador.email}
          </a>
        ) : (
          ''
        ),
    },
    {
      title: 'Teléfono',
      accessor: (colaborador) =>
        colaborador.telefono ? (
          <a rel="noopener noreferrer" target="_blank" href={`tel:${colaborador.telefono}`}>
            {colaborador.telefono}
          </a>
        ) : (
          ''
        ),
    },
    {
      title: 'Dirección',
      accessor: 'direccion',
      hideUntil: 'xl',
    },
    {
      title: 'Cargo',
      accessor: 'cargo',
      hideUntil: 'xl',
    },
  ];

  return <Table items={colaboradores} columns={tableColumns} />;
};

export default ColaboradoresInfoList;
