import React, { ReactElement, useState } from 'react';
import { Button, Col, Modal, Row, Card } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import LabelValue from '../LabelValue';
import {Carrera, CarreraForm } from '../../models/carreras/Carrera';
import CarreraFormComponent from './CarreraFormComponent';
import {deleteCarrera, putCarrera } from '../../api/carreras/carrerasApi';
import {formatCarreraForm, formatCarreraPostBody,} from '../../utils/carreras';
import useFormState from '../../hooks/useFormState';

interface CarrerasInfoProps {
  carrera: Carrera;
  onCarreraChange: (editedCarrera: Carrera) => void;
}

const CarreraInfoCard = ({
  carrera,
  onCarreraChange,
}: CarrerasInfoProps): ReactElement => {
  const {
    id,
    nombre,
  } = carrera;

  const formState = useFormState(
    formatCarreraForm(
      carrera,
    ),
  );

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const submitForm = (carreraForm: CarreraForm): void => {
    putCarrera(carrera.id, formatCarreraPostBody(carreraForm))
      .then((response) => {
        formState.setSubmitting(false);

        setShow(false);

        onCarreraChange(response.data);

        cogoToast.success('Carrera actualizado!');
      })
      .catch((error) => {
        cogoToast.error(error.message);

        formState.setSubmitting(false);
      });
  };

  const deleteCurrentCarrera = (): void => {
    formState.setSubmitting(true);

    deleteCarrera(carrera.id).then(() => {
      cogoToast.success('Carrera eliminado!');
      formState.setConfirmingDelete(false);
      history.replace('/carreras');
    }).catch((error) => {
      cogoToast.error(error.message);
      formState.setConfirmingDelete(false);
      formState.setSubmitting(false);
    })
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h3 className="m-0"> {nombre} </h3>
          <Button
            style={{ margin: '0 0 auto auto' }}
            variant="warning"
            size="sm"
            onClick={handleShow}>
            Editar
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} lg={3}>
              <LabelValue label="Nombre" value={nombre || '-'} />
            </Col>

          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Col className="text-sm-center">
            <Modal.Title>Editar Carrera</Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <CarreraFormComponent
            formState={formState}
            submitForm={submitForm}
            onCancel={handleClose}
            onDelete={deleteCurrentCarrera}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CarreraInfoCard;
