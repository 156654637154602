import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import { Tutor, TutorRequestBody, BaseTutor } from '../../models/tutores/Tutor';
import TutoresFilters from '../../models/tutores/TutoresFilters';
import {
  transformApiBaseTutorIntoBaseTutor,
  transformApiTutorIntoTutor,
} from '../../utils/tutores';
import { ListResponse } from '../../models/personas/Persona';
import { GetBitacoraParams, RegistroBitacora } from '../../models/bitacoras/Bitacora';
import { transformRegistroBitacoraApiIntoRegistroBitacora } from '../../utils/bitacoras';

export async function getTutores(filters: TutoresFilters): Promise<ListResponse<BaseTutor>> {
  const axiosResponse = await axios.get('tutores', { params: filters });

  const { items, pages } = axiosResponse.data;

  return {
    items: items.map(transformApiBaseTutorIntoBaseTutor),
    pages,
  };
}

export async function postTutor(requestBody: TutorRequestBody): Promise<Tutor> {
  const axiosResponse = await axios.post('tutores', requestBody);

  return transformApiTutorIntoTutor(axiosResponse.data);
}

export async function getTutor(tutorId: string): Promise<Tutor> {
  const axiosResponse = await axios.get(`tutores/${tutorId}`);

  return transformApiTutorIntoTutor(axiosResponse.data);
}

export async function putTutor(tutorId: number, requestBody: TutorRequestBody): Promise<Tutor> {
  const axiosResponse = await axios.put(`tutores/${tutorId}`, requestBody);

  return transformApiTutorIntoTutor(axiosResponse.data);
}

export function deleteTutor(tutorId: number): AxiosPromise<void> {
  return axios.delete(`tutores/${tutorId}`);
}

export async function getBitacoraTutor(tutorId: number, params: GetBitacoraParams): Promise<ListResponse<RegistroBitacora>> {
  const response = await axios.get(`tutores/${tutorId}/bitacora`, {
    params,
  });

  const {items, pages} = response.data;

  return {
    items: items.map(transformRegistroBitacoraApiIntoRegistroBitacora),
    pages,
  };
}
