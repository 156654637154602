import { AxiosPromise } from 'axios';
import axios from '../axiosWithAuth';
import InventarioListFilters from '../../models/inventario/InventarioListFilters';
import {
  MaterialInventario,
  MaterialInventarioRequestBody,
} from '../../models/inventario/MaterialInventario';

export function getMaterialesInventario(
  filters: InventarioListFilters,
): AxiosPromise<MaterialInventario[]> {
  return axios.get('inventario', {
    params: filters,
  });
}

export function createMaterialInventario(
  requestBody: MaterialInventarioRequestBody,
): AxiosPromise<MaterialInventario> {
  return axios.post('inventario', requestBody);
}

export function updateMaterialInventario(
  id: number,
  requestBody: MaterialInventarioRequestBody,
): AxiosPromise<MaterialInventario> {
  return axios.put(`inventario/${id}`, requestBody);
}

export function deleteMaterialInventario(id: number): AxiosPromise<void> {
  return axios.delete(`inventario/${id}`);
}
