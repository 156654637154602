import React, { ReactElement, useState } from 'react';
import { Button, Col, Row, Modal, ModalBody } from 'react-bootstrap';
import { useHistory } from 'react-router';
import cogoToast from 'cogo-toast';
import { ExtendedParalelo } from '../../models/paralelo/Paralelo';
import useFormState from '../../hooks/useFormState';
import { SesionForm } from '../../models/sesiones/Sesion';
import { formatSesionPostBody, getEmptySesionForm } from '../../utils/sesion';
import { postSesion } from '../../api/sesiones/sesionesPageRequests';
import useTutoresOptions from '../../hooks/useTutoresOptions';
import SesionFormComponent from '../sesiones/SesionFormComponent';

interface ParaleloSesionesProps {
  paralelo: ExtendedParalelo;
}

const ParaleloSesiones = ({ paralelo }: ParaleloSesionesProps): ReactElement => {
  const { cantidadSesiones } = paralelo;

  const [show, setShow] = useState(false);

  const formState = useFormState(getEmptySesionForm());

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const history = useHistory();

  const tutoresOptions = useTutoresOptions(paralelo.curso.programa.id);

  const submitForm = (sesionForm: SesionForm) => {
    postSesion(formatSesionPostBody(sesionForm))
      .then((response) => {
        const createdSesionId = response.data.id;
        history.push(`/cursos/sesiones/${createdSesionId}`);
        cogoToast.success('Sesión creada');
      })
      .catch((error) => {
        cogoToast.error(error.message);
        formState.setSubmitting(false);
      });
  };

  let sesionesRealizadasText = `${cantidadSesiones}`;

  if (cantidadSesiones !== 1) {
    sesionesRealizadasText += ' sesiones realizadas';
  } else {
    sesionesRealizadasText += ' sesión realizada';
  }

  return (
    <Row>
      <Col md={6} className="d-flex">
        <h4 className="my-auto">{sesionesRealizadasText}</h4>
      </Col>
      <Col md={6} className="text-left text-md-right">
        <Button className="mt-2 mt-0" variant="success" onClick={handleShow}>
          Agregar sesión
        </Button>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Col className="text-sm-center">
              <Modal.Title>Agregar Sesion</Modal.Title>
            </Col>
          </Modal.Header>
          <ModalBody>
            <SesionFormComponent
              formState={formState}
              submitForm={submitForm}
              tutoresOptions={tutoresOptions}
              paralelo={paralelo}
              onCancel={handleClose}
            />
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  );
};

export default ParaleloSesiones;
