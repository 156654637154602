import React, { Dispatch, ReactElement, ReactText, SetStateAction, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { SelectOption } from '../../models/form/Select';
import Select from '../form/select/Select';
import TipoMovimiento from '../../models/movimientos/TipoMovimiento';
import MovimientosFilters from '../../models/movimientos/MovimientosFilters';
import Anios from '../../models/movimientos/Anios';
import Meses from '../../models/movimientos/Meses';
import { BasePresupuesto } from '../../models/presupuestos/Presupuesto';
import { getPresupuesto } from '../../api/presupuestos/presupuestosApi';
import cogoToast from 'cogo-toast';
import NewMovimientoButton from './NewMovimientoButton';
import Persona from '../../models/personas/Persona';
import CurrencyFormat from 'react-currency-format';

interface MovimientosFiltersProps {
  persona: Persona;
  presupuestoId: string;
  tiposMovimientosOptions: SelectOption<TipoMovimiento>[];
  aniosOptions: SelectOption<Anios>[];
  mesesOptions: SelectOption<Meses>[];
  movimientosFilters: MovimientosFilters;
  onFiltersChange: Dispatch<SetStateAction<MovimientosFilters>>;
}

const MovimientosFiltersComponent = ({
  tiposMovimientosOptions,
  movimientosFilters,
  aniosOptions,
  mesesOptions,
  presupuestoId,
  persona,
  onFiltersChange,
}: MovimientosFiltersProps): ReactElement => {

  const [isLoading, setLoading] = useState(true);

  const [presupuesto, setPresupuesto] = useState<BasePresupuesto>();

  useEffect(() => {
    getPresupuesto(presupuestoId, movimientosFilters)
      .then((response): void => {
        setPresupuesto(response.data);
      })
      .catch(() => {
        cogoToast.error('No se pudo cargar la información del movimiento');
      })
      .then(() => setLoading(false));
  }, [presupuestoId, setPresupuesto]);

  return (
    <>
      <Card>
        <Card.Header className="d-flex">
          <h3 className="m-0">
            {`${presupuesto?.programa.nombre}   -   ${presupuesto?.sede.nombre}`}{' '}
          </h3>
          <div className="text-right" style={{ margin: '0 0 auto auto' }}>
            <NewMovimientoButton
              persona={persona}
              movimientosFilters={movimientosFilters}
              presupuestoId={presupuestoId}
              tiposMovimientosOptions={tiposMovimientosOptions}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={4}>
              <Select
                title="Años"
                options={aniosOptions}
                onSelect={(anios) => {
                  if (anios)
                    onFiltersChange({
                      ...movimientosFilters,
                      anioId: (anios as SelectOption<Anios>).value.id,
                    });
                }}
                onClear={() => {
                  onFiltersChange({
                    ...movimientosFilters,
                    anioId: undefined,
                  });
                }}
                placeholder="Año del Movimiento"
              />
              <Col lg={4}>
                <h6 className="m-0">Saldo Actual:</h6>
                <CurrencyFormat value={presupuesto?.saldo} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                
              </Col>
            </Col>
            <Col lg={4}>
              <Select
                title="Meses"
                options={mesesOptions}
                onSelect={(meses) => {
                  if (meses)
                    onFiltersChange({
                      ...movimientosFilters,
                      mesId: (meses as SelectOption<Meses>).value.id,
                    });
                }}
                onClear={() => {
                  onFiltersChange({
                    ...movimientosFilters,
                    mesId: undefined,
                  });
                }}
                placeholder="Mes del Movimiento"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default MovimientosFiltersComponent;
