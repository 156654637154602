import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SesionBitacorasForm } from '../../models/sesiones/Sesion';
import { FormState } from '../../hooks/useFormState';
import { Input } from '../form/input/Input';
import FormButtons from '../form/form-buttons/FormButtons';

interface EditarBitacoraProfesorFormProps {
  formState: FormState<SesionBitacorasForm>;
  submitForm: (sesionBitacorasForm: SesionBitacorasForm) => void;
  onCancel: () => void;
  onDelete?: () => void;
}
const EditarBitacoraProfesorForm = ({
  formState,
  submitForm,
  onCancel,
  onDelete,
}: EditarBitacoraProfesorFormProps): ReactElement => {
  const {
    value: sesionBitacorasForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(sesionBitacorasForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const cancelDelete = () => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} md={12}>
            <Input
              title="Bitácora Profesor"
              name="bitacoraProfesor"
              placeholder="Bitácora Profesor"
              value={sesionBitacorasForm.bitacoraProfesor}
              onChangeWithName={handleFieldChange}
              disabled={isSubmitting}
              type="textarea"
            />
          </Col>
        </Row>
        <FormButtons
          submitButtonContent="Guardar bitácora"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Form>
    </>
  );
};
export default EditarBitacoraProfesorForm;
