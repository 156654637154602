import React, { ReactElement, useContext, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import LayoutContext from '../../../contexts/LayoutContext';
import SidebarItem from '../../../models/layout/SidebarItem';
import useUserHasAnyRole from '../../../hooks/useUserHasAnyRol';

interface SidebarMenuItemProps {
  sidebarItem: SidebarItem;
}

const SidebarMenuItem = ({ sidebarItem }: SidebarMenuItemProps): ReactElement => {
  const { href, name, roles } = sidebarItem;

  const { toggleSidebar } = useContext(LayoutContext);

  const userHasAnyRole = useUserHasAnyRole(roles || [] );

  const history = useHistory();

  const isActive = useMemo(() => history.location.pathname.indexOf(href) === 0, [
    history.location.pathname,
    href,
  ]);

  const linkClassNames = classNames('menu-item d-none d-md-flex', {
    active: isActive,
  });

  const mobileLinkClassNames = classNames('menu-item d-flex d-md-none', {
    active: isActive,
  });

  if (roles && roles.length > 0 && !userHasAnyRole) {
    return <></>;
  }

  return (
    <>
      <Link to={href} className={linkClassNames}>
        <li>
          <span>{name}</span>
        </li>
      </Link>
      <Link to={href} className={mobileLinkClassNames} onClick={toggleSidebar}>
        <li>
          <span>{name}</span>
        </li>
      </Link>
    </>
  );
};

export default SidebarMenuItem;
